import styled from "styled-components";

export const Base = styled("div")({
  position: "relative",
  ".ql-toolbar": {
    border: "1px solid var(--color-primary)",
    borderTopLeftRadius: "0.25rem",
    borderTopRightRadius: "0.25rem",
    backgroundColor: "var(--color-general-light-gray)",
    color: "var(--color-general-black)",
  },
  ".quill": {
    ".ql-snow .ql-picker.ql-font": {
      width: "140px",
    },
    ".ql-container.ql-snow": {
      border: "1px solid var(--color-primary)",
      borderTop: 0,
      borderBottomLeftRadius: "0.25rem",
      borderBottomRightRadius: "0.25rem",
      resize: "vertical",
      // overflow: "auto",
      height: (props) => {
        if (props.height) {
          if (typeof props.height === "string") {
            return props.height;
          } else {
            return `${props.height}px`;
          }
        } else return "7rem";
      },
    },
    ".ql-editor": {
      backgroundColor: "#ffffff",
      fontSize: 16,
      fontFamily: "Gill Sans",
      color: "var(--color-general-black)",
    },
    // TODO PLACEHOLDER CSS
    ".ql-editor.ql-blank::before": {
      content: "attr(data-placeholder)",
      fontStyle: "normal",
      fontSize: "1rem",
      fontFamily: "Gill Sans",
      color: "#c2c2c2",
    },
  },
  ".hasError": {
    ".quill .ql-toolbar": {
      borderColor: "var(--color-secondary)",
    },
    ".quill .ql-container.ql-snow": {
      borderColor: "var(--color-secondary)",
    },
  },
});
