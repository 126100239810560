import styled from "styled-components";
import { Layout, Menu, Breadcrumb } from "antd";

const { Header, Sider } = Layout;
const { SubMenu } = Menu;

export const StyledHeader = styled(Header)`
  background: #fff;
  display: flex;
  border-bottom: 1px solid #ddd;
  justify-content: space-between;
`;

export const SiderButton = styled.div`
  cursor: pointer;
  font-size: 20px;
  padding: 0 25px;
`;

export const NameLink = styled("a")({
  color: "var(--color-general-black)",
  fontSize: 14,
});

export const MenuContainer = styled("div")({
  a: {
    fontSize: 14,
  },
});

export const NewLayout = styled(Layout)`
  height: 100vh;
  overflow: hidden;
`;

export const WrapperLayout = styled("div")({
  padding: "24px 32px",
  marginBottom: 0,
});

export const LogoTemp = styled("div")({
  width: 32,
  height: 20,
  backgroundColor: "white",
});

export const LogoWrapper = styled.div`
  height: 64px;
  background: #22334f;
  padding: 15px 0;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
`;

export const StyledLogo = styled.img`
  // width: 157px;
`;

export const StyledSider = styled(Sider)`
  .ant-layout-sider-children > .ant-menu {
    .ant-menu-submenu,
    .ant-menu-item {
      color: #dbdbdb;
      font-size: 14px;
      font-weight: 400;

      &.ant-menu-item-selected {
        background-color: var(--color-primary);
      }
      .cust-icon {
        opacity: 0.75;
      }
    }
    .ant-menu-item:hover {
      .cust-icon {
        opacity: 1;
      }
    }
  }
`;

export const SubMenuWrap = styled(SubMenu)`
  ul li {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: 50px !important;
  }
`;

export const Wrapper = styled("div")({
  background: "#fff",
  padding: "16px 25px",
});

export const StyledBreadcrumb = styled(Breadcrumb)`
  background: #fff;
  display: flex;
  padding: 16px 0;
  span:last-child a {
    pointer-events: none;
  }
`;

export const LabelTitle = styled("label")({
  fontSize: 20,
  fontWeight: 600,
  color: "var(--color-general-black)",
});
