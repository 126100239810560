export const dummyData = {
  data: {
    getNotificationById: {
      created_at: "2022-10-05T03:16:00.953Z",
      created_by: "JM Baldonado",
      cta_button_link: "",
      cta_button_name: "Book Now",
      description:
        '<p><span style="color: rgb(123, 136, 152);">Lorem ipsum dolor sit amet, </span></p>',
      end_date: "10/08/2022",
      end_time: "7:00 AM",
      frequency_details: {
        end_date: "10/18/2022",
        end_time: "6:00 AM",
        ends: "on",
        months_on: "",
        recur_every: 1,
        start_date: "10/18/2022",
        start_time: "12:00 AM",
        weeks_on: ["monday"],
        __typename: "FrequencyDetailsOutput",
      },
      id: "1b588349-b963-4815-ba68-0642f21b5d7c",
      is_deleted: false,
      name: "test",
      notification_frequency: "WEEKLY",
      notification_type: "PROMOTION",
      participating_hotels: [
        {
          brand: {
            id: "0718d095-71ce-430d-8bf9-2a20ea96fc79",
            name: "Fili Urban Resort",
            __typename: "Brand",
          },
          hotel: {
            id: "72858244-6ce7-4925-a139-807e9b770a88",
            name: "Fili Urban Resort Cebu",
            __typename: "Hotel",
          },
          id: "a17432fc-10d2-4d49-aaa0-adb5d4396201",
          notification_id: "1b588349-b963-4815-ba68-0642f21b5d7c",
          __typename: "ParticipatingHotel",
        },
      ],
      photo_url:
        "https://018-rhr-uat-website-bucket.s3.ap-southeast-1.amazonaws.com/1664938421304-Acai.jpg",
      recipients: [
        {
          id: "785d34d1-b706-42a9-972a-098b23f9da79",
          recipient_type: "HOTEL_BOOKING",
          value: '{"name":"hotel_booking","value":["Completed"]}',
          __typename: "Recipient",
        },
      ],
      release_schedules: [
        {
          before_after_value: 2,
          between_value: null,
          datetime_value: null,
          id: "5471f857-6505-44de-bc62-5ddb461e9976",
          schedule_type: "BEFORE",
          __typename: "ReleaseSchedule",
        },
        {
          before_after_value: 1,
          between_value: null,
          datetime_value: null,
          id: "54778aad-a59d-45ea-8204-387d0b7a407c",
          schedule_type: "AFTER",
          __typename: "ReleaseSchedule",
        },
        {
          before_after_value: null,
          between_value: null,
          datetime_value: "1665533400000",
          id: "54778aad-a59d-45ea-8204-387d0b7a407c",
          schedule_type: "DATETIME",
          __typename: "ReleaseSchedule",
        },
        {
          before_after_value: null,
          between_value: "11:16:00",
          datetime_value: null,
          id: "f8b26e23-3b6d-48f7-b27e-c0790459a15c",
          schedule_type: "BETWEEN",
          __typename: "ReleaseSchedule",
        },
      ],
      sent_at: null,
      sent_by: null,
      start_date: "10/06/2022",
      start_time: "6:00 AM",
      status: "DRAFT",
      updated_at: "2022-10-05T03:16:00.944Z",
      updated_by: "JM Baldonado",
      __typename: "Notification",
    },
  },
};

export const recipientType = {
  HOTEL_BOOKING: "By Hotel Booking",
  STATUS: "By Status",
  EMAIL: "By Email",
  BIRTHDAY: "By Birthday",
  LOCATION: "By Location",
  ANNIVERSARY: "By Anniversary",
  GENDER: "By Gender",
  PLATFORM: "By Platform",
};
