import * as yup from "yup";
import { ALPHANUM_PATTERN, URL_PATTERN } from "../../../../../utilities/regex";

const imageSchema = yup
  .object()
  .shape({
    status: yup.string().matches(/done/, { message: "File is still uploading" }),
    url: yup.string(),
  })
  .nullable();

export const validationSchema = yup.object().shape({
  photo: imageSchema.required("This is a required field."),
  notification_type: yup.string().trim().required("This is a required field."),
  name: yup
    .string()
    .matches(ALPHANUM_PATTERN, "Invalid format.")
    .trim()
    .required("This is a required field.")
    .min(2, "Oops! enter at least 2 characters."),
  description: yup
    .string()
    .nullable()
    .required("This is a required field.")
    .min(9, "Oops! enter at least 2 characters."),
  participating_hotels: yup
    .array()
    .required("This is a required field.")
    .min(1, "This is a required field."),
  start_date: yup.string().nullable().required("This is a required field."),
  start_time: yup.string().nullable().required("This is a required field."),
  end_date: yup.string().nullable().required("This is a required field."),
  end_time: yup.string().nullable().required("This is a required field."),
  cta_button_name: yup
    .string()
    .matches(ALPHANUM_PATTERN, "Invalid format.")
    .trim()
    .required("This is a required field.")
    .min(1, "Oops! enter at least 1 characters."),
  cta_button_link: yup
    .string()
    .matches(URL_PATTERN, "Invalid format.")
    .when("cta_button_name", {
      is: (value) => value !== "Book Now",
      then: yup
        .string()
        .matches(URL_PATTERN, "Invalid format.")
        .required("This is a required field."),
    }),
  notification_frequency: yup.string().required("This is a required field."),
  frequency_details: yup
    .object()
    .when("notification_frequency", {
      is: (value) => value === "DAILY",
      then: yup.object().shape({
        start_date: yup.string().nullable().trim().required("This is a required field."),
        start_time: yup.string().nullable().trim().required("This is a required field."),
        end_date: yup.string().nullable().trim().required("This is a required field."),
        end_time: yup.string().nullable().trim().required("This is a required field."),
      }),
    })
    .when("notification_frequency", {
      is: (value) => value === "WEEKLY",
      then: yup.object().shape({
        ends: yup.string().nullable().trim().required("This is a required field."),
        start_date: yup.string().nullable().trim().required("This is a required field."),
        start_time: yup.string().nullable().trim().required("This is a required field."),
        end_date: yup.string().when("ends", {
          is: (value) => value === "on" || value === "after",
          then: yup.string().trim().required("This is a required field."),
        }),
        end_time: yup.string().nullable().trim().required("This is a required field."),
        recur_every: yup.string().nullable().trim().required("This is a required field."),
        weeks_on: yup.array().min(1, "This is a required field."),
      }),
    })
    .when("notification_frequency", {
      is: (value) => value === "MONTHLY",
      then: yup.object().shape({
        ends: yup.string().nullable().trim().required("This is a required field."),
        start_date: yup.string().nullable().trim().required("This is a required field."),
        start_time: yup.string().nullable().trim().required("This is a required field."),
        end_date: yup
          .string()
          .nullable()
          .when("ends", {
            is: (value) => value === "on" || value === "after",
            then: yup.string().nullable().trim().required("This is a required field."),
          }),
        end_time: yup.string().nullable().trim().required("This is a required field."),
        recur_every: yup.string().nullable().trim().required("This is a required field."),
        months_on: yup.string().nullable().trim().required("This is a required field."),
      }),
    }),
  recipients_by: yup
    .array()
    .required("This is a required field.")
    .min(1, "This is a required field."),
  release_schedule: yup.object().shape({
    date_time: yup.array().of(
      yup
        .object()
        .shape({
          date: yup.string().nullable().trim().required("This is a required field."),
          time: yup.string().nullable().trim().required("This is a required field."),
        })
        .nullable()
    ),
    hours_before: yup
      .array()
      .of(yup.string().nullable().trim().required("This is a required field.")),
    hours_after: yup
      .array()
      .of(yup.string().nullable().trim().required("This is a required field.")),
    time_between: yup
      .array()
      .of(yup.string().nullable().trim().required("This is a required field.")),
  }),
});
