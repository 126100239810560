import { gql } from "@apollo/client";

export const GET_PROMO_AND_PACKAGE = gql`
  query GetPromoAndPackageById($id: String!) {
    getPromoAndPackageById(id: $id) {
      id
      title
      photo
      short_description
      redirect_link
      redirect_link_status
      status
      created_at
      created_by
      updated_at
      updated_by
      deployed_at
      deployed_by
      order
    }
  }
`;

export const CREATE_PROMO_AND_PACKAGE = gql`
  mutation CreatePromoAndPackage($input: CreatePromoAndPackageInput!) {
    createPromoAndPackage(input: $input) {
      id
      title
      photo
      short_description
      redirect_link
      status
      created_at
      created_by
      updated_at
      updated_by
      deployed_at
      deployed_by
    }
  }
`;

export const EDIT_PROMO_AND_PACKAGE = gql`
  mutation EditPromoAndPackage($editPromoAndPackageInput: EditPromoAndPackageInput!) {
    editPromoAndPackage(editPromoAndPackageInput: $editPromoAndPackageInput) {
      id
      title
      photo
      short_description
      redirect_link
      updated_at
      updated_by
    }
  }
`;

export const GET_PROMOTIONS = gql`
  query getPromotion {
    getPromotions(search: "") {
      id
      title
      photo_url
      description
      start_date
      start_time
      end_date
      end_time
      cta_button_name
      cta_button_link
      created_at
      created_by
      updated_at
      updated_by
      deployed_at
      deployed_by
      status
      promotions_participating_hotels {
        id
        promotion_id
        brand {
          id
          name
        }
        hotel {
          id
          name
        }
      }
    }
  }
`;

export const GET_ALL_PROMOTION_TITLES = gql`
  query getPromotion {
    getPromotions(search: "") {
      title
    }
  }
`;

export const GET_PROMOTION = gql`
  query getPromotionById($id: String!) {
    getPromotionById(id: $id) {
      id
      title
      photo_url
      description
      start_date
      start_time
      end_date
      end_time
      cta_button_name
      cta_button_link
      status
      created_at
      created_by
      updated_at
      updated_by
      deployed_at
      deployed_by
      is_featured
      promotions_participating_hotels {
        id
        promotion_id
        brand {
          id
          name
        }
        hotel {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_UPDATE_PROMOTION = gql`
  mutation createOrUpdatePromotion($createOrUpdateNotificationInput: CreatePromotionInput!) {
    createOrUpdatePromotion(createOrUpdateNotificationInput: $createOrUpdateNotificationInput) {
      id
      title
      photo_url
      description
      start_date
      start_time
      end_date
      end_time
      cta_button_name
      cta_button_link
      status
      is_featured
      promotions_participating_hotels {
        id
        promotion_id
        brand {
          id
          name
        }
        hotel {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_PROMOTION_STATUS = gql`
  mutation updatePromotionStatus($Input: UpdatePromotionStatusInput!) {
    updatePromotionStatus(Input: $Input) {
      id
      title
      photo_url
      description
      start_date
      start_time
      end_date
      end_time
      cta_button_name
      cta_button_link
      status
    }
  }
`;

export const GET_BRANDS = gql`
  query getBrands {
    getBrands(search: "") {
      id
      name
      status
      hotels {
        id
        name
      }
    }
  }
`;

export const GET_BRANDS_ORDER_BY_CATEGORY = gql`
  query getBrandsOrderByCategory {
    getBrandsOrderByCategory(search: "") {
      id
      name
      status
      hotels {
        id
        name
      }
      category {
        id
        name
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getNotifications {
    getNotifications(search: "") {
      id
      name
      photo_url
      description
      start_date
      start_time
      end_date
      end_time
      cta_button_name
      cta_button_link
      notification_frequency
      frequency_details {
        start_date
        start_time
        end_date
        end_time
        recur_every
        weeks_on
        months_on
        ends
      }
      status
      notification_type
      created_at
      created_by
      updated_at
      updated_by
      sent_at
      sent_by
      is_deleted
      participating_hotels {
        id
        notification_id
        brand {
          id
          name
        }
        hotel {
          id
          name
        }
      }
      release_schedules {
        id
        schedule_type
        datetime_value
        before_after_value
        between_value
      }
      recipients {
        id
        recipient_type
        value
      }
    }
  }
`;

export const GET_NOTIFICATION_BY_ID = gql`
  query getNotificationById($id: String!) {
    getNotificationById(id: $id) {
      id
      name
      photo_url
      description
      start_date
      start_time
      end_date
      end_time
      cta_button_name
      cta_button_link
      notification_frequency
      frequency_details {
        start_date
        start_time
        end_date
        end_time
        recur_every
        weeks_on
        months_on
        ends
      }
      status
      notification_type
      created_at
      created_by
      updated_at
      updated_by
      sent_at
      sent_by
      is_deleted
      participating_hotels {
        id
        notification_id
        brand {
          id
          name
        }
        hotel {
          id
          name
        }
      }
      release_schedules {
        id
        schedule_type
        datetime_value
        before_after_value
        between_value
      }
      recipients {
        id
        recipient_type
        value
      }
    }
  }
`;

export const CREATE_UPDATE_NOTIFICATIONS = gql`
  mutation createOrUpdateNotification($createOrUpdateNotificationInput: CreateNotificationInput!) {
    createOrUpdateNotification(createOrUpdateNotificationInput: $createOrUpdateNotificationInput) {
      id
      name
      photo_url
      description
      start_date
      start_time
      end_date
      end_time
      cta_button_name
      cta_button_link
      notification_frequency
      frequency_details {
        start_date
        start_time
        end_date
        end_time
        recur_every
        weeks_on
        months_on
        ends
      }
      status
      notification_type
      created_at
      created_by
      updated_at
      updated_by
      sent_at
      sent_by
      is_deleted
      participating_hotels {
        id
        notification_id
        brand {
          id
          name
        }
        hotel {
          id
          name
        }
      }
      release_schedules {
        id
        schedule_type
        datetime_value
        before_after_value
        between_value
      }
      recipients {
        id
        recipient_type
        value
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_STATUS = gql`
  mutation updateNotificationStatus($Input: UpdateNotificationStatusInput!) {
    updateNotificationStatus(Input: $Input) {
      id
      name
      status
    }
  }
`;

export const GET_ALL_CUSTOMER_USERS = gql`
  query getAllCustomerUsers {
    getCustomerUsers {
      id
      guest_id
      title
      first_name
      last_name
      suffix
      mobile_number
      country_code
      gender
      email
      nationality
      birth_date
      country
      address
      gorewards_loyalty_card_number
      anniversary
      preferences
      id_type
      id_url
      offers
      third_party_products
      app_communications
    }
  }
`;

export const GET_PROMOTIONS_FOR_NOTIFICATIONS = gql`
  query getPromotions {
    getPromotions(search: "") {
      id
      title
      status
    }
  }
`;
