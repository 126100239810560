import styled from "styled-components";
import { Row } from "antd";
import CustomButton from "@/components/CustomButton";

export const Span = styled.span`
  color: ${(props) => (props.primary ? "var(--color-general-black)" : "var(--color-secondary)")};
  margin-bottom: 4px;
  line-height: 25px;
  display: block;
  a {
    color: ${(props) => (props.primary ? "var(--color-general-black)" : "var(--color-secondary)")};
  }
`;

export const SpanBY = styled.span`
  color: var(--color-general-blue-gray);
  display: block;
`;

export const Subtitle = styled.p`
  color: var(--color-primary);
  margin-bottom: 4px;
  line-height: 25px;
`;

export const Subtext = styled.span`
  display: ${(props) => (props.show ? "block" : "none")};
  color: var(--color-general-blue-gray);
`;

export const Sectiontitle = styled.h2`
  color: var(--color-general-black);
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
`;

export const SectionWrap = styled.section`
  background: #fff;
  padding: 28px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 0.6rem;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
`;

export const BorderTop = styled.div`
  width: 100%;
  height: 2px;
  background-color: rgba(119, 145, 179, 0.6);
`;

export const ActionButtonWrap = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

export const PromoPageWrap = styled("div")`
  position: relative;
`;

export const ActionButtons = styled(CustomButton)`
  display: ${(props) => (props.show ? "inline-block" : "none")};
  margin-right: 15px;
  height: 50px;
  border-radius: 3px;
  color: ${(props) => (props.type === "primary" ? "#fff" : "#d7272d")};

  svg path {
    fill: ${(props) => (props.type === "primary" ? "#fff" : "#d7272d")};
  }
`;

export const ActionButtonsContainer = styled(Row)`
  opacity: ${(props) => (props.show ? "1" : "0")};
  position: absolute;
  top: -94px;
  left: -32px;
  width: calc(100% + 64px);
  display: block;
  background-color: "transparent";
  padding: 0px 30px;
  padding-bottom: 20px;
`;
