export const activeColumns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Role",
    dataIndex: "access_role",
  },
  {
    title: "Company",
    dataIndex: "company",
  },
  {
    title: "Last Logged in",
    dataIndex: "last_logged_in",
  },
  {
    title: "Last Edited by",
    dataIndex: "updated_at",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

export const dataTypes = [
  {
    label: "Mobile App User",
    value: "Mobile App",
  },
  {
    label: "Website Guest User",
    value: "Website",
  },
  {
    label: "Anonymous Guest",
    value: "Anonymous",
  },
];

// export const cmsUserDummyData = [
//     {
//       name: "John Reyes",
//       role:"Administrator",
//       company:"RHR",
//       id: "97d1fa72-5af9-489a-ad95-39a4c60fd2ce",
//       mobileNumber:"09228371993",
//       email:"fgeovic@gmail.com",
//       dateOfBirth:"10/02/1991",
//       nationality:"Filipino(PH)",
//       country:"Philippines",
//       stateProvince:"Metro Manila",
//       city:"Quezon City",
//       postal:"0291",
//       annivDate:"02/02/2004",
//       goRewardNo:"7770 221589 133",
//       idType:"Passport",
//       uploadId:"passport.png",
//       lastLoggedIn: "2021-10-31 23:12:00",
//       lastEditedBy: "John Reyes",
//       status: "Active",
//       action: "",
//     },
//     {

//       name: "Kristine Buenaventura",
//       role:"Content Creator",
//       company:"RHR",
//       id: "97d1fa75-5af9-489a-ad95-39a4c60fd2ce",
//       mobileNumber:"09278371912",
//       email:"avargas@gmail.com",
//       dateOfBirth:"11/20/1996",
//       nationality:"American(US)",
//       country:"United States",
//       stateProvince:"California",
//       city:"Los Angeles",
//       postal:"1921",
//       annivDate:"01/19/2008",
//       goRewardNo:"5555 4416899 220",
//       idType:"Drivers License",
//       uploadId:"license.png",
//       lastLoggedIn: "2021-10-31 23:12:00",
//       lastEditedBy: "Michael Santos",
//       status: "Active",
//       action: "",
//     },
//     {
//       name: "Michael Santos",
//       role:"Partner",
//       company:"RHR",
//       id: "97d1fa78-5af9-489a-ad95-39a4c60fd2ce",
//       mobileNumber:"09218371143",
//       email:"jamesvilleza@gmail.com",
//       dateOfBirth:"02/17/1994",
//       nationality:"Filipino(PH)",
//       country:"Philippines",
//       stateProvince:"Cebu",
//       city:"Cebu City",
//       postal:"0820",
//       annivDate:"09/22/2002",
//       goRewardNo:"3311 909599 090",
//       idType:"Drivers License",
//       uploadId:"license.png",
//       platform: "Mobile",
//       lastLoggedIn: "2021-10-31 23:12:00",
//       lastEditedBy: "Kristine Buenaventura",
//       status: "Active",
//       action: "",
//     },
//     {
//       name: "Angelo Melecia",
//       role:"Analyst",
//       company:"RHR",
//       id: "97d2fa75-5af9-489a-ad95-39a4c60fd2ce",
//       mobileNumber:"09168330993",
//       email:"myambao@gmail.com",
//       dateOfBirth:"05/20/1987",
//       nationality:"Chinese(CN)",
//       country:"China",
//       stateProvince:"Fujian",
//       city:"Xiamen",
//       postal:"3000",
//       annivDate:"05/21/2006",
//       platform: "Website",
//       goRewardNo:"1100 331029 230",
//       idType:"Passport",
//       uploadId:"passport.png",
//       lastLoggedIn: "2021-10-31 23:12:00",
//       lastEditedBy: "John Reyes",
//       status: "Active",
//       action: "",
//     },
//     {
//       name: "Ross Malaque",
//       role:"Advertiser",
//       company:"RHR",
//       id: "97d3fa75-5af9-489a-ad95-39a4c60fd2ce",
//       mobileNumber:"09178371125",
//       email:"zylagan@gmail.com",
//       dateOfBirth:"03/16/1993",
//       nationality:"Swedish(SE)",
//       country:"Sweden",
//       stateProvince:"Bohuslän",
//       city:"Kungälv",
//       postal:"1120",
//       annivDate:"11/02/2014",
//       goRewardNo:"2200 441599 140",
//       idType:"Drivers License",
//       uploadId:"license.png",
//       lastLoggedIn: "2021-10-31 23:12:00",
//       lastEditedBy: "Kristine Buenaventura",
//       status: "Active",
//       action: "",
//     },
//     {
//       name: "Louie Abanilla",
//       role:"Content Creator",
//       company:"RLC",
//       id: "97d4fa75-5af9-489a-ad95-39a4c60fd2ce",
//       mobileNumber:"09278382793",
//       email:"rbarcelo@gmail.com",
//       dateOfBirth:"04/13/1986",
//       nationality:"Russian(RU)",
//       country:"Russia",
//       stateProvince:"Adygeya",
//       city:"Maykop Federal City",
//       postal:"8880",
//       goRewardNo:"5910 291029 222",
//       annivDate:"02/14/2009",
//       idType:"Passport",
//       uploadId:"passport.png",
//       lastLoggedIn: "2021-10-31 23:12:00",
//       lastEditedBy: "John Reyes",
//       status: "For Activation",
//       action: "",
//     },
//     {
//       name: "John Mark Dela Cruz",
//       role:"-",
//       company:"RHR",
//       id: "97d5fa75-5af9-489a-ad95-39a4c60fd2ce",
//       mobileNumber:"09168725693",
//       email:"dparafina@gmail.com",
//       dateOfBirth:"08/12/1995",
//       nationality:"Swedish(SE)",
//       country:"Sweden",
//       stateProvince:"Öland",
//       city:"Borgholm",
//       postal:"8892",
//       annivDate:"03/10/2003",
//       goRewardNo:"9900 776629 210",
//       idType:"Passport",
//       uploadId:"passport.png",
//       platform: "Website",
//       lastLoggedIn: "2021-10-31 23:12:00",
//       lastEditedBy: "John Reyes",
//       status: "Active",
//       action: "",
//     },
//     {
//       name: "Ma. Sarah De Los Reyes",
//       role:"Administrator",
//       company:"RLC",
//       id: "97d6fa75-5af9-489a-ad95-39a4c60fd2ce",
//       mobileNumber:"09218261893",
//       email:"ksamaniego@gmail.com",
//       dateOfBirth:"03/22/1990",
//       nationality:"American(US)",
//       country:"United States",
//       stateProvince:"Florida",
//       city:"Orlando",
//       postal:"5522",
//       annivDate:"12/14/2001",
//       goRewardNo:"6671 331929 310",
//       idType:"Drivers License",
//       uploadId:"license.png",
//       lastLoggedIn: "2021-10-31 23:12:00",
//       lastEditedBy: "John Reyes",
//       status: "Inactive",
//       action: "",
//     },
//     {
//       name: "Jean Jennifer Rodrigo",
//       role:"-",
//       company:"JG Summit",
//       id: "97d6fa75-5af0-489a-ad95-39a4c60fd2ce",
//       mobileNumber:"09228329103",
//       email:"destopace@gmail.com",
//       dateOfBirth:"01/23/1988",
//       nationality:"Chinese(CN)",
//       country:"China",
//       stateProvince:"Guizhou",
//       city:"Anshun",
//       postal:"6633",
//       annivDate:"08/13/2012",
//       idType:"Drivers License",
//       uploadId:"license.png",
//       platform: "Website",
//       lastLoggedIn: "2021-10-31 23:12:00",
//       lastEditedBy: "Michael Santos",
//       status: "Inactive",
//       action: "",
//     },
//     {
//       name: "Christine Del Rosario",
//       role:"-",
//       company:"JG Summit",
//       id: "97d6fa75-51f9-489a-ad95-39a4c60fd2ce",
//       mobileNumber:"09261729993",
//       email:"acayanan@gmail.com",
//       dateOfBirth:"09/27/1998",
//       nationality:"Filipino(PH)",
//       country:"Philippines",
//       stateProvince:"Ilocos Sur",
//       city:"Vigan City",
//       postal:"4510",
//       annivDate:"07/10/2018",
//       goRewardNo:"0001 123029 230",
//       idType:"Passport",
//       uploadId:"passport.png",
//       lastLoggedIn: "2021-10-31 23:12:00",
//       lastEditedBy: "Kristine Buenaventura",
//       status: "For Activation",
//       action: "",
//     },
//   ];
