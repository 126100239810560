import React from "react";
import upperFirst from "lodash/upperFirst";
import { Modal, Row, Col, Button } from "antd";

import { WrapperModalComponent, ModalHeader } from "./styled";
import { sectionList } from "./constant";

const ModalComponent = ({ visible, onChange, push, onCancel }) => (
  <Modal
    visible={visible}
    footer={false}
    width={1000}
    bodyStyle={{ paddingTop: 40 }}
    onCancel={onCancel}
  >
    <ModalHeader>
      <h4>Add A Section</h4>
      <h5>Select your desired section</h5>
    </ModalHeader>
    <Row gutter={[24, 24]}>
      {sectionList.map((res, index) => (
        <Col span={6}>
          <Button
            style={{ height: "inherit" }}
            type={"text"}
            onClick={() =>
              onChange(
                {
                  type: res.type,
                  fields: res.fields,
                },
                push
              )
            }
          >
            <div style={{ width: "100%", wordBreak: "break-all" }}>
              <WrapperModalComponent>
                <img src={res.image} alt="" />
              </WrapperModalComponent>
              <p style={{ color: "var(--color-tertiary)", fontSize: 14 }}>
                {upperFirst(res.label)}
              </p>
            </div>
          </Button>
        </Col>
      ))}
    </Row>
  </Modal>
);
export default ModalComponent;
