import { Col, Collapse, Divider, Row,  } from "antd";
import { FieldArray } from "formik";
import { FormItem, Input, Select } from "formik-antd";

import Richtext2 from "@/components/Richtext2";
import {
  StyledPanel,
} from "../styled";

interface Props {
  formikBag: any;
}

const DOMPurify = require('dompurify')(window);

const { Option } = Select;

const FooterInfo = ({ formikBag }: Props) => {
  const { values, errors, setFieldValue } = formikBag;

  const renderItems = ({ event, values }) => {
    return (
      <>
        <Col span={24}>
            <Divider dashed={true} />
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIconPosition="right"
              ghost
            >
              <StyledPanel header="App Landing Page Footnote" key="1">
                <Row>
                  <Col span={24}>
                    <FormItem
                      label="Add app footnote title"
                      name={`footnote_title`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        name={`footnote_title`}
                        placeholder="Enter footnote title"
                      />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem
                      label="Add app footnote"
                      name={`footnote_body`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Richtext2
                        value={DOMPurify.sanitize(values.footnote_body)}
                        placeholder="Enter contents for you footnote"
                        onChange={(e) => {
                          if (e === "<p><br></p>") {
                            setFieldValue(`footnote_body`, "");
                          } else setFieldValue(`footnote_body`, e);
                        }}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </StyledPanel>
            </Collapse>
            <Divider dashed={true} />
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIconPosition="right"
              ghost
            >
              <StyledPanel header="RHR Description (Website)" key="1">
                <Row>
                  <Col span={24}>
                    <FormItem
                      label="Add RHR description"
                      name={`description`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      {/* <Input name={`description`}
                        placeholder="Enter description">
                        <textarea
                          defaultValue={values.description}
                          placeholder="Enter description"
                          style={{ 'width':'100%' }}
                        />
                      </Input> */}
                      <Input.TextArea
                        name={`description`}
                        placeholder="Enter description"
                      />
                    </FormItem>
                  </Col>
                </Row>
              </StyledPanel>
            </Collapse>
        </Col>
      </>
    );
  };

  return (
    <Row gutter={[20, 20]}>
      <FieldArray name="footer_info" render={(event) => renderItems({ event, values })} />
    </Row>
  );
};

export default FooterInfo;
