import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { message } from "antd";

import CustomDashboardList from "@/components/CustomDashboardList5";
import CustomModal from "@/components/CustomModal";

import { actions, activeColumns } from "./constants";
import { GET_PROMOTIONS, UPDATE_PROMOTION_STATUS } from "../../../graphql";
import { STATUS } from "../../../../../utilities/enums";

const PromotionsList: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const [isPublish, setIsPublish] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [bodyMessageSuccess, setBodyMessageSuccess] = useState("");
  const [statusStr, setStatusStr] = useState("");
  const [okTxt, setOkTxt] = useState("OK");
  const [promoId, setPromoId] = useState("");
  const [promosList, setPromosList] = useState<any>([]);
  const [modifyStatus] = useMutation(UPDATE_PROMOTION_STATUS);

  const { loading, refetch, data } = useQuery(GET_PROMOTIONS);

  useEffect(() => {
    if (data) {
      refetch();
      setPromosList(data.getPromotions);
    }
  }, [data, refetch]);

  const handleAddBtn = (): void => {
    history.push(`${match.path}/add-promotion`);
  };

  const handleSearch = (e): void => {
    const value = e.toLowerCase();

    const newData = data.getPromotions.filter((item) => {
      const title = item.title.toLowerCase();
      const updatedBy = item.updated_by.toLowerCase();
      const createdBy = item.created_by.toLowerCase();
      const promotionStatus = item.status.toLowerCase();

      return (
        title.includes(value) ||
        updatedBy.includes(value) ||
        createdBy.includes(value) ||
        promotionStatus.includes(value)
      );
    });
    setPromosList(newData);
  };

  const handleSelectedRow = (id): void => {
    history.push(`${match.path}/view-promotion/${id}`, { pageId: id });
  };

  const constructVariables = (promotionId: string, status: STATUS): object => {
    return {
      promotion_id: promotionId,
      status: status,
    };
  };

  const updateStatus = async (id: string, status: STATUS): Promise<void> => {
    const input = constructVariables(id, status);

    try {
      const { data: res } = await modifyStatus({
        variables: {
          Input: input,
        },
      });

      if (res.updatePromotionStatus) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const checkStatusStrOkModal = async (id, statusStr): Promise<void> => {
    if (statusStr === "published") {
      await updateStatus(id, STATUS.PUBLISHED);
      setBodyMessageSuccess("This promotion was successfully published.");
    } else if (statusStr === "archived") {
      await updateStatus(id, STATUS.ARCHIVED);
      setBodyMessageSuccess("This promotion was successfully archived.");
    } else if (statusStr === "deleted") {
      await updateStatus(id, STATUS.DELETED);
      setBodyMessageSuccess("This promo was successfully deleted.");
    }
    setShowModal(false);
    setShowModalSuccess(true);
  };

  const handleArchived = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By archiving this promotion, it will not be visible in the mobile app when deployed."
    );
    setStatusStr("archived");
    setOkTxt("Archive");
    setIsPublish(false);
    setPromoId(id);
  };

  const handleDelete = (id): void => {
    setShowModal(true);
    setTitleMessage("Delete Promotion?");
    setBodyMessage("Are you sure you want to delete this promo?");
    setStatusStr("deleted");
    setOkTxt("Delete");
    setIsPublish(false);
    setPromoId(id);
  };

  const handlePublish = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By publishing this promotion, all information and documents uploaded in this promotion will be visible in the mobile app when deployed."
    );
    setStatusStr("published");
    setOkTxt("Publish");
    setIsPublish(true);
    setPromoId(id);
  };

  const handleMenu = (menuItem: string, id: string): void => {
    if (menuItem === "Edit") {
      history.push(`${match.path}/edit-promotion/${id}`, { pageId: id });
    }
    if (menuItem === "View") {
      history.push(`${match.path}/view-promotion/${id}`, { pageId: id });
    }
    if (menuItem === "Publish") {
      handlePublish(id);
    }
    if (menuItem === "Delete") {
      handleDelete(id);
    }
    if (menuItem === "Archive") {
      handleArchived(id);
    }
  };

  return (
    <>
      <CustomDashboardList
        actions={actions}
        data={promosList}
        loading={loading}
        handleAddBtn={handleAddBtn}
        handleMenu={handleMenu}
        handleSearch={handleSearch}
        handleSelectedRow={handleSelectedRow}
        headerColumns={activeColumns}
        searchPlaceholder="Search Promotions"
      />

      {/* Confirmation modal */}
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showModal}
        onOk={() => checkStatusStrOkModal(promoId, statusStr)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />

      {/* Success Modal */}
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon
        titleMessage="Success!"
        visible={showModalSuccess}
        onOk={() => setShowModalSuccess(false)}
        onCancel={() => setShowModalSuccess(false)}
        message={bodyMessageSuccess}
        okText={isPublish ? "Great!" : "OK"}
      />
    </>
  );
};

export default PromotionsList;
