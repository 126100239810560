const initialReducer = {
  STORE_IS_DEPLOYED: (state, action) => {
    return {
      ...state,
      isDeployed: action.payload,
    };
  },
};

const initReducer: any = {
  ...initialReducer,
};

const mainReducer = (state: any, action: any) => {
  return initReducer[action.type](state, action);
};

export default mainReducer;
