import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ErrorMessage } from "formik";
import { Col, Divider, Radio, Row, Tabs, TreeSelect } from "antd";
import {
  DatePicker,
  Form,
  FormItem,
  Input,
  Select,
  TimePicker,
  TreeSelect as Treeselect,
} from "formik-antd";
import { PlusCircleFilled } from "@ant-design/icons";
import { useQuery, useLazyQuery } from "@apollo/client";
import moment from "moment";
import { has } from "lodash";
import ContentContainer from "@/components/ContentContainer";
import CustomButton from "@/components/CustomButton";
import CustomModal from "@/components/CustomModal";
import CategoriesContainer from "@/components/CategoriesContainer";
import Richtext2 from "@/components/Richtext2";
import TabContainer from "@/components/TabContainer";
import { snakeCase } from "lodash";
import { GET_BRANDS, GET_PROMOTIONS_FOR_NOTIFICATIONS, GET_PROMOTION } from "../../../graphql";
import { DeleteIconAlt } from "../../../../../utilities/icons";

import {
  AddSchedule,
  DeleteButton,
  ErrorMessageContainer,
  HeaderSubtitle,
  InstructionLabel,
  StyledButton,
  StyledUploadImage,
  StyledTabs,
  Subheader,
} from "./styled";
import RecurrenceContainer from "@/components/RecurrenceContainer";

const DOMPurify = require("dompurify")(window);

interface Props {
  formikBag: any;
  loader?: boolean;
}

const { Option } = Select;
const { TabPane } = Tabs;
const { SHOW_PARENT } = TreeSelect;
const { TreeNode } = Treeselect;

const Forms: React.FC<Props> = ({ formikBag, loader }) => {
  const { values, submitForm, errors, setFieldValue } = formikBag;
  const { date_time, hours_before, hours_after, time_between } = values.release_schedule;
  const { promotion_id } = values;
  const { pageId } = useParams();
  const history = useHistory();
  const { data: brandsQuery } = useQuery(GET_BRANDS);
  const { data: promotionsQuery } = useQuery(GET_PROMOTIONS_FOR_NOTIFICATIONS);
  const timeFormat = "h:mm a";

  const [brandsData, setBrandsData] = useState<any[]>([]);
  const [isModalVisibleLeaving, setIsModalVisibleLeaving] = useState<boolean>(false);
  const [isOtherButton, setIsOtherButton] = useState<boolean>(false);
  const [nameCount, setNameCount] = useState<number>(0);
  const [participatingHotels, setParticipatingHotels] = useState<any>([]);
  const [promotionsData, setPromotionsData] = useState<any>([]);
  const [recipientCategories, setRecipientCategories] = useState<any[]>([]);
  const [showRecipientCategories, setShowRecipientCategories] = useState<boolean>(false);

  const startDateValue = values.start_date ? moment(values.start_date) : undefined;
  const startTimeValue = values.start_time ? moment(values.start_time, "LT") : undefined;
  const endDateValue = values.end_date ? moment(values.end_date) : undefined;
  const endTimeValue = values.end_time ? moment(values.end_time, "LT") : undefined;
  const [getLazyQuery, { loading, refetch, data }] = useLazyQuery(GET_PROMOTION, {
    variables: {
      id: promotion_id,
    },
  });

  const recipientsList = [
    "All",
    "By Hotel Booking",
    "By Status",
    "By App Behavior",
    "By Loyalty Card",
    "By Birthday",
    "By Anniversary",
    "By Location",
    "By Nationality",
    "By Email",
    "By Gender",
    "By Platform",
  ];

  useEffect(() => {
    if (promotionsQuery && has(promotionsQuery, "getPromotions")) {
      const publishedPromo = promotionsQuery.getPromotions.filter(
        (item) => item.status === "PUBLISHED"
      );
      const parsedPromo = publishedPromo.map((promo) => {
        return {
          title: promo.title,
          value: promo.id,
        };
      });
      setPromotionsData(parsedPromo);
    }
  }, [promotionsQuery]);

  useEffect(() => {
    if (promotion_id) {
      getLazyQuery();
    }
  }, [promotion_id]);

  useEffect(() => {
    if (data) {
      const fields: any = values;
      const PromoData = data.getPromotionById;
      Object.keys(PromoData).forEach((key) => {
        if (key === "photo_url") {
          fields["photo"] = PromoData.photo_url
            ? {
                keyObj: PromoData.photo_url,
                url: PromoData.photo_url,
              }
            : "";
        } else if (key === "promotions_participating_hotels") {
          fields["participating_hotels"] = PromoData.promotions_participating_hotels.map((item) => {
            return JSON.stringify({
              brand_id: item.brand.id,
              hotel_id: item.hotel.id,
            });
          });
        } else if (key === "title") {
          fields["name"] = PromoData.title;
        } else {
          fields[snakeCase(key)] = PromoData[key];
        }
      });
      setFieldValue("cta_button_link", fields.cta_button_link);
      setFieldValue("cta_button_name", fields.cta_button_name);
      setFieldValue("participating_hotels", fields.participating_hotels);
      setFieldValue("description", fields.description);
      setFieldValue("name", fields.name);
      setFieldValue("end_date", fields.end_date);
      setFieldValue("end_time", fields.end_time);
      setFieldValue("start_date", fields.start_date);
      setFieldValue("start_time", fields.start_time);
    }
  }, [data]);

  useEffect(() => {
    if (brandsQuery && has(brandsQuery, "getBrands")) {
      const publishedBrands = brandsQuery.getBrands.filter((item) => item.status === "PUBLISHED");
      const parsedBrands = publishedBrands
        .filter((item) => item.hotels.length)
        .map((brand) => {
          const childrenHotels = brand.hotels.map((hotel) => {
            const hotelValue = JSON.stringify({ brand_id: brand.id, hotel_id: hotel.id });

            return {
              title: hotel.name,
              value: hotelValue,
            };
          });
          const valueHotels = brand.hotels.map((hotel) => {
            return {
              brand_id: brand.id,
              hotel_id: hotel.id,
            };
          });

          return {
            title: brand.name,
            value: JSON.stringify(valueHotels),
            children: childrenHotels,
          };
        });

      setBrandsData(publishedBrands);
      setParticipatingHotels(parsedBrands);
    }
  }, [brandsQuery]);

  const handleTreeSelect = (e) => {
    setFieldValue("participating_hotels", e);
  };

  useEffect(() => {
    if (isOtherButton) {
      setFieldValue("cta_button_name", "");
      setFieldValue("cta_button_link", "");
    } else {
      setFieldValue("cta_button_name", "Book Now");
      setFieldValue("cta_button_link", "");
    }
  }, [isOtherButton]);

  useEffect(() => {
    if (values.recipients_by) {
      setRecipientCategories(values.recipients_by);
      setShowRecipientCategories(true);
    }
  }, [values.recipients_by]);

  const recipientOptions = recipientsList.map((item) => {
    return { value: item, title: item };
  });

  const promotionOptions = promotionsData.map(({ title, value }) => {
    return <Option value={value}>{title}</Option>;
  });

  const disabledDate = (current) => {
    return current && current < moment().endOf("day");
  };

  const handleAddSchedule = (value) => {
    if (value === "date") {
      const index = date_time.length;
      setFieldValue(`release_schedule.date_time[${index}]`, { date: null, time: null });
    } else if (value === "before") {
      const index = hours_before.length;
      setFieldValue(`release_schedule.hours_before[${index}]`, null);
    } else if (value === "after") {
      const index = hours_after.length;
      setFieldValue(`release_schedule.hours_after[${index}]`, null);
    } else if (value === "time between") {
      const index = time_between.length;
      setFieldValue(`release_schedule.time_between[${index}]`, null);
    }
  };

  const handleButtonName = (e) => {
    setNameCount(e.target.value.length);
  };

  const handleOkLeaving = () => {
    setIsModalVisibleLeaving(false);
    history.push("/notification-settings/notifications");
  };

  const handleRadio = (e) => {
    e.target.value === "Other" ? setIsOtherButton(true) : setIsOtherButton(false);
  };

  const handleRecipients = (recipients) => {
    if (recipients.includes("All")) {
      setFieldValue("recipients_by", ["All"]);
    }
    const checklist = [
      "By Hotel Booking",
      "By Status",
      "By Email",
      "By Birthday",
      "By Anniversary",
      "By Location",
      "By Gender",
      "By Platform",
    ];
    const filteredRecipients = recipients.filter((item) => checklist.includes(item));

    if (!filteredRecipients.includes("By Hotel Booking")) {
      setFieldValue("categories.hotel_booking", []);
      setFieldValue("categories.brand_list", []);
      setFieldValue("categories.hotel_list", []);
    }
    if (!filteredRecipients.includes("By Status")) {
      setFieldValue("categories.status", []);
    }
    if (!filteredRecipients.includes("By Email")) {
      setFieldValue("categories.email", []);
    }
    if (!filteredRecipients.includes("By Birthday")) {
      setFieldValue("categories.birthday", []);
    }
    if (!filteredRecipients.includes("By Anniversary")) {
      setFieldValue("categories.anniversary", []);
    }
    if (!filteredRecipients.includes("By Location")) {
      setFieldValue("categories.location", []);
    }
    if (!filteredRecipients.includes("By Gender")) {
      setFieldValue("categories.gender", []);
    }
    if (!filteredRecipients.includes("By Platform")) {
      setFieldValue("categories.platform", []);
    }

    filteredRecipients.length > 0
      ? setShowRecipientCategories(true)
      : setShowRecipientCategories(false);
    setRecipientCategories(filteredRecipients);
  };

  const otherButton = isOtherButton ? (
    <>
      <Col span={24}>
        <FormItem
          label={`Button name (${nameCount}/30)*`}
          name="cta_button_name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            name="cta_button_name"
            placeholder="Enter button name"
            onChange={handleButtonName}
            maxLength={30}
          />
        </FormItem>
      </Col>
      <Col span={24}>
        <FormItem
          label="Redirect Link*"
          name="cta_button_link"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input name="cta_button_link" placeholder="Enter Redirect Link" />
        </FormItem>
      </Col>
    </>
  ) : null;

  const dateTimeList = date_time.map((item, index) => {
    const dateValue = item?.date ? moment(item.date) : undefined;
    const timeValue = item?.time ? moment(item.time, "LT") : undefined;
    const keyDate = `release_schedule.date_time[${index}].date`;
    const keyTime = `release_schedule.date_time[${index}].time`;

    const handleDelete = () => {
      setFieldValue(`release_schedule.date_time[${index}]`, null);
    };

    const deleteButton =
      index === 0 ? null : (
        <Col span={2}>
          <DeleteButton type="primary" shape="circle" onClick={handleDelete}>
            <DeleteIconAlt />
          </DeleteButton>
        </Col>
      );

    if (item) {
      return (
        <Row gutter={12} key={index}>
          <Col span={12}>
            <FormItem
              label="Date*"
              name={keyDate}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <DatePicker
                name={keyDate}
                style={{ width: "100%" }}
                value={dateValue}
                disabledDate={disabledDate}
                allowClear={true}
                onChange={(e) => {
                  setFieldValue(keyDate, moment(e).format("L"));
                }}
              />
            </FormItem>
          </Col>
          <Col span={10}>
            <FormItem
              label="Time*"
              name={keyTime}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TimePicker
                use12Hours
                name={keyTime}
                value={timeValue}
                style={{ width: "100%" }}
                format={timeFormat}
                onChange={(e) => {
                  setFieldValue(keyTime, moment(e).format("LT"));
                }}
              />
            </FormItem>
          </Col>
          {deleteButton}
        </Row>
      );
    } else {
      return null;
    }
  });

  const beforeCheckInList = hours_before.map((item, index) => {
    const handleDelete = () => {
      setFieldValue(`release_schedule.hours_before[${index}]`, undefined);
    };

    const deleteButton =
      index === 0 ? null : (
        <Col span={2}>
          <DeleteButton type="primary" shape="circle" onClick={handleDelete}>
            <DeleteIconAlt />
          </DeleteButton>
        </Col>
      );

    if (item !== undefined) {
      return (
        <Row gutter={12}>
          <Col span={22}>
            <FormItem
              label="No. of hours before check-in date*"
              name={`release_schedule.hours_before[${index}]`}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                type="number"
                min={0}
                name={`release_schedule.hours_before[${index}]`}
                placeholder="Enter number of hours"
              />
            </FormItem>
          </Col>
          {deleteButton}
        </Row>
      );
    } else {
      return null;
    }
  });

  const afterCheckInList = hours_after.map((item, index) => {
    const handleDelete = () => {
      setFieldValue(`release_schedule.hours_after[${index}]`, undefined);
    };

    const deleteButton =
      index === 0 ? null : (
        <Col span={2}>
          <DeleteButton type="primary" shape="circle" onClick={handleDelete}>
            <DeleteIconAlt />
          </DeleteButton>
        </Col>
      );

    if (item !== undefined) {
      return (
        <Row gutter={12}>
          <Col span={22}>
            <FormItem
              label="No. of hours after check-in date*"
              name={`release_schedule.hours_after[${index}]`}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                type="number"
                min={0}
                name={`release_schedule.hours_after[${index}]`}
                placeholder="Enter number of hours"
              />
            </FormItem>
          </Col>
          {deleteButton}
        </Row>
      );
    } else {
      return null;
    }
  });

  const betweenCheckinList = time_between.map((item, index) => {
    const timeValue = item ? moment(item, "LT") : undefined;
    const keyTime = `release_schedule.time_between[${index}]`;

    const handleDelete = () => {
      setFieldValue(`release_schedule.time_between[${index}]`, undefined);
    };

    const deleteButton =
      index === 0 ? null : (
        <Col span={2}>
          <DeleteButton type="primary" shape="circle" onClick={handleDelete}>
            <DeleteIconAlt />
          </DeleteButton>
        </Col>
      );

    if (item !== undefined) {
      return (
        <Row gutter={12}>
          <Col span={22}>
            <FormItem
              label="Time between the check-in and check-out date*"
              name={keyTime}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TimePicker
                use12Hours
                name={keyTime}
                value={timeValue}
                style={{ width: "100%" }}
                format={timeFormat}
                onChange={(e) => {
                  setFieldValue(keyTime, moment(e).format("LT"));
                }}
              />
            </FormItem>
          </Col>
          {deleteButton}
        </Row>
      );
    } else {
      return null;
    }
  });

  const renderCategories = showRecipientCategories ? (
    <CategoriesContainer
      brandsData={brandsData}
      formikBag={formikBag}
      recipientCategories={recipientCategories}
    />
  ) : null;

  return (
    <>
      <Form>
        <InstructionLabel>* Required fields, do not leave this blank.</InstructionLabel>
        <ContentContainer style={{ flexDirection: "column" }}>
          <Row gutter={40}>
            <Col md={8} span={24}>
              <Row>
                <Col span={24}>
                  <HeaderSubtitle>Add Photo*</HeaderSubtitle>
                  <div style={{ marginBottom: "1.5rem" }}></div>
                </Col>
                <Col span={24}>
                  <StyledUploadImage
                    accept={"image/png,image/jpeg,image/jpg"}
                    value={values.photo}
                    hasNote
                    ratio="169 : 100"
                    sampleRatio="338 x 200"
                    formatStr="Format: JPG or PNG"
                    maxFileSize={2}
                    sizeLimit={2}
                    onChange={(e) => {
                      setFieldValue("photo", e);
                    }}
                    errors={errors.photo}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={16} span={24}>
              <Row>
                <Col span={24}>
                  <FormItem
                    label="Notification Type*"
                    name="notification_type"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select name="notification_type" placeholder="Select Notification Type">
                      <Option value="PROMOTION">Promotion</Option>
                      <Option value="BOOKING">Booking</Option>
                      <Option value="IN_STAY">In-Stay</Option>
                    </Select>
                  </FormItem>
                </Col>
                {values.notification_type === "PROMOTION" && (
                  <Col span={24}>
                    <FormItem
                      label="Choose Published Promotion*"
                      name="promotion_id"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Select name="promotion_id" placeholder="Select Published Promo">
                        {promotionOptions}
                      </Select>
                    </FormItem>
                  </Col>
                )}
                <Col span={24}>
                  <FormItem
                    label="Name*"
                    name="name"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input name="name" placeholder="Enter Name" minLength={2} />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label="Description*"
                    name="description"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Richtext2
                      value={DOMPurify.sanitize(values.description)}
                      placeholder="Enter Description"
                      onChange={(e) => {
                        if (e === "<p><br></p>") {
                          setFieldValue("description", "");
                        } else setFieldValue("description", e);
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label="Participating Hotels*"
                    name="participating_hotels"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <TreeSelect
                      onChange={handleTreeSelect}
                      placeholder="Please select"
                      value={values.participating_hotels}
                      showCheckedStrategy={SHOW_PARENT}
                      treeCheckable={true}
                      treeData={participatingHotels}
                    />
                    <ErrorMessageContainer>
                      <ErrorMessage name="participating_hotels" />
                    </ErrorMessageContainer>
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label="Notification Period*"
                    name="notification_period"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Row gutter={24}>
                      <Col span={14}>
                        <FormItem
                          name="start_date"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <DatePicker
                            name="start_date"
                            placeholder="Start Date"
                            style={{ width: "100%" }}
                            value={startDateValue}
                            disabledDate={disabledDate}
                            allowClear={false}
                            onChange={(e) => {
                              setFieldValue("start_date", moment(e).format("L"));
                            }}
                          />
                        </FormItem>
                      </Col>
                      <Col span={10}>
                        <FormItem
                          name="start_time"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TimePicker
                            use12Hours
                            name="start_time"
                            placeholder="Start Time"
                            value={startTimeValue}
                            style={{ width: "100%" }}
                            format={timeFormat}
                            allowClear={false}
                            onChange={(e) => {
                              setFieldValue("start_time", moment(e).format("LT"));
                            }}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={14}>
                        <FormItem name="end_date" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                          <DatePicker
                            name="end_date"
                            placeholder="End Date"
                            style={{ width: "100%" }}
                            value={endDateValue}
                            disabledDate={disabledDate}
                            allowClear={false}
                            onChange={(e) => {
                              setFieldValue("end_date", moment(e).format("L"));
                            }}
                          />
                        </FormItem>
                      </Col>
                      <Col span={10}>
                        <FormItem name="end_time" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                          <TimePicker
                            use12Hours
                            placeholder="End Time"
                            name="end_time"
                            value={endTimeValue}
                            style={{ width: "100%" }}
                            format={timeFormat}
                            allowClear={false}
                            onChange={(e) => {
                              setFieldValue("end_time", moment(e).format("LT"));
                            }}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label="Choose CTA Button*"
                    name="cta_button"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Radio.Group
                      onChange={handleRadio}
                      defaultValue={values.cta_button_name === "Book Now" ? "Book Now" : "Other"}
                    >
                      <Radio value="Book Now">Book Now</Radio>
                      <Radio value="Other">Other Button</Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
                {otherButton}
                <Divider dashed={true} />
                <Col span={24}>
                  <Subheader>RELEASE DETAILS</Subheader>
                  <StyledTabs type="card" style={{ marginBottom: "0" }}>
                    <TabPane tab="Date & Time" key="1">
                      <TabContainer>
                        {dateTimeList}
                        <Divider />
                        <StyledButton type="dashed" onClick={() => handleAddSchedule("date")}>
                          <AddSchedule>
                            <PlusCircleFilled
                              style={{ fontSize: "1rem", color: "var(--color-general-blue-gray)" }}
                            />
                            <span>Add more release schedule</span>
                          </AddSchedule>
                        </StyledButton>
                      </TabContainer>
                    </TabPane>
                    <TabPane
                      tab={
                        <div style={{ textAlign: "center" }}>
                          No. of hours before
                          <br />
                          check-in date
                        </div>
                      }
                      key="2"
                    >
                      <TabContainer>
                        {beforeCheckInList}
                        <Divider />
                        <StyledButton type="dashed" onClick={() => handleAddSchedule("before")}>
                          <AddSchedule>
                            <PlusCircleFilled
                              style={{ fontSize: "1rem", color: "var(--color-general-blue-gray)" }}
                            />
                            <span>Add more release schedule</span>
                          </AddSchedule>
                        </StyledButton>
                      </TabContainer>
                    </TabPane>
                    <TabPane
                      tab={
                        <div>
                          No. of hours after
                          <br />
                          the check-in date
                        </div>
                      }
                      key="3"
                    >
                      <TabContainer>
                        {afterCheckInList}
                        <Divider />
                        <StyledButton type="dashed" onClick={() => handleAddSchedule("after")}>
                          <AddSchedule>
                            <PlusCircleFilled
                              style={{ fontSize: "1rem", color: "var(--color-general-blue-gray)" }}
                            />
                            <span>Add more release schedule</span>
                          </AddSchedule>
                        </StyledButton>
                      </TabContainer>
                    </TabPane>
                    <TabPane
                      tab={
                        <div>
                          Time between the check-in
                          <br />
                          and check-out date
                        </div>
                      }
                      key="4"
                    >
                      <TabContainer>
                        {betweenCheckinList}
                        <Divider />
                        <StyledButton
                          type="dashed"
                          onClick={() => handleAddSchedule("time between")}
                        >
                          <AddSchedule>
                            <PlusCircleFilled
                              style={{ fontSize: "1rem", color: "var(--color-general-blue-gray)" }}
                            />
                            <span>Add more release schedule</span>
                          </AddSchedule>
                        </StyledButton>
                      </TabContainer>
                    </TabPane>
                  </StyledTabs>
                </Col>
                <Divider dashed={true} />
                <Subheader>RECIPIENT DETAILS</Subheader>
                <Col span={24}>
                  <FormItem
                    label="Recipients*"
                    name="recipients_by"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Treeselect
                      treeCheckable={true}
                      style={{ width: "100%" }}
                      name="recipients_by"
                      placeholder="Select recipients"
                      onChange={handleRecipients}
                      treeData={recipientOptions}
                    />
                    <ErrorMessageContainer>
                      <ErrorMessage name="recipients_by" />
                    </ErrorMessageContainer>
                  </FormItem>
                  {renderCategories}
                </Col>
                <Divider dashed={true} />
                <Subheader>NOTIFICATION FREQUENCY</Subheader>
                <Col span={24}>
                  <FormItem
                    label="Frequency"
                    name="notification_frequency"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select
                      name="notification_frequency"
                      style={{ width: "100%" }}
                      placeholder="Select frequency"
                    >
                      <Option value="ONCE">Once</Option>
                      <Option value="DAILY">Daily</Option>
                      <Option value="WEEKLY">Weekly</Option>
                      <Option value="MONTHLY">Monthly</Option>
                    </Select>
                  </FormItem>
                  {values.notification_frequency && <RecurrenceContainer formikBag={formikBag} />}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="end" gutter={24} style={{ marginBottom: 10 }}>
            <Col span={4}>
              <CustomButton
                style={{ width: "100%" }}
                onClick={() => setIsModalVisibleLeaving(true)}
              >
                CANCEL
              </CustomButton>
            </Col>
            <Col span={4}>
              <CustomButton
                style={{ width: "100%", marginRight: 15 }}
                type="primary"
                onClick={() => submitForm()}
              >
                SAVE
              </CustomButton>
            </Col>
          </Row>
        </ContentContainer>
      </Form>

      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={pageId ? "Cancel Editing" : "Leave this page?"}
        visible={isModalVisibleLeaving}
        onOk={handleOkLeaving}
        onCancel={() => setIsModalVisibleLeaving(false)}
        message={
          pageId
            ? "Are you sure you want to cancel editing"
            : "All unsaved data will be lost. Are you sure you want to leave this page?"
        }
        cancelText={pageId ? "No" : "Stay here"}
        okText="Yes"
        okButtonWidth="7.5em"
        cancelButtonWidth="7.5em"
      />
    </>
  );
};

export default Forms;
