import styled from "styled-components";
import { typography } from "../../utilities/styles/theme";

import { Button } from "antd";

export const StyledButton2 = styled(Button)`
  min-width: 190px;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  border-raduis: 4px;
  padding: 15px 30px;
`;

export const StyledButton = styled(Button)({
  ...typography("button"),
  // minWidth: "190px",
  // height: "50px",
});
