import * as yup from "yup";
import {
  ALPHANUM_PATTERN,
  MOBILE_NUM_PATTERN,
  LANDLINE_NUM_PATTERN,
  URL_PATTERN,
} from "../../../../utilities/regex";

const imageSchema = yup
  .object()
  .shape({
    status: yup.string().matches(/done/, { message: "File is still uploading" }),
    url: yup.string(),
  })
  .nullable();

const fieldsValidation = yup.object().when("type", (value) => {
  switch (value) {
    case "LEFT_SIDE":
    case "CENTER_TOP":
    case "CENTER_BOTTOM":
    case "RIGHT_SIDE":
      return yup.object().shape({
        section_status: yup.boolean(),
        header_title: yup.string().required("This is a required field.").trim().nullable(),
        text_content: yup.string().required("This is a required field.").trim().nullable(),
        link: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
        image: imageSchema.required("This is a required field.").nullable(),
      });
    case "LEFT_ALIGN":
    case "CENTER_ALIGN":
    case "RIGHT_ALIGN":
      return yup.object().shape({
        section_status: yup.boolean(),
        header_title: yup.string().required("This is a required field.").trim().nullable(),
        text_content: yup.string().required("This is a required field.").trim().nullable(),
        link: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
      });

    case "FEATURED_VIDEO":
      return yup.object().shape({
        section_status: yup.boolean(),
        link: yup
          .string()
          .required("This is a required field.")
          .matches(URL_PATTERN, "Invalid format.")
          .trim(),
        image: imageSchema.required("This is a required field.").nullable(),
      });

    default:
      return yup.object().nullable();
  }
});

const componentType = yup.object().shape({
  type: yup.string(),
  fields: fieldsValidation,
});

export const validationSchema = yup.object().shape({
  logo: imageSchema.required("This is a required field."),
  redirect_link: yup.string().matches(URL_PATTERN, "Invalid format."),
  name: yup
    .string()
    .matches(ALPHANUM_PATTERN, "Invalid format.")
    .trim()
    .required("This is a required field.")
    .min(2, "Oops! enter at least 2 characters.")
    .max(40, "Maximum of 40 characters."),
  category: yup.string().trim().required("This is a required field."),
  short_description: yup
    .string()
    .trim()
    .required("This is a required field.")
    .min(25, "Oops! enter at least 25 characters.")
    .max(150, "Maximum of 150 characters."),
  long_description: yup.string().nullable().required("This is a required field."),
  landline_nos: yup.array().of(
    yup.object().shape({
      input: yup.string().trim().matches(LANDLINE_NUM_PATTERN, "Invalid format."),
    })
  ),
  mobile_nos: yup.array().of(
    yup.object().shape({
      input: yup.string().when(["country_code"], {
        is: (country_code) => country_code === "+63",
        then: yup
          .string()
          .trim()
          .max(12, "Mobile no. must not exceed 11 characters.")
          .matches(MOBILE_NUM_PATTERN, "Invalid format."),
        otherwise: yup
          .string()
          .trim()
          .nullable()
          .matches(/^[0-9]*$/, "Invalid format."),
      }),
      country_code: yup.string().default("+63"),
    })
  ),
  email_addresses: yup.array().of(
    yup.object().shape({
      input: yup.string().trim().email("Invalid email."),
    })
  ),
  category_logo: imageSchema.required("This is a required field."),
  carousel_image: imageSchema.required("This is a required field."),
  banner_feature_images: yup.array().of(imageSchema).min(1, "This is a required field.").nullable(),
  social_media: yup.object().shape({
    facebook: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
    instagram: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
    youtube: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
    twitter: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
    linkedin: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
    tiktok: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
    kumu: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
    viber_community: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
  }),
  // hotels: yup.array().of(
  //   yup.object().shape({
  //     website_logo: imageValidation.required("This is a required field."),
  //     app_logo: imageValidation.when(["partner_hotel_code"], {
  //       is: (partner_hotel_code) => partner_hotel_code,
  //       then: imageValidation.required("This is a required field."),
  //       otherwise: imageValidation,
  //     }),
  //     uploaded_hotel_photos: yup
  //       .array()
  //       .of(imageValidation)
  //       .min(1, "This is a required field.")
  //       .nullable(),
  //     name: yup
  //       .string()
  //       .trim()
  //       .required("This is a required field.")
  //       .matches(ALPHANUM_PATTERN, "Invalid format."),
  //     address: yup
  //       .string()
  //       .trim()
  //       .when(["partner_hotel_code"], {
  //         is: (partner_hotel_code) => partner_hotel_code,
  //         then: yup.string().trim().required("This is a required field."),
  //         otherwise: yup.string().trim(),
  //       }),
  //     website_link: yup
  //       .string()
  //       .trim()
  //       .required("This is a required field.")
  //       .matches(URL_PATTERN, "Invalid format."),
  //     request_for_proposal_link: yup
  //       .string()
  //       .trim()
  //       .required("This is a required field.")
  //       .matches(URL_PATTERN, "Invalid format."),
  //     tripadvisor_link: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
  //     mobile_no: yup
  //       .string()
  //       .trim()
  //       .when(["partner_hotel_code"], {
  //         is: (partner_hotel_code) => partner_hotel_code,
  //         then: yup
  //           .string()
  //           .trim()
  //           .required("This is a required field.")
  //           .max(12, "Mobile no. must not exceed 11 characters.")
  //           .matches(MOBILE_NUM_PATTERN, "Invalid format."),
  //         otherwise: yup
  //           .string()
  //           .trim()
  //           .max(12, "Mobile no. must not exceed 11 characters.")
  //           .matches(MOBILE_NUM_PATTERN, "Invalid format."),
  //       }),
  //     latitude: yup
  //       .string()
  //       .trim()
  //       .when(["partner_hotel_code"], {
  //         is: (partner_hotel_code) => partner_hotel_code,
  //         then: yup
  //           .string()
  //           .trim()
  //           .required("This is a required field.")
  //           .matches(/^[0-9.]*$/, "Invalid format."),
  //         otherwise: yup
  //           .string()
  //           .trim()
  //           .matches(/^[0-9.]*$/, "Invalid format."),
  //       }),
  //     longitude: yup
  //       .string()
  //       .trim()
  //       .when(["partner_hotel_code"], {
  //         is: (partner_hotel_code) => partner_hotel_code,
  //         then: yup
  //           .string()
  //           .trim()
  //           .required("This is a required field.")
  //           .matches(/^[0-9.]*$/, "Invalid format."),
  //         otherwise: yup
  //           .string()
  //           .trim()
  //           .matches(/^[0-9.]*$/, "Invalid format."),
  //       }),
  //     meetings_and_celebrations_link: yup
  //       .string()
  //       .trim()
  //       .required("This is a required field.")
  //       .matches(URL_PATTERN, "Invalid format."),
  //     booking_engine_link: yup
  //       .string()
  //       .trim()
  //       .required("This is a required field.")
  //       .matches(URL_PATTERN, "Invalid format."),
  //     group_booking_link: yup
  //       .string()
  //       .trim()
  //       .required("This is a required field.")
  //       .matches(URL_PATTERN, "Invalid format."),
  //     email_address: yup
  //       .string()
  //       .trim()
  //       .when(["partner_hotel_code"], {
  //         is: (partner_hotel_code) => partner_hotel_code,
  //         then: yup.string().trim().required("This is a required field.").email("Invalid email."),
  //         otherwise: yup.string().trim().email("Invalid email."),
  //       }),
  //     landline_no: yup
  //       .string()
  //       .trim()
  //       .when(["partner_hotel_code"], {
  //         is: (partner_hotel_code) => partner_hotel_code,
  //         then: yup.string().trim().required("This is a required field."),
  //         otherwise: yup.string().trim(),
  //       }),
  //     description: yup
  //       .string()
  //       .trim()
  //       .when(["partner_hotel_code"], {
  //         is: (partner_hotel_code) => partner_hotel_code,
  //         then: yup
  //           .string()
  //           .trim()
  //           .required("This is a required field.")
  //           .min(9, "Oops! enter at least 2 characters."),
  //         otherwise: yup.string().trim(),
  //       }),
  //     policies: yup
  //       .string()
  //       .trim()
  //       .when(["partner_hotel_code"], {
  //         is: (partner_hotel_code) => partner_hotel_code,
  //         then: yup
  //           .string()
  //           .trim()
  //           .required("This is a required field.")
  //           .min(9, "Oops! enter at least 2 characters."),
  //         otherwise: yup.string().trim(),
  //       }),
  //     facilities_and_amenities: yup
  //       .string()
  //       .trim()
  //       .when(["partner_hotel_code"], {
  //         is: (partner_hotel_code) => partner_hotel_code,
  //         then: yup
  //           .string()
  //           .trim()
  //           .required("This is a required field.")
  //           .min(9, "Oops! enter at least 2 characters."),
  //         otherwise: yup.string().trim(),
  //       }),
  //     dining: yup
  //       .string()
  //       .trim()
  //       .when(["partner_hotel_code"], {
  //         is: (partner_hotel_code) => partner_hotel_code,
  //         then: yup
  //           .string()
  //           .trim()
  //           .required("This is a required field.")
  //           .min(9, "Oops! enter at least 2 characters."),
  //         otherwise: yup.string().trim(),
  //       }),
  //     nearby_attractions: yup
  //       .string()
  //       .trim()
  //       .when(["partner_hotel_code"], {
  //         is: (partner_hotel_code) => partner_hotel_code,
  //         then: yup
  //           .string()
  //           .trim()
  //           .required("This is a required field.")
  //           .min(9, "Oops! enter at least 2 characters."),
  //         otherwise: yup.string().trim(),
  //       }),
  //     // payment_options: yup.array().of(
  //     //   yup.object().shape({
  //     //     id: yup.string().trim(),
  //     //     platform: yup.string().trim(),
  //     //     merchant_name: yup.string().when(["partner_hotel_code"], {
  //     //       is: (partner_hotel_code) => partner_hotel_code,
  //     //       then: yup.string().trim().required("This is a required field."),
  //     //       otherwise: yup.string().trim(),
  //     //     }),
  //     //     merchant_id: yup.string().when(["partner_hotel_code"], {
  //     //       is: (partner_hotel_code) => partner_hotel_code,
  //     //       then: yup.string().trim().required("This is a required field."),
  //     //       otherwise: yup.string().trim(),
  //     //     }),
  //     //     shared_key: yup.string().when(["partner_hotel_code"], {
  //     //       is: (partner_hotel_code) => partner_hotel_code,
  //     //       then: yup.string().trim().required("This is a required field."),
  //     //       otherwise: yup.string().trim(),
  //     //     }),
  //     //     status: yup.string().trim(),
  //     //     pay_at_hotel: yup.string().trim(),
  //     //   })
  //     // ),
  //   })
  // ),
  sections: yup.array().of(componentType),
});
