import { Col, Collapse, Row } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { FormItem, Input } from "formik-antd";

interface Props {
  formikBag: any;
}

const BrandSearchEngineOptimization = ({ formikBag }: Props) => {
  return (
    <Row>
      <Col span={24}>
        <Collapse>
          <Collapse.Panel header="BASIC TAGS" key="1">
            <Row>
              <Col span={11}>
                <Col span={24}>
                  <FormItem
                    label="Page Title"
                    name={`page_title`}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    tooltip={{
                      title:
                        "The text to display in the title bar of a visitor's web browser when they view this page. This meta tag may also be used as the title of the page when a visitor bookmars or favorites this page, or as the page title in a search engine result. It is common to append '[site:name]' to the end of this, so the site's name is automatically added. Title should contain maximum 55-65 characters long, including spaces.",
                      icon: <InfoCircleFilled style={{ color: "#144480" }} />,
                    }}
                  >
                    <Input
                      name={`page_title`}
                      placeholder="[node:title][current-page:pager][site:name]"
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label="Description"
                    name={`seo_description`}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    tooltip={{
                      title:
                        "A brief and concise summary of the page's content, with 150 characters or less. Where as the description meta tag may be used by search engines to display a snippet about the page in search results, the abstract tag is used by engines to archive a summary about the page. This will be truncated to a maximum of 380 characters.",
                      icon: <InfoCircleFilled style={{ color: "#144480" }} />,
                    }}
                  >
                    <Input name={`seo_description`} placeholder="[node:summary]" />
                  </FormItem>
                </Col>
              </Col>
              <Col span={11} offset={2}>
                <Col span={24}>
                  <FormItem
                    label="Keywords"
                    name={`seo_keywords`}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    tooltip={{
                      title:
                        "A comma-separate list of keywords about the page. This meta tag is no longer supported by most search engines anymore.",
                      icon: <InfoCircleFilled style={{ color: "#144480" }} />,
                    }}
                  >
                    <Input name={`seo_keywords`} placeholder="Enter Content" />
                  </FormItem>
                </Col>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default BrandSearchEngineOptimization;
