import { useState, useEffect } from "react";
import { Formik } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import { Spin, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { has, snakeCase } from "lodash";
import moment from "moment";

import CustomModal from "@/components/CustomModal";
import Form from "./form";

import { CREATE_UPDATE_NOTIFICATIONS, GET_NOTIFICATION_BY_ID } from "../../../graphql";
import { validationSchema } from "./validations";
import { dummyData, recipientType } from "./constant";

const NotificationsForm = () => {
  const history = useHistory();
  const { pageId } = useParams();
  const [createUpdateNotification] = useMutation(CREATE_UPDATE_NOTIFICATIONS);
  const { data: pageData, loading } = useQuery(GET_NOTIFICATION_BY_ID, {
    skip: !pageId,
    fetchPolicy: "no-cache",
    variables: {
      id: pageId || "",
    },
  });

  const [titleMessage, setTitleMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [reinitialize, setReinitialize] = useState(false);
  const [initialValues, setInitialValues] = useState({
    notification_type: "",
    name: "",
    description: "",
    photo: null,
    start_date: null,
    start_time: null,
    end_date: null,
    end_time: null,
    cta_button_name: "Book Now",
    cta_button_link: "",
    status: "DRAFT",
    is_featured: false,
    promotion_id: null,
    participating_hotels: [],
    recipients_by: undefined,
    release_schedule: {
      date_time: [{ date: null, time: null }],
      hours_before: [null],
      hours_after: [null],
      time_between: [null],
    },
    notification_details: undefined,
    categories: {
      hotel_booking: [],
      brand_list: [],
      hotel_list: [],
      status: [],
      email: [],
      birthday: [],
      location: [],
      anniversary: [],
      gender: [],
      platform: [],
    },
    notification_frequency: "ONCE",
    frequency_details: {
      start_date: null,
      start_time: null,
      end_date: undefined,
      end_time: null,
      recur_every: 1,
      weeks_on: [],
      months_on: "",
      ends: null,
    },
  });

  const handleOkSuccess = () => {
    history.push("/notification-settings/notifications/");
  };

  useEffect(() => {
    if (reinitialize) {
      setReinitialize(false);
    }
  }, [reinitialize]);

  useEffect(() => {
    if (pageId && has(pageData, "getNotificationById")) {
      const data = pageData.getNotificationById;

      const fields: any = initialValues;

      Object.keys(data).forEach((key) => {
        if (key === "photo_url") {
          fields["photo"] = data.photo_url
            ? {
                keyObj: data.photo_url,
                url: data.photo_url,
              }
            : "";
        } else if (key === "participating_hotels") {
          fields["participating_hotels"] = data.participating_hotels.map((item) => {
            return JSON.stringify({
              brand_id: item.brand.id,
              hotel_id: item.hotel.id,
            });
          });
        } else if (key === "recipients") {
          if (!data["recipients"].length) {
            fields["recipients_by"] = ["All"];
            return;
          }
          fields["recipients_by"] = data.recipients.map(({ recipient_type }) => {
            return recipientType[recipient_type];
          });
          data.recipients.forEach(({ value }) => {
            const parsedCategory = JSON.parse(value);
            fields["categories"][parsedCategory.name] = parsedCategory.value;
          });
        } else if (key === "release_schedules") {
          const beforeValueArray: any[] = [];
          const afterValueArray: any[] = [];
          const betweenValueArray: any[] = [];
          const dateTimeValueArray: any[] = [];

          //before
          const beforeValue = data.release_schedules.filter(({ schedule_type }) => {
            return schedule_type === "BEFORE";
          });
          beforeValue.forEach(({ before_after_value }) => {
            beforeValueArray.push(before_after_value);
          });
          fields["release_schedule"]["hours_before"] = beforeValueArray;

          //after
          const afterValue = data.release_schedules.filter(({ schedule_type }) => {
            return schedule_type === "AFTER";
          });
          afterValue.forEach(({ before_after_value }) => {
            afterValueArray.push(before_after_value);
          });
          fields["release_schedule"]["hours_after"] = afterValueArray;

          //between
          const betweenValue = data.release_schedules.filter(({ schedule_type }) => {
            return schedule_type === "BETWEEN";
          });
          betweenValue.forEach(({ between_value }) => {
            betweenValueArray.push(between_value);
          });
          fields["release_schedule"]["time_between"] = betweenValueArray;

          //date & time
          const dateTimeValue = data.release_schedules.filter(({ schedule_type }) => {
            return schedule_type === "DATETIME";
          });
          dateTimeValue.forEach(({ datetime_value }) => {
            const dateAndTime = parseInt(datetime_value!.slice(0, -3));
            const timeValue = moment.unix(dateAndTime).format("h:mm A");
            const dateValue = moment.unix(dateAndTime).format("YYYY-MM-DD");
            dateTimeValueArray.push({ date: dateValue, time: timeValue });
          });

          fields["release_schedule"]["date_time"] = dateTimeValueArray;
        } else if (key === "frequency_details") {
          if (data["frequency_details"]) {
            const { __typename, ...rest } = data["frequency_details"];
            fields["frequency_details"] = rest;
          } else {
            fields["frequency_details"] = null;
          }
        } else {
          fields[snakeCase(key)] = data[key];
        }
      });

      setInitialValues(fields);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      setReinitialize(true);
    }
  }, [pageData]);

  const dataMapper = (values) => {
    const { categories, recipients_by, release_schedule } = values;
    const { date_time, hours_before, hours_after, time_between } = release_schedule;
    const categoryKeys = Object.keys(categories);
    let participatingHotels: any[] = [];
    let releaseSchedule: any[] = [];

    values.participating_hotels?.forEach((item) => {
      const parsedItem: any = JSON.parse(item);
      if (Array.isArray(parsedItem) && parsedItem.length > 0) {
        participatingHotels = [...participatingHotels, ...parsedItem];
      } else {
        participatingHotels = [...participatingHotels, parsedItem];
      }
    });
    date_time.forEach((item) => {
      const newArrayItem = {
        schedule_type: "DATETIME",
        datetime_value: moment(item.date + " " + item.time).format("YYYY-MM-DD HH:mm:ss"),
      };
      releaseSchedule = [...releaseSchedule, newArrayItem];
    });
    hours_before.forEach((item) => {
      const newArrayItem = {
        schedule_type: "BEFORE",
        before_after_value: item,
      };
      releaseSchedule = [...releaseSchedule, newArrayItem];
    });
    hours_after.forEach((item) => {
      const newArrayItem = {
        schedule_type: "AFTER",
        before_after_value: item,
      };
      releaseSchedule = [...releaseSchedule, newArrayItem];
    });
    time_between.forEach((item) => {
      if (item) {
        const newArrayItem = {
          schedule_type: "BETWEEN",
          between_value: moment(item.time_between).format("HH:mm"),
        };
        releaseSchedule = [...releaseSchedule, newArrayItem];
      }
    });

    const recipients = categoryKeys
      .map((category) => {
        if (categories[category].length > 0) {
          let recipientType = "";
          if (
            category === "hotel_booking" ||
            category === "hotel_list" ||
            category === "brand_list"
          ) {
            recipientType = "HOTEL_BOOKING";
          } else if (category === "status") {
            recipientType = "STATUS";
          } else if (category === "email") {
            recipientType = "EMAIL";
          } else if (category === "birthday") {
            recipientType = "BIRTHDAY";
          } else if (category === "location") {
            recipientType = "LOCATION";
          } else if (category === "anniversary") {
            recipientType = "ANNIVERSARY";
          } else if (category === "gender") {
            recipientType = "GENDER";
          } else if (category === "platform") {
            recipientType = "PLATFORM";
          }
          const newArrayItem = {
            recipient_type: recipientType,
            value: {
              name: category,
              value: categories[category],
            },
          };
          return newArrayItem;
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);

    const additionalRecipients = recipients_by
      ?.map((item) => {
        let recipientType;
        if (item === "By App Behavior") {
          recipientType = "APP_BEHAVIOR";
        } else if (item === "By Loyalty Card") {
          recipientType = "LOYALTY_CARD";
        } else if (item === "By Nationality") {
          recipientType = "NATIONALITY";
        } else if (item === "By Nationality") {
          recipientType = "NATIONALITY";
        } else {
          return null;
        }

        const newArrayItem = {
          recipient_type: recipientType,
          value: {
            name: "",
            value: [],
          },
        };

        return newArrayItem;
      })
      .filter((item) => item !== null);

    if (additionalRecipients.length > 0) {
      recipients.push(...additionalRecipients);
    }

    const notificationDetails = {
      id: pageId,
      notification_type: values.notification_type,
      name: values.name,
      photo_url: values.photo?.url,
      description: values.description,
      start_date: values.start_date,
      start_time: values.start_time,
      end_date: values.end_date,
      end_time: values.end_time,
      cta_button_name: values.cta_button_name,
      cta_button_link: values.cta_button_link,
      status: values.status,
      notification_frequency: values.notification_frequency,
      frequency_details: {
        ...values.frequency_details,
        recur_every: parseInt(values.frequency_details.recur_every),
      },
    };

    const newData = {
      notification_details: notificationDetails,
      participating_hotels: participatingHotels,
      release_schedule: releaseSchedule,
      recipient: recipients,
    };

    return newData;
  };

  const handleSubmit = async (values) => {
    const data = dataMapper(values);
    setLoader(true);
    try {
      let update = false;
      if (pageData && has(pageData, "getNotificationById")) {
        update = true;
      }

      await createUpdateNotification({
        variables: {
          createOrUpdateNotificationInput: data,
        },
      });

      setTimeout(() => {
        setLoader(false);
      }, 1000);

      setIsSuccess(true);
      setShowModal(true);
      setTitleMessage("Success!");
      setBodyMessage(
        update
          ? "Changes for this notification was successfully saved."
          : "You have successfully added a new notification!"
      );
    } catch (error: any) {
      if (error) {
        if (error.message.startsWith("Data too long")) {
          setTitleMessage("Data not saved!");
          setBodyMessage(error.message);
          setShowModal(true);
        }
        if (error.message.startsWith("Brand with name")) {
          setTitleMessage("Data not saved!");
          setBodyMessage("Notification already exist.");
          setShowModal(true);
        } else if (error.graphQLErrors && error.graphQLErrors[0]) {
          const errGraphqlRes = error.graphQLErrors[0].extensions.response;
          let errStr = "";

          if (errGraphqlRes) {
            if (typeof errGraphqlRes.message === "string") {
              errStr = errGraphqlRes.message;
            } else if (errGraphqlRes?.message?.length > 0) {
              errGraphqlRes?.message?.forEach((item) => (errStr += item));
            } else errStr = errGraphqlRes?.message;
          }
          message.error(errStr);
        } else {
          setTitleMessage("Data not saved!");
          setBodyMessage("Kindly check your internet connection.");
          setShowModal(true);
        }
      }

      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  };

  return (
    <>
      <Spin spinning={loading || loader}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={reinitialize}
          onSubmit={async (values: any) => {
            await handleSubmit(values);
          }}
          render={(formikBag) => (
            <Form
              {...{
                formikBag,
                loader,
              }}
            />
          )}
        />
      </Spin>

      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isErrorIcon={!isSuccess}
        isSuccessIcon={isSuccess}
        titleMessage={titleMessage}
        visible={showModal}
        onOk={isSuccess ? handleOkSuccess : () => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={isSuccess ? "Great" : "OK"}
      />
    </>
  );
};

export default NotificationsForm;
