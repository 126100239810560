import React, { ReactNode } from "react";

import { Container } from "./styled";

interface Props {
    children: ReactNode;
    style?: object;
}

const LayoutContainer: React.FC<Props> = ({ children, style }) => (
    <Container data-index="container" style={style}>
        {children}
    </Container>
);

export default LayoutContainer;
