import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Checkbox, Form, Table, Row } from "antd";

import ContentContainer from "@/components/ContentContainer";
import CustomModal from "@/components/CustomModal";
import { ErrorTableMessage, InstructionLabel, StyledButton, StyledInput, Subtitle } from "./styled";

interface Props {
  apiLoaded: boolean;
  handleSubmit: () => void;
  hasError: boolean;
  roleName: string | undefined;
  setRoleName: React.Dispatch<React.SetStateAction<string>>;
  setTableData: React.Dispatch<React.SetStateAction<Object[]>>;
  tableData: Object[];
}

const Forms: React.FC<Props> = ({
  apiLoaded,
  handleSubmit,
  hasError,
  roleName,
  setRoleName,
  setTableData,
  tableData,
}) => {
  const { pageId } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();

  const [isModalVisibleLeaving, setIsModalVisibleLeaving] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
  }, [apiLoaded]);

  const handleChange = (e) => {
    setRoleName(e.target.value);
  };

  const handleCheckbox = (value, index, key) => {
    const updateData = [...tableData];
    updateData[index][key] = value;

    setTableData(updateData);
  };

  const handleOkLeaving = () => {
    setIsModalVisibleLeaving(false);
    history.push("/user-role-management/role-management");
  };

  const columns = [
    {
      title: "Features",
      dataIndex: "feature",
      width: "50%",
    },
    {
      title: "Add / Delete",
      dataIndex: "add/delete",
      width: "10%",
      render: (value, record, index) => (
        <Checkbox
          checked={value}
          onChange={(e) => handleCheckbox(e.target.checked, index, "add/delete")}
        />
      ),
    },
    {
      title: "View",
      dataIndex: "view",
      width: "10%",
      render: (value, record, index) => (
        <Checkbox
          checked={value}
          onChange={(e) => handleCheckbox(e.target.checked, index, "view")}
        />
      ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      width: "10%",
      render: (value, record, index) => (
        <Checkbox
          checked={value}
          onChange={(e) => handleCheckbox(e.target.checked, index, "edit")}
        />
      ),
    },
    {
      title: "Activate / Deactivate",
      dataIndex: "activate/deactivate",
      width: "10%",
      render: (value, record, index) => (
        <Checkbox
          checked={value}
          name="activate/deactivate"
          onChange={(e) => handleCheckbox(e.target.checked, index, "activate/deactivate")}
        />
      ),
    },
    {
      title: "Download",
      dataIndex: "download",
      width: "10%",
      render: (value, record, index) =>
        value !== undefined && (
          <Checkbox
            checked={value}
            onChange={(e) => handleCheckbox(e.target.checked, index, "download")}
          />
        ),
    },
  ];

  return (
    <>
      <Form form={form} onFinish={handleSubmit}>
        <InstructionLabel>* Required fields, do not leave this blank.</InstructionLabel>
        <ContentContainer style={{ flexDirection: "column" }}>
          <Form.Item
            label="Role Name*"
            name="roleName"
            initialValue={roleName}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: "This is a required field" }]}
          >
            <StyledInput
              placeholder="Enter Role Name"
              maxLength={70}
              minLength={2}
              onChange={handleChange}
            />
          </Form.Item>

          <Subtitle>Roles Access in CMS*</Subtitle>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            style={{ width: "100%", marginBottom: "32px", borderColor: "red" }}
          />
          <ErrorTableMessage show={hasError}>
            Please select at least 1 role access
          </ErrorTableMessage>

          <Row></Row>
          <Row justify="end" gutter={24} style={{ marginBottom: 10 }}>
            <Form.Item>
              <StyledButton onClick={() => setIsModalVisibleLeaving(true)}>CANCEL</StyledButton>
              <StyledButton type="primary" htmlType="submit">
                SAVE
              </StyledButton>
            </Form.Item>
          </Row>
        </ContentContainer>

        <CustomModal
          closable={false}
          maskClosable={false}
          isWarningIcon
          titleMessage={pageId ? "Cancel Editing" : "Leave this page?"}
          visible={isModalVisibleLeaving}
          onOk={handleOkLeaving}
          onCancel={() => setIsModalVisibleLeaving(false)}
          message={
            pageId
              ? "Are you sure you want to cancel editing"
              : "All unsaved data will be lost. Are you sure you want to leave this page?"
          }
          cancelText={pageId ? "No" : "Stay here"}
          okText="Yes"
          okButtonWidth="7.5em"
          cancelButtonWidth="7.5em"
        />
      </Form>
    </>
  );
};

export default Forms;
