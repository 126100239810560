import sectionOne from "../../../../assets/section-modal/section-1.svg";
import sectionTwo from "../../../../assets/section-modal/section-2.svg";
import sectionThree from "../../../../assets/section-modal/section-3.svg";
import sectionFour from "../../../../assets/section-modal/section-4.svg";
import sectionFive from "../../../../assets/section-modal/section-5.svg";
import sectionSix from "../../../../assets/section-modal/section-6.svg";
import sectionSeven from "../../../../assets/section-modal/section-7.svg";
import sectionEight from "../../../../assets/section-modal/section-8.svg";

const imageTitleDesction = {
  image: null,
  header_title: "",
  text_content: "",
  link: "",
  file: "",
  section_status: true,
};

const titleDescription = {
  // image: "",
  header_title: "",
  text_content: "",
  link: "",
  file: "",
  section_status: true,
};

export const sectionList = [
  {
    //title, text, image (left)
    label: "Title, Text & Image (Left Side)",
    image: sectionOne,
    // type: "section 1",
    type: "LEFT_SIDE",
    fields: imageTitleDesction,
  },
  {
    //title, text, image (center top)
    label: "Title, Text & Image (Center Top)",
    image: sectionTwo,
    type: "CENTER_TOP",
    fields: imageTitleDesction,
  },
  {
    //title, text, image (center bottom)
    label: "Title, Text & Image (Center Bottom)",
    image: sectionThree,
    type: "CENTER_BOTTOM",
    fields: imageTitleDesction,
  },
  {
    //title, text, image (right)
    label: "Title, Text & Image (Right Side)",
    image: sectionFour,
    type: "RIGHT_SIDE",
    fields: imageTitleDesction,
  },
  {
    //title, text (left align)
    label: "Title & Text (Left Align)",
    image: sectionFive,
    type: "LEFT_ALIGN",
    fields: titleDescription,
  },
  {
    //title, text (center align)
    label: "Title & Text (Center Align)",
    image: sectionSix,
    type: "CENTER_ALIGN",
    fields: titleDescription,
  },
  {
    //title, text (right align)
    label: "Title & Text (Right Align)",
    image: sectionSeven,
    type: "RIGHT_ALIGN",
    fields: titleDescription,
  },
  {
    // video link
    label: "Featured Video",
    image: sectionEight,
    type: "FEATURED_VIDEO",
    fields: {
      link: "",
      image: null,
      section_status: true,
    },
  },
];
