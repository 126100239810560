import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { activeColumns } from "./constants";
import CustomDashboardList from "@/components/CustomDashboard1";
import { GET_CMS_USERS, UPDATE_CMS_USER_STATUS  } from "../../../graphql";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import CustomModal from "@/components/CustomModal";
import { message } from "antd";

const CmsUserList: React.FC = () => {
  let match = useRouteMatch();
  const history = useHistory();
  const [cmsList, setCmsList] = useState<any>([]);
  const [originalCMSList, setOriginalCMSList] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState<string>("");
  const [bodyMessage, setBodyMessage] = useState<string>("");
  const [statusStr, setStatusStr] = useState<string>("");
  const [bodyMessageSuccess, setBodyMessageSuccess] = useState<string>("");
  const [okTxt, setOkTxt] = useState<string>("OK");
  const [userId, setUserId] = useState("");
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);

  const { loading, refetch, data } = useQuery(GET_CMS_USERS);
  const [modifyStatus] = useMutation(UPDATE_CMS_USER_STATUS);

  useEffect(() => {
    if (data) {
      refetch();
      const list = [...data.getUsers];

      setCmsList(list);
      setOriginalCMSList(list);
    }
  }, [data, refetch]);

  const handleMenu = (menuItem: string, id: string, name: string): void => {
    if (menuItem === "edit") {
      history.push(`${match.path}/edit-user/${id}`, { pageId: id });
    }
    if (menuItem === "view") {
      history.push(`${match.path}/view-user/${id}`, { pageId: id });
    }
    if (menuItem === "activate") {
      handleActivate(id, name);
    }
    if (menuItem === "deactivate") {
      handleDeactivate(id, name);
    }
  };

  const handleActivate = (id, name): void => {
    setShowModal(true);
    setTitleMessage("Activate Role?");
    setBodyMessage(`Are you sure you want to activate '${name}'?`);
    setStatusStr("activate");
    setOkTxt("Activate");
    setUserId(id);
  };

  const handleDeactivate = (id, name): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(`Are you sure you want to deactivate '${name}'?`);
    setStatusStr("deactivate");
    setOkTxt("Deactivate");
    setUserId(id);
  };

  const constructVariables = (user: string, status: any): object => {
    return {
      id: user,
      status: status,
    };
  };

  const updateStatus = async (id: string, status: string): Promise<void> => {
    const input = constructVariables(id, status);

    try {
      const { data: res } = await modifyStatus({
        variables: {
          updateUserStatusInput: input,
        },
      });

      if (res.updateUserStatus) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
    refetch();
  };

  const checkStatusStrOkModal = async (id, statusStr): Promise<void> => {
    if (statusStr === "activate") {
      await updateStatus(id, 'ACTIVE');
      setBodyMessageSuccess("This user was successfully activated.");
    } else if (statusStr === "deactivate") {
      await updateStatus(id, 'INACTIVE');
      setBodyMessageSuccess("This user was successfully deactivated.");
    }
    setShowModal(false);
    setShowModalSuccess(true);
  };

  // let cmsData;
  // if (localStorage.cmsUserList) {
  //   cmsData = JSON.parse(localStorage.cmsUserList);
  // } else {
  //   localStorage.setItem("cmsUserList", JSON.stringify(cmsUserDummyData));
  //   cmsData = cmsUserDummyData;
  // }

  const handleSelectedRow = (id): void => {
    history.push(`${match.path}/view-user/${encodeURI(id)}`, { pageId: id });
  };

  return (
    <>
      <CustomDashboardList
        loading={false}
        headerColumns={activeColumns}
        data={cmsList}
        fullData={cmsList}
        originalData={originalCMSList}
        handleMenu={handleMenu}
        handleData={setCmsList}
        handleSelectedRow={handleSelectedRow}
        searchPlaceholder="Search CMS User"
      />

      {/* Confirmation modal */}
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showModal}
        okButtonWidth="8em"
        onOk={() => checkStatusStrOkModal(userId, statusStr)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />

      {/* Success Modal */}
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon
        titleMessage="Success!"
        visible={showModalSuccess}
        onOk={() => setShowModalSuccess(false)}
        onCancel={() => setShowModalSuccess(false)}
        message={bodyMessageSuccess}
        okText={"OK"}
      />
    </>
  );
};

export default CmsUserList;
