import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Col, Row } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { PlusOutlined } from "@ant-design/icons";
import { message } from "antd";
import _ from "lodash";
import moment from "moment";
import ActionsMenu from "@/components/ActionsMenu";
import ContentContainer from "@/components/ContentContainer";
import CustomButton from "@/components/CustomButton";
import CustomModal from "@/components/CustomModal";
import { STATUS } from "../../../../../utilities/enums";
import { actions, dummyData, recipientFilter } from "./constants";
import { GET_NOTIFICATIONS, UPDATE_NOTIFICATION_STATUS } from "../../../graphql";
import { DateAndTime, StyledSearch, StyledTable } from "./styled";

const NotificationsList = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const [notifId, setNotifId] = useState("");

  const [isPublish, setIsPublish] = useState<boolean>(false);
  const [notificationsList, setNotificationsList] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [bodyMessageSuccess, setBodyMessageSuccess] = useState("");
  const [statusStr, setStatusStr] = useState("");
  const [okTxt, setOkTxt] = useState("OK");
  const [searchText, setSearchText] = useState("");
  const [modifyStatus] = useMutation(UPDATE_NOTIFICATION_STATUS);
  const { loading, refetch, data } = useQuery(GET_NOTIFICATIONS);
  const [creatorFilter, setCreatorFilter] = useState<{ text: string; value: string }[]>([]);
  const [editorFilter, setEditorFilter] = useState<{ text: string; value: string }[]>([]);

  useEffect(() => {
    if (data) {
      refetch();
      setNotificationsList(data.getNotifications);
    }
  }, [data, refetch]);

  const constructVariables = (notificationId: string, status: STATUS): object => {
    return {
      notification_id: notificationId,
      status: status,
    };
  };

  const updateStatus = async (id: string, status: STATUS): Promise<void> => {
    const input = constructVariables(id, status);

    try {
      const { data: res } = await modifyStatus({
        variables: {
          Input: input,
        },
      });

      if (res.updateNotificationStatus) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const checkStatusStrOkModal = async (id, statusStr): Promise<void> => {
    const { name } = notificationsList.find(({ id: notifID }) => id === notifID)!;

    if (statusStr === "published") {
      await updateStatus(id, STATUS.PUBLISHED);
      setBodyMessageSuccess(`${name} was successfully published.`);
    } else if (statusStr === "archived") {
      await updateStatus(id, STATUS.ARCHIVED);
      setBodyMessageSuccess(`${name} was successfully archived.`);
    } else if (statusStr === "scheduled") {
      await updateStatus(id, STATUS.SCHEDULED);
      setBodyMessageSuccess(`${name} was successfully scheduled.`);
    } else if (statusStr === "deleted") {
      await updateStatus(id, STATUS.DELETED);
      setBodyMessageSuccess(`${name} was successfully deleted.`);
    }
    setShowModal(false);
    setShowModalSuccess(true);
  };

  const handleDelete = (id: string): void => {
    const { name } = notificationsList.find(({ id: notifID }) => id === notifID)!;

    setShowModal(true);
    setTitleMessage("Delete Notification?");
    setBodyMessage(`Are you sure you want to delete ${name}?`);
    setStatusStr("deleted");
    setOkTxt("Delete");
    setIsPublish(false);
    setNotifId(id);
  };

  const handlePublish = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By publishing this notification, all information and documents uploaded in this notification will be visible in the mobile app when deployed."
    );
    setStatusStr("published");
    setOkTxt("Publish");
    setIsPublish(true);
    setNotifId(id);
  };

  const handleMenu = (menuItem: string, id: string): void => {
    if (menuItem === "Edit") {
      history.push(`${match.path}/edit-notification/${id}`, { pageId: id });
    }
    if (menuItem === "View") {
      history.push(`${match.path}/view-notification/${id}`, { pageId: id });
    }
    if (menuItem === "Publish") {
      handlePublish(id);
    }
    if (menuItem === "Schedule") {
      handleSchedule(id);
    }
    if (menuItem === "Delete") {
      handleDelete(id);
    }
    if (menuItem === "Archive") {
      handleArchived(id);
    }
  };

  const handleSchedule = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By scheduling this notification, all information and documents uploaded in this notification will be visible in the mobile app on its scheduled release."
    );
    setStatusStr("scheduled");
    setOkTxt("Schedule");
    setIsPublish(false);
    setNotifId(id);
  };

  const handleArchived = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage("By archiving this notification, it will not be visible in the mobile app.");
    setStatusStr("archived");
    setOkTxt("Archive");
    setIsPublish(false);
    setNotifId(id);
  };

  const filterByRecipient = (value, record) => {
    const AllRecipients = record.recipients.map(({ recipient_type }) => {
      return recipient_type;
    });
    if (value === "All") {
      return !AllRecipients.length;
    }
    return AllRecipients.includes(value);
  };

  useEffect(() => {
    setCreatorFilter(() => {
      const creators = _.map(notificationsList, ({ created_by }) => {
        return { text: created_by, value: created_by };
      });
      return _.uniqBy(creators, function (e: any) {
        return e.text;
      });
    });
    setEditorFilter(() => {
      const creators = _.map(notificationsList, ({ updated_by }) => {
        return { text: updated_by, value: updated_by };
      });
      return _.uniqBy(creators, function (e: any) {
        return e.text;
      });
    });
  }, [notificationsList]);

  const handleSelectedRow = (id, { target: { nodeName, textContent } }): void => {
    const actions = ["Edit", "Delete", "Archive", "Schedule", "Draft"];
    const nodeNames = ["svg", "path", "LI", "DIV", "IMG"];

    if (actions.includes(textContent) || nodeNames.includes(nodeName)) {
      return;
    }
    history.push(`${match.path}/view-notification/${id}`, { pageId: id });
  };

  function toTimeZone(time: any) {
    var format = "YYYY-MM-DD hh:mm:ss";
    return moment(time, format).format(format);
  }

  const columns = [
    {
      title: "Type",
      dataIndex: "notification_type",
      key: "notification_type",
      filters: [
        { text: "Promotion", value: "PROMOTION" },
        { text: "In-stay", value: "IN_STAY" },
        { text: "Booking", value: "BOOKING" },
      ],
      filterMode: "tree",
      onFilter: (value: string, record) => record.notification_type.includes(value),
      render: (text, record) => (
        <section>
          <span>{_.capitalize(text)}</span>
        </section>
      ),
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created by",
      dataIndex: "created_by",
      key: "created_by",
      filters: creatorFilter,
      onFilter: (value: string, record) => record.created_by === value,
      render: (text, record) => (
        <section>
          <span>{text}</span>
          <br />
          <DateAndTime>{toTimeZone(record.created_at)}</DateAndTime>
        </section>
      ),
    },
    {
      title: "Last Edited by",
      dataIndex: "updated_by",
      key: "updated_by",
      filters: editorFilter,
      onFilter: (value: string, record) => record.updated_by === value,
      render: (text, record) => (
        <section>
          <span>{text}</span>
          <br />
          <DateAndTime>{toTimeZone(record.updated_at)}</DateAndTime>
        </section>
      ),
    },
    {
      title: "Recipients",
      dataIndex: "recipients",
      key: "recipients",
      filters: recipientFilter,
      filterMode: "tree",
      onFilter: (value, record) => filterByRecipient(value, record),
      render: (categories, record) => {
        if (!categories.length) {
          return <span>All</span>;
        }
        const mappedCategories = categories
          .slice(0, 2)
          .map(({ recipient_type }) => {
            return `By ${_.startCase(recipient_type.toLowerCase().replace("_", " "))}`;
          })
          .join(", ");

        return (
          <>
            {mappedCategories}
            {categories.length > 2 && "..."}
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Sent", value: "SENT" },
        { text: "Deleted", value: "DELETED" },
        { text: "Scheduled", value: "SCHEDULED" },
        { text: "Draft", value: "DRAFT" },
        { text: "Archived", value: "ARCHIVED" },
        { text: "Published", value: "PUBLISHED" },
      ],
      filterMode: "tree",
      onFilter: (value: string, record) => record.status === value,
      render: (text) => {
        const checker = text.toLowerCase();
        let hexColor;
        if (checker === "sent") {
          hexColor = "#00C48C";
        } else if (checker === "deleted") {
          hexColor = "#D7272D";
        } else if (checker === "scheduled") {
          hexColor = "#FFCF5C";
        }

        return (
          <section>
            <span style={{ color: hexColor }}>{_.capitalize(text)}</span>
          </section>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return <ActionsMenu handleMenu={handleMenu} data={record} actions={actions} />;
      },
    },
  ];

  const handleAddBtn = () => {
    history.push(`${match.path}/add-notification`);
  };

  useEffect(() => {
    if (searchText) {
      const value = searchText.toLowerCase();

      const newData = data.getNotifications.filter((item) => {
        const title = item.name.toLowerCase();
        const updatedBy = item.updated_by.toLowerCase();
        const createdBy = item.created_by.toLowerCase();
        const promotionStatus = item.status.toLowerCase();

        return (
          title.includes(value) ||
          updatedBy.includes(value) ||
          createdBy.includes(value) ||
          promotionStatus.includes(value)
        );
      });
      setNotificationsList(newData);
    } else {
      setNotificationsList(data?.getNotifications);
    }
  }, [searchText]);

  return (
    <>
      <ContentContainer>
        <Row justify="space-between" style={{ marginBottom: "2rem" }}>
          <Col span={8}>
            <StyledSearch
              placeholder="Search Notifications"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              enterButton
              allowClear
            />
          </Col>

          <Col>
            <CustomButton onClick={handleAddBtn} style={{ height: 50, padding: "0px 30px" }}>
              <PlusOutlined /> Add
            </CustomButton>
          </Col>
        </Row>
        <StyledTable
          dataSource={notificationsList}
          columns={columns}
          onRow={({ id }) => {
            return {
              onClick: (e) => handleSelectedRow(id, e),
            };
          }}
          rowClassName={(record) => (record.status === "DELETED" ? "deleted" : null)}
        />
      </ContentContainer>

      {/* Confirmation modal */}
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showModal}
        onOk={() => checkStatusStrOkModal(notifId, statusStr)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />

      {/* Success Modal */}
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon
        titleMessage="Success!"
        visible={showModalSuccess}
        onOk={() => setShowModalSuccess(false)}
        onCancel={() => setShowModalSuccess(false)}
        message={bodyMessageSuccess}
        okText={isPublish ? "Great!" : "OK"}
      />
    </>
  );
};

export default NotificationsList;
