import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import RHRInformationsView from "./components/rhr-informations/rhr-informations-view"
import FooterInformationForm from "./components/rhr-informations/rhr-informations-form";

const FooterManagement: React.FC = () => {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.path}/rhr-informations`}
          render={(props: any) => <RHRInformationsView {...props} />}
        />
      </Switch>
      <Switch>
        <Route
          exact
          path={`${match.path}/rhr-informations/edit-rhr-informations/:pageId?`}
          render={(props: any) => <FooterInformationForm {...props} />}
        />
      </Switch>
    </>
  );
};

export default FooterManagement;
