import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { FieldArray } from "formik";
import { FormItem, Input } from "formik-antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";

import CustomSelect from "@/components/CustomSelect";

import SocialLinksSection from "./social-links";
import { GET_COUNTRY_CODE_LIST } from "../../../graphql";

import {
  CustomSelectInput,
  InputWrapper,
  StyledButtonAddContact,
  FormItemWrapper,
} from "../styled";

interface Props {
  formikBag: any;
}

const renderLandlineNos = ({ event, values }) => {
  const removeEmptyInput = (e) => {
    console.log(e.key);
    if (values.landline_nos.length < 5) {
      let arr = values.landline_nos;
      arr
        .filter((item) => item.input === "")
        .forEach((landline, i) => {
          event.remove(i);
        });
      event.push({ input: "" });
    }
  };

  return (
    <>
      <label>Landline No.</label>
      {values?.landline_nos?.map((item, key) => (
        <Col span={24}>
          <FormItemWrapper>
            <FormItem
              name={`landline_nos[${key}].input`}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <InputWrapper>
                <Input
                  name={`landline_nos[${key}].input`}
                  placeholder="e.g +63212345678"
                  maxLength={13}
                />
                <CloseOutlined
                  style={
                    values.landline_nos.length > 1 ? {} : { pointerEvents: "none", opacity: "0" }
                  }
                  className="remove-btn"
                  onClick={() => values.landline_nos.length > 1 && event.remove(key)}
                />
              </InputWrapper>
            </FormItem>
          </FormItemWrapper>
        </Col>
      ))}
      <StyledButtonAddContact
        style={
          values.landline_nos[values.landline_nos.length - 1].input === ""
            ? { pointerEvents: "none", opacity: "0.3" }
            : {}
        }
        type="text"
        disabled={values.landline_nos.length === 5}
        onClick={removeEmptyInput}
      >
        <PlusOutlined />
        Add Landline No.
      </StyledButtonAddContact>
    </>
  );
};

const renderMobileNos = ({ event, values }, countryCodeList) => {
  const removeEmptyInput = () => {
    if (values.mobile_nos.length < 5) {
      let arr = values.mobile_nos;
      arr
        .filter((item) => item.input === "")
        .forEach((mobile, i) => {
          event.remove(i);
        });
      event.push({ input: "", country_code: "+63" });
    }
  };
  return (
    <>
      <label>Mobile No.</label>
      {values?.mobile_nos?.map((item, key) => (
        <CustomSelectInput>
          <Row>
            <Col span={7}>
              <FormItem
                name={`mobile_nos[${key}].country_code`}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <CustomSelect
                  defaultValue="+63"
                  name={`mobile_nos[${key}].country_code`}
                  options={countryCodeList}
                  placeholder="+63"
                  hasFlag
                />
              </FormItem>
            </Col>
            <Col span={17}>
              <FormItem
                name={`mobile_nos[${key}].input`}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InputWrapper>
                  <Input
                    name={`mobile_nos[${key}].input`}
                    placeholder="e.g 9172980102"
                    maxLength={10}
                  />
                  <CloseOutlined
                    style={
                      values.mobile_nos.length > 1 ? {} : { pointerEvents: "none", opacity: "0" }
                    }
                    className="remove-btn"
                    onClick={() => values.mobile_nos.length > 1 && event.remove(key)}
                  />
                </InputWrapper>
              </FormItem>
            </Col>
          </Row>
        </CustomSelectInput>
      ))}
      <Col span={24}>
        <StyledButtonAddContact
          style={
            values.mobile_nos[values.mobile_nos.length - 1].input === ""
              ? { pointerEvents: "none", opacity: "0.3" }
              : {}
          }
          type="text"
          disabled={values.mobile_nos.length === 5}
          onClick={removeEmptyInput}
        >
          <PlusOutlined />
          Add Mobile No.
        </StyledButtonAddContact>
      </Col>
    </>
  );
};

const renderEmailAddresses = ({ event, values }) => {
  const removeEmptyInput = (e) => {
    console.log(e.key);
    if (values.email_addresses.length < 5) {
      let arr = values.email_addresses;
      arr
        .filter((item) => item.input === "")
        .forEach((mobile, i) => {
          event.remove(i);
        });
      event.push({ input: "" });
    }
  };
  return (
    <>
      <label>Email Address</label>
      {values?.email_addresses?.map((item, key) => (
        <Col span={24}>
          <FormItemWrapper>
            <FormItem
              name={`email_addresses[${key}].input`}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <InputWrapper>
                <Input
                  name={`email_addresses[${key}].input`}
                  type="email"
                  placeholder="Add Email Address"
                />
                <CloseOutlined
                  style={
                    values.email_addresses.length > 1 ? {} : { pointerEvents: "none", opacity: "0" }
                  }
                  className="remove-btn"
                  onClick={() => values.email_addresses.length > 1 && event.remove(key)}
                />
              </InputWrapper>
            </FormItem>
          </FormItemWrapper>
        </Col>
      ))}
      <Col span={24}>
        <StyledButtonAddContact
          style={
            values.email_addresses[values.email_addresses.length - 1].input === ""
              ? { pointerEvents: "none", opacity: "0.3" }
              : {}
          }
          type="text"
          disabled={values.email_addresses.length === 5}
          onClick={removeEmptyInput}
        >
          <PlusOutlined />
          Add Email Address
        </StyledButtonAddContact>
      </Col>
    </>
  );
};

const ContactInformationSection: React.FC<Props> = ({ formikBag }) => {
  const { values } = formikBag;
  const { data: countryCodes } = useQuery(GET_COUNTRY_CODE_LIST);
  const [countryCodeList, setCountryCodeList] = useState();

  useEffect(() => {
    let arrayList: any = [];

    if (countryCodes) {
      const countryCodeArr = countryCodes.countryCodes;
      countryCodeArr.forEach((item, index) => {
        if (item.dial_code) {
          arrayList.push({ value: item.dial_code, label: item.dial_code, flag: item.flag });
        }
      });
      setCountryCodeList(arrayList);
    }
  }, [countryCodes]);

  return (
    <>
      <Row gutter={20} style={{ marginBottom: "1.5rem" }}>
        <Col span={8}>
          <Row>
            <FieldArray
              name="landline_nos"
              render={(event) => renderLandlineNos({ event, values })}
            />
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <FieldArray
              name="mobile_nos"
              render={(event) => renderMobileNos({ event, values }, countryCodeList)}
            />
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <FieldArray
              name="email_addresses"
              render={(event) => renderEmailAddresses({ event, values })}
            />
          </Row>
        </Col>
      </Row>
      <SocialLinksSection formikBag={formikBag} />
    </>
  );
};

export default ContactInformationSection;
