export const activeColumns = [
  {
    title: "Logo",
    dataIndex: "logo",
  },
  {
    title: "Brand Name",
    dataIndex: "brandName",
  },
  {
    title: "Last Edited By",
    dataIndex: "lastEditedBy",
  },
  {
    title: "Last Deployed By",
    dataIndex: "lastDeployedBy",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Actions",
    dataIndex: "actions",
  },
];

// export const activeColumns = [
//   {
//     render: () => <img src={DragIcon} alt="^" />,
//     width: "7%",
//   },
//   {
//     title: "Logo",
//     dataIndex: "logo",
//     key: "logo",
//   },
//   {
//     title: "Brand Name",
//     dataIndex: "name",
//     key: "name",
//   },
//   {
//     title: "Last Edited By",
//     dataIndex: "updated_by",
//     key: "updated_by",
//     render: (text, record) => (
//       <>
//         <div style={{ color: "var(--color-general-black)", fontSize: 14 }}>{record.updated_by}</div>
//         <div style={{ color: "var(--color-general-blue-gray)", fontSize: 14 }}>
//           {moment(record.updated_at).format("YYYY-MM-DD HH:mm:ss")}
//         </div>
//       </>
//     ),
//   },
//   {
//     title: "Last Deployed By",
//     dataIndex: "deployed_by",
//     key: "deployed_by",
//     render: (text, record) => (
//       <>
//         <div style={{ color: "var(--color-general-black)", fontSize: 14 }}>
//           {record.deployed_by}
//         </div>
//         <div style={{ color: "var(--color-general-blue-gray)", fontSize: 14 }}>
//           {moment(record.deployed_at).format("YYYY-MM-DD HH:mm:ss")}
//         </div>
//       </>
//     ),
//   },
//   {
//     title: "Status",
//     dataIndex: "status",
//     key: "status",
//     render: (text, item) => (
//       <span
//         style={{
//           color:
//             lowerCase(item.status) === "deleted"
//               ? "var(--color-secondary)"
//               : "var(--color-general-black)",
//         }}
//       >
//         {capitalize(item.status)}
//       </span>
//     ),
//   },
// ];
