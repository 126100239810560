import { Alert, Col, message, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";

import ContentContainer from "@/components/ContentContainer";
import CustomModal from "@/components/CustomModal";
import GoLogo from "../../../../../assets/images/callback-image-no-BG.png";
import { IconKey, IconKeyTextCont } from "@/components/ActionsMenu/styled";
import { EditPenIcon, PublishIcon, DeleteIcon, ArchiveIcon } from "../../../../../utilities/icons";
import { PromotionsI } from "../../../../../models/PromotionsInterface";
import { GET_PROMOTION, UPDATE_PROMOTION_STATUS } from "../../../graphql";
import { STATUS } from "../../../../../utilities/enums";
import {
  ActionButtons,
  ActionButtonsContainer,
  ActionButtonWrap,
  AlertMessage,
  BorderTop,
  ImgPhoto,
  PromoPageWrap,
  SectionWrap,
  Sectiontitle,
  Span,
  SpanBY,
  Subcontext,
  Subtitle,
} from "./styled";

const DOMPurify = require("dompurify")(window);

const PromotionsView = (): JSX.Element => {
  const { pageId }: { pageId: string } = useParams();
  const history = useHistory();

  const [brandHotels, setBrandHotels] = useState<any>([]);
  const [brandKeys, setBrandKeys] = useState<any>([]);
  const [titleMessage, setTitleMessage] = useState<string>("");
  const [bodyMessage, setBodyMessage] = useState<string>("");
  const [bodyMessageSuccess, setBodyMessageSuccess] = useState<string>("");
  const [okTxt, setOkTxt] = useState<string>("OK");
  const [promoData, setPromoData] = useState<PromotionsI>();
  const [showActionButtons, setShowActionButtons] = useState<boolean>(false);
  const [showArchiveBtn, setShowArchiveBtn] = useState<boolean>(false);
  const [showDeleteBtn, setShowDeleteBtn] = useState<boolean>(false);
  const [showEditBtn, setShowEditBtn] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [showPublishBtn, setShowPublishBtn] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("draft");
  const [statusStr, setStatusStr] = useState<string>("");

  const [modifyStatus] = useMutation(UPDATE_PROMOTION_STATUS);

  const { loading, refetch, data } = useQuery(GET_PROMOTION, {
    skip: !pageId,
    fetchPolicy: "no-cache",
    variables: {
      id: pageId,
    },
  });

  useEffect(() => {
    if (data) {
      refetch();
      setPromoData(data.getPromotionById);
      setStatus(data.getPromotionById.status);
    }
  }, [data, refetch]);

  useEffect(() => {
    if (promoData) {
      const status = promoData.status;
      setShowActionButtons(status !== "DELETED");
      setShowArchiveBtn(status !== "ARCHIVED" && status !== "DRAFT" && status !== "DELETED");
      setShowDeleteBtn(status !== "PUBLISHED" && status !== "DELETED");
      setShowEditBtn(status !== "DELETED");
      setShowPublishBtn(status !== "PUBLISHED" && status !== "DELETED");

      const groupedHotels = promoData.promotions_participating_hotels
        .map((item) => {
          return {
            brand: item.brand.name,
            hotel: item.hotel.name,
          };
        })
        .reduce((acc, obj) => {
          let key = obj["brand"];
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj.hotel);
          return acc;
        }, {});

      setBrandKeys(Object.keys(groupedHotels));
      setBrandHotels(groupedHotels);
    }
  }, [promoData]);

  const onErrorImg = (e): void => {
    e.target.src = GoLogo;
  };

  const constructVariables = (promotionId: string, status: STATUS): object => {
    return {
      promotion_id: promotionId,
      status: status,
    };
  };

  const updateStatus = async (id: string, status: STATUS): Promise<void> => {
    const input = constructVariables(id, status);

    try {
      const { data: res } = await modifyStatus({
        variables: {
          Input: input,
        },
      });

      if (res.updatePromotionStatus) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const checkStatusStrOkModal = async (statusStr) => {
    if (statusStr === "published") {
      await updateStatus(pageId, STATUS.PUBLISHED);
      setBodyMessageSuccess("This promotion was successfully published.");
    }

    if (statusStr === "archived") {
      await updateStatus(pageId, STATUS.ARCHIVED);
      setBodyMessageSuccess("This promotion was successfully archived.");
    }
    if (statusStr === "deleted") {
      await updateStatus(pageId, STATUS.DELETED);
      setBodyMessageSuccess("This promo was successfully deleted.");
    }
    setShowModal(false);
    setShowModalSuccess(true);
  };

  // Go to Edit page
  const handleEdit = () => {
    history.push(`/notification-settings/promotions/edit-promotion/${pageId}`, { pageId });
  };

  // Delete confirmation modal content
  const handleDelete = () => {
    setShowModal(true);
    setTitleMessage("Delete Promotion?");
    setBodyMessage("Are you sure you want to delete this promo?");
    setStatusStr("deleted");
    setOkTxt("Delete");
  };

  // Archive confirmation modal content
  const handleArchived = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By archiving this promotion, it will not be visible in the mobile app when deployed."
    );
    setStatusStr("archived");
    setOkTxt("Archive");
  };

  const handlePublish = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By publishing this promo, all information and documents uploaded in this promo will be visible in the website when deployed."
    );
    setStatusStr("published");
    setOkTxt("Publish");
  };
  const showAlert = promoData?.is_featured ? (
    <Alert
      message="You have set this as a featured promotion"
      description={<AlertMessage>Only 3 featured promotions are allowed.</AlertMessage>}
      type="warning"
      showIcon
    />
  ) : null;

  const showBrandHotels = brandKeys.map((brand, index) => {
    const hotels = brandHotels[brand].join(", ");

    return (
      <Span primary key={index}>
        {`${brand} - ${hotels}`}
      </Span>
    );
  });

  return (
    <>
      <Spin spinning={loading}>
        {promoData !== undefined && (
          <PromoPageWrap>
            <ActionButtonsContainer show={showActionButtons}>
              <Row>
                <Col span="12" offset="12" style={{ textAlign: "right" }}>
                  <ActionButtonWrap>
                    <ActionButtons show={showDeleteBtn} onClick={handleDelete}>
                      <IconKeyTextCont>
                        <IconKey>
                          <DeleteIcon style={{ color: "red" }} />
                        </IconKey>{" "}
                        Delete
                      </IconKeyTextCont>
                    </ActionButtons>
                    <ActionButtons type="primary" show={showEditBtn} onClick={handleEdit}>
                      <IconKeyTextCont>
                        <IconKey>
                          <EditPenIcon />
                        </IconKey>{" "}
                        Edit
                      </IconKeyTextCont>
                    </ActionButtons>
                    <ActionButtons type="primary" show={showArchiveBtn} onClick={handleArchived}>
                      <IconKeyTextCont>
                        <IconKey>
                          <ArchiveIcon />{" "}
                        </IconKey>{" "}
                        Archive
                      </IconKeyTextCont>
                    </ActionButtons>
                    <ActionButtons type="primary" show={showPublishBtn} onClick={handlePublish}>
                      <IconKeyTextCont>
                        <IconKey>
                          <PublishIcon />{" "}
                        </IconKey>{" "}
                        Publish
                      </IconKeyTextCont>
                    </ActionButtons>
                  </ActionButtonWrap>
                </Col>
              </Row>
            </ActionButtonsContainer>
            <SectionWrap>
              <ContentContainer>
                <Row justify="end">This promo is currently {status.toUpperCase()}</Row>
                <Row>
                  <Col span={8}>
                    <Subtitle>Photo</Subtitle>
                    <ImgPhoto src={promoData.photo_url} onError={onErrorImg} alt="Promo Image" />
                  </Col>
                  <Col span={16}>
                    <Subcontext>
                      <Subtitle>Promo Title</Subtitle>
                      <Span primary>{promoData.title}</Span>
                    </Subcontext>
                    <Subcontext>
                      <Subtitle>Promo Description</Subtitle>
                      <Span
                        primary
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(promoData.description),
                        }}
                      />
                    </Subcontext>
                    <Subcontext>
                      <Subtitle>Participating Hotels</Subtitle>
                      {showBrandHotels}
                    </Subcontext>
                    <Subcontext>
                      <Subtitle>Promo Period</Subtitle>
                      <Span primary>
                        <Row>
                          <Col span={12}>Start Date: {promoData.start_date}</Col>
                          <Col span={12}>Start Time {promoData.start_time}</Col>
                        </Row>
                        <Row>
                          <Col span={12}>End Date: {promoData.end_date}</Col>
                          <Col span={12}>End Time {promoData.end_time}</Col>
                        </Row>
                      </Span>
                    </Subcontext>
                    <Subcontext>
                      <Subtitle>Redirect Link ({promoData.cta_button_name} Button)</Subtitle>
                      <Span href={promoData.cta_button_link}>{promoData.cta_button_link}</Span>
                    </Subcontext>
                    {showAlert}
                  </Col>
                </Row>
              </ContentContainer>
              <BorderTop />
              <ContentContainer>
                <Sectiontitle>Promo Details</Sectiontitle>
                <Row>
                  <Col span={8}>
                    <Subtitle>Date/Time Created</Subtitle>
                    <Span primary>
                      {moment(promoData.created_at).format("YYYY-MM-DD HH:mm:ss")}
                    </Span>
                    <SpanBY>by {promoData.created_by}</SpanBY>
                  </Col>
                  <Col span={8}>
                    <Subtitle>Last Date/Time Edited</Subtitle>
                    <Span primary>
                      {moment(promoData.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                    </Span>
                    <SpanBY>by {promoData.updated_by}</SpanBY>
                  </Col>
                  <Col span={8}>
                    <Subtitle>Last Date/Time Deployed</Subtitle>
                    <Span primary>
                      {moment(promoData.deployed_at).format("YYYY-MM-DD HH:mm:ss")}
                    </Span>
                    <SpanBY>by {promoData.deployed_by}</SpanBY>
                  </Col>
                </Row>
              </ContentContainer>
            </SectionWrap>
          </PromoPageWrap>
        )}
      </Spin>

      {/* Confirmation Modal */}
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showModal}
        onOk={() => checkStatusStrOkModal(statusStr)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />

      {/* Success Modal */}
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon
        titleMessage="Success!"
        visible={showModalSuccess}
        onOk={() => setShowModalSuccess(false)}
        onCancel={() => setShowModalSuccess(false)}
        message={bodyMessageSuccess}
        okText={"OK"}
      />
    </>
  );
};

export default PromotionsView;
