import { Menu } from "antd";
import { lowerCase } from "lodash";

import { IconKey, IconKeyTextCont, MenuContainer } from "./styled";
import kebabIcon from "../../assets/images/icons/ic_more_kebab.svg";
import {
  ArchiveIcon,
  CancelIcon,
  CheckIcon,
  DeleteIcon,
  EditPenIcon,
  EyeIcon,
  PublishIcon,
  ScheduleIcon,
} from "../../utilities/icons";

const { SubMenu, Item } = Menu;

interface Props {
  handleMenu?: (menuItem: string, id: string, name: string) => void;
  data: any;
  actions: Array<any>;
}

const ActionsMenu: React.FC<Props> = (props) => {
  const { handleMenu, data, actions } = props;

  const handleIcon = (name) => {
    if (name === "View") {
      return <EyeIcon />;
    } else if (name === "Edit") {
      return <EditPenIcon />;
    } else if (name === "Publish") {
      return <PublishIcon />;
    } else if (name === "Archive") {
      return <ArchiveIcon />;
    } else if (name === "Delete") {
      return <DeleteIcon />;
    } else if (name === "Activate") {
      return <CheckIcon />;
    } else if (name === "Deactivate") {
      return <CancelIcon />;
    } else if (name === "Schedule") {
      return <ScheduleIcon />;
    }
  };

  const onSelect = (item: any, id: string, name: string) => {
    handleMenu && handleMenu(item.key, id, name);
  };

  const actionItems = actions.map((item): JSX.Element | null => {
    const { name, checkStatus } = item;

    if (checkStatus !== null && checkStatus.indexOf(lowerCase(data.status)) >= 0) {
      return null;
    }

    const icon = handleIcon(name);
    const isDelete = name === "Delete" || name === "Deactivate";
    const itemColor = isDelete ? "red" : "#1b467f";

    return (
      <Item
        style={{
          display: "flex",
          alignItems: "center",
          height: 35,
          lineHeight: "unset",
          margin: 0,
          fontSize: 14,
          color: itemColor,
        }}
        key={name}
      >
        <IconKeyTextCont>
          <IconKey style={{ color: isDelete }}>{icon}</IconKey> <span>{name}</span>
        </IconKeyTextCont>
      </Item>
    );
  });

  return (
    <MenuContainer>
      <Menu
        selectable={false}
        className="kebab-menu"
        style={{
          display: lowerCase(data.status) === "deleted" ? "none" : "block",
        }}
        onClick={(menuItem) => onSelect(menuItem, data.id, data.name)}
      >
        <SubMenu icon={<img src={kebabIcon} alt="menu icon" />}>{actionItems}</SubMenu>
      </Menu>
    </MenuContainer>
  );
};

export default ActionsMenu;
