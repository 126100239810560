import styled from "styled-components";
import { Modal } from "antd";
import Icon from "@ant-design/icons";

export const StyledModal = styled(Modal)({
  ".ant-modal-close": {
    display: "none",
  },
  ".ant-modal-footer": {
    border: 0,
    textAlign: "center",
    paddingBottom: "1.5em",
  },
});

export const ContentWrapper = styled("div")({
  textAlign: "center",
});

export const ImageWrapper = styled("div")({
  img: {
    width: "4.5rem",
  },
});

export const Title = styled("h4")({
  fontSize: 20,
  color: "var(--color-primary)",
  margin: "1em 0",
});

export const Message = styled("div")({
  color: "var(--color-general-black)",
  lineHeight: 1.5,
});

const ErrorCircleSvg = () => (
  <svg width="80px" height="80px" fill="currentColor" viewBox="0 0 80 80">
    <path
      d="M40,0 C17.88,0 0,17.88 0,40 C0,62.12 17.88,80 40,80 C62.12,80 80,62.12 80,40 C80,17.88 62.12,0 40,0 L40,0 Z"
      id="Shape"
      fill="#E8EDF2"
    ></path>
    <polygon
      id="Path"
      fill="#D7272D"
      points="60 54.36 54.36 60 40 45.64 25.64 60 20 54.36 34.36 40 20 25.64 25.64 20 40 34.36 54.36 20 60 25.64 45.64 40"
    ></polygon>
  </svg>
);

export const ErrorCircleIcon = (props) => <Icon component={ErrorCircleSvg} {...props} />;
