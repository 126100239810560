import styled from "styled-components";

export const IconKey = styled("div")({
  display: "inline-block",
  marginRight: "0.5rem",
  svg: {
    width: "1.3rem",
  },
});

export const IconKeyTextCont = styled("div")({
  display: "block",
  "> *": {
    verticalAlign: "middle",
  },
});

export const MenuContainer = styled("div")({
  ".kebab-menu": {
    border: 0,
  },
  ".kebab-menu .ant-menu-submenu .ant-menu-submenu-title": {
    margin: 0,
    padding: 0,
    ".ant-menu-title-content": {
      display: "none",
    },
    ".ant-menu-submenu-arrow": {
      display: "none",
    },
  },
});
