import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ErrorMessage } from "formik";
import { Col, DatePicker, Radio, Row, TimePicker, TreeSelect } from "antd";
import { Checkbox, Form, FormItem, Input } from "formik-antd";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { has } from "lodash";

import ContentContainer from "@/components/ContentContainer";
import CustomButton from "@/components/CustomButton";
import CustomModal from "@/components/CustomModal";
import Richtext2 from "@/components/Richtext2";

import { GET_BRANDS_ORDER_BY_CATEGORY } from "../../../graphql";

import {
  AlertContainer,
  AlertDescription,
  AlertMessage,
  StyledUploadImage,
  HeaderSubtitle,
  InstructionLabel,
  ErrorMessageContainer,
} from "./styled";

const DOMPurify = require('dompurify')(window);

interface Props {
  formikBag: any;
  loader?: boolean;
  promoName?: string;
  featuredPromoLimit?: boolean;
}

const { SHOW_PARENT } = TreeSelect;

const Forms: React.FC<Props> = ({ formikBag, loader, promoName, featuredPromoLimit }) => {
  const { values, submitForm, errors, setFieldValue } = formikBag;
  const { pageId } = useParams();
  const history = useHistory();
  const { data: brandsData } = useQuery(GET_BRANDS_ORDER_BY_CATEGORY);
  const timeFormat = "h:mm a";

  const [isModalVisibleLeaving, setIsModalVisibleLeaving] = useState<boolean>(false);
  const [isOtherButton, setIsOtherButton] = useState<boolean>(false);
  const [participatingHotels, setParticipatingHotels] = useState<any>([]);
  const [nameCount, setNameCount] = useState<number>(0);

  const startDateValue = values.start_date ? moment(values.start_date) : undefined;
  const startTimeValue = values.start_time ? moment(values.start_time, "LT") : undefined;
  const endDateValue = values.end_date ? moment(values.end_date) : undefined;
  const endTimeValue = values.end_time ? moment(values.end_time, "LT") : undefined;

  useEffect(() => {
    if (brandsData && has(brandsData, "getBrandsOrderByCategory")) {
      const newBrands = brandsData.getBrandsOrderByCategory
        .filter((item) => item.status === "PUBLISHED" && item.hotels.length)
        .map((brand) => {
          const childrenHotels = brand.hotels.map((hotel) => {
            const hotelValue = JSON.stringify({
              brand_id: brand.id,
              hotel_id: hotel.id,
            });

            return {
              title: hotel.name,
              value: hotelValue,
            };
          });

          const valueHotels =
            brand.hotels.length > 0
              ? brand.hotels.map((hotel) => {
                  return {
                    brand_id: brand.id,
                    hotel_id: hotel.id,
                  };
                })
              : brand.name;

          return {
            title: brand.name,
            value: JSON.stringify(valueHotels),
            children: childrenHotels,
          };
        });

      setParticipatingHotels(newBrands);
    }
  }, [brandsData]);

  useEffect(() => {
    if (isOtherButton) {
      setFieldValue("cta_button_name", "");
      setFieldValue("cta_button_link", "");
    } else {
      setFieldValue("cta_button_name", "Book Now");
      setFieldValue("cta_button_link", "");
    }
  }, [isOtherButton]);

  const disabledDate = (current) => {
    return current && current < moment().endOf("day");
  };

  const handleButtonName = (e) => {
    setNameCount(e.target.value.length);
  };

  const handleCheckbox = (e) => {
    setFieldValue("is_featured", e.target.checked);
  };

  const handleOkLeaving = () => {
    setIsModalVisibleLeaving(false);
    history.push("/notification-settings/promotions");
  };

  const handleRadio = (e) => {
    e.target.value === "Other" ? setIsOtherButton(true) : setIsOtherButton(false);
  };

  const handleTreeSelect = (e) => {
    setFieldValue("participating_hotels", e);
  };

  const otherButton = isOtherButton ? (
    <>
      <Col span={24}>
        <FormItem
          label={`Button name (${nameCount}/30)*`}
          name="cta_button_name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            name="cta_button_name"
            placeholder="Enter button name"
            onChange={handleButtonName}
            maxLength={30}
          />
        </FormItem>
      </Col>
      <Col span={24}>
        <FormItem
          label="Redirect Link*"
          name="cta_button_link"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input name="cta_button_link" placeholder="Enter Redirect Link" />
        </FormItem>
      </Col>
    </>
  ) : null;

  return (
    <>
      <Form>
        <InstructionLabel>* Required fields, do not leave this blank.</InstructionLabel>
        <ContentContainer style={{ flexDirection: "column" }}>
          <Row gutter={40}>
            <Col md={8} span={24}>
              <Row>
                <Col span={24}>
                  <HeaderSubtitle>Add Photo*</HeaderSubtitle>
                  <div style={{ marginBottom: "1.5rem" }}></div>
                </Col>
                <Col span={24}>
                  {/* for "accept" props: no spaces allowed */}
                  <StyledUploadImage
                    accept={"image/png,image/jpeg,image/jpg"}
                    value={values.photo}
                    hasNote
                    ratio="169 : 100"
                    sampleRatio="338 x 200"
                    formatStr="Format: JPG or PNG"
                    maxFileSize={2}
                    sizeLimit={2}
                    onChange={(e) => {
                      setFieldValue("photo", e);
                    }}
                    errors={errors.photo}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={16} span={24}>
              <Row>
                <Col span={24}>
                  <FormItem
                    label="Promo Title*"
                    name="title"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input name="title" placeholder="Enter Title" minLength={2} />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label="Promo Description*"
                    name="description"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Richtext2
                      value={DOMPurify.sanitize(values.description)}
                      placeholder="Enter Promo Description"
                      onChange={(e) => {
                        if (e === "<p><br></p>") {
                          setFieldValue("description", "");
                        } else setFieldValue("description", e);
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label="Participating Hotels*"
                    name="participating_hotels"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <TreeSelect
                      onChange={handleTreeSelect}
                      placeholder="Please select"
                      value={values.participating_hotels}
                      showCheckedStrategy={SHOW_PARENT}
                      treeCheckable={true}
                      treeData={participatingHotels}
                    />
                    <ErrorMessageContainer>
                      <ErrorMessage name="participating_hotels" />
                    </ErrorMessageContainer>
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label="Promo Period*"
                    name="promo_period"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Row gutter={24}>
                      <Col span={14}>
                        <FormItem
                          name="start_date"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <DatePicker
                            placeholder="Start Date"
                            style={{ width: "100%" }}
                            value={startDateValue}
                            disabledDate={disabledDate}
                            allowClear={false}
                            onChange={(e) => {
                              setFieldValue("start_date", moment(e).format("L"));
                            }}
                          />
                        </FormItem>
                      </Col>
                      <Col span={10}>
                        <FormItem
                          name="start_time"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TimePicker
                            placeholder="Start Time"
                            use12Hours
                            value={startTimeValue}
                            style={{ width: "100%" }}
                            format={timeFormat}
                            allowClear={false}
                            onChange={(e) => {
                              setFieldValue("start_time", moment(e).format("LT"));
                            }}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={14}>
                        <FormItem name="end_date" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                          <DatePicker
                            placeholder="End Date"
                            style={{ width: "100%" }}
                            value={endDateValue}
                            disabledDate={disabledDate}
                            allowClear={false}
                            onChange={(e) => {
                              setFieldValue("end_date", moment(e).format("L"));
                            }}
                          />
                        </FormItem>
                      </Col>
                      <Col span={10}>
                        <FormItem name="end_time" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                          <TimePicker
                            placeholder="End Time"
                            use12Hours
                            value={endTimeValue}
                            style={{ width: "100%" }}
                            format={timeFormat}
                            allowClear={false}
                            onChange={(e) => {
                              setFieldValue("end_time", moment(e).format("LT"));
                            }}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label="Choose CTA Button*"
                    name="cta"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Radio.Group
                      onChange={handleRadio}
                      defaultValue={values.cta_button_name === "Book Now" ? "Book Now" : "Other"}
                    >
                      <Radio value="Book Now">Book Now</Radio>
                      <Radio value="Other">Other Button</Radio>
                    </Radio.Group>
                  </FormItem>
                </Col>
                {otherButton}
                <Col span={24}>
                  <AlertContainer
                    style={{
                      backgroundColor: featuredPromoLimit ? "#FFF1EF" : "#F4F6F9",
                      borderColor: featuredPromoLimit ? "#D7272D" : "#7791b3",
                      marginBottom: featuredPromoLimit ? "13px" : "",
                    }}
                    message={
                      <AlertMessage>
                        <div>Set this as a featured promotion</div>
                        <FormItem name="is_featured">
                          <Checkbox name="is_featured"></Checkbox>
                        </FormItem>
                      </AlertMessage>
                    }
                    description={
                      <AlertDescription>Only 3 featured promotions are allowed.</AlertDescription>
                    }
                  />
                  {featuredPromoLimit ? (
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#D7272D",
                        marginBottom: "30px",
                      }}
                    >
                      * You have selected 3 featured promos already. Try saving this to draft and
                      uncheck other featured promos.
                    </p>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="end" gutter={24} style={{ marginBottom: 10 }}>
            <Col span={4}>
              <CustomButton
                style={{ width: "100%" }}
                onClick={() => setIsModalVisibleLeaving(true)}
              >
                CANCEL
              </CustomButton>
            </Col>
            <Col span={4}>
              <CustomButton
                style={{ width: "100%", marginRight: 15 }}
                type="primary"
                onClick={() => submitForm()}
              >
                SAVE
              </CustomButton>
            </Col>
          </Row>
        </ContentContainer>
      </Form>

      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={pageId ? "Cancel Editing" : "Leave this page?"}
        visible={isModalVisibleLeaving}
        onOk={handleOkLeaving}
        onCancel={() => setIsModalVisibleLeaving(false)}
        message={
          pageId
            ? "Are you sure you want to cancel editing"
            : "All unsaved data will be lost. Are you sure you want to leave this page?"
        }
        cancelText={pageId ? "No" : "Stay here"}
        okText="Yes"
        okButtonWidth="7.5em"
        cancelButtonWidth="7.5em"
      />
    </>
  );
};

export default Forms;
