import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Col, Row, Spin, Image, message } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { startCase, upperCase } from "lodash";
import moment from "moment";

import CustomModal from "@/components/CustomModal";
import ContentContainer from "@/components/ContentContainer";
import CustomActionButtons from "@/components/CustomActionButtons";

import { BrandsI } from "../../../../models/RHRInterface";
import { GET_BRAND_BYID, MODIFY_BRAND_STATUS } from "../../graphql";
import GoLogo from "../../../../assets/images/callback-image-no-BG.png";
import { STATUS, SECTIONTYPE } from "../../../../utilities/enums";
import {
  Span,
  Subtitle,
  ImgLogo,
  ImgWrap,
  SectionWrap,
  BorderTop,
  Sectiontitle,
  SpanBY,
  HotelList,
  HotelCard,
  HotelWrap,
  ImgHotelLogo,
  ImgPhoto,
  FeatureImagesList,
  FeatureImageCard,
  FeatureImageWrap,
  BrandPageWrap,
  ActionButtonsContainer,
} from "./styled";

const DOMPurify = require("dompurify")(window);

const BrandManagementView = () => {
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const [modifyBrandStatus] = useMutation(MODIFY_BRAND_STATUS);
  const { loading, refetch, data } = useQuery(GET_BRAND_BYID, {
    skip: !id,
    fetchPolicy: "no-cache",
    variables: {
      id: id,
    },
  });

  const [brand, setBrand] = useState<BrandsI>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [bodyMessageSuccess, setBodyMessageSuccess] = useState("");
  const [statusStr, setStatusStr] = useState("");
  const [brandNameModal, setBrandNameModal] = useState("");
  const [okTxt, setOkTxt] = useState("OK");

  const constructVariables = (brand_id: string, status: STATUS) => {
    return {
      variables: {
        brand_id: brand_id,
        status: upperCase(status),
      },
    };
  };

  const updateStatus = async (id: string, status: STATUS) => {
    try {
      const { data: res } = await modifyBrandStatus(constructVariables(id, status));

      if (res.updateBrandStatus) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const checkStatusStrOkModal = async (statusStr, brandNameModal) => {
    console.log(id, statusStr, brandNameModal);
    if (statusStr === "published") {
      await updateStatus(id, STATUS.PUBLISHED);
      setBodyMessageSuccess(`"${brandNameModal}" was successfully published.`);
    }

    if (statusStr === "archived") {
      await updateStatus(id, STATUS.ARCHIVED);
      setBodyMessageSuccess(`"${brandNameModal}" was successfully archived.`);
    }
    if (statusStr === "deleted") {
      await updateStatus(id, STATUS.DELETED);
      setBodyMessageSuccess(`"${brandNameModal}" was successfully deleted.`);
    }
    setShowModal(false);
    setShowModalSuccess(true);
  };

  const handleArchive = (brandName) => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage("By archiving this brand, it will not be visible in the website when deployed");
    setStatusStr("archived");
    setBrandNameModal(brandName);
    setOkTxt("Archive");
  };

  const handlePublish = (brandName) => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By Publishing this brand, Informations and documents uploaded in this brand will be visible in the website when deployed."
    );
    setStatusStr("published");
    setBrandNameModal(brandName);
    setOkTxt("Publish");
  };

  const handleEdit = () => {
    history.push(`/brand-management/edit/${id}`, { pageId: id });
  };

  const handleDelete = (brandName) => {
    setShowModal(true);
    setTitleMessage("Delete Brand?");
    setBodyMessage(`Are you sure you want to delete "${brandName}?"`);
    setStatusStr("deleted");
    setBrandNameModal(brandName);
    setOkTxt("Delete");
  };

  useEffect(() => {
    if (data) {
      setBrand(data.getBrandById);
    }
  }, [data]);

  const onErrorImg = (e) => {
    e.target.src = GoLogo;
  };

  return (
    <>
      <Spin spinning={loading}>
        {brand !== undefined && (
          <BrandPageWrap>
            <ActionButtonsContainer>
              <Col span="24" style={{ textAlign: "right" }}>
                <CustomActionButtons
                  dataStatus={brand.status}
                  dataTitle={brand.name}
                  handlePublish={() => handlePublish(brand.name)}
                  handleArchive={() => handleArchive(brand.name)}
                  handleDelete={() => handleDelete(brand.name)}
                  handleEdit={handleEdit}
                />
              </Col>
            </ActionButtonsContainer>

            <SectionWrap>
              <Row style={{ padding: "0 32px", marginBottom: "24px" }}>
                <Col span={24}>
                  <Row>
                    <Col span={12}>
                      <Sectiontitle>Hotel Informations</Sectiontitle>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <Subtitle>This Brand is currently {brand.status}</Subtitle>
                    </Col>
                    <Col span={5}>
                      <article style={{ paddingRight: "30px" }}>
                        <Subtitle>Brand Logo</Subtitle>
                        <ImgWrap>
                          <ImgLogo src={brand.logo} onError={onErrorImg} alt="Brand Logo" />
                        </ImgWrap>
                      </article>
                    </Col>
                    <Col span={19} style={{ paddingLeft: "30px" }}>
                      <Row>
                        <Col span={12}>
                          <Subtitle>Brand Name</Subtitle>
                          <Span primary>{brand.name}</Span>
                        </Col>
                        <Col span={12}>
                          <Subtitle>Brand Category</Subtitle>
                          <Span primary>{brand.category.name}</Span>
                        </Col>
                        <Col span={24}>
                          <Subtitle>Short Description</Subtitle>
                          <Span primary>{brand.short_description}</Span>
                        </Col>
                        <Col span={24}>
                          <Subtitle>Long Write Up Description</Subtitle>
                          <Span primary>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(brand.long_description),
                              }}
                            />
                          </Span>
                        </Col>
                        {brand.redirect_link !== "" && (
                          <Col span={24}>
                            <Subtitle>Redirect link</Subtitle>
                            <Span style={{ wordBreak: "break-all" }}> {brand.redirect_link} </Span>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <BorderTop />
              <Row style={{ padding: "32px" }}>
                <Col span={24}>
                  <Sectiontitle>Article Details</Sectiontitle>
                </Col>
                <Col span={8}>
                  <Subtitle>Date/Time Created</Subtitle>
                  <Span primary style={{ marginBottom: "5px" }}>
                    {moment(brand.created_at).format("YYYY-MM-DD HH:mm:ss")}
                  </Span>
                  <SpanBY>by {brand.created_by}</SpanBY>
                </Col>
                <Col span={8}>
                  <Subtitle>Last Date/Time Edited</Subtitle>
                  <Span primary style={{ marginBottom: "5px" }}>
                    {moment(brand.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                  </Span>
                  <SpanBY>by {brand.updated_by}</SpanBY>
                </Col>
                <Col span={8}>
                  <Subtitle>Last Date/Time Deployed</Subtitle>
                  <Span primary style={{ marginBottom: "5px" }}>
                    {brand.deployed_at === null
                      ? "-"
                      : moment(brand.deployed_at).format("YYYY-MM-DD HH:mm:ss")}
                  </Span>
                  <SpanBY>{brand.deployed_by === null ? "-" : brand.deployed_by}</SpanBY>
                </Col>
                {brand.status === STATUS.ARCHIVED && (
                  <Col span={12}>
                    <Subtitle>Article Link</Subtitle>
                    <Span>
                      <a
                        href={`https://tarsier-dev.whitecloak.com/brand/${brand.slug}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ wordBreak: "break-all" }}
                      >
                        {`https://tarsier-dev.whitecloak.com/brand/${brand.slug}`}
                      </a>
                    </Span>
                  </Col>
                )}
              </Row>
              <BorderTop />
              <Row style={{ padding: "32px" }}>
                <Col span={24}>
                  <Sectiontitle>Brand Page Banner Feature Images</Sectiontitle>
                </Col>
                <FeatureImagesList>
                  {brand.uploaded_media
                    .filter((b) => b.uploaded_media_type.name === "banner_feature_image")
                    .map((filteredBrand) => (
                      <FeatureImageCard>
                        <FeatureImageWrap>
                          <Image
                            src={filteredBrand.url}
                            placeholder={
                              <ImgLogo
                                preview={false}
                                src={`${filteredBrand.url}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit`}
                                alt={filteredBrand.uploaded_media_type.description}
                              />
                            }
                          />
                        </FeatureImageWrap>
                      </FeatureImageCard>
                    ))}
                </FeatureImagesList>
              </Row>

              <Row style={{ padding: "32px" }}>
                <FeatureImagesList>
                  {brand.uploaded_media
                    .filter((b) => b.uploaded_media_type.name === "category_logo")
                    .map((filteredBrand) => (
                      <FeatureImageCard>
                        <Sectiontitle>Brand Category Logo</Sectiontitle>
                        <FeatureImageWrap>
                          <Image
                            src={filteredBrand.url}
                            placeholder={
                              <ImgLogo
                                preview={false}
                                src={`${filteredBrand.url}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit`}
                                alt={filteredBrand.uploaded_media_type.description}
                              />
                            }
                          />
                        </FeatureImageWrap>
                      </FeatureImageCard>
                    ))}
                  {brand.uploaded_media
                    .filter((b) => b.uploaded_media_type.name === "carousel_image")
                    .map((filteredBrand) => (
                      <FeatureImageCard>
                        <Sectiontitle>Brand Carousel Image</Sectiontitle>
                        <FeatureImageWrap>
                          <Image
                            src={filteredBrand.url}
                            placeholder={
                              <ImgLogo
                                preview={false}
                                src={`${filteredBrand.url}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit`}
                                alt={filteredBrand.uploaded_media_type.description}
                              />
                            }
                          />
                        </FeatureImageWrap>
                      </FeatureImageCard>
                    ))}
                </FeatureImagesList>
              </Row>
            </SectionWrap>
            <ContentContainer>
              <Row gutter={16}>
                <Col span={24}>
                  <Sectiontitle>Contact Information</Sectiontitle>
                </Col>
                <Col span={8}>
                  <Subtitle>Landline No.</Subtitle>
                  {brand.landline_nos
                    .filter((landline) => landline.input !== "")
                    .map((landline, i) => (
                      <Span
                        key={i}
                        primary
                        style={brand.landline_nos.length - 1 === i ? {} : { marginBottom: "5px" }}
                      >
                        {landline.input}
                      </Span>
                    ))}
                  {brand.landline_nos.filter((landline) => landline.input !== "").length === 0 && (
                    <Span primary>No Input</Span>
                  )}
                </Col>
                <Col span={8}>
                  <Subtitle>Mobile No.</Subtitle>
                  {brand.mobile_nos
                    .filter((mobile) => mobile.input !== "")
                    .map((mobile, i) => (
                      <Span
                        key={i}
                        primary
                        style={brand.mobile_nos.length - 1 === i ? {} : { marginBottom: "5px" }}
                      >
                        {mobile.input !== "" ? mobile.country_code + " " + mobile.input : ""}
                      </Span>
                    ))}
                  {brand.mobile_nos.filter((mobile) => mobile.input !== "").length === 0 ? (
                    <Span primary>No Input</Span>
                  ) : (
                    ""
                  )}
                </Col>
                <Col span={8}>
                  <Subtitle>Email Address</Subtitle>
                  {brand.email_addresses.length !== 0 ? (
                    brand.email_addresses[0].input.length !== 0 ? (
                      <Span primary style={{ wordBreak: "break-all" }}>
                        {brand.email_addresses[0].input}
                      </Span>
                    ) : (
                      <Span primary>No Input</Span>
                    )
                  ) : (
                    <Span primary>No Input</Span>
                  )}
                </Col>
                {brand.social_media.map((social, i) => (
                  <Col span={8} key={i}>
                    <Subtitle style={{ textTransform: "capitalize" }}>
                      {social.social_media_type.name} Link
                    </Subtitle>
                    <Span style={{ wordBreak: "break-all" }}>{social.url}</Span>
                  </Col>
                ))}
              </Row>
            </ContentContainer>
            {brand.hotels.filter((hotel) => hotel.name !== "").length !== 0 && (
              <ContentContainer>
                <Row>
                  <Col span={24}>
                    <Sectiontitle>Manage Hotel</Sectiontitle>
                  </Col>
                  <HotelList>
                    {brand.hotels.map((hotel, i) => (
                      <HotelCard key={i}>
                        <HotelWrap>
                          <Row>
                            <Col span={6}>
                              <ImgHotelLogo
                                src={hotel.website_logo}
                                onError={onErrorImg}
                                alt="Brand Logo"
                              />
                            </Col>
                            <Col span={18} style={{ paddingLeft: "32px" }}>
                              <Subtitle>Hotel Name</Subtitle>
                              <Span primary>{hotel.name}</Span>

                              <Subtitle>Hotel Location</Subtitle>
                              <Span primary>{hotel.address}</Span>

                              <Subtitle>Mobile Number</Subtitle>
                              <Span primary>{hotel.country_code + " " + hotel.mobile_no}</Span>

                              <Subtitle>Website Link</Subtitle>
                              <Span style={{ wordBreak: "break-all" }}>{hotel.website_link}</Span>
                            </Col>
                          </Row>
                        </HotelWrap>
                      </HotelCard>
                    ))}
                  </HotelList>
                </Row>
              </ContentContainer>
            )}
            {brand.sections.length !== 0 && (
              <div className="sections">
                {brand.sections
                  .sort((a, b) => (a.order > b.order ? 1 : -1))
                  .map((section, key) => (
                    <ContentContainer>
                      <Row>
                        <Col span={12}>
                          <Sectiontitle>
                            {!section.photo_thumbnail_url &&
                              section.section_type.name !== SECTIONTYPE.FEATUREDVIDEO &&
                              `Title & Text (${startCase(
                                section.section_type.name.split("_").join(" ")
                              )})`}
                            {section.photo_thumbnail_url &&
                              section.section_type.name !== SECTIONTYPE.FEATUREDVIDEO &&
                              `Title, Text & Image (${startCase(
                                section.section_type.name.split("_").join(" ")
                              )})`}
                            {section.section_type.name === SECTIONTYPE.FEATUREDVIDEO &&
                              "Featured Video"}
                          </Sectiontitle>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Subtitle>This section is currently {section.section_status}</Subtitle>
                        </Col>
                        <Col span={24}>
                          <Row>
                            {section.photo_thumbnail_url && (
                              <Col span={8} style={{ paddingRight: "45px" }}>
                                <Subtitle>Photo</Subtitle>
                                <ImgPhoto
                                  src={section.photo_thumbnail_url}
                                  onError={onErrorImg}
                                  alt="Section Photo"
                                />
                              </Col>
                            )}
                            {section.section_type.name !== SECTIONTYPE.FEATUREDVIDEO ? (
                              <Col span={16}>
                                <Subtitle>Header Title</Subtitle>
                                <Span primary>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(section.header_title),
                                    }}
                                  />
                                </Span>

                                <Subtitle>Text Content</Subtitle>
                                <Span primary>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(section.text_content),
                                    }}
                                  />
                                </Span>

                                {section.link !== "" && (
                                  <div>
                                    <Subtitle>Link</Subtitle>
                                    <Span>{section.link}</Span>
                                  </div>
                                )}

                                {section.pdf_url !== "" && (
                                  <div>
                                    <Subtitle>Downloadable File</Subtitle>
                                    <Span>{section.pdf_url}</Span>
                                  </div>
                                )}
                              </Col>
                            ) : (
                              <Col span={16}>
                                <Subtitle>Facebook link or Embedded YouTube link</Subtitle>
                                <Span>{section.link}</Span>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </ContentContainer>
                  ))}
              </div>
            )}
          </BrandPageWrap>
        )}
      </Spin>
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showModal}
        onOk={() => checkStatusStrOkModal(statusStr, brandNameModal)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon
        titleMessage="Success!"
        visible={showModalSuccess}
        onOk={() => setShowModalSuccess(false)}
        onCancel={() => setShowModalSuccess(false)}
        message={bodyMessageSuccess}
        okText={"OK"}
      />
    </>
  );
};

export default BrandManagementView;
