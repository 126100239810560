import React from "react";

import { Base, ActionButton, IconKeyTextCont, IconKey } from "./styled";

import { STATUS } from "../../utilities/enums";
import { EditPenIcon, PublishIcon, DeleteIcon, ArchiveIcon } from "../../utilities/icons";

interface Props {
  dataStatus: string;
  dataTitle: string;
  handlePublish?: (name: string) => void;
  handleArchive?: (name: string) => void;
  handleDelete?: (name: string) => void;
  handleEdit?: () => void;
}

const CustomActionButtons: React.FC<Props> = ({
  dataStatus,
  dataTitle,
  handlePublish,
  handleArchive,
  handleDelete,
  handleEdit,
}) => {
  const onHandlePublish = () => {
    handlePublish && handlePublish(dataTitle);
  };
  const onHandleArchive = () => {
    handleArchive && handleArchive(dataTitle);
  };
  const onHandleDelete = () => {
    handleDelete && handleDelete(dataTitle);
  };
  return (
    <>
      {dataStatus !== STATUS.DELETED && (
        <Base>
          {dataStatus !== STATUS.PUBLISHED && (
            <ActionButton onClick={onHandleDelete}>
              <IconKeyTextCont>
                <IconKey>
                  <DeleteIcon style={{ color: "red" }} />
                </IconKey>{" "}
                Delete
              </IconKeyTextCont>
            </ActionButton>
          )}
          <ActionButton type="primary" onClick={handleEdit}>
            <IconKeyTextCont>
              <IconKey>
                <EditPenIcon />
              </IconKey>{" "}
              Edit
            </IconKeyTextCont>
          </ActionButton>
          {dataStatus !== STATUS.ARCHIVED && dataStatus !== STATUS.DRAFT && (
            <ActionButton type="primary" onClick={onHandleArchive}>
              <IconKeyTextCont>
                <IconKey>
                  <ArchiveIcon />{" "}
                </IconKey>{" "}
                Archive
              </IconKeyTextCont>
            </ActionButton>
          )}
          {dataStatus !== STATUS.PUBLISHED && (
            <ActionButton type="primary" onClick={onHandlePublish}>
              <IconKeyTextCont>
                <IconKey>
                  <PublishIcon />{" "}
                </IconKey>{" "}
                Publish
              </IconKeyTextCont>
            </ActionButton>
          )}
        </Base>
      )}
    </>
  );
};

export default CustomActionButtons;
