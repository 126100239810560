import { Alert, Col, Divider, message, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import ContentContainer from "@/components/ContentContainer";
import CustomModal from "@/components/CustomModal";
import GoLogo from "../../../../../assets/images/callback-image-no-BG.png";
import { IconKey, IconKeyTextCont } from "@/components/ActionsMenu/styled";
import { EditPenIcon, PublishIcon, DeleteIcon, ArchiveIcon } from "../../../../../utilities/icons";
import { PromotionsI } from "../../../../../models/PromotionsInterface";
import { UPDATE_NOTIFICATION_STATUS, GET_NOTIFICATION_BY_ID } from "../../../graphql";
import { STATUS } from "../../../../../utilities/enums";
import {
  ActionButtons,
  ActionButtonsContainer,
  ActionButtonWrap,
  AlertMessage,
  ArchivedLink,
  BorderTop,
  ImgPhoto,
  PromoPageWrap,
  SectionWrap,
  Sectiontitle,
  Span,
  SpanBY,
  Subcontext,
  Subheader,
  Subtitle,
} from "./styled";
import { dummyData } from "./constants";

const NotificationsView = (): JSX.Element => {
  const { pageId }: { pageId: string } = useParams();
  const history = useHistory();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState<string>("");
  const [bodyMessage, setBodyMessage] = useState<string>("");
  const [bodyMessageSuccess, setBodyMessageSuccess] = useState<string>("");
  const [statusStr, setStatusStr] = useState<string>("");
  const [okTxt, setOkTxt] = useState<string>("OK");
  const [notificationData, setNotificationData] = useState<any>(dummyData);
  const [brandKeys, setBrandKeys] = useState<any>([]);
  const [brandHotels, setBrandHotels] = useState<any>([]);
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [end, setEnd] = useState("");
  const [recipients, setRecipients] = useState([]);
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [showArchiveBtn, setShowArchiveBtn] = useState(false);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [showEditBtn, setShowEditBtn] = useState(false);
  const [showPublishBtn, setShowPublishBtn] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [status, setStatus] = useState<string>("draft");
  const [modifyStatus] = useMutation(UPDATE_NOTIFICATION_STATUS);

  const { loading, refetch, data } = useQuery(GET_NOTIFICATION_BY_ID, {
    skip: !pageId,
    fetchPolicy: "no-cache",
    variables: {
      id: pageId,
    },
  });

  useEffect(() => {
    if (data) {
      refetch();
      setNotificationData(data.getNotificationById);
      setStatus(data.getNotificationById.status);
    }
  }, [data, refetch]);

  useEffect(() => {
    if (notificationData) {
      const { status } = notificationData;
      setShowActionButtons(status !== "DELETED");
      setShowArchiveBtn(status !== "ARCHIVED" && status !== "DRAFT" && status !== "DELETED");
      setShowDeleteBtn(status !== "PUBLISHED" && status !== "DELETED");
      setShowEditBtn(status !== "DELETED");
      setShowPublishBtn(status !== "PUBLISHED" && status !== "DELETED");
      setIsArchived(status === "ARCHIVED");

      const groupedHotels = notificationData.participating_hotels
        .map((item) => {
          return {
            brand: item.brand.name,
            hotel: item.hotel.name,
          };
        })
        .reduce((acc, obj) => {
          let key = obj["brand"];
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj.hotel);
          return acc;
        }, {});

      setBrandKeys(Object.keys(groupedHotels));
      setBrandHotels(groupedHotels);

      setRecipients(
        notificationData.recipients
          .map(({ recipient_type }, i) => {
            return `By ${_.startCase(recipient_type.replace("_", " ").toLowerCase())}`;
          })
          .join(", ")
      );

      const { datetime_value } = notificationData.release_schedules.find(
        ({ schedule_type }) => schedule_type === "DATETIME"
      );
      const timeValue = moment.unix(datetime_value).format("h:mm A");
      const dateValue = moment.unix(datetime_value.slice(0, -3)).format("YYYY-MM-DD");
      setTime(timeValue);
      setDate(dateValue);

      setEnd(() => {
        if (notificationData.frequency_details) {
          const {
            frequency_details: { ends, end_date },
          } = notificationData;

          if (ends === "never") {
            return "Never";
          }
          if (ends === "after") {
            if (end_date > 3) {
              return `${end_date}th ocurrence`;
            }
            return `${["1st", "2nd", "3rd"][end_date - 1]} occurence`;
          }
          return end_date;
        }
      });
    }
  }, [notificationData]);

  const onErrorImg = (e): void => {
    e.target.src = GoLogo;
  };

  const constructVariables = (notificationId: string, status: STATUS): object => {
    return {
      notification_id: notificationId,
      status: status,
    };
  };

  const updateStatus = async (id: string, status: STATUS): Promise<void> => {
    const input = constructVariables(id, status);

    try {
      const { data: res } = await modifyStatus({
        variables: {
          Input: input,
        },
      });

      if (res.updateNotificationStatus) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const checkStatusStrOkModal = async (statusStr) => {
    if (statusStr === "published") {
      await updateStatus(pageId, STATUS.PUBLISHED);
      setBodyMessageSuccess("This promo was successfully published.");
    }

    if (statusStr === "archived") {
      await updateStatus(pageId, STATUS.ARCHIVED);
      setBodyMessageSuccess("This promo was successfully archived.");
    }
    if (statusStr === "deleted") {
      await updateStatus(pageId, STATUS.DELETED);
      setBodyMessageSuccess("This promo was successfully deleted.");
    }
    setShowModal(false);
    setShowModalSuccess(true);
  };

  // Go to Edit page
  const handleEdit = () => {
    history.push(`/notification-settings/notifications/edit-notification/${pageId}`, { pageId });
  };

  // Delete confirmation modal content
  const handleDelete = () => {
    setShowModal(true);
    setTitleMessage("Delete Brand?");
    setBodyMessage("Are you sure you want to delete this promo?");
    setStatusStr("deleted");
    setOkTxt("Delete");
  };

  // Archive confirmation modal content
  const handleArchived = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By archiving this promo, informations and documents uploaded in this promo will not be visible in the website."
    );
    setStatusStr("archived");
    setOkTxt("Archive");
  };

  const handlePublish = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By publishing this promo, all information and documents uploaded in this promo will be visible in the website when deployed"
    );
    setStatusStr("published");
    setOkTxt("Publish");
  };

  const showBrandHotels = brandKeys.map((brand, index) => {
    const hotels = brandHotels[brand].join(", ");

    return (
      <Span primary key={index}>
        {`${brand} - ${hotels}`}
      </Span>
    );
  });

  const showWeeksOn = () => {
    if (
      notificationData.frequency_details &&
      notificationData.notification_frequency === "WEEKLY"
    ) {
      return notificationData?.frequency_details?.weeks_on
        .map((item) => _.capitalize(item))
        .join(", ");
    }
  };

  const archived = notificationData && (
    <Row>
      <ArchivedLink>
        <Subtitle>Archived Link</Subtitle>
        <Span>{notificationData.redirect_link}</Span>
      </ArchivedLink>
    </Row>
  );

  return (
    <>
      <Spin spinning={loading}>
        {notificationData !== undefined && (
          <PromoPageWrap>
            <ActionButtonsContainer show={showActionButtons}>
              <Row>
                <Col span="12" offset="12" style={{ textAlign: "right" }}>
                  <ActionButtonWrap>
                    <ActionButtons show={showDeleteBtn} onClick={handleDelete}>
                      <IconKeyTextCont>
                        <IconKey>
                          <DeleteIcon style={{ color: "red" }} />
                        </IconKey>{" "}
                        Delete
                      </IconKeyTextCont>
                    </ActionButtons>
                    <ActionButtons type="primary" show={showEditBtn} onClick={handleEdit}>
                      <IconKeyTextCont>
                        <IconKey>
                          <EditPenIcon />
                        </IconKey>{" "}
                        Edit
                      </IconKeyTextCont>
                    </ActionButtons>
                    <ActionButtons type="primary" show={showArchiveBtn} onClick={handleArchived}>
                      <IconKeyTextCont>
                        <IconKey>
                          <ArchiveIcon />{" "}
                        </IconKey>{" "}
                        Archive
                      </IconKeyTextCont>
                    </ActionButtons>
                    <ActionButtons type="primary" show={showPublishBtn} onClick={handlePublish}>
                      <IconKeyTextCont>
                        <IconKey>
                          <PublishIcon />{" "}
                        </IconKey>{" "}
                        Publish
                      </IconKeyTextCont>
                    </ActionButtons>
                  </ActionButtonWrap>
                </Col>
              </Row>
            </ActionButtonsContainer>
            <SectionWrap>
              <ContentContainer>
                <Row justify="end">This promo is currently {status.toUpperCase()}</Row>
                <Row>
                  <Col span={8}>
                    <Subtitle>Photo</Subtitle>
                    <ImgPhoto
                      src={notificationData.photo_url}
                      onError={onErrorImg}
                      alt="Promo Image"
                    />
                  </Col>
                  <Col span={16}>
                    <Subcontext>
                      <Subtitle>Notification Type</Subtitle>
                      <Span primary>{notificationData.notification_type}</Span>
                    </Subcontext>
                    <Subcontext>
                      <Subtitle>Title</Subtitle>
                      <Span primary>{notificationData.name}</Span>
                    </Subcontext>
                    <Subcontext>
                      <Subtitle>Promo Description</Subtitle>
                      <Span
                        primary
                        dangerouslySetInnerHTML={{ __html: notificationData.description }}
                      ></Span>
                    </Subcontext>
                    <Subcontext>
                      <Subtitle>Participating Hotels</Subtitle>
                      {showBrandHotels}
                    </Subcontext>
                    <Subcontext>
                      <Subtitle>Promo Period</Subtitle>
                      <Span primary>
                        <Row>
                          <Col span={12}>Start Date: {notificationData.start_date}</Col>
                          <Col span={12}>Start Time: {notificationData.start_time}</Col>
                        </Row>
                        <Row>
                          <Col span={12}>End Date: {notificationData.end_date}</Col>
                          <Col span={12}>End Time: {notificationData.end_time}</Col>
                        </Row>
                      </Span>
                    </Subcontext>
                    <Subcontext>
                      <Subtitle>Call to action</Subtitle>
                      {!notificationData.cta_button_link && <Span primary>KNOW MORE</Span>}
                      {notificationData.cta_button_link && (
                        <Span href={notificationData.cta_button_link}>
                          {notificationData.cta_button_link}
                        </Span>
                      )}
                    </Subcontext>
                    <Divider dashed={true} />
                    <Subheader>RELEASE DETAILS</Subheader>
                    <Subcontext>
                      <Subtitle>Schedule</Subtitle>
                      <Span primary>{`Date and Time: ${date} ${time}`}</Span>
                    </Subcontext>
                    <Subcontext>
                      <Subtitle>Recipients</Subtitle>
                      <Span primary>{recipients.length ? recipients : "All"}</Span>
                    </Subcontext>
                    <Subcontext>
                      <Subtitle>Frequency</Subtitle>
                      <Span primary>{_.capitalize(notificationData.notification_frequency)}</Span>
                      {notificationData.notification_frequency !== "ONCE" && (
                        <>
                          <Span primary>
                            Start time: {notificationData.frequency_details.start_time} End
                            time:&nbsp;
                            {notificationData.frequency_details.end_time}
                          </Span>
                          {["WEEKLY", "MONTHLY"].includes(
                            notificationData.notification_frequency
                          ) && (
                            <Span primary>
                              Recur every {notificationData.frequency_details.recur_every}
                              {notificationData.notification_frequency === "WEEKLY"
                                ? `week${
                                    notificationData.frequency_details.recur_every > 1 ? "s" : ""
                                  }`
                                : `month${
                                    notificationData.frequency_details.recur_every > 1 ? "s" : ""
                                  }`}
                              &nbsp;on&nbsp;
                              {notificationData.notification_frequency === "WEEKLY"
                                ? showWeeksOn()
                                : notificationData.frequency_details.months_on}
                            </Span>
                          )}
                          <Span primary>
                            Start of recurrence: {notificationData.frequency_details.start_date}
                          </Span>
                          <Span primary>End after: {end}</Span>
                        </>
                      )}
                    </Subcontext>
                  </Col>
                </Row>
              </ContentContainer>
              <BorderTop />
              <ContentContainer>
                <Sectiontitle>Notification Details</Sectiontitle>
                <Row>
                  <Col span={8}>
                    <Subtitle>Date/Time Created</Subtitle>
                    <Span primary>
                      {moment(notificationData.created_at).format("YYYY-MM-DD HH:mm:ss")}
                    </Span>
                    <SpanBY>by {notificationData.created_by}</SpanBY>
                  </Col>
                  <Col span={8}>
                    <Subtitle>Last Date/Time Edited</Subtitle>
                    <Span primary>
                      {moment(notificationData.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                    </Span>
                    <SpanBY>by {notificationData.updated_by}</SpanBY>
                  </Col>
                  {notificationData.deployed_at && (
                    <Col span={8}>
                      <Subtitle>Last Date/Time Deployed</Subtitle>
                      <Span primary>
                        {moment(notificationData.deployed_at).format("YYYY-MM-DD HH:mm:ss")}
                      </Span>
                      <SpanBY>by {notificationData.deployed_by}</SpanBY>
                    </Col>
                  )}
                </Row>
                {isArchived ? archived : null}
              </ContentContainer>
            </SectionWrap>
          </PromoPageWrap>
        )}
      </Spin>

      {/* Confirmation Modal */}
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showModal}
        onOk={() => checkStatusStrOkModal(statusStr)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />

      {/* Success Modal */}
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon
        titleMessage="Success!"
        visible={showModalSuccess}
        onOk={() => setShowModalSuccess(false)}
        onCancel={() => setShowModalSuccess(false)}
        message={bodyMessageSuccess}
        okText={"OK"}
      />
    </>
  );
};

export default NotificationsView;
