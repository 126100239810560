import styled from "styled-components";
import { Button } from "antd";
import { Input } from "formik-antd";
import { typography } from "../../../../../utilities/styles/theme";

export const ErrorTableMessage = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  color: red;
  font-size: 14px;
`;

export const InstructionLabel = styled("label")({
  ...typography("body-2"),
  color: "var(--color-secondary)",
});

export const Subtitle = styled.div`
  margin-bottom: 5px;
  color: #1b467f;
`;

export const StyledInput = styled(Input)`
  padding: 20px;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  margin-right: 15px;
  width: 140px;
  height: auto;
  padding: 16px 0;
`;

export const InputWrapper = styled("div")({
  position: "relative",
  ".remove-btn": {
    position: "absolute",
    right: 10,
    top: 9,
    cursor: "pointer",
  },
});

export const CustomSelectInput = styled("div")({
  width: "70%",
  ".ant-select": {
    width: "100%",
  },
  ".ant-select .ant-select-selector": {
    border: "1px solid var(--color-general-blue-gray)",
    borderRadius: 2,
    outline: "none",
    borderRight: "1px solid var(--color-general-blue-gray)",
    height: 67,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    padding: "15px",
    fontSize: "18px",
  },
  ".ant-input": {
    border: "1px solid var(--color-general-blue-gray)",
    borderRadius: 2,
    outline: "none",
    borderLeft: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  ".ant-form-item": {
    marginBottom: 15,
  },
  ".ant-select .ant-select-selection-item": {
    paddingRight: 0,
  },
});

