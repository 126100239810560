import React from "react";
import { Row, Col } from "antd";
import { FormItem, Input } from "formik-antd";

interface Props {
  formikBag: any;
}

const SocialLinksSection: React.FC<Props> = ({ formikBag }) => {
  return (
    <Row gutter={20}>
      <Col span={8}>
        <FormItem
          label={`Facebook Link`}
          name="social_media.facebook"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input name="social_media.facebook" placeholder="e.g https://www.sample.com" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          label={`Instagram Link`}
          name="social_media.instagram"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input name="social_media.instagram" placeholder="e.g https://www.sample.com" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          label={`Youtube Link`}
          name="social_media.youtube"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input name="social_media.youtube" placeholder="e.g https://www.sample.com" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          label={`Twitter Link`}
          name="social_media.twitter"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input name="social_media.twitter" placeholder="e.g https://www.sample.com" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          label={`LinkedIn Link`}
          name="social_media.linkedin"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input name="social_media.linkedin" placeholder="e.g https://www.sample.com" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          label={`Tiktok Link`}
          name="social_media.tiktok"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input name="social_media.tiktok" placeholder="e.g https://www.sample.com" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          label={`Kumu Link`}
          name="social_media.kumu"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input name="social_media.kumu" placeholder="e.g https://www.sample.com" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          label={`Viber Community Link`}
          name="social_media.viber_community"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input name="social_media.viber_community" placeholder="e.g https://www.sample.com" />
        </FormItem>
      </Col>
    </Row>
  );
};

export default SocialLinksSection;
