export const activeColumns = [
  {
    title: "Title",
    dataIndex: "title",
  },
  {
    title: "Last Edited By",
    dataIndex: "lastEditedBy",
  },
  {
    title: "Last Deployed By",
    dataIndex: "lastDeployedBy",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Actions",
    dataIndex: "actions",
  },
];

export const actions = [
  {
    name: "View",
    checkStatus: null,
  },
  {
    name: "Edit",
    checkStatus: null,
  },
  {
    name: "Publish",
    checkStatus: ["published"],
  },
  {
    name: "Archive",
    checkStatus: ["archived", "draft"],
  },
  {
    name: "Delete",
    checkStatus: ["published"],
  },
];
