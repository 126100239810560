export const actions = [
  {
    name: "View",
    checkStatus: null,
  },
  {
    name: "Edit",
    checkStatus: ["sent"],
  },
  {
    name: "Draft",
    checkStatus: ["archived", "draft", "scheduled", "sent"],
  },
  {
    name: "Archive",
    checkStatus: ["archived", "draft", "sent"],
  },
  {
    name: "Schedule",
    checkStatus: ["scheduled", "sent"],
  },
  {
    name: "Delete",
    checkStatus: ["scheduled", "sent"],
  },
];

export const recipientFilter = [
  { text: "Multiple Recipients", value: "All" },
  { text: "Hotel Booking", value: "HOTEL_BOOKING" },
  { text: "Status", value: "STATUS" },
  { text: "Email", value: "EMAIL" },
  { text: "Birthday", value: "BIRTHDAY" },
  { text: "Location", value: "LOCATION" },
  { text: "Anniversary", value: "ANNIVERSARY" },
  { text: "Gender", value: "GENDER" },
  { text: "Platform", value: "PLATFORM" },
  { text: "By App Behavior", value: "APP_BEHAVIOR" },
  { text: "By Nationality", value: "NATIONALITY" },
];

export const dummyData = [
  {
    id: "acac4eec-f338-4264-b4cf-2f8e2bacc1d0",
    notification_type: "PROMOTION",
    name: "Alphas",
    created_by: "John Reyes",
    created_at: "2022-11-17T19:20:42.343Z",
    updated_by: "Keifer Ramos",
    updated_at: "2022-11-17T19:20:42.340Z",
    recipients: [
      {
        id: "d63c0ae7-f40a-4259-a7e4-8d9162173a11",
        recipient_type: "STATUS",
        value: '{"name":"status","value":["active"]}',
        __typename: "Recipient",
      },
      {
        id: "1253f2dd-7556-41e5-b493-52ee299547fe",
        recipient_type: "HOTEL_BOOKING",
        value: '{"name":"hotel_booking","value":["Completed"]}',
        __typename: "Recipient",
      },
      {
        id: "15abc31a-c1dc-44eb-8d5f-4c9afd995daa",
        recipient_type: "LOYALTY_CARD",
        value: '{"name":"","value":[]}',
        __typename: "Recipient",
      },
    ],
    status: "SENT",
  },
  {
    id: "acac4eec-f338-4264-b4cf-2f8e2bacc1d0",
    notification_type: "BOOKING",
    name: "Bravo",
    created_by: "Keifer Ramos",
    created_at: "2021-10-31 23:12:00",
    updated_by: "Jane Villa",
    updated_at: "2021-10-31 23:12:00",
    recipients: [
      {
        id: "d63c0ae7-f40a-4259-a7e4-8d9162173a11",
        recipient_type: "STATUS",
        value: '{"name":"status","value":["active"]}',
        __typename: "Recipient",
      },
      {
        id: "1253f2dd-7556-41e5-b493-52ee299547fe",
        recipient_type: "HOTEL_BOOKING",
        value: '{"name":"hotel_booking","value":["Completed"]}',
        __typename: "Recipient",
      },
      {
        id: "15abc31a-c1dc-44eb-8d5f-4c9afd995daa",
        recipient_type: "LOYALTY_CARD",
        value: '{"name":"","value":[]}',
        __typename: "Recipient",
      },
    ],
    status: "DELETED",
  },
  {
    id: "acac4eec-f338-4264-b4cf-2f8e2bacc1d0",
    notification_type: "IN-STAY",
    name: "Charlie",
    created_by: "Allen Rose",
    created_at: "2021-10-31 23:12:00",
    updated_by: "Jane Villa",
    updated_at: "2021-10-31 23:12:00",
    recipients: [
      {
        id: "d63c0ae7-f40a-4259-a7e4-8d9162173a11",
        recipient_type: "STATUS",
        value: '{"name":"status","value":["active"]}',
        __typename: "Recipient",
      },
    ],
    status: "SCHEDULED",
  },
  {
    id: "acac4eec-f338-4264-b4cf-2f8e2bacc1d0",
    notification_type: "IN-STAY",
    name: "Delta",
    created_by: "John Reyes",
    created_at: "2021-10-31 23:12:00",
    updated_by: "Jane Villa",
    updated_at: "2021-10-31 23:12:00",
    recipients: [
      {
        id: "d63c0ae7-f40a-4259-a7e4-8d9162173a11",
        recipient_type: "STATUS",
        value: '{"name":"status","value":["active"]}',
        __typename: "Recipient",
      },
      {
        id: "1253f2dd-7556-41e5-b493-52ee299547fe",
        recipient_type: "HOTEL_BOOKING",
        value: '{"name":"hotel_booking","value":["Completed"]}',
        __typename: "Recipient",
      },
    ],
    status: "ARCHIVED",
  },
  {
    id: "acac4eec-f338-4264-b4cf-2f8e2bacc1d0",
    notification_type: "IN-STAY",
    name: "Foxtrot",
    created_by: "John Reyes",
    created_at: "2021-10-31 23:12:00",
    updated_by: "Allen Rose",
    updated_at: "2021-10-31 23:12:00",
    recipients: [],
    status: "DRAFT",
  },
];
