import styled from "styled-components";
import { Button, Divider, Tabs, Collapse } from "antd";
import { Input, Switch, Select } from "formik-antd";
import UploadSingleFile from "@/components/UploadSingleFile";
import { typography } from "../../../../../utilities/styles/theme";

const { Panel } = Collapse;

export const HeaderSubtitle = styled("h2")({
  ...typography("subtitle-1"),
  marginBottom: "1em",
});

export const StyledUploadImage = styled(UploadSingleFile)`
  width: 180px;
  height: 180px;
  display: block;
  overflow: hidden;
  background: #fff;

  .ant-upload {
    display: block;

    .upload-button {
      height: 180px;
      width: 180px;
      display: block;
    }
  }

  .upload-button {
    height: 180px;
    width: 180px;
    display: block;
  }

  .uploaded-file {
    height: 180px;
    width: 180px;

    img {
      height: 180px;
      width: 180px;
    }
  }

  .remove-file {
    top: 4px;
    right: 4px;
  }
`;

export const StyledUploadImageRectangle = styled(UploadSingleFile)`
  width: 160px;
  height: 100px;
  display: block;
  overflow: hidden;
  background: #fff;

  .ant-upload {
    display: block;

    .upload-button {
      width: 160px;
      height: 100px;
      display: block;
    }
  }

  .upload-button {
    width: 160px;
    height: 100px;
    display: block;
  }

  .uploaded-file {
    width: 160px;
    height: 100px;

    img {
      width: 160px;
      height: 100px;
    }
  }

  .remove-file {
    top: 4px;
    right: 4px;
  }
`;

export const StyledUploadImageSmall = styled(UploadSingleFile)`
  width: 104px;
  height: 104px;
  display: block;
  overflow: hidden;
  background: #fff;

  .ant-upload {
    display: block;

    .upload-button {
      height: 220px;
      width: 100%;
      height: 104px;
      width: 104px;
      display: block;
    }
  }

  .upload-button {
    height: 220px;
    width: 100%;
    width: 104px;
    height: 104px;
    display: block;
  }

  .uploaded-file {
    width: 104px;
    height: 104px;

    img {
      width: 104px;
      height: 104px;
    }
  }

  .remove-file {
    top: 4px;
    right: 4px;
  }
`;

export const ManageHotelContainer = styled.div`
  background: rgba(246, 246, 246, 1);
  border-radius: 5px;
  padding: 25px 35px;
  margin-bottom: 15px;
  position: relative;
  box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.22);
`;

export const StyledDivider = styled(Divider)({
  borderColor: "var(--color-general-blue-gray)",
  borderWidth: "1.5px",
});

export const CustomSelectInput = styled("div")({
  width: "100%",
  ".ant-select": {
    width: "100%",
  },
  ".ant-select .ant-select-selector": {
    border: "1px solid var(--color-general-blue-gray)",
    borderRadius: 2,
    outline: "none",
    borderRight: "1px solid var(--color-general-blue-gray)",
    height: 33,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  ".ant-input": {
    border: "1px solid var(--color-general-blue-gray)",
    borderRadius: 2,
    outline: "none",
    borderLeft: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  ".ant-form-item": {
    marginBottom: 15,
  },
  ".ant-select .ant-select-selection-item": {
    paddingRight: 0,
  },
});

export const HotelDeleteButton = styled(Button)`
  color: var(--color-secondary);
  padding: 0;
  // height: 31px;
  // width: 31px;
  margin-right: 5px;
  border: 0;
  background: transparent;
  box-shadow: none;

  :focus {
    color: var(--color-secondary);
    background: transparent;
  }

  :hover {
    color: var(--color-secondary);
    background: transparent;
    opacity: 0.75;
  }

  &:last-child {
    margin-right: 0;
  }

  .anticon {
    height: 15px;
    width: 15px;
  }
`;

export const InputWrapper = styled("div")({
  position: "relative",
  ".remove-btn": {
    position: "absolute",
    right: 10,
    top: 9,
    cursor: "pointer",
  },
});

export const IconKey = styled("div")({
  display: "inline-block",
  marginRight: "0.5rem",
});

export const StyledButtonAddContact = styled(Button)({
  padding: 0,
});

export const FormItemWrapper = styled("div")({
  ".ant-form-item": {
    marginBottom: 15,
  },
});

export const WrapperModalComponent = styled.div`
  width: 200px;
  height: 120px;
  background: rgba(242, 242, 242, 1);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(217, 217, 217, 1);
  border-radius: 2px;

  img {
    width: 200px;
    height: 118px;
  }
`;

export const ModalHeader = styled("div")({
  textAlign: "center",
  marginBottom: "1rem",
  "> * ": {
    margin: "0 0 1rem 0 ",
  },
  "> h4": {
    ...typography("subtitle-1"),
    color: "var(--color-primary)",
  },
});

export const InstructionLabel = styled("label")({
  ...typography("body-2"),
  color: "var(--color-secondary)",
});

export const SwitchCont = styled("div")({
  display: "flex",
  gap: "1em",
  alignItems: "baseline",
  ".ant-switch-checked": {
    backgroundColor: "var(--color-green)",
  },
});

// Manage hotels
export const ButtonWrapper = styled("div")({
  height: "100%",
  ".changeHeight": {
    height: "100% !important",
  },
  backgroundColor: "#E8EDF2",
});

export const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 0.3rem;
  height: 60px;
`;

export const AddHotelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  span {
    margin-left: 10px;
  }
`;

export const PayToggle = styled(Switch)`
  &.ant-switch-checked {
    background-color: #73c531;
  }
`;

export const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
    background-color: #7791b3;
    color: #ffffff;
    border-radius: 5px 5px 0 0;
    padding: 16px 24px;

    &.ant-tabs-tab-active {
      background-color: #1b467f;
      & .ant-tabs-tab-btn {
        color: #ffffff;
      }
    }
  }
`;

export const SmallNote = styled.small`
  margin: 20px 0 10px;

  span {
    color: #000000;
  }
`;

export const CountryCode = styled(Select)`
  & .ant-select-selector {
    border-radius: 0 !important;
    height: 33px !important;
  }
`;

export const MobileNumber = styled(Input)`
  border-radius: 0;
  margin-top: 32px;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const PhotoDescription = styled(Input)`
  padding: 0;
  margin-bottom: 0;
  border-bottom: solid 1px black;
  border-radius: 0;

  &:focus,
  &:hover {
    border-bottom: solid 1px black;
    border-radius: 0;
  }
`;

export const StyledInputNum = styled(Input)`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledPanel = styled(Panel)`
  border-bottom: 0 !important;

  & .ant-collapse-header {
    font-size: 20px;
    font-weight: 700;
    padding: 12px 0px !important;
  }

  & .ant-collapse-content .ant-collapse-content-box {
    padding: 0;
  }
`;

export const StyledInnerPanel = styled(Panel)`
  & .ant-collapse-header {
    font-size: 16px;
    font-weight: 400;
    padding: 12px 16px !important;
  }

  & .ant-collapse-content .ant-collapse-content-box {
    padding: 16px;
  }
`;
