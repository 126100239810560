import styled from "styled-components";
import { Button } from "antd";

export const Container = styled.div`
  display: flex;
  width: 100%;
  white-space: break-spaces;
  flex-wrap: wrap;

  .hasError .ant-upload .ant-btn {
    border-color: var(--color-secondary);
  }
`;

export const ButtonStyle = styled(Button)`
  height: 100px;
  width: 160px;
  padding: 0;
  overflow: hidden;
  color: #d8d8d8;
  background: #fafafa;

  img {
    height: 100px;
    width: 160px;
    object-fit: cover;
  }

  .anticon {
    svg {
      font-size: 24px;
    }
  }

  h1 {
    margin: 0;
    color: inherit;
  }
`;

export const RemoveBtn = styled.div`
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 3px;

  .anticon {
    svg {
      font-size: 15px;
    }

    &:hover {
      color: #00714b;
    }
  }
  img:hover {
    opacity: 0.85;
  }
`;

export const ImageWrapper = styled.div`
  background: #d8d8d8;
  margin: 0 15px 15px 0;
  position: relative;
  height: 100px;
  width: 160px;

  img {
    height: 100px;
    width: 160px;
    object-fit: cover;
  }

  video {
    width: 160px;
    height: 100px;
    object-fit: cover;
  }
`;

export const VideoPlayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .anticon.anticon-play-circle {
    color: #fff;
    font-size: 45px;
  }
`;

export const BrokenImageContainer = styled.div`
  position: relative;

  img {
    width: 100%;
    opacity: 10%;
  }

  p {
    position: absolute;
    top: 12px;
    background: darkgrey;
    width: 100%;
    text-align: center;
    padding: 12px;
    font-weight: 500;
    color: #000;
    opacity: 80%;
    font-size: 12px;
  }
`;

export const ImageNotScannedContainer = styled.div`
  position: relative;

  p {
    position: absolute;
    top: 2px;
    background: darkgrey;
    width: 100%;
    text-align: center;
    padding: 12px;
    font-weight: 500;
    color: #000;
    opacity: 80%;
    font-size: 12px;
  }
`;
