import { message, Row, Col, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";

import ContentContainer from "@/components/ContentContainer";
import CustomModal from "@/components/CustomModal";
import { IconKey, IconKeyTextCont } from "@/components/ActionsMenu/styled";
import {
  EditPenIcon,
  DeleteIcon,
  CancelIcon,
  InvertedCheckIcon,
} from "../../../../../utilities/icons";
import { RoleI } from "../../../../../models/RoleManagementInterface";
import { GET_ROLE, UPDATE_ROLE_STATUS } from "../../../graphql";
import { STATUS } from "../../../../../utilities/enums";
import { ROLES } from "./constants";
import {
  ActionButtons,
  ActionButtonsContainer,
  ActionButtonWrap,
  BorderTop,
  PromoPageWrap,
  SectionWrap,
  Sectiontitle,
  Span,
  SpanBY,
  Subtext,
  Subtitle,
} from "./styled";

const RoleManagementView = (): JSX.Element => {
  const { pageId }: { pageId: string } = useParams();
  const history = useHistory();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState<string>("");
  const [bodyMessage, setBodyMessage] = useState<string>("");
  const [bodyMessageSuccess, setBodyMessageSuccess] = useState<string>("");
  const [statusStr, setStatusStr] = useState<string>("");
  const [okTxt, setOkTxt] = useState<string>("OK");
  const [roleData, setRoleData] = useState<RoleI>();

  const [showActionButtons, setShowActionButtons] = useState<boolean>(false);
  const [showDeactivateBtn, setShowDeactivateBtn] = useState<boolean>(false);
  const [showDeleteBtn, setShowDeleteBtn] = useState<boolean>(false);
  const [showEditBtn, setShowEditBtn] = useState<boolean>(false);
  const [showActivateBtn, setShowActivateBtn] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("draft");
  const [scope, setScope] = useState<{}>();
  const [modifyStatus] = useMutation(UPDATE_ROLE_STATUS);

  const { loading, refetch, data } = useQuery(GET_ROLE, {
    skip: !pageId,
    fetchPolicy: "no-cache",
    variables: {
      id: pageId,
    },
  });

  useEffect(() => {
    if (data) {
      refetch();
      setRoleData(data.getRoleById);
      setStatus(data.getRoleById.status);
      setScope(JSON.parse(data.getRoleById.scope));
    }
  }, [data, refetch]);

  useEffect(() => {
    if (roleData) {
      const { status } = roleData;
      setShowActionButtons(status !== "DELETED");
      setShowDeactivateBtn(status !== "DEACTIVATED" && status !== "DELETED");
      setShowDeleteBtn(status !== "ACTIVE" && status !== "DELETED");
      setShowEditBtn(status !== "DELETED");
      setShowActivateBtn(status !== "ACTIVE" && status !== "DELETED");
    }
  }, [roleData]);

  const constructVariables = (roleId: string, status: STATUS): object => {
    return {
      role_id: roleId,
      status: status,
    };
  };

  const updateStatus = async (id: string, status: STATUS): Promise<void> => {
    const input = constructVariables(id, status);

    try {
      const { data: res } = await modifyStatus({
        variables: {
          updateRoleStatus: input,
        },
      });

      if (res.updateRoleStatus) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const checkStatusStrOkModal = async (statusStr) => {
    if (statusStr === "active") {
      await updateStatus(pageId, STATUS.ACTIVE);
      setBodyMessageSuccess("This role is now active.");
    }

    if (statusStr === "deactivate") {
      await updateStatus(pageId, STATUS.DEACTIVATED);
      setBodyMessageSuccess("This role is now deactivated.");
    }
    if (statusStr === "deleted") {
      await updateStatus(pageId, STATUS.DELETED);
      setBodyMessageSuccess("This role was successfully deleted.");
    }
    setShowModal(false);
    setShowModalSuccess(true);
  };

  // Go to Edit page
  const handleEdit = () => {
    history.push(`/user-role-management/role-management/edit-role/${pageId}`, { pageId });
  };

  // Delete confirmation modal content
  const handleDelete = () => {
    setShowModal(true);
    setTitleMessage("Delete Brand?");
    setBodyMessage(`Are you sure you want to activate '${roleData?.name}'?`);
    setStatusStr("deleted");
    setOkTxt("Delete");
  };

  // Archive confirmation modal content
  const handleDeactivate = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(`Are you sure you want to activate '${roleData?.name}'?`);
    setStatusStr("deactivate");
    setOkTxt("Deactivate");
  };

  const handleActivate = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(`Are you sure you want to activate '${roleData?.name}'?`);
    setStatusStr("active");
    setOkTxt("Activate");
  };

  const roleAccessArray =
    scope &&
    ROLES.map((data, index) => {
      const checkList = scope[data.key];
      console.log(checkList["add/delete"]);
      return (
        <Col span={8} key={index}>
          <Span primary>{data.title}</Span>
          <Subtext show={checkList["add/delete"] ? 1 : 0}>- Add / Delete</Subtext>
          <Subtext show={checkList["view"] ? 1 : 0}>- View</Subtext>
          <Subtext show={checkList["edit"] ? 1 : 0}>- Edit</Subtext>
          <Subtext show={checkList["activate/deactivate"] ? 1 : 0}>- Activate / Deactivate</Subtext>
          <Subtext show={checkList["download"] ? 1 : 0}>- Download</Subtext>
        </Col>
      );
    });

  return (
    <>
      <Spin spinning={loading}>
        {roleData !== undefined && (
          <PromoPageWrap>
            <ActionButtonsContainer show={showActionButtons ? 1 : 0}>
              <Row>
                <Col span="12" offset="12" style={{ textAlign: "right" }}>
                  <ActionButtonWrap>
                    <ActionButtons show={showDeleteBtn ? 1 : 0} onClick={handleDelete}>
                      <IconKeyTextCont>
                        <IconKey>
                          <DeleteIcon style={{ color: "red" }} />
                        </IconKey>{" "}
                        Delete
                      </IconKeyTextCont>
                    </ActionButtons>
                    <ActionButtons show={showDeactivateBtn ? 1 : 0} onClick={handleDeactivate}>
                      <IconKeyTextCont>
                        <IconKey>
                          <CancelIcon />{" "}
                        </IconKey>{" "}
                        Deactivate
                      </IconKeyTextCont>
                    </ActionButtons>
                    <ActionButtons
                      type="primary"
                      show={showActivateBtn ? 1 : 0}
                      onClick={handleActivate}
                    >
                      <IconKeyTextCont>
                        <IconKey>
                          <InvertedCheckIcon checkColor="#1B467F" />{" "}
                        </IconKey>{" "}
                        Activate
                      </IconKeyTextCont>
                    </ActionButtons>
                    <ActionButtons type="primary" show={showEditBtn ? 1 : 0} onClick={handleEdit}>
                      <IconKeyTextCont>
                        <IconKey>
                          <EditPenIcon />
                        </IconKey>{" "}
                        Edit
                      </IconKeyTextCont>
                    </ActionButtons>
                  </ActionButtonWrap>
                </Col>
              </Row>
            </ActionButtonsContainer>
            <SectionWrap>
              <ContentContainer>
                <Row justify="end">This Role is currently {status.toUpperCase()}</Row>
                <Subtitle>Role Name</Subtitle>
                <Span primary style={{ marginBottom: "24px" }}>
                  {roleData.name}
                </Span>
                <Subtitle>Role Access</Subtitle>
                <Row gutter={[8, 24]}>{roleAccessArray}</Row>
              </ContentContainer>
              <BorderTop />
              <ContentContainer>
                <Sectiontitle>Details</Sectiontitle>
                <Row>
                  <Col span={8}>
                    <Subtitle>Date/Time Created</Subtitle>
                    <Span primary>{moment(roleData.created_at).format("YYYY-MM-DD HH:mm:ss")}</Span>
                    <SpanBY>by {roleData.created_by}</SpanBY>
                  </Col>
                  <Col span={8}>
                    <Subtitle>Last Date/Time Edited</Subtitle>
                    <Span primary>{moment(roleData.updated_at).format("YYYY-MM-DD HH:mm:ss")}</Span>
                    <SpanBY>by {roleData.updated_by}</SpanBY>
                  </Col>
                </Row>
              </ContentContainer>
            </SectionWrap>
          </PromoPageWrap>
        )}
      </Spin>

      {/* Confirmation Modal */}
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showModal}
        okButtonWidth="8em"
        onOk={() => checkStatusStrOkModal(statusStr)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />

      {/* Success Modal */}
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon
        titleMessage="Success!"
        visible={showModalSuccess}
        onOk={() => setShowModalSuccess(false)}
        onCancel={() => setShowModalSuccess(false)}
        message={bodyMessageSuccess}
        okText={"OK"}
      />
    </>
  );
};

export default RoleManagementView;
