import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Radio } from "antd";
import { Form, FormItem, Input } from "formik-antd";

import ContentContainer from "@/components/ContentContainer";
import CustomButton from "@/components/CustomButton";
import CustomModal from "@/components/CustomModal";

import { StyledUploadImage, HeaderSubtitle, InstructionLabel, BorderTop } from "./styled";

interface Props {
  formikBag: any;
  brandName?: string;
}

const Forms: React.FC<Props> = ({ formikBag, brandName }) => {
  const { values, submitForm, errors, setFieldValue } = formikBag;
  const { pageId } = useParams();

  const history = useHistory();

  const [isModalVisibleLeaving, setIsModalVisibleLeaving] = useState<boolean>(false);
  const [isModalVisibleMedia, setIsModalVisibleMedia] = useState<boolean>(false);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState<boolean>(false);
  const [isMobileBannerFormVisible, setIsMobileBannerFormVisible] = useState<boolean>(true);

  const handleOkLeaving = () => {
    setIsModalVisibleLeaving(false);
    history.push("/home-page-management/rhr-hero-banner");
  };

  const changeIsMobileBannerFormVisibility = (value) => {
    setIsMobileBannerFormVisible(value);
  };

  const handleRadioOnChange = (e) => {
    if (pageId) {
      setIsModalVisibleMedia(true);
    } else {
      if (e.target.value === "image") {
        setFieldValue("media_type", "image");
        changeIsMobileBannerFormVisibility(true);
      } else {
        setFieldValue("media_type", "video");
        changeIsMobileBannerFormVisibility(false);
      }
    }
  };

  const handleOkMediaSwitch = () => {
    if (values.media_type === "video") {
      setFieldValue("media_type", "image");
      changeIsMobileBannerFormVisibility(true);
      setFieldValue("image", "");
    } else {
      setFieldValue("media_type", "video");
      setFieldValue("video_url", "");
      changeIsMobileBannerFormVisibility(false);
    }

    setIsModalVisibleMedia(false);
  };

  return (
    <>
      <Form>
        <InstructionLabel>* Required fields, do not leave this blank.</InstructionLabel>
        <ContentContainer
          style={{
            flexDirection: "column",
            borderRadius: "0.6rem 0.6rem 0 0",
            marginBottom: "0",
          }}
        >
          <Row gutter={40}>
            <Col md={12} span={24}>
              <Row>
                <Col span={24}>
                  <HeaderSubtitle>Media*</HeaderSubtitle>
                  <div style={{ marginBottom: "1.5rem" }}>
                    <Radio.Group
                      name="media_type"
                      defaultValue="image"
                      value={values.media_type}
                      onChange={handleRadioOnChange}
                    >
                      <Radio value="image">Image</Radio>
                      <Radio value="video">Video</Radio>
                    </Radio.Group>
                  </div>
                </Col>
                <Col span={24}>
                  {values.media_type && values.media_type === "image" ? (
                    <StyledUploadImage
                      accept={"image/png,image/jpeg"}
                      value={values.website_image}
                      hasNote
                      ratio="29:19"
                      sampleRatio="1450 x 950"
                      formatStr="JPG or PNG"
                      maxFileSize={5}
                      sizeLimit={5}
                      onChange={(e) => {
                        setFieldValue("website_image", e);
                      }}
                      errors={errors.website_image}
                    />
                  ) : (
                    <FormItem
                      label="Facebook link or Embedded Youtube link"
                      name={`video_url`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input name={`video_url`} placeholder="Enter Link" />
                    </FormItem>
                  )}
                </Col>
              </Row>
            </Col>
            <Col md={12} span={24}>
              <Row>
                <Col span={24}>
                  <FormItem
                    label={
                      <>
                        Title*
                        <small
                          style={{
                            marginLeft: "1rem",
                            color: "var(--color-general-blue-gray)",
                          }}
                        >
                          {" "}
                          Character Limit: 2 (min), 30(max)
                        </small>
                      </>
                    }
                    name={"title"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input name="title" placeholder="Enter Title" maxLength={30} minLength={2} />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label={
                      <>
                        Description*
                        <small
                          style={{
                            marginLeft: "1rem",
                            color: "var(--color-general-blue-gray)",
                          }}
                        >
                          {" "}
                          Character Limit: 10 (min), 50(max)
                        </small>
                      </>
                    }
                    name={"description"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      name="description"
                      placeholder="Enter Description"
                      maxLength={50}
                      minLength={10}
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label={
                      <>
                        Location*
                        <small
                          style={{
                            marginLeft: "1rem",
                            color: "var(--color-general-blue-gray)",
                          }}
                        >
                          {" "}
                          Character Limit: 2 (min), 30(max)
                        </small>
                      </>
                    }
                    name={"location"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      name="location"
                      placeholder="Enter Location. e.g Manila, Cebu City"
                      maxLength={30}
                      minLength={2}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
        </ContentContainer>
        {isMobileBannerFormVisible && <BorderTop />}
        <ContentContainer style={{ borderRadius: "0 0 0.6rem 0.6rem ", marginTop: "0" }}>
          {isMobileBannerFormVisible && (
            <Col>
              <HeaderSubtitle>Upload hero banner photo for mobile app</HeaderSubtitle>
              <Col span={9}>
                <StyledUploadImage
                  accept={"image/png,image/jpeg"}
                  value={values.mobile_image}
                  hasNote
                  ratio="1:1"
                  sampleRatio="414 x 896"
                  formatStr="JPG or PNG"
                  maxFileSize={5}
                  sizeLimit={5}
                  onChange={(e) => {
                    setFieldValue("mobile_image", e);
                  }}
                  errors={errors.mobile_image}
                />
              </Col>
            </Col>
          )}

          <div style={{ display: "flex", justifyContent: "end", gap: "1rem" }}>
            <Col span={4}>
              <CustomButton
                style={{ width: "100%" }}
                onClick={() => setIsModalVisibleLeaving(true)}
              >
                CANCEL
              </CustomButton>
            </Col>
            <Col span={3}>
              <CustomButton
                style={{ width: "100%" }}
                type="primary"
                onClick={pageId ? () => setIsModalVisibleEdit(true) : () => submitForm()}
              >
                SAVE
              </CustomButton>
            </Col>
          </div>
        </ContentContainer>
      </Form>

      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={"Save Changes?"}
        visible={isModalVisibleEdit}
        onOk={() => {
          setIsModalVisibleEdit(false);
          submitForm();
        }}
        onCancel={() => setIsModalVisibleEdit(false)}
        message={`Done editing? Are you sure you want to save changes for ${brandName}?`}
        cancelText="CANCEL"
        okText="SAVE"
        okButtonWidth="7.5em"
        cancelButtonWidth="7.5em"
      />

      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={pageId ? "Cancel Editing?" : "Leave this page?"}
        visible={isModalVisibleLeaving}
        onOk={handleOkLeaving}
        onCancel={() => setIsModalVisibleLeaving(false)}
        message={
          pageId
            ? "Are you sure you want to cancel editing?"
            : "All unsaved data will be lost. Are you sure you want to leave this page?"
        }
        cancelText={pageId ? "No" : "Stay here"}
        okText="Yes"
        okButtonWidth="7.5em"
        cancelButtonWidth="7.5em"
      />

      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage="Heads up!"
        visible={isModalVisibleMedia}
        onOk={handleOkMediaSwitch}
        onCancel={() => setIsModalVisibleMedia(false)}
        message="Switching media will delete your current uploaded/linked media."
        okText="Continue"
        okButtonWidth="7.5em"
        cancelButtonWidth="7.5em"
      />
    </>
  );
};

export default Forms;
