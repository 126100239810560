import styled from "styled-components";

export const Container = styled("div")`
  background: #fff;
  padding: 12px 12px;
  display: flex;
  border-radius: 0.6rem;
  flex-direction: column;
  box-shadow: 0px 2px 6px 0px rgba(221, 220, 220, 1);
  -webkit-box-shadow: 0px 2px 6px 0px rgba(221, 220, 220, 1);
  -moz-box-shadow: 0px 2px 6px 0px rgba(221, 220, 220, 1);
`;
