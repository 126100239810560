import { Row, Col } from "antd";

import ContentContainer from "@/components/ContentContainer";
import CustomButton from "@/components/CustomButton";

import { EditPenIcon } from "../../../../../utilities/icons";
import { WhiteTitle, BlueText, BlackText, BlackTitle, BrokenLine, WhiteText, RedText } from "./style";
import { useHistory, useRouteMatch } from "react-router-dom";
import { GET_FOOTER, } from "../../../graphql";
import { useQuery } from "@apollo/client";
import { useState, useEffect, useContext } from "react";
import { Context } from "../../../../../context";
import { FooterInformationI } from "../../../../../models/FooterInformationInterface";
import {
  Span,
} from "./style";
import moment from "moment";

const DOMPurify = require('dompurify')(window);

const RHRInformationsView = (): JSX.Element => {
  let match = useRouteMatch();
  const history = useHistory();

  const [dataFooter, setDataFooter] = useState<FooterInformationI>();
  const [handleFooter, sethandleDataFooter] = useState<FooterInformationI>();
  const [footerSocial, setFooterSocial] = useState({});

  const {
    loading: brandLoading,
    refetch,
    data,
  } = useQuery(GET_FOOTER);

  const {
    state,
    dispatch: { storeIsDeployed },
  } = useContext(Context);

  useEffect(() => {
    if (state?.isDeployed === true) {
      refetch();
      setDataFooter(data.getFooter[0]);
      sethandleDataFooter(data.getFooter[0]);
      storeIsDeployed(false);
    }
  }, [state]);

  useEffect(() => {
    if (data) {
      refetch();
      setDataFooter(data.getFooter[0]);
      sethandleDataFooter(data.getFooter[0]);

      let footerSocial = {};
      if (data.getFooter[0] && data.getFooter[0].social_media) {
        data.getFooter[0].social_media.map((soc, i) => {
            footerSocial[soc.social_media_type.name] = soc.url;
        });
      }
      setFooterSocial(footerSocial);
    }
  }, [data]);

  const goToEdit = (id): void => {
    if (id) {
      history.push(`${match.path}/edit-rhr-informations/` + id);
    } else {
      history.push(`${match.path}/edit-rhr-informations/`);
    }
  };

  return (
    <>
      <ContentContainer style={{ backgroundColor: "rgb(29,43,66)" }}>
        <Row>
          <WhiteTitle>Details</WhiteTitle>
        </Row>
        <Row justify="space-between">
          <Col>
            <Row>
              <BlueText>Last date/time edited:</BlueText>
              <WhiteText>
                { (dataFooter && dataFooter.updated_at) ? moment(dataFooter.updated_at).format("YYYY-MM-DD HH:mm:ss") : "-" }
              </WhiteText>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <BlueText>Last date/time deployed</BlueText>
              <WhiteText>
              { (dataFooter && dataFooter.deployed_at) ? moment(dataFooter.deployed_at).format("YYYY-MM-DD HH:mm:ss") : "-" }
              </WhiteText>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>
            <Row>
              <BlueText>Last edited by</BlueText>
              <WhiteText>
                { (dataFooter && dataFooter.updated_by) ? dataFooter.updated_by : "-" }
              </WhiteText>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <BlueText>Last deployed by</BlueText>
              <WhiteText>
              { (dataFooter && dataFooter.deployed_by) ? dataFooter.deployed_by : "-" }
              </WhiteText>
            </Row>
          </Col>
        </Row>
      </ContentContainer>
      <Row justify="space-between">
        <Col>
          <BlackTitle> Footer Section Content</BlackTitle>
        </Col>
        <Col>
          <CustomButton style={{ height: 50, backgroundColor: "rgb(239,242,245)" }} onClick={ (dataFooter) ? () => goToEdit(dataFooter.id) : () => goToEdit(null) }>
            <EditPenIcon style={{ fontSize: "40px" }} />
            Edit
          </CustomButton>
        </Col>
      </Row>

      <ContentContainer style={{ marginBottom: "0" }}>
        <Row>
          <BlueText>Location</BlueText>
        </Row>
        <Row style={{ marginBottom: "1rem" }}>
          <BlackText>{ (dataFooter && dataFooter.location) ? dataFooter.location : "No Input" }</BlackText>
        </Row>

        <Row>
          <BlueText>Telephone No.</BlueText>
        </Row>
        <Row style={{ marginBottom: "1rem" }}>
          <Col span={12}>
            <Row>
              {
                (dataFooter && dataFooter.landline_nos) ? (
                  dataFooter.landline_nos
                    .filter((landline) => landline.input !== "")
                    .map((landline, i) => (
                      <BlackText
                        key={i}
                        style={dataFooter.landline_nos.length - 1 === i ? {} : { marginBottom: "5px" }}
                      >
                        { dataFooter.landline_nos.length - 1 === i ? landline.input : landline.input + ', ' }  &nbsp;
                      </BlackText>
                    ))
                ) : <BlackText>No Input</BlackText>
              }
            </Row>
          </Col>
        </Row>

        <Row>
          <BlueText>Mobile No.</BlueText>
        </Row>
        <Row style={{ marginBottom: "1rem" }}>
          <Col span={12}>
            <Row>
              {
                (dataFooter && dataFooter.mobile_nos) ? (
                  dataFooter.mobile_nos
                    .filter((mobile) => mobile.input !== "")
                    .map((mobile, i) => (
                      <BlackText
                        key={i}
                        style={dataFooter.mobile_nos.length - 1 === i ? {} : { marginBottom: "5px" }}
                      >
                        { (dataFooter.mobile_nos.length - 1 === i && mobile.input !== "") ? mobile.country_code + " " + mobile.input : mobile.country_code + " " + mobile.input + ',' } &nbsp;
                      </BlackText>
                    ))
                ) : <BlackText>No Input</BlackText>
              }
            </Row>
          </Col>
        </Row>

        <Row>
          <BlueText>Email Address</BlueText>
        </Row>
        <Row style={{ marginBottom: "1rem" }}>
          <Col span={12}>
            <Row>
              {
                (dataFooter && dataFooter.email_addresses) ? (
                  dataFooter.email_addresses
                    .filter((email) => email.input !== "")
                    .map((email, i) => (
                      <BlackText
                        key={i}
                        primary
                        style={dataFooter.email_addresses.length - 1 === i ? {} : { marginBottom: "5px" }}
                      >
                        { dataFooter.email_addresses.length - 1 === i ? email.input : email.input + ', ' }  &nbsp;
                      </BlackText>
                    ))
                ) : <BlackText>No Input</BlackText>
              }
            </Row>
          </Col>
        </Row>
        <Row style={{ marginBottom: "1rem" }}>
          <Col span={8}>
            <BlueText>Facebook Link</BlueText>
            <RedText>
              { (dataFooter && footerSocial['facebook']) ? footerSocial['facebook'] : 'No input' }
            </RedText>
          </Col>
          <Col span={8}>
            <BlueText>Instagram Link</BlueText>
            <RedText>
              { (dataFooter && footerSocial['instagram']) ? footerSocial['instagram'] : 'No input' }
            </RedText>
          </Col>
          <Col span={8}>
            <BlueText>Youtube Link</BlueText>
            <RedText>
              { (dataFooter && footerSocial['youtube']) ? footerSocial['youtube'] : 'No input' }
            </RedText>
          </Col>
        </Row>
        <Row style={{ marginBottom: "1rem" }}>
          <Col span={8}>
            <BlueText>Twitter Link</BlueText>
            <RedText>
              { (dataFooter && footerSocial['twitter']) ? footerSocial['twitter'] : 'No input' }
            </RedText>
          </Col>
          <Col span={8}>
            <BlueText>LinkedIn Link</BlueText>
            <RedText>
              { (dataFooter && footerSocial['linkedin']) ? footerSocial['linkedin'] : 'No input' }
            </RedText>
          </Col>
          <Col span={8}>
            <BlueText>TikTok Link</BlueText>
            <RedText>
             { (dataFooter && footerSocial['tiktok']) ? footerSocial['tiktok'] : 'No input' }
            </RedText>
          </Col>
        </Row>
        <Row style={{ marginBottom: "1rem" }}>
          <Col span={8}>
            <BlueText>Kumu Link</BlueText>
            <RedText>
              { (dataFooter && footerSocial['kumu']) ? footerSocial['kumu'] : 'No input' }
            </RedText>
          </Col>
          <Col span={8}>
            <BlueText>Viber Community Link</BlueText>
            <RedText>
              { (dataFooter && footerSocial['viber_community']) ? footerSocial['viber_community'] : 'No input' }
            </RedText>
          </Col>
        </Row>
      </ContentContainer>

      <BrokenLine></BrokenLine>

      <ContentContainer style={{ marginTop: "0", marginBottom: "0" }}>
        <Row>
          <BlueText>Footnote title</BlueText>
        </Row>
        <Row style={{ marginBottom: "1rem" }}>
          <BlackText>{ ((dataFooter && dataFooter.footnote_title)) ? dataFooter.footnote_title : "No Input" }</BlackText>
        </Row>

        <Row>
          <BlueText>Footnote content</BlueText>
        </Row>
        <Row style={{ marginBottom: "1rem" }}>
          <BlackText>
            <div dangerouslySetInnerHTML={{ __html: ((dataFooter && dataFooter.footnote_body)) ? DOMPurify.sanitize(dataFooter.footnote_body) : "No Input" }} />
          </BlackText>
        </Row>
      </ContentContainer>

      <BrokenLine></BrokenLine>

      <ContentContainer style={{ marginTop: "0", marginBottom: "0" }}>
        <Row>
          <BlueText>RHR Description (Website)</BlueText>
        </Row>
        <Row style={{ marginBottom: "1rem" }}>
          <BlackText>{ ((dataFooter && dataFooter.description)) ? dataFooter.description : "No Input" }</BlackText>
        </Row>
      </ContentContainer>
    </>
  );
};

export default RHRInformationsView;
