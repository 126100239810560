import { Button } from "antd";
import styled from "styled-components";

export const ActionButtons = styled(Button)`
  min-width: 190px;
  height: 50px;
  margin-right: 15px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${(props) => (props.type === "primary" ? "#fff" : "#d7272d")};
  svg path {
    fill: ${(props) => (props.type === "primary" ? "#fff" : "#d7272d")};
  }
`;
