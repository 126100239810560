export const activeColumns = [
  {
    title: "Thumbnail",
    dataIndex: "thumbnail",
  },
  {
    title: "Title",
    dataIndex: "title",
  },
  {
    title: "Last Edited By",
    dataIndex: "lastEditedBy",
  },
  {
    title: "Last Deployed By",
    dataIndex: "lastDeployedBy",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Actions",
    dataIndex: "actions",
  },
];
