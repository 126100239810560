export const activeColumns = [
  {
    title: "Role",
    dataIndex: "role",
  },
  {
    title: "Last Updated by",
    dataIndex: "updatedBy",
  },
  {
    title: "Created by",
    dataIndex: "createdBy",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

export const actions = [
  {
    name: "View",
    checkStatus: null,
  },
  {
    name: "Edit",
    checkStatus: null,
  },
  {
    name: "Activate",
    checkStatus: ["active"],
  },
  {
    name: "Deactivate",
    checkStatus: ["deactivated"],
  },
  {
    name: "Delete",
    checkStatus: ["active"],
  },
];
