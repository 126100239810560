import { gql } from "@apollo/client";

export const GET_HERO_BANNERS = gql`
  query getHeroBanners {
    getHeroBanners(search: "") {
      id
      title
      location
      status
      order
      description
      created_by
      updated_by
      created_at
      updated_at
      deployed_by
      deployed_at
      media_hero_banner {
        id
        platform
        url
        media_type
      }
    }
  }
`;

export const GET_HERO_BANNER = gql`
  query getHeroBannerById($id: String!) {
    getHeroBannerById(id: $id) {
      id
      title
      description
      location
      status
      order
      created_by
      updated_by
      created_at
      updated_at
      deployed_by
      deployed_at
      media_hero_banner {
        id
        platform
        url
        media_type
      }
    }
  }
`;

export const CREATE_UPDATE_HERO_BANNER = gql`
  mutation createUpdateHeroBanner($data: CreateHeroBannerInput!) {
    createOrUpdateHeroBanner(createOrUpdateHeroBannerInput: $data) {
      id
      title
    }
  }
`;

export const UPDATE_HERO_BANNER_ORDER = gql`
  mutation updateHeroBannerOrder($updateHeroBannerOrderInput: UpdateHeroBannerOrderInput!) {
    updateHeroBannerOrder(updateHeroBannerOrderInput: $updateHeroBannerOrderInput) {
      id
      title
      location
      order
      updated_by
      updated_at
    }
  }
`;

export const GET_PROMOS_AND_PACKAGES = gql`
  query GetPromosAndPackages($search: String!) {
    getPromosAndPackages(search: $search) {
      id
      title
      photo
      short_description
      redirect_link
      redirect_link_status
      status
      created_at
      created_by
      updated_at
      updated_by
      deployed_at
      deployed_by
      is_deleted
      order
    }
  }
`;

export const GET_PROMO_AND_PACKAGE = gql`
  query GetPromoAndPackageById($id: String!) {
    getPromoAndPackageById(id: $id) {
      id
      title
      photo
      short_description
      redirect_link
      redirect_link_status
      status
      created_at
      created_by
      updated_at
      updated_by
      deployed_at
      deployed_by
      order
    }
  }
`;

export const CREATE_PROMO_AND_PACKAGE = gql`
  mutation CreatePromoAndPackage($input: CreatePromoAndPackageInput!) {
    createPromoAndPackage(input: $input) {
      id
      title
      photo
      short_description
      redirect_link
      status
      created_at
      created_by
      updated_at
      updated_by
      deployed_at
      deployed_by
    }
  }
`;

export const EDIT_PROMO_AND_PACKAGE = gql`
  mutation EditPromoAndPackage($editPromoAndPackageInput: EditPromoAndPackageInput!) {
    editPromoAndPackage(editPromoAndPackageInput: $editPromoAndPackageInput) {
      id
      title
      photo
      short_description
      redirect_link
      updated_at
      updated_by
    }
  }
`;

export const UPDATE_PROMO_AND_PACKAGE_STATUS = gql`
  mutation UpdatePromoAndPackageStatus(
    $updatePromoAndPackageStatusInput: UpdatePromoAndPackageStatusInput!
  ) {
    updatePromoAndPackageStatus(
      updatePromoAndPackageStatusInput: $updatePromoAndPackageStatusInput
    ) {
      id
      title
      is_deleted
      status
    }
  }
`;

export const UPDATE_PROMO_AND_PACKAGE_ORDER = gql`
  mutation UpdatePromoAndPackageOrder(
    $updatePromoAndPackageOrderInput: UpdatePromoAndPackageOrderInput!
  ) {
    updatePromoAndPackageOrder(updatePromoAndPackageOrderInput: $updatePromoAndPackageOrderInput) {
      id
      title
      short_description
      order
      updated_by
      updated_at
    }
  }
`;

export const MODIFY_STATUS = gql`
  mutation updateHeroBannerStatus($hero_banner_id: String!, $status: Status!) {
    updateHeroBannerStatus(
      updateHeroBannerStatusInput: { hero_banner_id: $hero_banner_id, status: $status }
    ) {
      id
      title
      status
      updated_by
    }
  }
`;

export const GET_UPDATES_AND_ANNOUNCEMENTS = gql`
  query getUpdatesAndAnnouncements($search: String!) {
    getUpdatesAndAnnouncements(search: $search) {
      id
      title
      short_description
      redirect_link
      photo_url
      status
      created_at
      created_by
      updated_at
      updated_by
      deployed_at
      deployed_by
      is_deleted
      order
    }
  }
`;

export const UPDATE_UPDATES_AND_ANNOUNCEMENTS_STATUS = gql`
  mutation updateUpdateAndAnnouncementStatus(
    $updateUpdateAndAnnouncementStatusInput: UpdateUpdateAndAnnouncementStatusInput!
  ) {
    updateUpdateAndAnnouncementStatus(
      updateUpdateAndAnnouncementStatusInput: $updateUpdateAndAnnouncementStatusInput
    ) {
      id
      title
      is_deleted
      status
    }
  }
`;

export const UPDATE_UPDATES_AND_ANNOUNCEMENTS_ORDER = gql`
  mutation updateUpdateAndAnnouncementOrder(
    $updateUpdateAndAnnouncementOrderInput: UpdateUpdateAndAnnouncementOrderInput!
  ) {
    updateUpdateAndAnnouncementOrder(
      updateUpdateAndAnnouncementOrderInput: $updateUpdateAndAnnouncementOrderInput
    ) {
      id
      title
      short_description
      order
      updated_by
      updated_at
    }
  }
`;

export const GET_UPDATE_AND_ANNOUNCEMENT = gql`
  query getUpdateAndAnnouncement($id: String!) {
    getUpdateAndAnnouncementById(id: $id) {
      id
      title
      photo_url
      short_description
      redirect_link
      status
      created_at
      created_by
      updated_at
      updated_by
      deployed_at
      deployed_by
      order
    }
  }
`;

export const CREATE_UPDATE_AND_ANNOUNCEMENT = gql`
  mutation createUpdateAndAnnouncement($input: CreateUpdateAndAnnouncementInput!) {
    createUpdateAndAnnouncement(input: $input) {
      id
      title
      photo_url
      short_description
      redirect_link
      status
      created_at
      created_by
      updated_at
      updated_by
      deployed_at
      deployed_by
    }
  }
`;

export const EDIT_UPDATE_AND_ANNOUNCEMENT = gql`
  mutation editUpdateAndAnnouncement(
    $editUpdateAndAnnouncementInput: EditUpdateAndAnnouncementInput!
  ) {
    editUpdateAndAnnouncement(editUpdateAndAnnouncementInput: $editUpdateAndAnnouncementInput) {
      id
      title
      photo_url
      short_description
      redirect_link
      updated_at
      updated_by
    }
  }
`;
