import { useState } from "react";
import { FormItem, Input } from "formik-antd";
import { Row, Col } from "antd";
import { Field } from "formik";
import { UploadOutlined, DeleteFilled } from "@ant-design/icons";

import Richtext from "@/components/Richtext2";
import CustomSwitch from "@/components/CustomSwitch";
import CustomModal from "@/components/CustomModal";

import ReorderIcon from "../../../../../assets/images/icons/ic_reorder.svg";

import {
  WrapperForm,
  UploadSingleFileStyled,
  SectionHeader,
  DeleteButton,
  IconKeyTextCont,
  IconKey,
} from "./styled";

const SectionSeven = ({
  fields,
  index,
  setFieldValue,
  fieldsArrayEvent,
  values,
  uploadComplete,
  setUploadStatus,
  providedRef,
  provided,
  errors,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isStatusEnable, setIsStatusEnable] = useState<boolean>(
    values.sections[index].fields.section_status && values.sections[index].fields.section_status
  );
  const enableText =
    values.sections[index].fields.section_status &&
    values.sections[index].fields.section_status === true
      ? "Enabled"
      : "Disabled";

  const handleOk = () => {
    setIsStatusEnable(!isStatusEnable);
    setFieldValue(`sections[${index}].fields.section_status`, false);
    setIsModalVisible(false);
  };

  return (
    <>
      <WrapperForm ref={providedRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <div style={{ color: "var(--color-general-blue-gray)", fontWeight: 600 }}>
            <IconKeyTextCont>
              <IconKey>
                <img src={ReorderIcon} alt="Reorder" />
              </IconKey>{" "}
              <span>Reorder</span>
            </IconKeyTextCont>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <div style={{ display: "flex", gap: "1em" }}>
              <label>Status:</label>
              <div>
                <div style={{ display: "flex", gap: "1em" }}>
                  <Field name={`sections[${index}].fields.status`}>
                    {() => (
                      <CustomSwitch
                        name={`sections[${index}].fields.status`}
                        isStatusEnable={
                          values.sections[index].fields.section_status &&
                          values.sections[index].fields.section_status
                        }
                        onClick={
                          values.sections[index].fields.section_status &&
                          values.sections[index].fields.section_status === true
                            ? () => setIsModalVisible(true)
                            : () => setFieldValue(`sections[${index}].fields.section_status`, true)
                        }
                      />
                    )}
                  </Field>
                  <span
                    style={{
                      color:
                        values.sections[index].fields.section_status &&
                        values.sections[index].fields.section_status
                          ? "var(--color-green)"
                          : "var(--color-primary)",
                    }}
                  >
                    {enableText}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <DeleteButton
                type="text"
                onClick={() => {
                  uploadComplete !== "uploading" && fieldsArrayEvent.remove(index);
                }}
              >
                <DeleteFilled /> Delete
              </DeleteButton>
            </div>
          </div>
        </div>
        <div>
          <SectionHeader>Title & Text (Right Align)</SectionHeader>
        </div>

        <Row gutter={24}>
          <Col span={24}>
            <div style={{ marginBottom: 24 }}>
              <Row className="ant-form-item">
                <Col span={24} className="ant-form-item-label">
                  <label>Header Title*</label>
                </Col>
                <Col span={24}>
                  <Field name={`sections[${index}].fields.header_title`}>
                    {() => (
                      <Richtext
                        value={values.sections[index].fields.header_title}
                        placeholder="Enter Header Title"
                        onChange={(e) => {
                          if (e === "<p><br></p>") {
                            setFieldValue(`sections[${index}].fields.header_title`, "");
                          } else setFieldValue(`sections[${index}].fields.header_title`, e);
                        }}
                        errors={errors && errors.length > 0 && errors[index]?.fields.header_title}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </div>

            <div style={{ marginBottom: 24 }}>
              <Row className="ant-form-item">
                <Col span={24} className="ant-form-item-label">
                  <label>Text Content*</label>
                </Col>
                <Col span={24}>
                  <Field name={`sections[${index}].fields.text_content`}>
                    {() => (
                      <Richtext
                        value={values.sections[index].fields.text_content}
                        placeholder="Enter content"
                        onChange={(e) => {
                          if (e === "<p><br></p>") {
                            setFieldValue(`sections[${index}].fields.text_content`, "");
                          } else setFieldValue(`sections[${index}].fields.text_content`, e);
                        }}
                        errors={errors && errors.length > 0 && errors[index]?.fields.text_content}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </div>

            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label={"Link"}
                  name={`sections[${index}].fields.link`}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    name={`sections[${index}].fields.link`}
                    placeholder="e.g https://www.sample.com"
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={24}>
                    <UploadSingleFileStyled
                      label={"Add Downloadable File"}
                      value={values.sections[index].fields.file}
                      onChange={(e) => setFieldValue(`sections[${index}].fields.file`, e)}
                      showUploadList={true}
                      accept={".pdf"}
                      children={
                        <div className={"label-button"}>
                          <UploadOutlined style={{ fontSize: 34 }} />
                          <p>UPLOAD PDF</p>
                        </div>
                      }
                      hasNote
                      notePlacing="right"
                      maxFileSize={5}
                      sizeLimit={5}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </WrapperForm>
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage="Heads up!"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        message="By Disabling, this section will not be visible once published."
        okText="Yes, Disable"
        okButtonWidth="8em"
        cancelButtonWidth="8em"
      />
    </>
  );
};

export default SectionSeven;
