export enum STATUS {
  PUBLISHED = "PUBLISHED",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  SCHEDULED = "SCHEDULED",
  DEACTIVATED = "DEACTIVATED",
}

export enum SECTIONTYPE {
  FEATUREDVIDEO = "featured_video",
}

export enum SECTIONSTATUS {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export enum MEDIA_TYPE {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum BOOKINGSTATUS {
  BOOKED = "BOOKED",
  FAILED = "FAILED",
  PENDING = "PENDING",
}

export enum PLATFORM {
  WEBSITE = "WEBSITE",
  MOBILE = "MOBILE",
}
