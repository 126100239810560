import Icon from "@ant-design/icons";

const DeleteSvg = () => (
  <svg width="24px" height="24px" fill="currentColor" viewBox="0 0 24 24">
    <path
      d="M6,19 C6,20.1 6.9,21 8,21 L16,21 C17.1,21 18,20.1 18,19 L18,7 L6,7 L6,19 L6,19 Z M19,4 L15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 L19,4 Z"
      id="Shape"
    ></path>
  </svg>
);

export const DeleteIcon = (props) => <Icon component={DeleteSvg} {...props} />;

const DeleteSvgAlt = () => (
  <svg width="24px" height="24px" fill="#1B467F" viewBox="0 0 24 24">
    <path
      d="M6,19 C6,20.1 6.9,21 8,21 L16,21 C17.1,21 18,20.1 18,19 L18,7 L6,7 L6,19 L6,19 Z M19,4 L15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 L19,4 Z"
      id="Shape"
    ></path>
  </svg>
);

export const DeleteIconAlt = (props) => <Icon component={DeleteSvgAlt} {...props} />;

const PublishSvg = () => (
  <svg width="24px" height="24px" fill="currentColor" viewBox="0 0 24 24">
    <path
      d="M5,4 L5,6 L19,6 L19,4 L5,4 L5,4 Z M5,14 L9,14 L9,20 L15,20 L15,14 L19,14 L12,7 L5,14 L5,14 Z"
      id="Shape"
      fill="#1B467F"
    ></path>
  </svg>
);

export const PublishIcon = (props) => <Icon component={PublishSvg} {...props} />;

const EyeSvg = () => (
  <svg width="24px" height="24px" fill="currentColor" viewBox="0 0 24 24">
    <path
      d="M12,4.5 C7,4.5 2.73,7.61 1,12 C2.73,16.39 7,19.5 12,19.5 C17,19.5 21.27,16.39 23,12 C21.27,7.61 17,4.5 12,4.5 L12,4.5 Z M12,17 C9.24,17 7,14.76 7,12 C7,9.24 9.24,7 12,7 C14.76,7 17,9.24 17,12 C17,14.76 14.76,17 12,17 L12,17 Z M12,9 C10.34,9 9,10.34 9,12 C9,13.66 10.34,15 12,15 C13.66,15 15,13.66 15,12 C15,10.34 13.66,9 12,9 L12,9 Z"
      id="Shape"
      fill="#1B467F"
    ></path>
  </svg>
);

export const EyeIcon = (props) => <Icon component={EyeSvg} {...props} />;

const EditPenSvg = () => (
  <svg width="24px" height="24px" fill="currentColor" viewBox="0 0 24 24">
    <path
      d="M3,17.25 L3,21 L6.75,21 L17.81,9.94 L14.06,6.19 L3,17.25 L3,17.25 Z M20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C17.98,2.9 17.35,2.9 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 L20.71,7.04 Z"
      id="Shape"
    ></path>
  </svg>
);

export const EditPenIcon = (props) => <Icon component={EditPenSvg} {...props} />;

const ArchiveSvg = () => (
  <svg width="24px" height="24px" fill="currentColor" viewBox="0 0 24 24">
    <path
      d="M20.54,5.23 L19.15,3.55 C18.88,3.21 18.47,3 18,3 L6,3 C5.53,3 5.12,3.21 4.84,3.55 L3.46,5.23 C3.17,5.57 3,6.02 3,6.5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,6.5 C21,6.02 20.83,5.57 20.54,5.23 L20.54,5.23 Z M12,17.5 L6.5,12 L10,12 L10,10 L14,10 L14,12 L17.5,12 L12,17.5 L12,17.5 Z M5.12,5 L5.93,4 L17.93,4 L18.87,5 L5.12,5 L5.12,5 Z"
      id="Shape"
    ></path>
  </svg>
);

export const ArchiveIcon = (props) => <Icon component={ArchiveSvg} {...props} />;

const CheckSvg = () => (
  <svg width="16px" height="16px" viewBox="0 0 80 80">
    <g id="CMS" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
      <g id="Successful-Unpublish" transform="translate(-680.000000, -410.000000)">
        <g id="Group-3" transform="translate(484.000000, 362.000000)">
          <g id="Group" transform="translate(196.000000, 48.000000)">
            <path
              d="M40,0 C17.92,0 0,17.92 0,40 C0,62.08 17.92,80 40,80 C62.08,80 80,62.08 80,40 C80,17.92 62.08,0 40,0 L40,0 Z"
              id="Shape"
              fill="#1B467F"
            ></path>
            <polygon
              id="Path"
              fill="#FFFFFF"
              points="32.0122888 60.2150538 12.0430108 40.4020391 17.6743472 34.814769 32.0122888 49.0008875 62.3256528 18.9247312 67.9569892 24.5516274"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const CheckIcon = (props) => <Icon component={CheckSvg} {...props} />;

const InvertedCheckSvg = () => (
  <svg width="16px" height="16px" viewBox="0 0 80 80">
    <g id="CMS" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
      <g id="Successful-Unpublish" transform="translate(-680.000000, -410.000000)">
        <g id="Group-3" transform="translate(484.000000, 362.000000)">
          <g id="Group" transform="translate(196.000000, 48.000000)">
            <path
              d="M40,0 C17.92,0 0,17.92 0,40 C0,62.08 17.92,80 40,80 C62.08,80 80,62.08 80,40 C80,17.92 62.08,0 40,0 L40,0 Z"
              id="Shape"
              fill="#FFFFFF"
            ></path>
            <polygon
              id="Path"
              fill="#1B467F"
              points="32.0122888 60.2150538 12.0430108 40.4020391 17.6743472 34.814769 32.0122888 49.0008875 62.3256528 18.9247312 67.9569892 24.5516274"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const InvertedCheckIcon = (props) => <Icon component={InvertedCheckSvg} {...props} />;

const CancelSvg = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <g id="CMS:-Sprint-1-2" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
      <g
        id="RHR-Hero-Banner:-Edit-Banner-Item-(Image)"
        transform="translate(-787.000000, -357.000000)"
      >
        <g id="Group" transform="translate(787.000000, 357.000000)">
          <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
          <circle id="Oval" fill="#FFFFFF" cx="13" cy="12" r="8"></circle>
          <path
            d="M12,2 L12.2802563,2.00383574 C17.6802413,2.15182993 22,6.56372881 22,12 C22,17.53 17.53,22 12,22 C6.47,22 2,17.53 2,12 C2,6.47 6.47,2 12,2 L12,2 Z M17,15.59 L15.59,17 L12,13.41 L8.41,17 L7,15.59 L10.59,12 L7,8.41 L8.41,7 L12,10.59 L15.59,7 L17,8.41 L13.41,12 L17,15.59 L17,15.59 Z"
            id="Shape"
            fill="#D7272D"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export const CancelIcon = (props) => <Icon component={CancelSvg} {...props} />;

const ScheduleSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 24 24"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#1B467F"
  >
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <g>
        <path d="M17,12c-2.76,0-5,2.24-5,5s2.24,5,5,5c2.76,0,5-2.24,5-5S19.76,12,17,12z M18.65,19.35l-2.15-2.15V14h1v2.79l1.85,1.85 L18.65,19.35z" />
        <path d="M11,12l-6-1.5V7.01l8.87,3.74c0.94-0.47,2-0.75,3.13-0.75c0.1,0,0.19,0.01,0.28,0.01L3,4v16l7-2.95c0-0.02,0-0.03,0-0.05 c0-0.8,0.14-1.56,0.39-2.28L5,16.99V13.5L11,12z" />
      </g>
    </g>
  </svg>
);

export const ScheduleIcon = (props) => <Icon component={ScheduleSvg} {...props} />;
