import { gql } from "@apollo/client";

export const GET_COUNTRY_CODE_LIST = gql`
  query {
    countryCodes {
      id
      name
      flag
      code
      dial_code
    }
  }
`;

export const GET_CATEGORY_LIST = gql`
  query {
    getCategories {
      id
      name
    }
  }
`;

export const GET_BRANDS = gql`
  query GetBrands($search: String!) {
    getBrands(search: $search) {
      id
      name
      logo
      short_description
      long_description
      status
      slug
      order
      created_by
      created_at
      updated_by
      updated_at
      deployed_by
      deployed_at
    }
  }
`;

export const GET_BRAND_BYID = gql`
  query GetBrandById($id: String!) {
    getBrandById(id: $id) {
      id
      name
      logo
      short_description
      long_description
      redirect_link
      redirect_link_status
      status
      slug
      created_at
      updated_at
      created_by
      updated_by
      deployed_at
      deployed_by
      page_title
      seo_description
      seo_keywords
      category {
        id
        name
      }
      landline_nos {
        id
        input
      }
      mobile_nos {
        id
        country_code
        input
      }
      email_addresses {
        id
        input
      }
      uploaded_media {
        uploaded_media_type {
          name
          description
        }
        url
      }
      hotels {
        id
        name
        website_logo
        app_logo
        meetings_and_celebrations_link
        booking_engine_link
        request_for_proposal_link
        group_booking_link
        address
        website_link
        partner_hotel_code
        longitude
        latitude
        country_code
        mobile_no
        landline_no
        email_address
        tripadvisor_link
        description
        policies
        facilities_and_amenities
        dining
        nearby_attractions
        footnote_title
        footnote_body
        seo_keywords
        seo_description
        page_title
        payment_options {
          id
          platform
          merchant_id
          merchant_name
          shared_key
          status
          pay_at_hotel
        }
        uploaded_hotel_photos {
          id
          url
          photo_description
        }
      }
      social_media {
        social_media_type {
          name
        }
        url
      }
      sections {
        id
        order
        section_status
        section_type {
          name
        }
        header_title
        text_content
        photo_thumbnail_url
        link
        pdf_url
        created_at
        created_by
        updated_at
        updated_by
        order
      }
    }
  }
`;

export const CREATE_BRAND = gql`
  mutation createBrand($data: CreateBrandInput!) {
    createBrand(createBrandInput: $data) {
      id
      name
      logo
    }
  }
`;

export const MODIFY_BRAND_STATUS = gql`
  mutation updateBrandStatus($brand_id: String!, $status: Status!) {
    updateBrandStatus(updateBrandStatusInput: { brand_id: $brand_id, status: $status }) {
      id
      status
      name
      updated_by
    }
  }
`;

export const MODIFY_BRAND = gql`
  mutation modifyBrand($data: CreateBrandInput!) {
    createOrUpdateBrand(createOrUpdateBrandInput: $data) {
      id
      name
      logo
    }
  }
`;

export const REORDER_BRAND = gql`
  mutation ReOrderBrand($details: [UpdateBrandOrder!]!) {
    updateBrandOrder(updateBrandOrderInput: { details: $details }) {
      id
      order
      name
      created_by
    }
  }
`;
