import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Col, Row, Spin, message } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { upperCase } from "lodash";

import CustomModal from "@/components/CustomModal";
import CustomActionButtons from "@/components/CustomActionButtons";

import { GET_HERO_BANNER, MODIFY_STATUS } from "../../../graphql";
import { STATUS, MEDIA_TYPE } from "../../../../../utilities/enums";
import GoLogo from "../../../../../assets/images/brands/go_logo_colored.png";

import {
  Span,
  Subtitle,
  ImgLogo,
  ImgWrap,
  SectionWrap,
  BorderTop,
  Sectiontitle,
  SpanBY,
  BrandPageWrap,
  Wrapper,
  ActionButtonsContainer,
} from "./styled";

const RhrHeroBannerView = () => {
  const history = useHistory();
  const { pageId }: { pageId: string } = useParams();
  const [modifyStatus] = useMutation(MODIFY_STATUS);
  const {
    loading,
    refetch,
    data: heroBannerData,
  } = useQuery(GET_HERO_BANNER, {
    skip: !pageId,
    fetchPolicy: "no-cache",
    variables: {
      id: pageId,
    },
  });

  const [heroBanner, setHeroBanner] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [bodyMessageSuccess, setBodyMessageSuccess] = useState("");
  const [statusStr, setStatusStr] = useState("");
  const [brandNameModal, setBrandNameModal] = useState("");
  const [okTxt, setOkTxt] = useState("OK");

  const constructVariables = (hero_banner_id: string, status: STATUS) => {
    return {
      variables: {
        hero_banner_id: hero_banner_id,
        status: upperCase(status),
      },
    };
  };

  const updateStatus = async (id: string, status: STATUS) => {
    try {
      const { data: res } = await modifyStatus(constructVariables(id, status));
      if (res.updateHeroBannerStatus) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const checkStatusStrOkModal = async (statusStr, brandNameModal) => {
    if (statusStr === "published") {
      await updateStatus(pageId, STATUS.PUBLISHED);
      setBodyMessageSuccess(`Banner item was successfully published.`);
    }

    if (statusStr === "archived") {
      await updateStatus(pageId, STATUS.ARCHIVED);
      setBodyMessageSuccess(`This banner item was successfuly archived.`);
    }
    if (statusStr === "deleted") {
      await updateStatus(pageId, STATUS.DELETED);
      setBodyMessageSuccess(`This banner item was successfuly deleted.`);
    }
    setShowModal(false);
    setShowModalSuccess(true);
  };

  const handleArchive = (brandName) => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage("By archiving this brand, it will not be visible in the website when deployed.");
    setStatusStr("archived");
    setBrandNameModal(brandName);
    setOkTxt("Archive");
  };

  const handlePublish = (brandName) => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By Publishing this content, all information will be visible in the website once deployed."
    );
    setStatusStr("published");
    setBrandNameModal(brandName);
    setOkTxt("Publish");
  };

  const handleEdit = () => {
    history.push(`/home-page-management/rhr-hero-banner/edit-banner-content/${pageId}`, { pageId });
  };

  const handleDelete = (brandName) => {
    setShowModal(true);
    setTitleMessage("Delete Brand?");
    setBodyMessage(`Are you sure you want to delete "${brandName}?"`);
    setStatusStr("deleted");
    setBrandNameModal(brandName);
    setOkTxt("Delete");
  };

  useEffect(() => {
    if (heroBannerData) {
      setHeroBanner(heroBannerData.getHeroBannerById);
    }
  }, [heroBannerData]);

  const onErrorImg = (e) => {
    e.target.src = GoLogo;
  };

  return (
    <>
      <Spin spinning={loading}>
        {heroBanner !== undefined && (
          <BrandPageWrap>
            <ActionButtonsContainer>
              <Col span="24" style={{ textAlign: "right" }}>
                <CustomActionButtons
                  dataStatus={heroBanner.status}
                  dataTitle={heroBanner.title}
                  handlePublish={() => handlePublish(heroBanner.title)}
                  handleArchive={() => handleArchive(heroBanner.title)}
                  handleDelete={() => handleDelete(heroBanner.title)}
                  handleEdit={handleEdit}
                />
              </Col>
            </ActionButtonsContainer>

            <SectionWrap>
              <Wrapper>
                <div style={{ textAlign: "right", marginBottom: 24 }}>
                  <Subtitle>This banner item is currently {heroBanner.status}</Subtitle>
                </div>
                <Row gutter={40}>
                  {heroBanner.media_hero_banner
                    .filter((media) => media.platform === "WEBSITE")
                    .map((media, index) => (
                      <>
                        <Col span={media.media_type === MEDIA_TYPE.IMAGE ? 6 : 12}>
                          <article>
                            <Subtitle>Media</Subtitle>
                            {media.media_type === MEDIA_TYPE.IMAGE ? (
                              <ImgWrap>
                                <ImgLogo src={media.url} onError={onErrorImg} alt="Media" />
                              </ImgWrap>
                            ) : (
                              <Span>{media.url}</Span>
                            )}
                          </article>
                        </Col>
                        <Col span={media.media_type === MEDIA_TYPE.IMAGE ? 18 : 12}>
                          <Row>
                            <Col span={24}>
                              <Subtitle>Title</Subtitle>
                              <Span primary>{heroBanner.title}</Span>
                            </Col>
                            <Col span={24}>
                              <Subtitle>Description</Subtitle>
                              <Span primary>{heroBanner.description}</Span>
                            </Col>
                            <Col span={24}>
                              <Subtitle>Location</Subtitle>
                              <Span primary>{heroBanner.location}</Span>
                            </Col>
                          </Row>
                        </Col>
                      </>
                    ))}
                </Row>
              </Wrapper>

              <BorderTop />
              <Row gutter={20} style={{ padding: "0 32px", marginTop: 24 }}>
                <Col span={24}>
                  <Sectiontitle>Banner Item Details</Sectiontitle>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Row gutter={20}>
                        <Col span={8}>
                          <Subtitle>Date/Time Created</Subtitle>
                          <Span primary style={{ marginBottom: "5px" }}>
                            {moment(heroBanner.created_at).format("YYYY-MM-DD HH:mm:ss")}
                          </Span>
                          <SpanBY>by {heroBanner.created_by}</SpanBY>
                        </Col>
                        <Col span={8}>
                          <Subtitle>Last Date/Time Edited</Subtitle>
                          <Span primary style={{ marginBottom: "5px" }}>
                            {moment(heroBanner.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                          </Span>
                          <SpanBY>by {heroBanner.updated_by}</SpanBY>
                        </Col>
                        <Col span={8}>
                          <Subtitle>Last Date/Time Deployed</Subtitle>
                          <Span primary style={{ marginBottom: "5px" }}>
                            {heroBanner.deployed_at === null
                              ? "-"
                              : moment(heroBanner.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                          </Span>
                          <SpanBY>
                            {heroBanner.deployed_by === null ? "-" : heroBanner.deployed_by}
                          </SpanBY>
                        </Col>
                      </Row>
                    </Col>
                    {heroBanner.status === STATUS.ARCHIVED && (
                      <Col span={24}>
                        <Subtitle>Article Link</Subtitle>
                        <Span>
                          <a
                            href={`${process.env.REACT_APP_WEBSITE_LINK}/homepage`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {process.env.REACT_APP_WEBSITE_LINK}/homepage
                          </a>
                        </Span>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </SectionWrap>
          </BrandPageWrap>
        )}
      </Spin>
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showModal}
        onOk={() => checkStatusStrOkModal(statusStr, brandNameModal)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon
        titleMessage="Success!"
        visible={showModalSuccess}
        onOk={() => setShowModalSuccess(false)}
        onCancel={() => setShowModalSuccess(false)}
        message={bodyMessageSuccess}
        okText={"OK"}
      />
    </>
  );
};

export default RhrHeroBannerView;
