import React from "react";
import { Col, Row, Spin, Menu, Pagination } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { lowerCase } from "lodash";
import CustomButton from "@/components/CustomButton";
import { useHistory, useRouteMatch } from "react-router-dom";

import ContentContainer from "@/components/ContentContainer";

import { StyledSearch, MenuContainer, IconKeyTextCont, IconKey, NoResultFoundWrap } from "./styled";

import { DeleteIcon, EditPenIcon, EyeIcon, CheckIcon } from "../../utilities/icons";

import KebabIconImg from "../../assets/images/icons/ic_more_kebab.svg";
import NoResultFound from "../../assets/images/icons/no-result-found-icon.svg";
import moment from "moment";

const { SubMenu } = Menu;

interface Props {
  loading?: boolean;
  headerColumns?: any;
  handleData: React.Dispatch<any>;
  data: any;
  fullData: any;
  originalData: any;
  handleMenu?: (menuItem: string, id: string, name: string) => void;
  handleAddBtn?: () => void;
  handleInfiniteOnLoad?: any;
  hasMore?: boolean;
  onDragEnd?: any;
  searchPlaceholder?: string;
  addLabelText?: string;
  handleSelectedRow?: (id: string, slug: string, brandName?: string) => void;
}

const CustomDashboard1: React.FC<Props> = ({
  loading,
  headerColumns,
  data,
  handleData,
  fullData,
  originalData,
  handleMenu,
  handleAddBtn,
  onDragEnd,
  addLabelText,
  handleSelectedRow,
  searchPlaceholder,
}) => {
  let match = useRouteMatch();
  const history = useHistory();
  const onSelect = (item: any, id: string, name: any) => {
    handleMenu && handleMenu(item.key, id, name);
  };

  const onClickRow = (id, slug, brandName) => {
    handleSelectedRow && handleSelectedRow(id, slug, brandName);
  };

  const onAddBtnClick = () => {
    history.push(`${match.path}/add-user`);
  };

  const handleSearch = (e) => {
    const value = e.toLowerCase();
    const newData = originalData.filter((item) => {
      const name = item.name.toLowerCase();
      const role = item.access_role.toLowerCase();
      const company = item.company.toLowerCase();

      return name.includes(value) || role.includes(value) || company.includes(value);
    });

    handleData(newData);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (value === "") {
      handleData(originalData);
    } else {
      handleSearch(value);
    }
  };

  return (
    <div>
      <ContentContainer>
        <Row justify="space-between" style={{ marginBottom: "2rem", paddingTop: "1rem" }}>
          <Col span={8}>
            <StyledSearch
              placeholder={searchPlaceholder}
              onChange={handleChange}
              enterButton
              allowClear
            />
          </Col>
          <Col>
            <CustomButton onClick={onAddBtnClick} style={{ height: 50 }}>
              <PlusOutlined /> Add User {addLabelText}
            </CustomButton>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Row>
            <Col span={24}>
              <DragDropContext onDragEnd={onDragEnd}>
                <div className="ant-table-wrapper">
                  <div className="ant-spin-nested-loading">
                    <div className="ant-spin-container">
                      <div className="ant-table" data-index="table">
                        <div className="ant-table-container">
                          <div className="ant-table-content">
                            <table style={{ tableLayout: "fixed" }}>
                              <colgroup></colgroup>
                              <thead className="ant-table-thead">
                                <tr
                                  className="ant-table-row"
                                  style={{ position: "sticky", top: 0, zIndex: 1 }}
                                >
                                  {headerColumns.map((item, index) => (
                                    <th className="ant-table-cell" key={`title_${index}`}>
                                      {item.title}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <Droppable droppableId="droppable">
                                {(provided) => (
                                  <tbody className="ant-table-tbody" ref={provided.innerRef}>
                                    {data.map((item, index) => (
                                      <Draggable
                                        key={index}
                                        draggableId={`dragId_${index}`}
                                        index={index}
                                        isDragDisabled={true}
                                      >
                                        {(provided, snapshots) => (
                                          <tr
                                            key={index}
                                            className={`ant-table-row tr-draggable ${
                                              lowerCase(item.status) === "deleted" && "tr-deleted"
                                            }`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onClick={() =>
                                              onClickRow(item.id, item.slug, item.name)
                                            }
                                          >
                                            <td
                                              className="ant-table-cell"
                                              style={{ color: "rgb(48,90,142)" }}
                                            >
                                              {item.name}
                                            </td>
                                            <td
                                              className="ant-table-cell"
                                              style={{
                                                color: item.access_role === "Advertiser" ? "red" : "black",
                                              }}
                                            >
                                              {item.access_role}
                                            </td>
                                            <td
                                              className="ant-table-cell"
                                              style={{ color: "black" }}
                                            >
                                              {item.company}
                                            </td>
                                            <td
                                              className="ant-table-cell"
                                              style={{ color: "black" }}
                                            >
                                              {item.last_logged_in === null ? "-" : moment(item.last_logged_in).format("YYYY-MM-DD HH:mm:ss")}
                                            </td>
                                            <td
                                              className="ant-table-cell"
                                              style={{ color: "black" }}
                                            >
                                              {item.updated_at === null ? "-" : moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                                            </td>
                                            <td
                                              className="ant-table-cell"
                                              style={{ color: "gray" }}
                                            >
                                              {item.status}
                                            </td>

                                            <td
                                              className="ant-table-cell"
                                              onClick={(e) => e.stopPropagation()}
                                            >
                                              <MenuContainer>
                                                <Menu
                                                  selectable={false}
                                                  className="kebab-menu"
                                                  style={{
                                                    display:
                                                      lowerCase(item.action) === "deleted"
                                                        ? "none"
                                                        : "block",
                                                  }}
                                                  onClick={(menuItem) =>
                                                    onSelect(
                                                      menuItem,
                                                      item.id,
                                                      item.name
                                                    )
                                                  }
                                                >
                                                  <SubMenu
                                                    icon={
                                                      <img src={KebabIconImg} alt="Kebab Icon" />
                                                    }
                                                  >
                                                    <Menu.Item
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        height: 35,
                                                        lineHeight: "unset",
                                                        margin: "0.5rem 0 0 0",
                                                        fontSize: 14,
                                                      }}
                                                      key="view"
                                                    >
                                                      <IconKeyTextCont>
                                                        <IconKey>
                                                          <EyeIcon />
                                                        </IconKey>{" "}
                                                        View
                                                      </IconKeyTextCont>
                                                    </Menu.Item>
                                                    <Menu.Item
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        height: 35,
                                                        lineHeight: "unset",
                                                        margin: 0,
                                                        fontSize: 14,
                                                      }}
                                                      key="edit"
                                                    >
                                                      <IconKeyTextCont>
                                                        <IconKey>
                                                          <EditPenIcon />
                                                        </IconKey>{" "}
                                                        Edit
                                                      </IconKeyTextCont>
                                                    </Menu.Item>
                                                    <Menu.Item
                                                      className="delete-menu-item"
                                                      style={{
                                                        display:
                                                          (lowerCase(item.status) === "active")
                                                            ? "flex"
                                                            : "none",
                                                        alignItems: "center",
                                                        height: 35,
                                                        lineHeight: "unset",
                                                        margin: "0 0 0.5rem 0",
                                                        color: "red",
                                                        fontSize: 14,
                                                      }}
                                                      key="deactivate"
                                                    >
                                                      <IconKeyTextCont>
                                                        <IconKey>
                                                          <DeleteIcon style={{ color: "red" }} />
                                                        </IconKey>{" "}
                                                        Deactivate
                                                      </IconKeyTextCont>
                                                    </Menu.Item>
                                                    <Menu.Item
                                                      className="delete-menu-item"
                                                      style={{
                                                        display:
                                                        (item.status === "FOR_ACTIVATION")
                                                            ? "flex"
                                                            : "none",
                                                        alignItems: "center",
                                                        height: 35,
                                                        lineHeight: "unset",
                                                        margin: "0 0 0.5rem 0",
                                                        fontSize: 14,
                                                      }}
                                                      key="activate"
                                                    >
                                                      <IconKeyTextCont>
                                                        <IconKey>
                                                          <CheckIcon />
                                                        </IconKey>{" "}
                                                        Activate
                                                      </IconKeyTextCont>
                                                    </Menu.Item>
                                                  </SubMenu>
                                                </Menu>
                                              </MenuContainer>
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </tbody>
                                )}
                              </Droppable>
                            </table>
                            {data.length === 0 && loading === false ? (
                              <NoResultFoundWrap className="no-results">
                                <img src={NoResultFound} alt="No Results Found" />
                                <span>No results Found</span>
                              </NoResultFoundWrap>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DragDropContext>
            </Col>
          </Row>
        </Spin>
      </ContentContainer>
      <Row justify="space-between">
        <Col> </Col>
        <Col>
          {" "}
          <Pagination total={100} defaultPageSize={10} defaultCurrent={1} />
        </Col>
      </Row>
    </div>
  );
};

export default CustomDashboard1;
