import { Col, Radio, Row } from "antd";
import { DatePicker, FormItem, Input, Select, TimePicker, Checkbox } from "formik-antd";
import moment from "moment";
import { ErrorMessage } from "formik";

import { useEffect, useState } from "react";
import { days } from "./constant";
import {
  Container,
  BrokenLine,
  InputStyle,
  DatePickerStyle,
  ErrorMessageContainer,
} from "./styled";

const { Option } = Select;

function RecurrenceContainer({ formikBag }) {
  const { setFieldValue, values } = formikBag;
  const { notification_frequency, frequency_details } = values;
  const [radio, setRadio] = useState(frequency_details?.ends);
  const { start_date, start_time, end_date, end_time } =
    frequency_details !== null && frequency_details;
  const timeFormat = "h:mm a";

  const startDateValue = start_date ? moment(start_date) : undefined;
  const startTimeValue = start_time ? moment(start_time, "LT") : undefined;
  const endDateValue = end_date ? moment(end_date) : undefined;
  const endTimeValue = end_time ? moment(end_time, "LT") : undefined;

  const disabledDate = (current) => {
    return current && current < moment().endOf("day");
  };

  useEffect(() => {
    setRadio(frequency_details?.ends);
  }, [frequency_details?.ends]);

  useEffect(() => {
    function handleClick() {
      if (isNaN(frequency_details?.recur_every)) {
        setFieldValue("frequency_details.recur_every", 1);
      }
    }

    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [values]);

  const capitalizedWord = (word: string) => {
    const firstChar = word.charAt(0);
    return `${firstChar.toUpperCase()}${word.slice(1)}`;
  };

  const DailyRecurrence =
    notification_frequency === "DAILY" ? (
      <Col span={24}>
        <Row gutter={24}>
          <Col span={14}>
            <FormItem
              name="frequency_details.start_date"
              label="Date"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <DatePicker
                name="frequency_details.start_date"
                placeholder="Start Date"
                style={{ width: "100%" }}
                value={startDateValue}
                disabledDate={disabledDate}
                allowClear={false}
                onChange={(e) => {
                  setFieldValue("frequency_details.start_date", moment(e).format("L"));
                }}
              />
            </FormItem>
          </Col>
          <Col span={10}>
            <FormItem
              name="frequency_details.start_time"
              label="Time"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TimePicker
                use12Hours
                name="frequency_details.start_time"
                placeholder="Start Time"
                value={startTimeValue}
                style={{ width: "100%" }}
                format={timeFormat}
                allowClear={false}
                onChange={(e) => {
                  setFieldValue("frequency_details.start_time", moment(e).format("LT"));
                }}
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={14}>
            <FormItem
              name="frequency_details.end_date"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <DatePicker
                name="frequency_details.end_date"
                placeholder="End Date"
                style={{ width: "100%" }}
                value={endDateValue}
                disabledDate={disabledDate}
                allowClear={false}
                onChange={(e) => {
                  setFieldValue("frequency_details.end_date", moment(e).format("L"));
                }}
              />
            </FormItem>
          </Col>
          <Col span={10}>
            <FormItem
              name="frequency_details.end_time"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TimePicker
                use12Hours
                placeholder="End Time"
                name="frequency_details.end_time"
                value={endTimeValue}
                style={{ width: "100%" }}
                format={timeFormat}
                allowClear={false}
                onChange={(e) => {
                  setFieldValue("frequency_details.end_time", moment(e).format("LT"));
                }}
              />
            </FormItem>
          </Col>
        </Row>
      </Col>
    ) : null;

  const weeklyRecurrence =
    notification_frequency === "WEEKLY" ? (
      <Col span={24}>
        <FormItem
          label="Time"
          name="notification_period"
          style={{ margin: 0 }}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                name="frequency_details.start_time"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <TimePicker
                  use12Hours
                  name="frequency_details.start_time"
                  placeholder="Start Time"
                  value={startTimeValue}
                  style={{ width: "100%" }}
                  format={timeFormat}
                  allowClear={false}
                  onChange={(e) => {
                    setFieldValue("frequency_details.start_time", moment(e).format("LT"));
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="frequency_details.end_time"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <TimePicker
                  use12Hours
                  placeholder="End Time"
                  name="frequency_details.end_time"
                  value={endTimeValue}
                  style={{ width: "100%" }}
                  format={timeFormat}
                  allowClear={false}
                  onChange={(e) => {
                    setFieldValue("frequency_details.end_time", moment(e).format("LT"));
                  }}
                />
              </FormItem>
            </Col>
          </Row>
        </FormItem>
        <BrokenLine></BrokenLine>
        <Row gutter={24}>
          <Col span={4} style={{ display: "flex", gap: "35px", marginTop: "25px" }}>
            <p>
              Recur <br /> every:
            </p>
            <FormItem
              name="frequency_details.recur_every"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ display: "flex" }}
            >
              <Input
                name="frequency_details.recur_every"
                value={frequency_details?.recur_every}
                style={{ ...InputStyle, textAlign: "center" }}
              ></Input>
            </FormItem>
          </Col>
          <Col span={20} style={{ display: "flex", gap: "20px", marginTop: "25px" }}>
            <p>
              week(s) <br /> on:
            </p>
            <span style={{ borderLeft: "1px solid #bebebe", height: "53px" }}></span>
            <Checkbox.Group
              name="frequency_details.weeks_on"
              style={{ width: "100%", fontSize: "20px", marginBottom: "25px" }}
              value={frequency_details?.weeks_on}
              onChange={(val) => setFieldValue("frequency_details.weeks_on", val)}
            >
              <Row>
                {days.map((val) => {
                  return (
                    <Col span={6}>
                      <Checkbox name="frequency_details.weeks_on" value={val}>
                        {capitalizedWord(val)}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
              <ErrorMessageContainer>
                <ErrorMessage name="frequency_details.weeks_on" />
              </ErrorMessageContainer>
            </Checkbox.Group>
          </Col>
        </Row>
        <BrokenLine></BrokenLine>
        <Col span={10}>
          <div
            style={{
              position: "relative",
              background: "#fff",
            }}
          >
            <FormItem
              name="frequency_details.start_date"
              label="Start date of recurrence"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <span style={{ position: "absolute", top: "35%", left: "10px" }}>Start of date:</span>
              <DatePicker
                name="frequency_details.start_date"
                placeholder="MM/DD/YYYY"
                style={DatePickerStyle}
                value={startDateValue}
                disabledDate={disabledDate}
                allowClear={false}
                onChange={(e) => {
                  setFieldValue("frequency_details.start_date", moment(e).format("L"));
                }}
              />
            </FormItem>
          </div>
          <p>Ends after</p>
          <Row gutter={24}>
            <Col span={4}>
              <Radio.Group
                name="frequency_details.end_date"
                onChange={(e) => {
                  if (e.target.value !== "never") {
                    setFieldValue("frequency_details.ends", e.target.value);
                    setFieldValue("frequency_details.end_date", undefined);
                  } else {
                    setFieldValue("frequency_details.ends", e.target.value);
                  }
                }}
                value={radio}
                style={{ display: "grid", gap: "37px" }}
              >
                <Radio value="never">Never</Radio>
                <Radio value="on">On</Radio>
                <Radio value="after">After</Radio>
              </Radio.Group>
            </Col>
            <Col
              span={20}
              style={{
                display: "flex",
                flexDirection: "column-reverse",
                alignItems: "baseline",
                gap: "30px",
                paddingLeft: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #7791b3",
                  paddingRight: "10px",
                  position: "relative",
                }}
              >
                <Input
                  style={{ width: "50px", border: "none", color: "#7791b3" }}
                  name="frequency_details.end_date"
                  value={radio === "after" ? frequency_details?.end_date : ""}
                  disabled={radio !== "after"}
                  autoComplete="off"
                />
                <span>occurences</span>
                {radio === "after" && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-23px",
                      left: "0",
                      width: "200px",
                      fontSize: "14px",
                      color: "#ff4d4f",
                    }}
                  >
                    <ErrorMessage name="frequency_details.end_date" />
                  </div>
                )}
              </div>
              <div style={{ width: "250px", height: "35px" }}>
                <DatePicker
                  name="frequency_details.end_date"
                  placeholder="MM/DD/YYYY"
                  style={{ width: "100%" }}
                  value={radio === "on" ? endDateValue : undefined}
                  disabled={radio !== "on"}
                  allowClear={false}
                  onChange={(e) => {
                    setFieldValue("frequency_details.end_date", moment(e).format("L"));
                  }}
                />
                {radio === "on" && (
                  <div style={{ color: "#ff4d4f", fontSize: "14px" }}>
                    <ErrorMessage name="frequency_details.end_date" />
                  </div>
                )}
              </div>
            </Col>
            <div style={{ color: "#ff4d4f", padding: "10px", fontSize: "14px" }}>
              <ErrorMessage name="frequency_details.ends" />
            </div>
          </Row>
        </Col>
      </Col>
    ) : null;

  const monthlyRecurrence =
    notification_frequency === "MONTHLY" ? (
      <Col span={24}>
        <FormItem
          label="Time"
          name="notification_period"
          style={{ margin: 0 }}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                name="frequency_details.start_time"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <TimePicker
                  use12Hours
                  name="frequency_details.start_time"
                  placeholder="Start Time"
                  value={startTimeValue}
                  style={{ width: "100%" }}
                  format={timeFormat}
                  allowClear={false}
                  onChange={(e) => {
                    setFieldValue("frequency_details.start_time", moment(e).format("LT"));
                  }}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="frequency_details.end_time"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <TimePicker
                  use12Hours
                  placeholder="End Time"
                  name="frequency_details.end_time"
                  value={endTimeValue}
                  style={{ width: "100%" }}
                  format={timeFormat}
                  allowClear={false}
                  onChange={(e) => {
                    setFieldValue("frequency_details.end_time", moment(e).format("LT"));
                  }}
                />
              </FormItem>
            </Col>
          </Row>
        </FormItem>
        <BrokenLine></BrokenLine>
        <Row gutter={24}>
          <Col span={4} style={{ display: "flex", gap: "35px", marginTop: "25px" }}>
            <p>
              Recur <br /> every:
            </p>
            <FormItem
              name="frequency_details.recur_every"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ display: "flex" }}
            >
              <Input
                name="frequency_details.recur_every"
                value={frequency_details?.recur_every}
                style={{ ...InputStyle, textAlign: "center" }}
              ></Input>
            </FormItem>
          </Col>
          <Col span={20} style={{ display: "flex", gap: "20px", marginTop: "25px" }}>
            <p>
              month(s) <br /> on:
            </p>
            <span style={{ borderLeft: "1px solid #bebebe", height: "53px" }}></span>
            <FormItem
              name="frequency_details.months_on"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ width: "100%" }}
            >
              <Select
                name="frequency_details.months_on"
                style={{ width: "100%" }}
                placeholder="select day of the Month"
              >
                <Option value="first day of the month">Monthly on day 1</Option>
                <Option value="first Sunday of the month">Monthly on first Sunday</Option>
                <Option value="first Monday of the month">Monthly on first Monday</Option>
                <Option value="first Tuesday of the month">Monthly on first Tuesday</Option>
                <Option value="first Wednesday of the month">Monthly on first Wednesday</Option>
                <Option value="first Thursday of the month">Monthly on first Thursday</Option>
                <Option value="first Friday of the month">Monthly on first Friday</Option>
                <Option value="first Saturday of the month">Monthly on first Saturday</Option>
                <Option value="Last day of the month">Monthly on last day</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <BrokenLine></BrokenLine>
        <Col span={10}>
          <div
            style={{
              position: "relative",
              background: "#fff",
            }}
          >
            <FormItem
              name="frequency_details.start_date"
              label="Start date of recurrence"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <span style={{ position: "absolute", top: "35%", left: "10px" }}>Start of date:</span>
              <DatePicker
                name="frequency_details.start_date"
                placeholder="MM/DD/YYYY"
                style={DatePickerStyle}
                value={startDateValue}
                disabledDate={disabledDate}
                allowClear={false}
                onChange={(e) => {
                  setFieldValue("frequency_details.start_date", moment(e).format("L"));
                }}
              />
            </FormItem>
          </div>
          <p>Ends after</p>
          <Row gutter={24}>
            <Col span={4}>
              <Radio.Group
                onChange={(e) => {
                  if (e.target.value !== "never") {
                    setFieldValue("frequency_details.ends", e.target.value);
                    setFieldValue("frequency_details.end_date", undefined);
                  } else {
                    setFieldValue("frequency_details.ends", e.target.value);
                  }
                }}
                value={radio}
                style={{ display: "grid", gap: "35px" }}
              >
                <Radio value="never">Never</Radio>
                <Radio value="on">On</Radio>
                <Radio value="after">After</Radio>
              </Radio.Group>
            </Col>
            <Col
              span={20}
              style={{
                display: "flex",
                flexDirection: "column-reverse",
                alignItems: "baseline",
                gap: "30px",
                paddingLeft: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #7791b3",
                  paddingRight: "10px",
                }}
              >
                <Input
                  style={{ width: "50px", border: "none", color: "#7791b3" }}
                  name="frequency_details.end_date"
                  value={radio === "after" ? frequency_details?.end_date : ""}
                  disabled={radio !== "after"}
                  autoComplete="off"
                />
                <span>occurences</span>
                {radio === "after" && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-23px",
                      left: "0",
                      width: "200px",
                      fontSize: "14px",
                      color: "#ff4d4f",
                      marginLeft: "20px",
                    }}
                  >
                    <ErrorMessage name="frequency_details.end_date" />
                  </div>
                )}
              </div>
              <div style={{ width: "250px", height: "35px" }}>
                <DatePicker
                  name="frequency_details.end_date"
                  placeholder="MM/DD/YYYY"
                  style={{ width: "100%" }}
                  value={radio === "on" ? endDateValue : undefined}
                  disabled={radio !== "on"}
                  allowClear={false}
                  onChange={(e) => {
                    setFieldValue("frequency_details.end_date", moment(e).format("L"));
                  }}
                />
                {radio === "on" && (
                  <div style={{ color: "#ff4d4f", fontSize: "14px" }}>
                    <ErrorMessage name="frequency_details.end_date" />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <div style={{ color: "#ff4d4f", padding: "5px", fontSize: "14px" }}>
            <ErrorMessage name="frequency_details.ends" />
          </div>
        </Col>
      </Col>
    ) : null;

  return (
    <Container>
      {DailyRecurrence}
      {weeklyRecurrence}
      {monthlyRecurrence}
    </Container>
  );
}

export default RecurrenceContainer;
