import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { Breadcrumb } from "antd";

import BrandManagementForm from "./components/brand-management-forms";
import BrandManagementList from "./components/brand-management-lists";
import BrandManagementView from "./components/brand-management-view";

export const StyledBreadcrumb = styled(Breadcrumb)`
  background: #fff;
  display: flex;
  padding: 16px 25px;
`;

const BrandManagement: React.FC = () => {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.path}`}
          render={(props: any) => <BrandManagementList {...props} />}
        />
        <Route
          exact
          path={`${match.path}/add`}
          render={(props: any) => <BrandManagementForm {...props} />}
        />
        <Route
          exact
          path={`${match.path}/edit/:pageId`}
          render={(props: any) => <BrandManagementForm {...props} />}
        />
        <Route
          exact
          path={`${match.path}/:slug/:id`}
          render={(props: any) => <BrandManagementView {...props} />}
        />
      </Switch>
    </>
  );
};

export default BrandManagement;
