import React from "react";
import { Upload, Spin } from "antd";
import findIndex from "lodash/findIndex";
import has from "lodash/has";
import axios from "axios";
import { message } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import { convertFileToBase64 } from "../../utilities/convert-to-base64";
import { ButtonStyle, RemoveBtn, Container, ImageWrapper } from "./styled";
import XIcon from "../../assets/images/icons/ic_x_circle.svg";

const BAD_REQUEST = 400;
const BASE_URL = `${process.env.REACT_APP_CONTENT_SERVICE}/upload`;

interface Props {
  fileList: any;
  onChange: any;
  path?: string;
  className?: string;
  renameAddStr?: string;
  accept?: string;
  validate?: boolean;
  sizeLimit?: number;
  preview?: (value: string | undefined) => void;
  errors?: any;
}

const UploadMultipleImages: React.FC<Props> = ({
  fileList,
  onChange,
  path,
  className,
  renameAddStr,
  accept,
  validate,
  sizeLimit,
  preview,
  errors,
}) => {
  const deleteItem = async (key: number, list: any) => {
    let newList = list.filter((value, index) => index !== key);
    onChange(newList);
  };

  const handleOnChange = async ({ file, fileList: list }) => {
    const limit = !!sizeLimit ? sizeLimit : 49;
    const isLt2M = file.size / 1024 / 1024 < limit;

    if (file.status !== "uploading" && has(file, "response")) {
      const base64 = await convertFileToBase64(file.originFileObj);
      const index = await findIndex(list, (e: any) => e.uid === file.uid);
      const urlLink = file.response.url;
      list[index]["url"] = file.response.url;
      list[index]["keyObj"] = urlLink.substring(urlLink.substring(urlLink.lastIndexOf("/") + 1));
      list[index]["thumbUrl"] = base64;
      onChange(list);
    }

    if (file.status === "uploading" && isLt2M) {
      const index = await findIndex(list, (e: any) => e.uid === file.uid);
      list[index]["thumbUrl"] = "";
      list[index]["url"] = "";
      list[index]["keyObj"] = "";
      onChange(list);
    }
  };

  const UploadImage = () => {
    return (
      <ButtonStyle type="dashed">
        <div>
          <PlusCircleFilled
            style={{ fontSize: "1.75rem", color: "var(--color-general-blue-gray)" }}
          />
        </div>
      </ButtonStyle>
    );
  };

  const renderMediaType = (file, data, index) => {
    if (file.url || file.thumbUrl) {
      return (
        <ImageWrapper>
          <img src={file.url || file.thumbUrl} alt="" />
          <RemoveBtn onClick={() => deleteItem(index, data)}>
            <img src={XIcon} alt="X" style={{ width: 24, height: 24 }} />
          </RemoveBtn>
        </ImageWrapper>
      );
    }

    return (
      <ImageWrapper>
        <RemoveBtn onClick={() => deleteItem(index, data)}>
          <img src={XIcon} alt="X" style={{ width: 24, height: 24 }} />
        </RemoveBtn>
      </ImageWrapper>
    );
  };

  const renderItems = (data) => {
    return data.map((file, index) => (
      <Spin spinning={file.status === "uploading"}>{renderMediaType(file, data, index)}</Spin>
    ));
  };

  const handleBeforeUpload = (file) => {
    // Check file type
    const fileTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (!fileTypes.includes(file.type)) {
      message.error("You can only upload JPG/PNG file type");
      return false;
    }

    const limit = !!sizeLimit ? sizeLimit : 49;
    const isLt2M = file.size / 1024 / 1024 < limit;

    if (!isLt2M) {
      message.error("File is too big");
      return false;
    }
    return file;
  };

  const handleRequest = (option) => {
    let formData = new FormData();
    formData.append("file", option.file);

    axios({
      method: "POST",
      headers: { authorization: `Bearer ${sessionStorage.getItem("act")}` || null },
      url: BASE_URL,
      data: formData,
    })
      .then((res) => {
        option.onSuccess(res.data, option.file);
      })
      .catch((err) => {
        const { status } = err.response;

        if (status === BAD_REQUEST) {
          const { data } = err.response;
          message.error(data.message);
        }
      });
  };

  return (
    <Container>
      {renderItems(fileList)}
      <div className={`${errors && errors ? "hasError" : ""}`}>
        <Upload
          action={BASE_URL}
          showUploadList={false}
          customRequest={handleRequest}
          onChange={(event) => handleOnChange(event)}
          accept={accept}
          fileList={fileList}
          beforeUpload={handleBeforeUpload}
        >
          {validate && UploadImage()}
        </Upload>
        {errors && errors ? (
          <>
            {typeof errors === "string" ? (
              <div style={{ color: "#ff4d4f", fontSize: 14 }}>{errors}</div>
            ) : null}
          </>
        ) : null}
      </div>
    </Container>
  );
};

export default UploadMultipleImages;
