import styled from "styled-components";

export const Container = styled.div`
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 3px;
`;

export const ErrorMessageContainer = styled("div")({
  color: "#ff4d4f",
  fontSize: "14px",
  position: "absolute",
});

export const BrokenLine = styled.hr`
  border: none;
  border-top: 1px dashed #7793b4;
  color: #fffff;
  background-color: #fffff;
  height: 1px;
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const InputStyle = {
  height: "55px",
  maxWidth: "45px",
  fontSize: "19px",
  color: "#376bf4",
  borderRadius: "5px",
};

export const DatePickerStyle = {
  width: "100%",
  padding: "10px",
  borderRadius: "5px",
  paddingLeft: "100px",
  marginTop: "10px",
  background: "none",
};
