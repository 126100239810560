import {
  ApolloClient,
  HttpLink,
  NormalizedCacheObject,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";

const { REACT_APP_CONTENT_SERVICE } = process.env;

const cache = new InMemoryCache();

const httpLink = new HttpLink({ uri: `${REACT_APP_CONTENT_SERVICE}/graphql` });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      authorization: `Bearer ${sessionStorage.getItem("act")}` || null,
      ...headers,
    },
  }));
  return forward(operation);
});

const link = ApolloLink.from([authMiddleware, httpLink]);

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: link,
});

export default client;
