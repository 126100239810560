const useAuth = () => {
  return {
    setAccessToken: (newAccessToken: string) => {
      window.sessionStorage.setItem("act", newAccessToken);
    },
    isLoggedIn: () => !!sessionStorage.getItem("act") || "",
  };
};

export default useAuth;
