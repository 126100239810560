import React from "react";

import { FormOutlined } from "@ant-design/icons";
import { Route, Redirect } from "react-router-dom";
import styled from "styled-components";

import BrandManagement from "../../features/BrandManagement";
import HomePageManagement from "../../features/HomePageManagement";
import useAuth from "../../hooks/useAuth";
import UserRoleManagement from "../../features/UserRoleManagement";
import FooterManagement from "../../features/FooterManagement";
import NotificationSettings from "../../features/NotificationSettings";

export const PageNotFound = styled.div`
  height: 100%;
  font-size: 5vh;
  text-align: center;
  padding-top: 10%;
`;

const BASE_URL = "/";

export const routes = [
  {
    name: "BRAND_MANAGEMENT",
    path: `${BASE_URL}brand-management`,
    exact: false,
    icon: <FormOutlined />,
    component: <BrandManagement />,
    access: "*",
  },
  {
    name: "HOME_PAGE_MANAGEMENT",
    path: `${BASE_URL}home-page-management`,
    exact: false,
    icon: <FormOutlined />,
    component: <HomePageManagement />,
    access: "*",
  },
  {
    name: "USER_ROLE_MANAGEMENT",
    path: `${BASE_URL}user-role-management`,
    exact: false,
    icon: <FormOutlined />,
    component: <UserRoleManagement />,
    access: "*",
  },
  {
    name: "FOOTER_MANAGEMENT",
    path: `${BASE_URL}footer-management`,
    exact: false,
    icon: <FormOutlined />,
    component: <FooterManagement />,
  },
  {
    name: "NOTIFICATION_SETTINGS",
    path: `${BASE_URL}notification-settings`,
    exact: false,
    icon: <FormOutlined />,
    component: <NotificationSettings />,
  },
  {
    name: "INVALID",
    path: "*",
    exact: false,
    icon: <FormOutlined />,
    component: <PageNotFound />,
    access: "invalid",
  },
];

export const getRoute = () => {
  return routes.map(({ name, path, exact, component }) => {
    return (
      <Route
        key={name}
        exact={exact}
        path={path}
        render={() => {
          const { isLoggedIn } = useAuth();
          if (!isLoggedIn()) {
            return <Redirect to="/" />;
          }

          return component;
        }}
      />
    );
  });
};
