import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Button, Modal } from "antd";
import { Field } from "formik";
import { Form, FormItem, Input } from "formik-antd";

import ContentContainer from "@/components/ContentContainer";
import CustomButton from "@/components/CustomButton";
import CustomSelect from "@/components/CustomSelect";
import CustomModal from "@/components/CustomModal";
import CustomSwitch from "@/components/CustomSwitch";
import Richtext2 from "@/components/Richtext2";

import BrandSearchEngineOptimization from "./field-array-sections/brand-seo";
import ContactInformationSection from "./field-array-sections/contact-information";
import ManageHotels from "./field-array-sections/manage-hotels";
import BannerFeatureImages from "./field-array-sections/banner-feature-images";
import Sections from "./field-array-sections/sections";

import WarningIcon from "../../../../assets/images/icons/ic_report.svg";

import {
  StyledUploadImage,
  StyledUploadImageRectangle,
  StyledDivider,
  HeaderSubtitle,
  InstructionLabel,
} from "./styled";

const DOMPurify = require('dompurify')(window);

interface Props {
  formikBag: any;
  brandCategoryList: any;
  loader?: boolean;
  brandName?: string;
}

const Forms: React.FC<Props> = ({ formikBag, brandCategoryList, loader, brandName }) => {
  const { values, submitForm, errors, setFieldValue } = formikBag;
  const { pageId } = useParams();

  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isModalVisibleLeaving, setIsModalVisibleLeaving] = useState<boolean>(false);
  const [isStatusEnable, setIsStatusEnable] = useState<boolean>(
    values.redirect_link_status && values.redirect_link_status
  );
  const enableText =
    values.redirect_link_status && values.redirect_link_status === true ? "Enabled" : "Disabled";

  const handleOk = () => {
    setIsStatusEnable(!isStatusEnable);
    setFieldValue("redirect_link_status", false);
    setIsModalVisible(false);
  };

  const handleOkLeaving = () => {
    setIsModalVisibleLeaving(false);
    history.push("/brand-management");
  };

  const handleCancelLeaving = () => {
    setIsModalVisibleLeaving(false);
  };

  const handleEditConfirm = (submitForm) => {
    const btnProps: any = {
      size: "large",
      style: {
        width: "6em",
        textTransform: "uppercase",
      },
    };
    const onClickHandle = submitForm;

    Modal.confirm({
      title: (
        <>
          <div style={{ textAlign: "center" }}>
            <img src={WarningIcon} alt="" />
            <br />
            <h4 style={{ color: "var(--color-primary)", fontSize: 20, margin: "1em 0" }}>
              Save Changes?
            </h4>
            <div style={{ color: "var(--color-general-black)", lineHeight: 1.5, fontSize: 16 }}>
              Done editing? Are you sure you want to save changes for "{brandName}"?
            </div>
          </div>
        </>
      ),
      icon: <></>,
      okText: "SAVE",
      cancelText: "CANCEL",
      cancelButtonProps: btnProps,
      okButtonProps: btnProps,
      onOk() {
        onClickHandle();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Form>
        <InstructionLabel>* Required fields, do not leave this blank.</InstructionLabel>
        <ContentContainer style={{ flexDirection: "column" }}>
          <HeaderSubtitle>Header Details</HeaderSubtitle>
          <Row gutter={40}>
            <Col md={7} span={24}>
              <StyledUploadImage
                label={"Brand Logo*"}
                accept={"image/png"}
                value={values.logo}
                hasNote
                ratio="1:1"
                sampleRatio="512 x 512"
                formatStr="PNG transparent background."
                maxFileSize={5}
                sizeLimit={5}
                extraNoteBg="White in color to standout."
                onChange={(e) => {
                  setFieldValue("logo", e);
                }}
                errors={errors.logo}
              />
            </Col>
            <Col md={17} span={24}>
              <Row>
                <Col span={24}>
                  <FormItem
                    label={"Brand Name*"}
                    name={"name"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      name="name"
                      placeholder="Enter brand name"
                      maxLength={40}
                      minLength={2}
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label={"Brand Category*"}
                    name={"category"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <CustomSelect
                      name="category"
                      placeholder="Select Brand Category"
                      options={brandCategoryList}
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label={
                      <>
                        Short Description ({`${values.short_description.length} / 150`})*
                        <small
                          style={{ marginLeft: "1rem", color: "var(--color-general-blue-gray)" }}
                        >
                          {" "}
                          Will be displayed in Our Brands section in Home Page
                        </small>
                      </>
                    }
                    name={"short_description"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      name="short_description"
                      placeholder="Enter Content"
                      maxLength={150}
                      minLength={25}
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <div style={{ marginBottom: 24 }}>
                    <Row className="ant-form-item">
                      <Col span={24} className="ant-form-item-label">
                        <label>
                          Long Description*
                          <small
                            style={{ marginLeft: "1rem", color: "var(--color-general-blue-gray)" }}
                          >
                            {" "}
                            Will be displayed in Brand's Page
                          </small>
                        </label>
                      </Col>
                      <Col span={24}>
                        <Field name="long_description">
                          {() => (
                            <Richtext2
                              value={DOMPurify.sanitize(values.long_description)}
                              placeholder="Enter Content"
                              onChange={(e) => {
                                if (e === "<p><br></p>") {
                                  setFieldValue("long_description", "");
                                } else setFieldValue("long_description", e);
                              }}
                              errors={errors.long_description}
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={24}>
                  <Row gutter={15}>
                    <Col span={15}>
                      <FormItem
                        label={"Redirect Link (Know More Button)"}
                        name={"redirect_link"}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input name="redirect_link" placeholder="e.g https://www.sample.com" />
                      </FormItem>
                    </Col>
                    <Col span={9}>
                      <div style={{ display: "flex", gap: "1em", paddingTop: "2.25rem" }}>
                        <label>Status</label>
                        <div>
                          <div style={{ display: "flex", gap: "1em" }}>
                            <Field name="redirect_link_status">
                              {() => (
                                <CustomSwitch
                                  name="redirect_link_status"
                                  isStatusEnable={
                                    values.redirect_link_status && values.redirect_link_status
                                  }
                                  onClick={
                                    values.redirect_link_status &&
                                    values.redirect_link_status === true
                                      ? () => setIsModalVisible(true)
                                      : () => setFieldValue("redirect_link_status", true)
                                  }
                                />
                              )}
                            </Field>
                            <span
                              style={{
                                color:
                                  values.redirect_link_status && values.redirect_link_status
                                    ? "var(--color-green)"
                                    : "var(--color-primary)",
                              }}
                            >
                              {enableText}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <StyledDivider dashed />
          <HeaderSubtitle>Uploaded Media</HeaderSubtitle>

          <BannerFeatureImages formikBag={formikBag} />

          <Row gutter={20} style={{ marginTop: "1.5rem" }}>
            <Col style={{ width: "20%" }}>
              <StyledUploadImageRectangle
                label="Brand Category Logo*"
                accept={"image/png"}
                value={values.category_logo}
                hasNote
                ratio="16:9"
                sampleRatio="150 x 85"
                formatStr="PNG transparent BG"
                maxFileSize={5}
                sizeLimit={5}
                onChange={(e) => {
                  setFieldValue("category_logo", e);
                }}
                errors={errors.category_logo}
              />
            </Col>
            <Col style={{ width: "20%" }}>
              <StyledUploadImageRectangle
                label="Brand Carousel Image*"
                accept={"image/jpeg,image/jpg,image/png"}
                value={values.carousel_image}
                noteMessage={".jpeg, .jpg, .png (max 50 MB)"}
                hasNote
                ratio="16:9"
                sampleRatio="150 x 85"
                formatStr="JPG or PNG"
                maxFileSize={5}
                sizeLimit={5}
                onChange={(e) => {
                  setFieldValue("carousel_image", e);
                }}
                errors={errors.carousel_image}
              />
            </Col>
          </Row>
        </ContentContainer>

        <ContentContainer>
          <HeaderSubtitle>Contact Information</HeaderSubtitle>
          <ContactInformationSection formikBag={formikBag} />
        </ContentContainer>

        <ContentContainer>
          <HeaderSubtitle>Brand Search Engine Optimization (SEO)</HeaderSubtitle>
          <BrandSearchEngineOptimization formikBag={formikBag} />
        </ContentContainer>

        <ContentContainer>
          <HeaderSubtitle>Manage Hotel</HeaderSubtitle>
          <ManageHotels formikBag={formikBag} />
        </ContentContainer>

        <ContentContainer>
          <div>
            <Sections formikBag={formikBag} />
          </div>
        </ContentContainer>

        <div style={{ display: "flex", justifyContent: "end", gap: "1rem" }}>
          <Col span={3}>
            <Button style={{ width: "100%" }} onClick={() => setIsModalVisibleLeaving(true)}>
              CANCEL
            </Button>
          </Col>
          <Col span={3}>
            <CustomButton
              style={{ width: "100%" }}
              type="primary"
              onClick={pageId ? () => handleEditConfirm(submitForm) : () => submitForm()}
            >
              SAVE
            </CustomButton>
          </Col>
        </div>
      </Form>

      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage="Heads up!"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        message="By Disabling, this section will not be visible once published."
        okText="Yes, Disable"
        okButtonWidth="8em"
        cancelButtonWidth="8em"
      />

      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={pageId ? "Cancel Editing?" : "Leave this page?"}
        visible={isModalVisibleLeaving}
        onOk={handleOkLeaving}
        onCancel={handleCancelLeaving}
        message={
          pageId
            ? "Are you sure you want to cancel editing?"
            : "All unsaved data will be lost. Are you sure you want to leave this page?"
        }
        cancelText={pageId ? "No" : "Stay here"}
        okText="Yes"
        okButtonWidth="7.5em"
        cancelButtonWidth="7.5em"
      />
    </>
  );
};

export default Forms;
