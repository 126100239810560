import * as yup from "yup";
import { ALPHANUM_PATTERN, URL_PATTERN } from "../../../../../utilities/regex";

const imageSchema = yup
  .object()
  .shape({
    status: yup.string().matches(/done/, { message: "File is still uploading" }),
    url: yup.string(),
  })
  .nullable();

export const validationSchema = (existingTitles) => {
  return yup.object().shape({
    photo: imageSchema.required("This is a required field."),
    title: yup
      .string()
      .matches(ALPHANUM_PATTERN, "Invalid format.")
      .trim()
      .required("This is a required field.")
      .min(2, "Oops! enter at least 2 characters.")
      .notOneOf(existingTitles, "Promotion already exists"),
    participating_hotels: yup.array().required("This is a required field.").min(1, "This is a required field."),
    description: yup
      .string()
      .required("This is a required field.")
      .min(9, "Oops! enter at least 2 characters."),
    cta_button_name: yup
      .string()
      .matches(ALPHANUM_PATTERN, "Invalid format.")
      .trim()
      .required("This is a required field.")
      .min(1, "Oops! enter at least 1 characters."),
    cta_button_link: yup
      .string()
      .matches(URL_PATTERN, "Invalid format.")
      .when("cta_button_name", {
        is: (value) => value !== "Book Now",
        then: yup
          .string()
          .matches(URL_PATTERN, "Invalid format.")
          .required("This is a required field."),
      }),
    is_featured: yup.boolean(),
    start_date: yup
      .string()
      .nullable()
      .required("This is a required field."),
    start_time: yup
      .string()
      .nullable()
      .required("This is a required field."),
    end_date: yup
      .string()
      .nullable()
      .required("This is a required field."),
    end_time: yup
      .string()
      .nullable()
      .required("This is a required field.")
  });
} 
