import React, { useState, useEffect, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import moment from "moment";
import { useReactToPrint } from "react-to-print";
import ReactExport from "react-data-export";

import { activeColumns, dataTypes, actions } from "./constants";
import CustomDashboardList from "@/components/CustomDashboard";

import { DownloadOutlined } from "@ant-design/icons";

import { Row, Col, Modal, Radio, Space, Avatar } from "antd";
import { DownloadButton } from "./style";
import { useQuery } from "@apollo/client";

import { GET_CUSTOMER_USERS } from "../../../graphql";

import { CustomerListI } from "../../../../../models/RHRInterface";

import {
  CustomerViewPageWrap,
  ContentContainer,
  SectionWrap,
  Sectiontitle,
  Status,
  Graytitle,
  BlueTitle,
  BlackTitle,
  LightBlueTitle,
} from "./style";

const CustomerUserList: React.FC = () => {
  const componentRef = useRef<HTMLDivElement>(null);

  let match = useRouteMatch();
  const history = useHistory();

  const [customerList, setCustomerList] = useState<CustomerListI[]>([]);
  const [selectedData, setSelectedData] = useState<any>([]);

  const [docFileType, SetdocFileType] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const onChangeFileType = (e) => {
    SetdocFileType(e.target.value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (docFileType === "PDF") {
      handlePrintPDF();
      handleCancel();
    } else {
      let element: HTMLElement = document.getElementsByClassName("dloadExcel")[0] as HTMLElement;
      element.click();
      handleCancel();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handlePrintPDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Customer_Users.pdf",
  });

  const {
    loading: customerListLoading,
    refetch,
    data: customerData,
  } = useQuery(GET_CUSTOMER_USERS, {
    variables: {
      search: "",
    },
  });

  useEffect(() => {
    if (customerData) {
      refetch();
      const list = [...customerData.getCustomerUsers, ...customerData.getAnonymousGuests];
      setSelectedData(list);
      setCustomerList(list);
    }
  }, [customerData, refetch]);

  const fetchData = (callback) => {
    if (customerData) {
      callback(customerData.getCustomerUsers.slice(customerList.length).slice(0, 10));
    }
  };

  const handleInfiniteOnLoad = () => {
    let dataList = customerData?.getCustomerUsers;

    fetchData((res) => {
      dataList = customerList.concat(res);
      setTimeout(() => {
        setCustomerList(dataList);
      }, 600);
    });
  };

  const handleSelectedRow = (id): void => {
    if (!id.includes("auth")) {
      return;
    }
    history.push(`${match.path}/view-user/${encodeURI(id)}`, { pageId: id });
  };

  const handleKebabMenu = (menuItem: string, id: string): void => {
    if (menuItem === "View") {
      history.push(`${match.path}/view-user/${id}`, { pageId: id });
    }
  };

  return (
    <>
      <Modal
        title="Please choose the file type to download"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Radio.Group onChange={onChangeFileType}>
          <Space direction="vertical">
            <Radio
              value="PDF"
              name="pdf"
              onClick={() => {
                SetdocFileType("PDF");
              }}
            >
              As PDF
            </Radio>

            <Radio
              value="EXCEL"
              name="excel"
              onClick={() => {
                SetdocFileType("EXCEL");
              }}
            >
              As Excel
            </Radio>
          </Space>
        </Radio.Group>
        <Space direction="vertical">
          <DownloadOutlined />
          <DownloadOutlined />
        </Space>
      </Modal>

      <CustomDashboardList
        loading={customerListLoading}
        headerColumns={activeColumns}
        data={customerList}
        selectedData={selectedData}
        handleData={setSelectedData}
        handleInfiniteOnLoad={handleInfiniteOnLoad}
        handleSelectedRow={handleSelectedRow}
        handleMenu={handleKebabMenu}
        searchPlaceholder="Search Customer"
        dataTypes={dataTypes}
        actions={actions}
      />
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <CustomerViewPageWrap>
            <Row style={{ marginBottom: "2rem" }}></Row>
            {selectedData.map((cus, index) => {
              return (
                <SectionWrap>
                  <div style={{ display: "none" }}>
                    <ExcelFile
                      element={<button className="dloadExcel">Download Data</button>}
                      filename={"Customer Users"}
                    >
                      <ExcelSheet data={selectedData} name="Customer Users">
                        <ExcelColumn
                          label="Guest ID"
                          bold={true}
                          value={(col) => (col.guest_id ? col.guest_id : col.id)}
                        />
                        <ExcelColumn
                          label="First Name"
                          value={(col) => (col.first_name ? col.first_name : col.name)}
                        />
                        <ExcelColumn label="Last Name" value="last_name" />
                        <ExcelColumn label="Suffix" value="suffix" />
                        <ExcelColumn label="Gender" value="gender" />
                        <ExcelColumn label="Mobile Number" value="mobile_number" />
                        <ExcelColumn label="Email" value="email" />
                        <ExcelColumn
                          label="Date of Birth"
                          value={(col) => moment(parseInt(col.birth_date)).format("YYYY-MM-DD")}
                        />
                        <ExcelColumn
                          label="Nationality"
                          value={(col) =>
                            col.nationality ? JSON.parse(col.nationality).name : "N/A"
                          }
                        />
                        <ExcelColumn
                          label="Country"
                          value={(col) => (col.country ? JSON.parse(col.country)?.name : "N/A")}
                        />
                        <ExcelColumn label="Address" value="address" />
                        <ExcelColumn
                          label="Go Rewards Card Number"
                          value="gorewards_loyalty_card_numberes"
                        />
                        <ExcelColumn label="ID Type" value="id_type" />
                        <ExcelColumn label="ID URL" value="id_url" />
                        <ExcelColumn label="Created at" value="created_at" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>

                  <ContentContainer style={{ margin: "0", padding: "0 45px" }}>
                    <Sectiontitle style={{ marginBottom: "0" }}>Personal Details</Sectiontitle>
                  </ContentContainer>

                  <ContentContainer>
                    <Col>
                      <Row>
                        {/*  */}
                        <Col>
                          <Avatar
                            size={100}
                            style={{
                              backgroundColor: "rgb(29,43,66)",
                              fontSize: "40px",
                              marginRight: "25px",
                            }}
                          >
                            {cus.first_name?.substring(0, 1) || "N/A"}
                          </Avatar>
                        </Col>
                        <Col style={{ marginTop: "1.2rem", marginRight: "auto" }}>
                          <Sectiontitle style={{ marginBottom: "0" }}>
                            {cus.first_name ? cus.first_name : cus.name} {cus.last_name}
                          </Sectiontitle>
                          <Graytitle>Guest ID: {cus.guest_id ? cus.guest_id : cus.id}</Graytitle>
                        </Col>
                        <Col style={{ paddingTop: "1rem", fontSize: "14px" }}>
                          <Status>ACTIVE </Status>
                        </Col>
                      </Row>
                    </Col>

                    <Row style={{ paddingTop: "2.5rem" }}>
                      <Col span={5}>
                        <Graytitle>Mobile Number</Graytitle>
                      </Col>
                      <Col>
                        <BlueTitle>
                          {cus.country_code}
                          {cus.birth_date}
                        </BlueTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={5}>
                        <Graytitle>Email Address</Graytitle>
                      </Col>
                      <Col>
                        <BlueTitle>{cus.email || "N/A"}</BlueTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={5}>
                        <Graytitle>Date of Birth</Graytitle>
                      </Col>
                      <Col>
                        <BlackTitle>
                          {moment(parseInt(cus.birth_date)).format("YYYY-MM-DD") || "N/A"}
                        </BlackTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={5}>
                        <Graytitle>Nationality</Graytitle>
                      </Col>
                      <Col>
                        <BlackTitle>
                          {cus.nationality ? JSON.parse(cus.nationality).name : "N/A"}
                        </BlackTitle>
                      </Col>
                    </Row>
                    <Row className="row-content">
                      <Col span={5}>
                        <Graytitle>Gender</Graytitle>
                      </Col>
                      <Col>
                        <BlackTitle>{cus.gender || "N/A"}</BlackTitle>
                      </Col>
                    </Row>
                  </ContentContainer>
                  <ContentContainer style={{ paddingBottom: "10px" }}>
                    <Sectiontitle style={{ marginBottom: "1.5rem" }}>Other Details</Sectiontitle>
                    <Row>
                      <Col span={9}>
                        <Row>
                          <Col span={8}>
                            <Graytitle>Country</Graytitle>
                          </Col>
                          <Col span={8}>
                            <BlackTitle>
                              {cus.country ? JSON.parse(cus.country)?.name : "N/A"}
                            </BlackTitle>
                          </Col>
                        </Row>
                        <Row className="row-content">
                          <Col span={8}>
                            <Graytitle>Address</Graytitle>
                          </Col>
                          <Col span={10}>
                            <BlackTitle>{cus.address || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={9}>
                        <Row>
                          <Col span={12}>
                            <Graytitle>Anniversary Date</Graytitle>
                          </Col>
                          <Col span={9}>
                            <BlackTitle>{cus.anniversary || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                        <Row className="row-content">
                          <Col span={9}>
                            <Graytitle>Preferences</Graytitle>
                          </Col>
                          <Col span={9}>
                            <BlackTitle>{cus.preferences || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                        <Row className="row-content">
                          <Col span={11}>
                            <Graytitle>Go Rewards No.</Graytitle>
                          </Col>
                          <Col span={9}>
                            <BlackTitle>{cus.gorewards_loyalty_card_number || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={6}>
                        <Row>
                          <Col span={11}>
                            <Graytitle>Type of ID</Graytitle>
                          </Col>
                          <Col span={9}>
                            <BlackTitle>{cus.id_type || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                        <Row className="row-content">
                          <Col span={11}>
                            <Graytitle>Uploaded ID</Graytitle>
                          </Col>
                          <Col span={7}>
                            <BlackTitle>{cus.id_url || "N/A"}</BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </ContentContainer>

                  <ContentContainer>
                    <Sectiontitle style={{ marginBottom: "1.5rem" }}>Logs</Sectiontitle>
                    <Row>
                      <Col span={7}>
                        <Row>
                          <Col>
                            <BlueTitle>Date/Time Created</BlueTitle>
                            <BlackTitle>
                              {moment(cus.created_at).format("YYYY-MM-DD") || "N/A"}
                            </BlackTitle>
                            <LightBlueTitle> </LightBlueTitle>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={7}>
                        <Row>
                          <Col>
                            <BlueTitle>Last Date/Time Edited</BlueTitle>
                            <BlackTitle>
                              {" "}
                              {moment(cus.created_at).format("YYYY-MM-DD") || "N/A"}{" "}
                            </BlackTitle>
                            <LightBlueTitle> by {cus.first_name} </LightBlueTitle>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={6}>
                        <Row>
                          <Col>
                            <BlueTitle>Last Logged in</BlueTitle>
                            <BlackTitle>N/A </BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={4}>
                        <Row>
                          <Col>
                            <BlueTitle>Last Page Visited</BlueTitle>
                            <BlackTitle> N/A</BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row style={{ marginBottom: "30px", marginTop: "50px" }}>
                      <Col span={7}>
                        <Row>
                          <Col>
                            <BlueTitle>Pages Frequently Visited</BlueTitle>
                            <BlackTitle style={{ maxWidth: "260px" }}> N/A</BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={7}>
                        <Row>
                          <Col>
                            <BlueTitle>Notification Subscription</BlueTitle>
                            <BlackTitle style={{ maxWidth: "260px" }}>
                              {" "}
                              {cus.promotions == "ENABLED" ? "News Letter" : "N/A"}{" "}
                            </BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={6}>
                        <Row>
                          <Col>
                            <BlueTitle>Platform</BlueTitle>
                            <BlackTitle> MOBILE </BlackTitle>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </ContentContainer>
                </SectionWrap>
              );
            })}
          </CustomerViewPageWrap>
        </div>
      </div>
      <Row justify="end">
        <Col>
          <DownloadButton onClick={showModal}>DOWNLOAD FILE</DownloadButton>
        </Col>
      </Row>
    </>
  );
};

export default CustomerUserList;
