import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import { Spin, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { has, snakeCase } from "lodash";

import CustomModal from "@/components/CustomModal";
import Form from "./form";

import {
  CREATE_PROMO_AND_PACKAGE,
  EDIT_PROMO_AND_PACKAGE,
  GET_PROMO_AND_PACKAGE,
} from "../../../graphql";

import { validationSchema } from "./validations";

const PromosAndPackagesForm = () => {
  const history = useHistory();
  const { pageId } = useParams();
  const [createPromoAndPackage] = useMutation(CREATE_PROMO_AND_PACKAGE);
  const [editPromoAndPackage] = useMutation(EDIT_PROMO_AND_PACKAGE);
  const { data: pageData, loading } = useQuery(GET_PROMO_AND_PACKAGE, {
    skip: !pageId,
    fetchPolicy: "no-cache",
    variables: {
      id: pageId || "",
    },
  });

  const [promoName, setPromoName] = useState("");
  const [titleMessage, setTitleMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [reinitialize, setReinitialize] = useState(false);
  const [initialValues, setInitialValues] = useState({
    title: "",
    short_description: "",
    redirect_link: "",
    photo: null,
    status: "DRAFT",
  });

  const handleOkSuccess = () => {
    history.push("/home-page-management/promos-and-packages/");
  };

  useEffect(() => {
    if (reinitialize) {
      setReinitialize(false);
    }
  }, [reinitialize]);

  useEffect(() => {
    if (pageId && has(pageData, "getPromoAndPackageById")) {
      const data = pageData.getPromoAndPackageById;
      const bName = data.title;
      const fields: any = initialValues;

      Object.keys(data).forEach((key, index) => {
        if (key === "photo") {
          fields["photo"] = data.photo
            ? {
                keyObj: data.photo,
                url: data.photo,
              }
            : "";
        } else {
          fields[snakeCase(key)] = data[key];
        }
      });

      setPromoName(bName);
      setInitialValues(fields);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      setReinitialize(true);
    }
  }, [pageData]);

  const dataMapper = (values) => {
    let data: any = {
      photo: values.photo.keyObj,
      title: values.title.trim(),
      short_description: values.short_description.trim(),
      redirect_link: values.redirect_link.trim(),
    };
    return data;
  };

  const handleSubmit = async (values) => {
    const data = dataMapper(values);
    const editedData = { ...data, id: pageId };

    setLoader(true);
    try {
      let update = false;
      if (pageData && has(pageData, "getPromoAndPackageById")) {
        update = true;
      }

      if (update) {
        await editPromoAndPackage({
          variables: {
            editPromoAndPackageInput: editedData,
          },
        });
      } else {
        await createPromoAndPackage({
          variables: {
            input: { promoAndPackage_details: data },
          },
        });
      }

      setTimeout(() => {
        setLoader(false);
      }, 1000);

      setIsSuccess(true);
      setShowModal(true);
      setTitleMessage("Success!");
      setBodyMessage(
        update
          ? "Changes for this content was successfully saved."
          : "You have successfully added a new content!"
      );
    } catch (error: any) {
      if (error) {
        if (error.message.startsWith("Brand with name")) {
          setTitleMessage("Data not saved!");
          setBodyMessage("Promo already exist.");
          setShowModal(true);
        } else if (error.graphQLErrors && error.graphQLErrors[0]) {
          const errGraphqlRes = error.graphQLErrors[0].extensions.response;
          let errStr = "";

          if (errGraphqlRes) {
            if (errGraphqlRes?.message.length > 0) {
              errGraphqlRes?.message.forEach((item) => (errStr += item));
            } else errStr = errGraphqlRes?.message;
          }
          message.error(errStr);
        } else {
          setTitleMessage("Data not saved!");
          setBodyMessage("Kindly check your internet connection.");
          setShowModal(true);
        }
      }

      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  };

  return (
    <>
      <Spin spinning={loading || loader}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={reinitialize}
          onSubmit={async (values: any) => {
            await handleSubmit(values);
          }}
          render={(formikBag) => (
            <Form
              {...{
                formikBag,
                loader,
                promoName,
              }}
            />
          )}
        />
      </Spin>

      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isErrorIcon={!isSuccess}
        isSuccessIcon={isSuccess}
        titleMessage={titleMessage}
        visible={showModal}
        onOk={isSuccess ? handleOkSuccess : () => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={isSuccess ? "Great" : "OK"}
      />
    </>
  );
};

export default PromosAndPackagesForm;
