import { useState } from "react";
import { Col, Row, Spin, Avatar } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroller";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { capitalize, lowerCase } from "lodash";
import moment from "moment";

import ActionsMenu from "@/components/ActionsMenu";
import ContentContainer from "@/components/ContentContainer";
import CustomButton from "@/components/CustomButton";

import {
  DateText,
  DemoCont,
  NameText,
  NoResultFoundWrap,
  StatusText,
  StyledPagination,
  StyledSearch,
} from "./styled";

import DragIcon from "../../assets/images/icons/ic_unfold_more.svg";
import NoResultFound from "../../assets/images/icons/no-result-found-icon.svg";

interface Props {
  actions: Array<any>;
  addLabelText?: string;
  data: any;
  dragDisable: boolean;
  loading?: boolean;
  handleAddBtn?: () => void;
  handleDrag?: (value: any) => void;
  handleMenu?: (menuItem: string, id: string, name: string) => void;
  handleSearch: (query) => void;
  handleSelectedRow?: (id: string, slug: string, brandName?: string) => void;
  headerColumns?: any;
  noResultPlaceholder: string;
  searchPlaceholder: string;
  showCreated?: boolean;
}

const CustomDashboardList: React.FC<Props> = ({
  actions,
  addLabelText,
  data,
  dragDisable,
  handleAddBtn,
  handleDrag,
  handleMenu,
  handleSearch,
  handleSelectedRow,
  headerColumns,
  loading,
  noResultPlaceholder,
  searchPlaceholder,
  showCreated,
}) => {
  const [toggleDisable, setToggleDisable] = useState<boolean>(false);

  const showDragIcon = !dragDisable ? <Avatar src={DragIcon} /> : null;

  const onClickRow = (id, slug, brandName) => {
    handleSelectedRow && handleSelectedRow(id, slug, brandName);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    value ? setToggleDisable(true) : setToggleDisable(false);
    handleSearch(value);
  };

  const handlePage = (page, pageSize) => {
    console.log(page);
    console.log(pageSize);
  };

  const showCreateInfo = (item) => {
    return (
      <td className="ant-table-cell last-deployed-by">
        <NameText>{item.created_by === null ? "-" : item.created_by}</NameText>
        <DateText>
          {item.created_at === null ? "-" : moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
        </DateText>
      </td>
    );
  };

  const showDeployInfo = (item) => {
    return (
      <td className="ant-table-cell last-deployed-by">
        <NameText>{item.deployed_by === null ? "-" : item.deployed_by}</NameText>
        <DateText>
          {item.deployed_at === null ? "-" : moment(item.deployed_at).format("YYYY-MM-DD HH:mm:ss")}
        </DateText>
      </td>
    );
  };

  return (
    <div>
      <ContentContainer>
        <Row justify="space-between" style={{ marginBottom: "2rem" }}>
          <Col span={8}>
            <StyledSearch
              placeholder={searchPlaceholder}
              onSearch={handleSearch}
              onChange={handleChange}
              enterButton
              allowClear
            />
          </Col>

          <Col>
            <CustomButton onClick={handleAddBtn} style={{ height: 50 }}>
              <PlusOutlined /> Add {addLabelText}
            </CustomButton>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Row>
            <Col span={24}>
              <DemoCont dragDisable={dragDisable}>
                <InfiniteScroll initialLoad={false} pageStart={0} hasMore={true} useWindow={false}>
                  <DragDropContext onDragEnd={handleDrag}>
                    <div className="ant-table" data-index="table">
                      <table style={{ tableLayout: "fixed" }}>
                        <thead className="ant-table-thead">
                          <tr
                            className="ant-table-row"
                            style={{ position: "sticky", top: 0, zIndex: 1 }}
                          >
                            <th className="ant-table-cell" />
                            {headerColumns.map((item, index) => (
                              <th className="ant-table-cell" key={`title_${index}`}>
                                {item.title}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <Droppable droppableId="droppable">
                          {(provided) => (
                            <tbody className="ant-table-tbody" ref={provided.innerRef}>
                              {data.map((item, index) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={item.order || index}
                                  isDragDisabled={dragDisable || toggleDisable}
                                >
                                  {(provided, snapshots) => (
                                    <tr
                                      key={index}
                                      className={`ant-table-row tr-draggable ${
                                        lowerCase(item.status) === "deleted" && "tr-deleted"
                                      }`}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      onClick={() => onClickRow(item.id, item.slug, item.name)}
                                    >
                                      <td className="ant-table-cell">{showDragIcon}</td>
                                      <td className="ant-table-cell">{item.title || item.name}</td>
                                      <td className="ant-table-cell last-edited-by">
                                        <NameText>{item.updated_by}</NameText>
                                        <DateText>
                                          {moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                                        </DateText>
                                      </td>
                                      {showCreated ? showCreateInfo(item) : showDeployInfo(item)}
                                      <td className="ant-table-cell">
                                        <StatusText
                                          style={{
                                            color:
                                              lowerCase(item.status) === "deleted"
                                                ? "var(--color-secondary)"
                                                : "var(--color-general-black)",
                                          }}
                                        >
                                          {capitalize(item.status)}
                                        </StatusText>
                                      </td>
                                      <td
                                        className="ant-table-cell"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <ActionsMenu
                                          handleMenu={handleMenu}
                                          data={item}
                                          actions={actions}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </table>
                      {data.length === 0 && loading === false && (
                        <NoResultFoundWrap className="no-results">
                          <img src={NoResultFound} alt="No Results Found" />
                          <span>{noResultPlaceholder}</span>
                        </NoResultFoundWrap>
                      )}
                    </div>
                  </DragDropContext>
                </InfiniteScroll>
              </DemoCont>
            </Col>
          </Row>
        </Spin>
      </ContentContainer>
    </div>
  );
};

export default CustomDashboardList;
