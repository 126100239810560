import styled from "styled-components";
import Bg from "../../assets/images/login_bg.png";

export const Base = styled("div")({
  ".login .ant-layout-content": {
    height: "100vh",
  },
  ".ant-layout-sider": {
    maxWidth: "50% !important",
    minWidth: "50% !important",
    minHeight: "100vh",
  },
  ".sider-form": {
    background: "#f0f2f5",
  },
  ".sider-bg": {
    backgroundImage: `url(${Bg}) !important`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover !important",
    backgroundPosition: "100% 100%",
  },
});

export const FirstColumn = styled("div")({
  height: "100%",
  padding: "1.5em",
  display: "grid",
  gridTemplateRows: "1fr 67vh 1fr",
  gap: "2em",
  ".login-btn[disabled]": {
    background: "var(--color-primary)",
    borderColor: "var(--color-primary)",
    color: "var(--color-general-white)",
    opacity: 0.5,
  },
  ".forgot-btn": {
    padding: 0,
  },
});

export const SecondColumn = styled("div")({
  padding: "1.5em",
  textAlign: "right",
  height: "100%",
  "div:nth-child(2)": {
    margin: "5em 0 6em",
  },
  img: {
    width: "80%",
  },
});

export const Welcome = styled("h2")({
  fontSize: 48,
  marginBottom: 0,
});

export const LoginInstruction = styled("h4")({
  fontSize: 20,
  color: "var(--color-general-black)",
  marginBottom: "1.75em",
});

export const Copyright = styled("p")({
  margin: 0,
  color: "var(--color-general-blue-gray)",
});

export const PoweredBy = styled(Copyright)({
  color: "var(--color-general-white)",
});

export const Portal = styled(LoginInstruction)({
  color: "var(--color-general-white)",
});
