import { FC } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Base } from "./styled";

type Props = {
  className?: string;
  placeholder?: string;
  height?: string | number;
  defaultValue?: string | any;
  value?: string | any;
  onChange?: any;
  errors?: any;
};

const RichText: FC<Props> = ({
  className,
  placeholder,
  defaultValue,
  value,
  onChange,
  errors,
  height,
}) => {
  // Add fonts to whitelist and register them
  const Font = Quill.import("formats/font");
  Font.whitelist = [
    false,
    "arial",
    "comic-sans",
    "courier-new",
    "georgia",
    "helvetica",
    "lucida",
    "times-new-roman",
  ];
  Quill.register(Font, true);

  const toolbarOptions = [
    [{ size: ["small", false, "large", "huge"] }, { font: Font.whitelist }],
    ["bold", "italic", "underline", "strike"],
    [
      { color: [] },
      { align: [] },
      { list: "bullet" },
      { list: "ordered" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["blockquote", "code-block", "link", "clean"],
  ];

  const modules = {
    toolbar: toolbarOptions,
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "size",
    "font",
    "color",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "align",
    "blockquote",
    "code-block",
    "link",
    "clean",
  ];

  return (
    <>
      <Base className={className} height={height}>
        <div className={errors && errors ? "hasError" : ""}>
          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            placeholder={placeholder}
            value={value ? value : ""}
            defaultValue={defaultValue}
            onChange={onChange}
          />
        </div>
        {errors && errors ? (
          <>
            {typeof errors === "string" ? (
              <div style={{ color: "#ff4d4f", fontSize: 14 }}>{errors}</div>
            ) : null}
          </>
        ) : null}
      </Base>
    </>
  );
};

export default RichText;
