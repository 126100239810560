import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Checkbox, Table, Row, Col, Modal, Button } from "antd";
import { FormItem, Form, Input } from "formik-antd";

import ContentContainer from "@/components/ContentContainer";
import CustomModal from "@/components/CustomModal";
import { ErrorTableMessage, InstructionLabel, StyledButton, StyledInput, Subtitle } from "./styled";
import CustomSelect from "@/components/CustomSelect";
import { CloseOutlined } from "@ant-design/icons";
import WarningIcon from "../../../../../assets/images/icons/ic_report.svg";
import CustomButton from "@/components/CustomButton";

import { GET_COUNTRY_CODE_LIST, GET_ROLES } from "../../../graphql";
import { useQuery } from "@apollo/client";

import {
  CustomSelectInput,
  InputWrapper,
} from "./styled";


interface Props {
  formikBag: any;
  loader?: boolean;
}

const Forms: React.FC<Props> = ({ formikBag, loader }) => {
  const { values, submitForm, errors, setFieldValue } = formikBag;

  const { pageId } = useParams();
  const history = useHistory();
  const { data: countryCodes } = useQuery(GET_COUNTRY_CODE_LIST);
  const { data: getRoles } = useQuery(GET_ROLES);
  const [countryCodeList, setCountryCodeList] = useState();
  const [roleList, setRoleList] = useState();

  const [isModalVisibleLeaving, setIsModalVisibleLeaving] = useState<boolean>(false);

  useEffect(() => {
    let arrayList: any = [];

    if (countryCodes) {
      const countryCodeArr = countryCodes.countryCodes;
      countryCodeArr.forEach((item, index) => {
        if (item.dial_code) {
          arrayList.push({ value: item.dial_code, label: item.dial_code, flag: item.flag });
        }
      });
      setCountryCodeList(arrayList);
    }
  }, [countryCodes]);

  useEffect(() => {
    let arrayList: any = [];

    if (getRoles) {
      const getRolesArr = getRoles.getRoles;
      getRolesArr.forEach((item, index) => {
          arrayList.push({ value: item.id, label: item.name });
      });
      setRoleList(arrayList);
    }
  }, [getRoles]);


  const handleOkLeaving = () => {
    setIsModalVisibleLeaving(false);
    history.push("/user-role-management/cms-user-management");
  };

  const handleEditConfirm = (submitForm) => {
    const btnProps: any = {
      size: "large",
      style: {
        width: "6em",
        textTransform: "uppercase",
      },
    };
    const onClickHandle = submitForm;

    Modal.confirm({
      title: (
        <>
          <div style={{ textAlign: "center" }}>
            <img src={WarningIcon} alt="" />
            <br />
            <h4 style={{ color: "var(--color-primary)", fontSize: 20, margin: "1em 0" }}>
              Save Changes?
            </h4>
            <div style={{ color: "var(--color-general-black)", lineHeight: 1.5, fontSize: 16 }}>
              Are you sure you want to save changes for footer information?
            </div>
          </div>
        </>
      ),
      icon: <></>,
      okText: "SAVE",
      cancelText: "CANCEL",
      cancelButtonProps: btnProps,
      okButtonProps: btnProps,
      onOk() {
        onClickHandle();
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };


  return (
    <>
      <Form>
        <InstructionLabel>* Required fields, do not leave this blank.</InstructionLabel>
        <ContentContainer style={{ flexDirection: "column" }}>
          <Row>
            <Col span={12}>
              <div style={{ paddingRight: '50px' }}>
                <FormItem
                  label="First Name*"
                  name="first_name"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <StyledInput
                    name="first_name"
                    placeholder="Enter First Name"
                  />
                </FormItem>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ paddingRight: '50px' }}>
                <FormItem
                  label="Last Name*"
                  name="last_name"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <StyledInput
                    name="last_name"
                    placeholder="Enter Last Name"
                  />
                </FormItem>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{ paddingRight: '50px' }}>
                <FormItem
                  label="Email Address*"
                  name="email"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <StyledInput
                    name="email"
                    placeholder="Enter Email Address"
                  />
                </FormItem>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ paddingRight: '50px', marginTop: '1%' }}>
                <label>Mobile No.*</label>
                <CustomSelectInput>
                  <Row>
                    <Col span={7}>
                      <FormItem
                        name={`country_code`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <CustomSelect
                          defaultValue="+63"
                          name={`country_code`}
                          options={countryCodeList}
                          placeholder="+63"
                          style={{ width: "100%" }}
                          hasFlag
                        />
                      </FormItem>
                    </Col>
                    <Col span={17}>
                      <Form.Item
                        name="contact_number"
                        initialValue={``}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: "This is a required field" }]}
                      >
                        <InputWrapper>
                          <Input
                            name={`contact_number`}
                            placeholder="e.g 9172980102"
                            maxLength={10}
                            style={{ padding: "20px", width: "160%" }}
                          />
                        </InputWrapper>
                        </Form.Item>
                    </Col>
                  </Row>
                </CustomSelectInput>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{ paddingRight: '50px' }}>
                <FormItem
                  label="Company*"
                  name="company"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <StyledInput
                    name="company"
                    placeholder="Enter Company Name"
                  />
                </FormItem>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ paddingRight: '50px' }}>
                <FormItem
                  label="Position*"
                  name="position"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <StyledInput
                    name="position"
                    placeholder="Enter Position"
                  />
                </FormItem>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{ paddingRight: '50px', marginTop: '1%' }}>
                {/* <label><span style={{ color: 'red' }}>*</span> Access Role Assigned</label> */}
                <CustomSelectInput>
                  <Row>
                    <Col span={12}>
                      <FormItem
                        label="Access Role Assigned*"
                        name="access_role"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <CustomSelect
                          defaultValue="Select Role"
                          name={`access_role`}
                          options={roleList}
                          placeholder="Select Role"
                          style={{ width: "285%" }}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </CustomSelectInput>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ paddingRight: '50px', marginTop: '1%' }}>
                <CustomSelectInput>
                  <Row>
                    <Col span={12}>
                      <FormItem
                        label="Brand Access*"
                        name={`brand_access`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <CustomSelect
                          disabled
                          defaultValue="Select Brand Access"
                          name={`brand_access`}
                          options={[]}
                          placeholder="Select Brand Access"
                          style={{ width: "285%" }}
                          hasFlag
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </CustomSelectInput>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div style={{ paddingRight: '50px', marginTop: '2%' }}>
                <label><span style={{ color: 'red' }}>*</span> Hotel Access</label>
                <label style={{ color: 'grey', marginLeft: "40px" }}> Select Brand Access First</label>
                <CustomSelectInput>
                  <Row>
                    <Col span={12}>
                      <FormItem
                        name={`hotel_access`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <CustomSelect
                          disabled
                          defaultValue="Select Hotel Access"
                          name={`hotel_access`}
                          options={[]}
                          placeholder="Select Hotel Access"
                          style={{ width: "285%" }}
                          hasFlag
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </CustomSelectInput>
              </div>
            </Col>
          </Row>
          
          <div style={{ display: "flex", justifyContent: "end", gap: "1rem" }}>
          <Col span={3}>
            <Button style={{ width: "100%" }} onClick={() => setIsModalVisibleLeaving(true)}>
              CANCEL
            </Button>
          </Col>
          <Col span={3}>
            <CustomButton
              style={{ width: "100%" }}
              type="primary"
              onClick={pageId ? () => handleEditConfirm(submitForm) : () => submitForm()}
            >
              SAVE
            </CustomButton>
          </Col>
        </div>
        </ContentContainer>
      </Form>

        <CustomModal
          closable={false}
          maskClosable={false}
          isWarningIcon
          titleMessage={pageId ? "Cancel Editing" : "Leave this page?"}
          visible={isModalVisibleLeaving}
          onOk={handleOkLeaving}
          onCancel={() => setIsModalVisibleLeaving(false)}
          message={
            pageId
              ? "Are you sure you want to cancel editing"
              : "All unsaved data will be lost. Are you sure you want to leave this page?"
          }
          cancelText={pageId ? "No" : "Stay here"}
          okText="Yes"
          okButtonWidth="7.5em"
          cancelButtonWidth="7.5em"
        />
    </>
  );
};

export default Forms;
