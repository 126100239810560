import styled from "styled-components"

export const WhiteTitle = styled.h1`
  color: white;
  margin-bottom: 5px;
`;

export const GrayText = styled.p`
  color: gray;
  margin-bottom: 5px;
`;

export const BlueText = styled.p`
  color: #2E5984;
  margin-bottom: 5px;
`;

export const RedText = styled.p`
  color: red;
  margin-bottom: 5px;
`;

export const WhiteText = styled.p`
  color: white;
  margin-top: 1px;
  margin-left: 10px;
  font-size: 14px;
`;

export const BlackText = styled.p`
  color: black;
  margin-bottom: 5px;
`;

export const BlackTitle = styled.h1`
  color: black;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
`;


export const BrokenLine = styled.hr`
  border:none;
  border-top:1px dashed #7793B4;
  color:#fffff;
  background-color:#fffff;
  height:1px;
  width:100%;
  margin:0;
  padding:0;
`;

export const Span = styled.span`
  color: ${(props) => (props.primary ? "var(--color-general-black, #050e19)" : "#D7272D")};
  margin-bottom: 15px;
  display: block;
  a {
    color: ${(props) => (props.primary ? "#050E19" : "#D7272D")};
  }
`;
