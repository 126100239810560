import { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Spin, message } from "antd";
import { useHistory, useParams } from "react-router-dom";

import CustomModal from "@/components/CustomModal";
import Form from "./form";
import { CREATE_ROLE, EDIT_ROLE, GET_ROLE } from "../../../graphql";
import { featuresLabel } from "./constants";
import { has } from "lodash";

const RoleManagementForm = () => {
  const history = useHistory();
  const { pageId } = useParams();

  const { data: pageData, loading } = useQuery(GET_ROLE, {
    skip: !pageId,
    fetchPolicy: "no-cache",
    variables: {
      id: pageId || "",
    },
  });

  const [roleName, setRoleName] = useState<string>("");
  const [titleMessage, setTitleMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [apiLoaded, setApiLoaded] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([
    {
      key: "cms_users",
      feature: "User Role Management (CMS Users)",
      "add/delete": false,
      view: false,
      edit: false,
      "activate/deactivate": false,
      download: false,
    },
    {
      key: "customer_accounts",
      feature: "User Role Management (Customer Accounts)",
      "add/delete": false,
      view: false,
      edit: false,
      "activate/deactivate": false,
      download: false,
    },
    {
      key: "pages",
      feature: "Home Page, About Us, Footer and Common Pages",
      "add/delete": false,
      view: false,
      edit: false,
      "activate/deactivate": false,
    },
    {
      key: "brands",
      feature: "Brands",
      "add/delete": false,
      view: false,
      edit: false,
      "activate/deactivate": false,
    },
    {
      key: "hotel_list",
      feature: "Hotel List",
      "add/delete": false,
      view: false,
      edit: false,
      "activate/deactivate": false,
    },
    {
      key: "hotel_information",
      feature: "Hotel Information",
      "add/delete": false,
      view: false,
      edit: false,
      "activate/deactivate": false,
    },
    {
      key: "promotions",
      feature: "Promotions",
      "add/delete": false,
      view: false,
      edit: false,
      "activate/deactivate": false,
    },
    {
      key: "analytics",
      feature: "Analytics",
      "add/delete": false,
      view: false,
      edit: false,
      "activate/deactivate": false,
    },
    {
      key: "seo",
      feature: "SEO",
      "add/delete": false,
      view: false,
      edit: false,
      "activate/deactivate": false,
    },
    {
      key: "notifications",
      feature: "Notifications",
      "add/delete": false,
      view: false,
      edit: false,
      "activate/deactivate": false,
    },
  ]);

  const [createRole] = useMutation(CREATE_ROLE);
  const [editRole] = useMutation(EDIT_ROLE);

  const handleOkSuccess = () => {
    history.push("/user-role-management/role-management");
  };

  useEffect(() => {
    if (pageData) {
      const { name, scope } = pageData.getRoleById;
      const parsedScope = JSON.parse(scope);
      const newTableData = featuresLabel.map((item) => {
        const { key, title } = item;
        const isDownload = title.indexOf("User") ? undefined : parsedScope[key]["download"];

        return {
          ...parsedScope[key],
          key,
          feature: title,
          download: isDownload,
        };
      });

      setTableData(newTableData);
      setRoleName(name);
      setApiLoaded(true);
    }
  }, [pageData]);

  const dataMapper = () => {
    const parsedTableData = tableData.map((item) => {
      const { key, feature, ...checks } = item;

      return {
        [item.key]: {
          ...checks,
        },
      };
    });

    let newScope = {};
    for (let index = 0; index < parsedTableData.length; index++) {
      newScope = { ...newScope, ...parsedTableData[index] };
    }

    const data = {
      name: roleName,
      scope: JSON.stringify(newScope),
    };
    return data;
  };

  const handleCheckTable = () => {
    const filteredArray = tableData.filter((obj) =>
      Object.keys(obj).some((key) => obj[key] === true)
    );
    return filteredArray.length ? true : false;
  };

  const handleSubmit = async () => {
    if (!handleCheckTable()) {
      setHasError(true);
      return;
    }

    const data = dataMapper();
    setLoader(true);
    try {
      let update = false;
      if (pageData && has(pageData, "getRoleById")) {
        update = true;
      }

      if (update) {
        await editRole({
          variables: {
            editRoleInput: { ...data, id: pageId },
          },
        });
      } else {
        await createRole({
          variables: {
            createRoleInput: data,
          },
        });
      }

      setTimeout(() => {
        setLoader(false);
      }, 1000);

      setIsSuccess(true);
      setShowModal(true);
      setTitleMessage("Success!");
      setBodyMessage(
        update
          ? "Changes for this content was successfully saved."
          : "You have successfully added a new role!"
      );
    } catch (error: any) {
      if (error) {
        if (error.message.startsWith("Role with name")) {
          setTitleMessage("Data not saved!");
          setBodyMessage("Role already exist.");
          setShowModal(true);
        } else if (error.graphQLErrors && error.graphQLErrors[0]) {
          const errGraphqlRes = error.graphQLErrors[0].extensions.response;
          let errStr = "";

          if (errGraphqlRes) {
            if (errGraphqlRes?.message.length > 0) {
              errGraphqlRes?.message.forEach((item) => (errStr += item));
            } else errStr = errGraphqlRes?.message;
          }
          message.error(errStr);
        } else {
          setTitleMessage("Data not saved!");
          setBodyMessage("Kindly check your internet connection.");
          setShowModal(true);
        }
      }

      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  };

  return (
    <>
      <Spin spinning={loading || loader}>
        <Form
          {...{
            apiLoaded,
            handleSubmit,
            hasError,
            roleName,
            setApiLoaded,
            setRoleName,
            setTableData,
            tableData,
          }}
        />
      </Spin>

      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isErrorIcon={!isSuccess}
        isSuccessIcon={isSuccess}
        titleMessage={titleMessage}
        visible={showModal}
        onOk={isSuccess ? handleOkSuccess : () => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={isSuccess ? "Great" : "OK"}
      />
    </>
  );
};

export default RoleManagementForm;
