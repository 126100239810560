import * as yup from "yup";
import {
  ALPHANUM_PATTERN,
  ALPHANUM_AND_SYMBOLS_PATTERN,
  MOBILE_NUM_PATTERN,
  LANDLINE_NUM_PATTERN,
  URL_PATTERN,
} from "../../../../../utilities/regex";

const imageSchema = yup
  .object()
  .shape({
    status: yup.string().matches(/done/, { message: "File is still uploading" }),
    url: yup.string(),
  })
  .nullable();

const fieldsValidation = yup.object().when("type", (value) => {
  switch (value) {
    case "LEFT_SIDE":
    case "CENTER_TOP":
    case "CENTER_BOTTOM":
    case "RIGHT_SIDE":
      return yup.object().shape({
        section_status: yup.boolean(),
        header_title: yup.string().required("This is a required field.").trim().nullable(),
        text_content: yup.string().required("This is a required field.").trim().nullable(),
        link: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
        image: imageSchema.required("This is a required field.").nullable(),
      });
    case "LEFT_ALIGN":
    case "CENTER_ALIGN":
    case "RIGHT_ALIGN":
      return yup.object().shape({
        section_status: yup.boolean(),
        header_title: yup.string().required("This is a required field.").trim().nullable(),
        text_content: yup.string().required("This is a required field.").trim().nullable(),
        link: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
      });

    case "FEATURED_VIDEO":
      return yup.object().shape({
        section_status: yup.boolean(),
        link: yup
          .string()
          .required("This is a required field.")
          .matches(URL_PATTERN, "Invalid format.")
          .trim(),
        image: imageSchema.required("This is a required field.").nullable(),
      });

    default:
      return yup.object().nullable();
  }
});

const componentType = yup.object().shape({
  type: yup.string(),
  fields: fieldsValidation,
});

export const validationSchema = yup.object().shape({
  location: yup
    .string()
    .matches(ALPHANUM_AND_SYMBOLS_PATTERN, "Invalid format.")
    .trim()
    .required("This is a required field."),
  footnote_title: yup.string().trim().min(2, "Oops! Enter at least 2 characters."),
  footnote_body: yup.string().trim().min(9, "Oops! Enter at least 2 characters."),
  description: yup
    .string()
    .trim()
    .required("This is a required field.")
    .min(2, "Oops! Enter at least 2 characters."),
  landline_nos: yup.array().of(
    yup.object().shape({
      input: yup.string().trim().required("This is a required field.").matches(LANDLINE_NUM_PATTERN, "Invalid format."),
    })
  ),
  mobile_nos: yup.array().of(
    yup.object().shape({
      input: yup.string().when(["country_code"], {
        is: (country_code) => country_code === "+63",
        then: yup
          .string()
          .trim()
          .max(12, "Mobile no. must not exceed 11 characters.")
          .required("This is a required field.")
          .matches(MOBILE_NUM_PATTERN, "Invalid format."),
        otherwise: yup
          .string()
          .trim()
          .nullable()
          .matches(/^[0-9]*$/, "Invalid format."),
      }),
      country_code: yup.string().default("+63"),
    })
  ),
  email_addresses: yup.array().of(
    yup.object().shape({
      input: yup.string().trim().required("This is a required field.").email("Invalid email."),
    })
  ),
  category_logo: imageSchema.required("This is a required field."),
  carousel_image: imageSchema.required("This is a required field."),
  banner_feature_images: yup.array().of(imageSchema).min(1, "This is a required field.").nullable(),
  social_media: yup.object().shape({
    facebook: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
    instagram: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
    youtube: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
    twitter: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
    linkedin: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
    tiktok: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
    kumu: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
    viber_community: yup.string().trim().matches(URL_PATTERN, "Invalid format."),
  }),
  sections: yup.array().of(componentType),
});
