import { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroller";
import { capitalize, lowerCase } from "lodash";
import moment from "moment";

import ActionsMenu from "@/components/ActionsMenu";
import ContentContainer from "@/components/ContentContainer";
import CustomButton from "@/components/CustomButton";

import {
  DateText,
  DemoCont,
  NameText,
  NoResultFoundWrap,
  StatusText,
  StyledPagination,
  StyledSearch,
} from "./styled";
import NoResultFound from "../../assets/images/icons/no-result-found-icon.svg";

interface Props {
  actions: Array<any>;
  addLabelText?: string;
  data: any;
  loading?: boolean;
  handleAddBtn?: () => void;
  handleMenu?: (menuItem: string, id: string, name: string) => void;
  handleSearch: (query) => void;
  handleSelectedRow?: (id: string, slug: string, brandName?: string) => void;
  headerColumns?: any;
  searchPlaceholder: string;
}

const CustomDashboardList: React.FC<Props> = ({
  actions,
  data,
  handleAddBtn,
  handleMenu,
  handleSearch,
  handleSelectedRow,
  headerColumns,
  loading,
  searchPlaceholder,
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [dataDisplay, setDataDisplay] = useState<any[]>(data);

  useEffect(() => {
    const filteredData = data.filter((item, index) => {
      const end = pageSize * pageNumber;
      const start = end - pageSize;
      return index >= start && index < end;
    });

    setDataDisplay(filteredData);
  }, [pageNumber, pageSize, data]);

  const onClickRow = (id, slug, brandName) => {
    handleSelectedRow && handleSelectedRow(id, slug, brandName);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    handleSearch(value);
  };

  const handlePage = (page, size) => {
    setPageSize(size);
    setPageNumber(page);
  };

  return (
    <div>
      <ContentContainer>
        <Row justify="space-between" style={{ marginBottom: "2rem" }}>
          <Col span={8}>
            <StyledSearch
              placeholder={searchPlaceholder}
              onSearch={handleSearch}
              onChange={handleChange}
              enterButton
              allowClear
            />
          </Col>

          <Col>
            <CustomButton onClick={handleAddBtn} style={{ height: 50, padding: "0px 30px" }}>
              <PlusOutlined /> Add
            </CustomButton>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Row>
            <Col span={24}>
              <DemoCont>
                <InfiniteScroll initialLoad={false} pageStart={0} hasMore={true} useWindow={false}>
                  <div className="ant-table" data-index="table">
                    <table style={{ tableLayout: "fixed" }}>
                      <thead className="ant-table-thead">
                        <tr
                          className="ant-table-row"
                          style={{ position: "sticky", top: 0, zIndex: 1 }}
                        >
                          {headerColumns.map((item, index) => (
                            <th className="ant-table-cell" key={`title_${index}`}>
                              {item.title}
                            </th>
                          ))}
                        </tr>
                      </thead>

                      <tbody className="ant-table-tbody">
                        {dataDisplay.map((item, index) => (
                          <tr
                            key={index}
                            className={`ant-table-row tr-draggable ${
                              lowerCase(item.status) === "deleted" && "tr-deleted"
                            }`}
                            onClick={() => onClickRow(item.id, item.slug, item.name)}
                          >
                            <td className="ant-table-cell">
                              <NameText>{item.title}</NameText>
                              {item.featured ? <DateText>Featured</DateText> : null}
                            </td>
                            <td className="ant-table-cell last-edited-by">
                              <NameText>{item.created_by}</NameText>
                              <DateText>
                                {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
                              </DateText>
                            </td>
                            <td className="ant-table-cell last-deployed-by">
                              <NameText>
                                {item.updated_by === null ? "-" : item.updated_by}
                              </NameText>
                              <DateText>
                                {item.edited_at === null
                                  ? "-"
                                  : moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                              </DateText>
                            </td>
                            <td className="ant-table-cell">
                              <StatusText
                                style={{
                                  color:
                                    lowerCase(item.status) === "deleted"
                                      ? "var(--color-secondary)"
                                      : "var(--color-general-black)",
                                }}
                              >
                                {capitalize(item.status)}
                              </StatusText>
                            </td>
                            <td className="ant-table-cell" onClick={(e) => e.stopPropagation()}>
                              <ActionsMenu handleMenu={handleMenu} data={item} actions={actions} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {data.length === 0 && loading === false && (
                      <NoResultFoundWrap className="no-results">
                        <img src={NoResultFound} alt="No Results" />
                        <span>No promotions yet</span>
                      </NoResultFoundWrap>
                    )}
                  </div>
                </InfiniteScroll>
              </DemoCont>
            </Col>
          </Row>
        </Spin>
      </ContentContainer>
      <Row justify="space-between">
        <Col span={24}>
          <StyledPagination
            current={pageNumber}
            defaultPageSize={10}
            hideOnSinglePage={true}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            size="default"
            onChange={(page, size) => handlePage(page, size)}
            total={data.length}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CustomDashboardList;
