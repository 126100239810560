import styled from "styled-components";
import { Input } from "antd";
import { typography } from "../../utilities/styles/theme";

const { Search } = Input;

export const BrandList = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  gap: "1em",
  justifyContent: "space-between",
  color: "var(--color-general-black)",
  alignItems: "center",
  li: {
    position: "relative",

    ":first-child": {
      flex: "0 0 8%",
    },
    ":nth-child(2)": {
      flex: "0 0 15%",
    },
    ":nth-child(3)": {
      flex: "0 0 25%",
    },
    ":nth-child(4)": {
      flex: "0 0 25%",
    },
    ":nth-child(5)": {
      flex: "0 0 10%",
    },
    ":nth-child(6)": {
      flex: "0 0 5%",
      paddingRight: "3.5em",
    },
  },
  ".brand-name": {
    ...typography("body-2"),
    color: "var(--color-primary)",
  },
});

export const TitleList = styled("ul")({
  listStyle: "none",
  margin: 0,
  display: "flex",
  color: "var(--color-general-black)",
  backgroundColor: "var(--color-general-light-gray)",
  borderTopRightRadius: "0.25rem",
  borderTopLeftRadius: "0.25rem",
  padding: "0.75em 1.5em 0.75em 0",
  justifyContent: "space-between",
  alignItems: "center",
  li: {
    color: "var(--color-general-black)",
    position: "relative",
    ":first-child": {
      flex: "0 0 5%",
    },
    ":nth-child(2)": {
      flex: "0 0 8%",
    },
    ":nth-child(3)": {
      flex: "0 0 15%",
    },
    ":nth-child(4)": {
      flex: "0 0 25%",
    },
    ":nth-child(5)": {
      flex: "0 0 25%",
    },
    ":nth-child(6)": {
      flex: "0 0 10%",
    },
    ":nth-child(7)": {
      flex: "0 0 5%",
    },
  },
});

export const NameText = styled("p")({
  ...typography("body-2"),
  margin: 0,
  color: "var(--color-general-black)",
});

export const StatusText = styled("p")({
  ...typography("body-2"),
  margin: 0,
  color: "var(--color-general-black)",
});

export const DateText = styled(NameText)({
  color: "var(--color-general-blue-gray)",
});

export const StyledSearch = styled(Search)({
  ".ant-input-group > .ant-input-group-addon:last-child": {
    left: 10,
    borderRadiusTopLeft: 0,
    borderRadiusBottomLeft: 0,
  },
  // ".ant-input-group > .ant-input-affix-wrapper": {
  //   padding: "12px 22px"
  // },
  ".ant-input-group > .ant-input-affix-wrapper .ant-input-suffix span svg": {
    display: "none",
  },
  ".ant-input-group > .ant-input-affix-wrapper .ant-input-suffix span": {
    background: "var(--color-primary)",
    height: "13px",
    position: "relative",
    width: "3px",
    transform: "rotate(45deg)",
  },
  ".ant-input-group > .ant-input-affix-wrapper, .ant-input-group > .ant-input-group-addon .ant-input-search-button":
    {
      height: "50px",
      borderRadius: "3px !important",
    },
  ".ant-input-group > .ant-input-affix-wrapper .ant-input-suffix span:after": {
    content: "''",
    background: "var(--color-primary)",
    height: "3px",
    left: "-5px",
    position: "absolute",
    top: "5px",
    width: "14px",
  },
});

export const DemoCont = styled.div`
  // min-height: 350px;
  height: 67vh;
  // padding: 8px 18px 8px 24px;
  // padding: 8px 18px 8px 5px;
  overflow: auto;
  // border: 1px solid #e8e8e8;
  border-radius: 4px;

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .demo-loading-container {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
  }
  .ant-list-items .ant-list-item .ant-list-item-meta {
    align-items: center;
  }

  .ant-list-items .ant-list-item .ant-list-item-meta-avatar {
    margin-left: 10px;
  }

  table tr th:first-child,
  table tr td:first-child {
    width: 7%;
  }

  table tr th:nth-child(2),
  table tr td:nth-child(2) {
    width: 10%;
  }

  table tr th:nth-child(3),
  table tr td:nth-child(3) {
    width: 21%;
  }

  table tr th:nth-child(4),
  table tr th:nth-child(5),
  table tr .last-edited-by,
  table tr .last-deployed-by {
    width: 20%;
  }

  table tr th:nth-child(6),
  table tr td:nth-child(6) {
    width: 11%;
  }

  table tr th:last-child,
  table tr td:last-child {
    width: 11%;
  }

  table .tr-deleted {
    background: #fcedee !important;
  }

  table .tr-deleted:hover > td {
    background: #fcedee !important;
  }

  table tbody .tr-draggable:hover {
    background: transparent;
  }

  .not-draggable table tr th:first-child,
  .not-draggable table tr td:first-child {
    display: none;
  }
`;

export const MenuContainer = styled("div")({
  ".kebab-menu": {
    border: 0,
  },
  ".kebab-menu .ant-menu-submenu .ant-menu-submenu-title": {
    margin: 0,
    padding: 0,
    ".ant-menu-title-content": {
      display: "none",
    },
    ".ant-menu-submenu-arrow": {
      display: "none",
    },
  },
});

export const IconKeyTextCont = styled("div")({
  display: "block",
  "> *": {
    verticalAlign: "middle",
  },
});

export const IconKey = styled("div")({
  display: "inline-block",
  marginRight: "0.5rem",
  svg: {
    width: "1.3rem",
  },
});

export const TableWrapper = styled.div`
  table tbody {
    display: block;
    max-height: 350px;
    overflow-y: scroll;
  }

  table thead,
  table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const NoResultFoundWrap = styled.div`
  width: 250px;
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;

  img {
    margin-bottom: 20px;
  }

  span {
    font-size: 20px;
    color: var(--color-general-blue-gray);
    font-weight: bold;
  }
`;
