export const initialData = [
  {
    key: "cms_users",
    feature: "User Role Management (CMS Users)",
    "add/delete": false,
    view: false,
    edit: false,
    "activate/deactivate": false,
    download: false,
  },
  {
    key: "customer_accounts",
    feature: "User Role Management (Customer Accounts)",
    "add/delete": false,
    view: false,
    edit: false,
    "activate/deactivate": false,
    download: false,
  },
  {
    key: "pages",
    feature: "Home Page, About Us, Footer and Common Pages",
    "add/delete": false,
    view: false,
    edit: false,
    "activate/deactivate": false,
  },
  {
    key: "brands",
    feature: "Brands",
    "add/delete": false,
    view: false,
    edit: false,
    "activate/deactivate": false,
  },
  {
    key: "hotel_list",
    feature: "Hotel List",
    "add/delete": false,
    view: false,
    edit: false,
    "activate/deactivate": false,
  },
  {
    key: "hotel_information",
    feature: "Hotel Information",
    "add/delete": false,
    view: false,
    edit: false,
    "activate/deactivate": false,
  },
  {
    key: "promotions",
    feature: "Promotions",
    "add/delete": false,
    view: false,
    edit: false,
    "activate/deactivate": false,
  },
  {
    key: "analytics",
    feature: "Analytics",
    "add/delete": false,
    view: false,
    edit: false,
    "activate/deactivate": false,
  },
  {
    key: "seo",
    feature: "SEO",
    "add/delete": false,
    view: false,
    edit: false,
    "activate/deactivate": false,
  },
  {
    key: "notifications",
    feature: "Notifications",
    "add/delete": false,
    view: false,
    edit: false,
    "activate/deactivate": false,
  },
];

export const featuresLabel = [
  { title: "User Role Management (CMS Users)", key: "cms_users" },
  { title: "User Role Management (Customer Accounts)", key: "customer_accounts" },
  { title: "Home Page, About Us, Footer and Common Pages", key: "pages" },
  { title: "Brands", key: "brands" },
  { title: "Hotel List", key: "hotel_list" },
  { title: "Hotel Information", key: "hotel_information" },
  { title: "Promotions", key: "promotions" },
  { title: "Analytics", key: "analytics" },
  { title: "SEO", key: "seo" },
  { title: "Notifications", key: "notifications" },
];
