import React, { useState, ReactNode, useEffect } from "react";
import { Layout } from "antd";

import ScreenLoader from "../ScreenLoader";

import Navigation from "./navigation";
import Header from "./header";
import Breadcrumbs from "./breadcrumbs";

import { WrapperLayout, NewLayout } from "./styled";

const { Content } = Layout;

interface Props {
  children: ReactNode;
}

const MainLayout: React.FC<Props> = ({ children }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <NewLayout data-index="layout">
      {!loader ? (
        <>
          <Navigation collapsed={collapsed} />
          <Layout className="site-layout" style={{ height: "100vh", overflowY: "auto" }}>
            <Header collapsed={collapsed} toggle={toggle} />
            <Content>
              <Breadcrumbs />
              <WrapperLayout>{children}</WrapperLayout>
            </Content>
          </Layout>
        </>
      ) : (
        <ScreenLoader visible={true} />
      )}
    </NewLayout>
  );
};

export default MainLayout;
