import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import RhrHeroBannerList from "./components/rhr-hero-banner/rhr-hero-banner-lists";
import RhrHeroBannerForm from "./components/rhr-hero-banner/rhr-hero-banner-forms";
import RhrHeroBannerView from "./components/rhr-hero-banner/rhr-hero-banner-view";

import PromosAndPackagesList from "./components/promos-and-packages/promos-and-packages-lists";
import PromosAndPackagesForm from "./components/promos-and-packages/promos-and-packages-form";
import PromosAndPackagesView from "./components/promos-and-packages/promos-and-packages-view";

import UpdatesAndAnnouncementsList from "./components/updates-and-announcements/updates-and-announcements-lists";
import UpdatesAndAnnouncementsForm from "./components/updates-and-announcements/updates-and-announcements-form";
import UpdatesAndAnnouncementsView from "./components/updates-and-announcements/updates-and-announcements-view";

const BrandManagement: React.FC = () => {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.path}/rhr-hero-banner`}
          render={(props: any) => <RhrHeroBannerList {...props} />}
        />
        <Route
          exact
          path={`${match.path}/rhr-hero-banner/add-banner-item`}
          render={(props: any) => <RhrHeroBannerForm {...props} />}
        />
        <Route
          exact
          path={`${match.path}/rhr-hero-banner/edit-banner-content/:pageId`}
          render={(props: any) => <RhrHeroBannerForm {...props} />}
        />
        <Route
          exact
          path={`${match.path}/rhr-hero-banner/view-banner-item/:pageId`}
          render={(props: any) => <RhrHeroBannerView {...props} />}
        />
        <Route
          exact
          path={`${match.path}/promos-and-packages`}
          render={(props: any) => <PromosAndPackagesList {...props} />}
        />
        <Route
          exact
          path={`${match.path}/promos-and-packages/add-promo`}
          render={(props: any) => <PromosAndPackagesForm {...props} />}
        />
        <Route
          exact
          path={`${match.path}/promos-and-packages/edit-promo/:pageId`}
          render={(props: any) => <PromosAndPackagesForm {...props} />}
        />
        <Route
          exact
          path={`${match.path}/promos-and-packages/view-promo/:pageId`}
          render={(props: any) => <PromosAndPackagesView {...props} />}
        />
        <Route
          exact
          path={`${match.path}/updates-and-announcements`}
          render={(props: any) => <UpdatesAndAnnouncementsList {...props} />}
        />
        <Route
          exact
          path={`${match.path}/updates-and-announcements/add-update`}
          render={(props: any) => <UpdatesAndAnnouncementsForm {...props} />}
        />
        <Route
          exact
          path={`${match.path}/updates-and-announcements/edit-update/:pageId`}
          render={(props: any) => <UpdatesAndAnnouncementsForm {...props} />}
        />
        <Route
          exact
          path={`${match.path}/updates-and-announcements/view-update/:pageId`}
          render={(props: any) => <UpdatesAndAnnouncementsView {...props} />}
        />
      </Switch>
    </>
  );
};

export default BrandManagement;
