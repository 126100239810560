import { message, Row, Col, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";

import ContentContainer from "@/components/ContentContainer";
import CustomModal from "@/components/CustomModal";
import { IconKey, IconKeyTextCont } from "@/components/ActionsMenu/styled";
import {
  EditPenIcon,
  DeleteIcon,
  CancelIcon,
  InvertedCheckIcon,
} from "../../../../../utilities/icons";
import { RoleI } from "../../../../../models/RoleManagementInterface";
import { UPDATE_CMS_USER_STATUS, GET_CMS_USER } from "../../../graphql";
import { STATUS } from "../../../../../utilities/enums";
import { ROLES } from "./constants";
import {
  ActionButtons,
  ActionButtonsContainer,
  ActionButtonWrap,
  BorderTop,
  PromoPageWrap,
  SectionWrap,
  Sectiontitle,
  Span,
  SpanBY,
  Subtext,
  Subtitle,
} from "./styled";

const CMSUserManagementView = (): JSX.Element => {
  const { pageId }: { pageId: string } = useParams();
  const history = useHistory();


  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState<string>("");
  const [bodyMessage, setBodyMessage] = useState<string>("");
  const [bodyMessageSuccess, setBodyMessageSuccess] = useState<string>("");
  const [statusStr, setStatusStr] = useState<string>("");
  const [okTxt, setOkTxt] = useState<string>("OK");
  // const [userData, setuserData] = useState<RoleI>();
  const [userData, setUserData] = useState<any>();

  const [showActionButtons, setShowActionButtons] = useState<boolean>(false);
  const [showDeactivateBtn, setShowDeactivateBtn] = useState<boolean>(false);
  const [showDeleteBtn, setShowDeleteBtn] = useState<boolean>(false);
  const [showEditBtn, setShowEditBtn] = useState<boolean>(false);
  const [showActivateBtn, setShowActivateBtn] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("draft");
  const [scope, setScope] = useState<{}>();
  const [modifyStatus] = useMutation(UPDATE_CMS_USER_STATUS);

  const { loading, refetch, data } = useQuery(GET_CMS_USER, {
    skip: !decodeURI(pageId),
    fetchPolicy: "no-cache",
    variables: {
      id: decodeURI(pageId),
    },
  });

  useEffect(() => {
    if (data) {
      refetch();
      setUserData(data.getUserById);
      setStatus(data.getUserById.status);
    }
  }, [data, refetch]);

  useEffect(() => {
    if (userData) {
      const { status } = userData;
      setShowActionButtons(status !== "DELETED");
      setShowDeactivateBtn(status !== "INACTIVE" && status !== "FOR_ACTIVATION");
      setShowEditBtn(status !== "DELETED");
      setShowActivateBtn(status !== "ACTIVE");
    }
  }, [userData]);

  const constructVariables = (user: string, status: string): object => {
    return {
      id: user,
      status: status,
    };
  };

  const updateStatus = async (id: string, status: string): Promise<void> => {
    const input = constructVariables(id, status);

    try {
      const { data: res } = await modifyStatus({
        variables: {
          updateUserStatusInput: input,
        },
      });

      if (res.updateUserStatus) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
    refetch();
  };

  const checkStatusStrOkModal = async (statusStr) => {
    if (statusStr === "active") {
      await updateStatus(pageId, 'ACTIVE');
      setBodyMessageSuccess("This user is now active.");
    }

    if (statusStr === "deactivate") {
      await updateStatus(pageId, 'INACTIVE');
      setBodyMessageSuccess("This user is now deactivated.");
    }

    setShowModal(false);
    setShowModalSuccess(true);
  };

  // Go to Edit page
  const handleEdit = () => {
    history.push(`/user-role-management/cms-user-management/edit-user/${pageId}`, { pageId });
  };

  // Delete confirmation modal content
  const handleDelete = () => {
    setShowModal(true);
    setTitleMessage("Delete Brand?");
    setBodyMessage(`Are you sure you want to delete '${userData?.first_name}'?`);
    setStatusStr("deleted");
    setOkTxt("Delete");
  };

  // Archive confirmation modal content
  const handleDeactivate = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(`Are you sure you want to deactivate '${userData?.first_name}'?`);
    setStatusStr("deactivate");
    setOkTxt("Deactivate");
  };

  const handleActivate = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(`Are you sure you want to activate '${userData?.first_name}'?`);
    setStatusStr("active");
    setOkTxt("Activate");
  };

  const roleAccessArray =
    scope &&
    ROLES.map((data, index) => {
      const checkList = scope[data.key];
      return (
        <Col span={8} key={index}>
          <Span primary>{data.title}</Span>
          <Subtext show={checkList["add/delete"] ? 1 : 0}>- Add / Delete</Subtext>
          <Subtext show={checkList["view"] ? 1 : 0}>- View</Subtext>
          <Subtext show={checkList["edit"] ? 1 : 0}>- Edit</Subtext>
          <Subtext show={checkList["activate/deactivate"] ? 1 : 0}>- Activate / Deactivate</Subtext>
          <Subtext show={checkList["download"] ? 1 : 0}>- Download</Subtext>
        </Col>
      );
    });

  return (
    <>
      <Spin spinning={loading}>
        {userData !== undefined && (
          <PromoPageWrap>
            <ActionButtonsContainer show={showActionButtons ? 1 : 0}>
              <Row>
                <Col span="12" offset="12" style={{ textAlign: "right" }}>
                  <ActionButtonWrap>
                    <ActionButtons show={showDeactivateBtn ? 1 : 0} onClick={handleDeactivate}>
                      <IconKeyTextCont>
                        <IconKey>
                          <CancelIcon />{" "}
                        </IconKey>{" "}
                        Deactivate
                      </IconKeyTextCont>
                    </ActionButtons>
                    <ActionButtons
                      type="primary"
                      show={showActivateBtn ? 1 : 0}
                      onClick={handleActivate}
                    >
                      <IconKeyTextCont>
                        <IconKey>
                          <InvertedCheckIcon checkColor="#1B467F" />{" "}
                        </IconKey>{" "}
                        Activate
                      </IconKeyTextCont>
                    </ActionButtons>
                    <ActionButtons type="primary" show={showEditBtn ? 1 : 0} onClick={handleEdit}>
                      <IconKeyTextCont>
                        <IconKey>
                          <EditPenIcon />
                        </IconKey>{" "}
                        Edit
                      </IconKeyTextCont>
                    </ActionButtons>
                  </ActionButtonWrap>
                </Col>
              </Row>
            </ActionButtonsContainer>
            <SectionWrap>
              <ContentContainer>
                <Row justify="end">This user is currently {status.toUpperCase()}</Row>
                <Row>
                  <Col span={8}>
                    <Subtitle>First Name</Subtitle>
                    <Span primary style={{ marginBottom: "24px" }}>
                      {userData.first_name}
                    </Span>
                  </Col>
                  <Col span={8}>
                    <Subtitle>Last Name</Subtitle>
                    <Span primary style={{ marginBottom: "24px" }}>
                      {userData.last_name}
                    </Span>
                  </Col>
                  <Col span={8}>
                    <Subtitle>Email Address</Subtitle>
                    <Span primary style={{ marginBottom: "24px" }}>
                      {userData.email}
                    </Span>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Subtitle>Contact Number</Subtitle>
                    <Span primary style={{ marginBottom: "24px" }}>
                      {userData.contact_number}
                    </Span>
                  </Col>
                  <Col span={8}>
                    <Subtitle>Company</Subtitle>
                    <Span primary style={{ marginBottom: "24px" }}>
                      {userData.company}
                    </Span>
                  </Col>
                  <Col span={8}>
                    <Subtitle>Position</Subtitle>
                    <Span primary style={{ marginBottom: "24px" }}>
                      {userData.position}
                    </Span>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Subtitle>Access Role Assigned</Subtitle>
                    <Span primary style={{ marginBottom: "24px" }}>
                      {userData.access_role}
                    </Span>
                  </Col>
                  <Col span={8}>
                    <Subtitle>Brand Access</Subtitle>
                    <Span primary style={{ marginBottom: "24px" }}>
                      -
                    </Span>
                  </Col>
                  <Col span={8}>
                    <Subtitle>Hotel Access</Subtitle>
                    <Span primary style={{ marginBottom: "24px" }}>
                      -
                    </Span>
                  </Col>
                </Row>
              </ContentContainer>
              <BorderTop />
              <ContentContainer>
                <Sectiontitle>Details</Sectiontitle>
                <Row>
                  <Col span={8}>
                    <Subtitle>Date/Time Created</Subtitle>
                    <Span primary>{moment(userData.created_at).format("YYYY-MM-DD HH:mm:ss")}</Span>
                    <SpanBY>by {userData.created_by}</SpanBY>
                  </Col>
                  <Col span={8}>
                    <Subtitle>Last Date/Time Edited</Subtitle>
                    <Span primary>{moment(userData.updated_at).format("YYYY-MM-DD HH:mm:ss")}</Span>
                    <SpanBY>by {userData.updated_by}</SpanBY>
                  </Col>
                  <Col span={8}>
                    <Subtitle>Last Logged In</Subtitle>
                    <Span primary>{moment(userData.last_logged_in).format("YYYY-MM-DD HH:mm:ss")}</Span>
                  </Col>
                </Row>
              </ContentContainer>
            </SectionWrap>
          </PromoPageWrap>
        )}
      </Spin>

      {/* Confirmation Modal */}
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showModal}
        okButtonWidth="8em"
        onOk={() => checkStatusStrOkModal(statusStr)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />

      {/* Success Modal */}
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon
        titleMessage="Success!"
        visible={showModalSuccess}
        onOk={() => setShowModalSuccess(false)}
        onCancel={() => setShowModalSuccess(false)}
        message={bodyMessageSuccess}
        okText={"OK"}
      />
    </>
  );
};

export default CMSUserManagementView;
