import { Button } from "antd";
import styled from "styled-components";

export const DownloadButton = styled(Button)({
  color: "#fff",
  backgroundColor: "#1b467f",
  fontWeight:"bold",
  padding: "25px 10px",
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  textOverflow: "ellipsis",
});




export const BorderTop = styled.div`
  width: 100%;
  height: 0.8px;
  background-color: rgb(239, 242, 245);
`;

export const CustomerViewPageWrap = styled("div")`
  position: relative;
`;

export const ContentContainer = styled("div")`
  padding: 20px 45px 20px 50px;
  .row-content {
    padding-top: 15px;
  }
`;

export const Sectiontitle = styled.h2`
  color: var(--color-general-black);
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
`;

export const SectionWrap = styled.section`
  background: #fff;
  padding: 28px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 0.6rem;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
`;

export const Status = styled.p`
  background-color: #D5F2EA;
  color: #00C48C;
  font-size: 16px;
  font-weight: 400;
  border-radius: 3px;
  padding 10px 23px;
  margin: 0;
`;

export const Graytitle = styled.p`
  color: gray;
  margin-bottom: 5px;
`;

export const BlueTitle = styled.p`
  color: rgb(20, 68, 128);
  margin-bottom: 5px;
`;

export const LightBlueTitle = styled.p`
  color: #7791B3;
  margin-bottom: 5px;
`;

export const BlackTitle = styled.p`
  color: black;
  margin-bottom: 5px;
`;
