import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col } from "antd";
import { Form, FormItem, Input } from "formik-antd";

import ContentContainer from "@/components/ContentContainer";
import CustomButton from "@/components/CustomButton";
import CustomModal from "@/components/CustomModal";

import { StyledUploadImage, HeaderSubtitle, InstructionLabel, FormCounter } from "./styled";

interface Props {
  formikBag: any;
  loader?: boolean;
  announcementName?: string;
}

const Forms: React.FC<Props> = ({ formikBag, loader, announcementName }) => {
  const { values, submitForm, errors, setFieldValue } = formikBag;
  const { pageId } = useParams();
  const { TextArea } = Input;

  const history = useHistory();

  const [isModalVisibleLeaving, setIsModalVisibleLeaving] = useState<boolean>(false);
  const [inputCharCount, setInputCharCount] = useState({
    short_description: values.short_description ? values.short_description.length : 0,
    title: values.title ? values.title.length : 0,
  });

  const handleOkLeaving = () => {
    setIsModalVisibleLeaving(false);
    history.push("/home-page-management/updates-and-announcements/");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputCharCount({
      ...inputCharCount,
      [e.target.name]: e.target.value.length,
    });
  };

  return (
    <>
      <Form>
        <InstructionLabel>* Required fields, do not leave this blank.</InstructionLabel>
        <ContentContainer style={{ flexDirection: "column" }}>
          <Row gutter={40}>
            <Col md={12} span={24}>
              <Row>
                <Col span={24}>
                  <HeaderSubtitle>Announcement Photo*</HeaderSubtitle>
                  <div style={{ marginBottom: "1.5rem" }}></div>
                </Col>
                <Col span={24}>
                  {/* for "accept" props: no spaces allowed */}
                  <StyledUploadImage
                    accept={"image/png,image/jpeg,image/jpg"}
                    value={values.photo_url}
                    hasNote
                    ratio="13.9"
                    sampleRatio="325 x 225"
                    formatStr="Format: JPG or PNG"
                    maxFileSize={5}
                    sizeLimit={5}
                    onChange={(e) => {
                      setFieldValue("photo_url", e);
                    }}
                    errors={errors.photo_url}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={12} span={24}>
              <Row>
                <Col span={24}>
                  <FormItem
                    label={
                      <>
                        Title*
                        <small
                          style={{ marginLeft: "1rem", color: "var(--color-general-blue-gray)" }}
                        >
                          {" "}
                          Character Limit: 2 (min), 70(max)
                        </small>
                      </>
                    }
                    name={"title"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      name="title"
                      placeholder="Enter Title"
                      maxLength={70}
                      minLength={2}
                      onChange={handleChange}
                    />
                    <FormCounter>{`${values.title.length} / 70`}</FormCounter>
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label={
                      <>
                        Short Description*
                        <small
                          style={{ marginLeft: "1rem", color: "var(--color-general-blue-gray)" }}
                        >
                          {" "}
                          Character Limit: 2 (min), 150(max)
                        </small>
                      </>
                    }
                    name={"short_description"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <TextArea
                      name="short_description"
                      placeholder="Enter Content"
                      maxLength={150}
                      minLength={2}
                      rows={4}
                      onChange={handleChange}
                    />
                    <FormCounter>{`${values.short_description.length} / 150`}</FormCounter>
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label={<>Redirect link (Know More Button)</>}
                    name={"redirect_link"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input name="redirect_link" placeholder="Enter Redirect Link" />
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row justify="end" gutter={24} style={{ marginBottom: 10 }}>
            <Col span={4}>
              <CustomButton
                style={{ width: "100%" }}
                onClick={() => setIsModalVisibleLeaving(true)}
              >
                CANCEL
              </CustomButton>
            </Col>
            <Col span={4}>
              <CustomButton
                style={{ width: "100%", marginRight: 15 }}
                type="primary"
                // onClick={pageId ? () => handleEditConfirm(submitForm) : () => submitForm()}
                onClick={() => submitForm()}
              >
                SAVE
              </CustomButton>
            </Col>
          </Row>
        </ContentContainer>
      </Form>

      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={pageId ? "Cancel Editing" : "Leave this page?"}
        visible={isModalVisibleLeaving}
        onOk={handleOkLeaving}
        onCancel={() => setIsModalVisibleLeaving(false)}
        message={
          pageId
            ? "Are you sure you want to cancel editing"
            : "All unsaved data will be lost. Are you sure you want to leave this page?"
        }
        cancelText={pageId ? "No" : "Stay here"}
        okText="Yes"
        okButtonWidth="7.5em"
        cancelButtonWidth="7.5em"
      />
    </>
  );
};

export default Forms;
