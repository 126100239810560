import styled from "styled-components";
import { Row } from "antd";
import CustomButton from "@/components/CustomButton";

export const Span = styled.span`
  color: ${(props) => (props.primary ? "var(--color-general-black)" : "var(--color-secondary)")};
  margin-bottom: 15px;
  display: block;
  a {
    color: ${(props) => (props.primary ? "var(--color-general-black)" : "var(--color-secondary)")};
  }
`;

export const SpanBY = styled.span`
  color: var(--color-general-blue-gray);
  margin-bottom: 15px;
  display: block;
`;

export const Subtitle = styled.p`
  color: var(--color-primary);
  margin-bottom: 5px;
`;

export const Sectiontitle = styled.h2`
  color: var(--color-general-black);
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
`;

export const ImgLogo = styled.img`
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 5px;
`;

export const ImgHotelLogo = styled.img`
  width: 100%;
  max-width: 100%;
  padding: 30px;
  height: 150px;
  object-fit: cover;
`;

export const ImgPhoto = styled.img`
  width: 100%;
  max-width: 100%;
  border-radius: 5px;
  height: 200px;
  object-fit: cover;
`;

export const ImgWrap = styled.div`
  margin-top: 20px;
  width: 100%;
  max-width: 100%;
  border-radius: 5px;
  background-color: var(--color-tertiary);
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionWrap = styled.section`
  background: #fff;
  // margin: 24px 0;
  padding: 24px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 0.6rem;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
`;

export const BorderTop = styled.div`
  width: 100%;
  height: 2px;
  background-color: rgba(119, 145, 179, 0.6);
`;

export const HotelList = styled.div`
  width: 100%;
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
`;

export const HotelCard = styled.div`
  width: 50%;
  padding: 0 8px;
  margin-bottom: 16px;
`;
export const HotelWrap = styled.div`
  width: 100%;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  padding: 24px 0;
  height: 100%;
`;

export const FeatureImagesList = styled.div`
  width: 100%;
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
`;

export const FeatureImageCard = styled.div`
  width: 20%;
  padding: 0 8px;
`;

export const FeatureImageWrap = styled.div`
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  height: 150px;

  .ant-image {
    height: 100%;
  }

  .ant-image img {
    object-fit: cover;
    height: 100%;
  }
  .ant-image-mask .ant-image-mask-info {
    font-size: 0;
  }
  .ant-image-mask .ant-image-mask-info span {
    font-size: 16px;
  }
`;

export const ActionButtonWrap = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

export const BrandPageWrap = styled("div")`
  position: relative;
`;

export const Wrapper = styled("div")({
  padding: "0 32px",
  marginBottom: 24,
});

export const ActionButtons = styled(CustomButton)`
  margin-right: 15px;
  border-radius: 3px;
  color: ${(props) => (props.type === "primary" ? "#fff" : "#d7272d")};

  svg path {
    fill: ${(props) => (props.type === "primary" ? "#fff" : "#d7272d")};
  }
`;

export const ActionButtonsContainer = styled(Row)`
  position: absolute;
  top: -73px;
  left: -32px;
  width: calc(100% + 64px);
  display: block;
  padding: 0px 30px;
  padding-bottom: 20px;
`;
