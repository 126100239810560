import React from "react";

import { Modal, Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

interface Props {
    visible: boolean;
}

const ScreenLoader: React.FC<Props> = ({ visible }) => (
    <Modal
        centered={true}
        closable={false}
        visible={visible}
        footer={false}
        width={100}
        zIndex={9999}
        bodyStyle={{ display: "flex", justifyContent: "center" }}
    >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
    </Modal>
);

export default ScreenLoader;
