import React from "react";
import { Select } from "formik-antd";
import { StyledSelect } from "./styled";

interface Props {
  name: string;
  placeholder?: string;
  options?: any[];
  defaultValue?: string;
  onChange?: any;
  onSelect?: any;
  disabled?: boolean;
  hasFlag?: boolean;
  style?: any;
}

const CustomSelect: React.FC<Props> = ({
  name,
  placeholder,
  options,
  defaultValue,
  onChange,
  style,
  onSelect,
  disabled,
  hasFlag,
}) => {
  const renderOptions = () => {
    if (options !== undefined) {
      if (hasFlag) {
        return options.map(({ label, value, flag }, key: number) => (
          <Select.Option key={key} value={value}>
            <div style={{ fontSize: 12, display: "inline-block" }}>{flag}</div> {label}
          </Select.Option>
        ));
      }

      return options.map(({ label, value }, key: number) => (
        <Select.Option key={key} value={value}>
          {label}
        </Select.Option>
      ));
    }
  };

  return (
    <StyledSelect
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={onChange && onChange}
      onSelect={onSelect && onSelect}
      disabled={disabled}
      style={style}
    >
      {renderOptions()}
    </StyledSelect>
  );
};

export default CustomSelect;
