import React from "react";
import { Switch } from "react-router-dom";
import "./App.less";

import "./assets/css/themes/styles.css";
import "./assets/css/theme.css";

import MainLayout from "./components/Layout";
import { getRoute } from "./context/routers";

const App: React.FC = () => {
  return (
    <MainLayout data-index="layout">
      <Switch>{getRoute()}</Switch>
    </MainLayout>
  );
};

export default App;
