export const ROLES = [
  { title: "User Role Management (CMS Users)", key: "cms_users" },
  { title: "User Role Management (Customer Accounts)", key: "customer_accounts" },
  { title: "Home Page, About Us, Footer and Common Pages", key: "pages" },
  { title: "Brands", key: "brands" },
  { title: "Hotel List", key: "hotel_list" },
  { title: "Hotel Information", key: "hotel_information" },
  { title: "Promotions", key: "promotions" },
  { title: "Analytics", key: "analytics" },
  { title: "SEO", key: "seo" },
  { title: "Notifications", key: "notifications" },
];
