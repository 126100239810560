import styled from "styled-components";
import UploadSingleFile from "@/components/UploadSingleFile";
import CustomButton from "@/components/CustomButton";
import { typography } from "../../../../../utilities/styles/theme";

export const WrapperForm = styled.div`
  background: rgba(246, 246, 246, 1);
  border-radius: 2px;
  padding: 25px 35px;
  margin-bottom: 25px;
  position: relative;
  box-shadow: 0px 4px 11px 1px rgba(0, 0, 0, 0.22);

  .remove-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
`;

export const MediaContainer = styled(UploadSingleFile)`
  height: 155px;
  width: 100%;
  display: block;
  border-radius: 3px;
  overflow: hidden;
  background: #fff;

  .ant-upload {
    display: block;

    .upload-button {
      height: 155px;
      width: 100%;
      display: block;
      border-radius: 3px;
    }
  }

  .upload-button {
    height: 155px;
    width: 100%;
    display: block;
    border-radius: 3px;
  }

  .uploaded-file {
    height: 155px;
    width: 100%;

    img {
      height: 155px;
      width: 100%;
    }
  }

  .remove-file {
    top: 6px;
    right: 10px;
  }
`;

export const UploadSingleFileStyled = styled(UploadSingleFile)`
  height: unset;
  width: 100%;
  display: block;
  overflow: hidden;

  .ant-upload-select-text {
    background-color: #fff;
  }

  .ant-upload {
    .upload-button {
      width: 100%;
      height: unset;
      border-style: solid;
      background: unset;
      color: var(--color-primary);
    }
  }

  .label-button {
    display: flex;
    gap: 1rem;
    padding: 0.1rem 1rem;
    svg {
    }

    p {
      font-size: 18px;
    }

    h5 {
      color: #d3d3d3;
    }
  }

  .ant-upload-list-text {
    display: none;
  }
`;

export const SectionHeader = styled("h4")({
  ...typography("subtitle-1"),
});

export const DeleteButton = styled(CustomButton)({
  color: " var(--color-secondary)",
  textTransform: "capitalize",
  ":focus": {
    color: "var(--color-secondary)",
    background: "transparent",
  },
  ":hover": {
    color: "var(--color-secondary)",
    background: "transparent",
    opacity: 0.75,
  },
});

export const IconKeyTextCont = styled("div")({
  display: "block",
  "> *": {
    verticalAlign: "middle",
  },
});

export const IconKey = styled("div")({
  display: "inline-block",
  svg: {
    width: "1.3rem",
  },
});

export const SwitchCont = styled("div")({
  display: "flex",
  gap: "1em",
  alignItems: "baseline",
  ".ant-switch-checked": {
    backgroundColor: "var(--color-green)",
  },
  ".ant-form-item": {
    marginBottom: 0,
  },
});
