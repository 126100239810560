import React, { ReactNode } from "react";
import { ButtonProps } from "antd";

import { StyledButton as Button } from "./styled";

interface Props extends ButtonProps {
  onClick?: () => void;
  children: ReactNode;
  style?: object;
  className?: string;
  disabled?: boolean;
}

const CustomButton: React.FC<Props> = ({
  onClick,
  children,
  style,
  className,
  disabled,
  ...etcProps
}) => (
  <Button
    {...etcProps}
    onClick={onClick}
    style={style}
    data-index="button"
    className={className}
    disabled={disabled}
  >
    {children}
  </Button>
);

export default CustomButton;
