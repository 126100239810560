import React from "react";
import { Col, Row, Spin, Avatar, Menu } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroller";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { capitalize, lowerCase } from "lodash";
import moment from "moment";

import ContentContainer from "@/components/ContentContainer";
import CustomButton from "@/components/CustomButton";

import {
  NameText,
  DateText,
  StyledSearch,
  DemoCont,
  MenuContainer,
  IconKeyTextCont,
  IconKey,
  NoResultFoundWrap,
  StatusText,
} from "./styled";

import { DeleteIcon, EditPenIcon, PublishIcon, EyeIcon, ArchiveIcon } from "../../utilities/icons";

import KebabIconImg from "../../assets/images/icons/ic_more_kebab.svg";
import DragIcon from "../../assets/images/icons/ic_unfold_more.svg";
import GoLogo from "../../assets/images/brands/go_logo_colored.png";
import NoResultFound from "../../assets/images/icons/no-result-found-icon.svg";
import PlayIcon from "../../assets/images/icons/ic_play_circle.svg";

const { SubMenu } = Menu;

interface Props {
  loading?: boolean;
  headerColumns?: any;
  handleSearch?: (query) => void;
  data: any;
  handleMenu?: (menuItem: string, id: string, slug: string, brandName?: any, type?: string) => void;
  handleAddBtn?: () => void;
  handleInfiniteOnLoad?: any;
  hasMore?: boolean;
  onDragEnd?: (value: any) => void;
  addLabelText?: string;
  handleSelectedRow?: (id: string, slug: string, brandName?: string) => void;
}

const CustomDashboardList: React.FC<Props> = ({
  loading,
  headerColumns,
  data,
  handleSearch,
  handleMenu,
  handleAddBtn,
  handleInfiniteOnLoad,
  hasMore,
  onDragEnd,
  addLabelText,
  handleSelectedRow,
}) => {
  const onSelect = (item: any, id: string, slug: string, brandName: any) => {
    handleMenu && handleMenu(item.key, id, slug, brandName);
  };

  const onClickRow = (id, slug, brandName) => {
    handleSelectedRow && handleSelectedRow(id, slug, brandName);
  };

  const onAddBtnClick = () => {
    handleAddBtn && handleAddBtn();
  };

  return (
    <div>
      <ContentContainer>
        <Row justify="end" style={{ marginBottom: "2rem" }}>
          {handleSearch && (
            <Col span={8}>
              <StyledSearch
                placeholder="Search Brand Management"
                onSearch={handleSearch}
                enterButton
                allowClear
              />
            </Col>
          )}
          <Col>
            <CustomButton onClick={onAddBtnClick} style={{ height: 50 }}>
              <PlusOutlined /> Add {addLabelText}
            </CustomButton>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Row>
            <Col span={24}>
              <DemoCont>
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={handleInfiniteOnLoad}
                  hasMore={hasMore}
                  useWindow={false}
                >
                  <DragDropContext onDragEnd={onDragEnd}>
                    <div className="ant-table-wrapper">
                      <div className="ant-spin-nested-loading">
                        <div className="ant-spin-container">
                          <div className="ant-table" data-index="table">
                            <div className="ant-table-container">
                              <div className="ant-table-content">
                                <table style={{ tableLayout: "fixed" }}>
                                  <colgroup></colgroup>
                                  <thead className="ant-table-thead">
                                    <tr
                                      className="ant-table-row"
                                      style={{ position: "sticky", top: 0, zIndex: 1 }}
                                    >
                                      <th className="ant-table-cell" />
                                      {headerColumns.map((item, index) => (
                                        <th className="ant-table-cell" key={`title_${index}`}>
                                          {item.title}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <Droppable droppableId="droppable">
                                    {(provided) => (
                                      <tbody className="ant-table-tbody" ref={provided.innerRef}>
                                        {data.map((item, index) => (
                                          <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={item.order || index}
                                          >
                                            {(provided, snapshots) => (
                                              <tr
                                                key={index}
                                                className={`ant-table-row tr-draggable ${
                                                  lowerCase(item.status) === "deleted" &&
                                                  "tr-deleted"
                                                }`}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                onClick={() =>
                                                  onClickRow(item.id, item.slug, item.name)
                                                }
                                              >
                                                <td className="ant-table-cell">
                                                  <Avatar src={DragIcon} />
                                                </td>
                                                <td className="ant-table-cell">
                                                  {item.image_url && (
                                                    <img
                                                      src={item.image_url ? item.image_url : GoLogo}
                                                      alt="Logo"
                                                      style={{
                                                        width: "45px",
                                                        objectFit: "cover",
                                                        height: "45px",
                                                      }}
                                                    />
                                                  )}
                                                  {item.video_url && (
                                                    <div
                                                      style={{
                                                        backgroundColor: "var(--color-primary)",
                                                        width: "45px",
                                                        height: "45px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <img
                                                        src={PlayIcon}
                                                        alt=">"
                                                        style={{ width: 20, height: 20 }}
                                                      />
                                                    </div>
                                                  )}
                                                </td>
                                                <td className="ant-table-cell">{item.title}</td>
                                                <td className="ant-table-cell last-edited-by">
                                                  <NameText>{item.updated_by}</NameText>
                                                  <DateText>
                                                    {moment(item.updated_at).format(
                                                      "YYYY-MM-DD HH:mm:ss"
                                                    )}
                                                  </DateText>
                                                </td>
                                                <td className="ant-table-cell last-deployed-by">
                                                  <NameText>
                                                    {item.deployed_by === null
                                                      ? "-"
                                                      : item.deployed_by}
                                                  </NameText>
                                                  <DateText>
                                                    {item.deployed_at === null
                                                      ? "-"
                                                      : moment(item.deployed_at).format(
                                                          "YYYY-MM-DD HH:mm:ss"
                                                        )}
                                                  </DateText>
                                                </td>
                                                <td className="ant-table-cell">
                                                  <StatusText
                                                    style={{
                                                      color:
                                                        lowerCase(item.status) === "deleted"
                                                          ? "var(--color-secondary)"
                                                          : "var(--color-general-black)",
                                                    }}
                                                  >
                                                    {capitalize(item.status)}
                                                  </StatusText>
                                                </td>
                                                <td
                                                  className="ant-table-cell"
                                                  onClick={(e) => e.stopPropagation()}
                                                >
                                                  <MenuContainer>
                                                    <Menu
                                                      selectable={false}
                                                      className="kebab-menu"
                                                      style={{
                                                        display:
                                                          lowerCase(item.status) === "deleted"
                                                            ? "none"
                                                            : "block",
                                                      }}
                                                      onClick={(menuItem) =>
                                                        onSelect(
                                                          menuItem,
                                                          item.id,
                                                          item.slug,
                                                          item.title
                                                        )
                                                      }
                                                    >
                                                      <SubMenu
                                                        icon={
                                                          <img
                                                            src={KebabIconImg}
                                                            alt="Kebab Icon"
                                                          />
                                                        }
                                                      >
                                                        <Menu.Item
                                                          style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            height: 35,
                                                            lineHeight: "unset",
                                                            margin: "0.5rem 0 0 0",
                                                            fontSize: 14,
                                                          }}
                                                          key="view"
                                                        >
                                                          <IconKeyTextCont>
                                                            <IconKey>
                                                              <EyeIcon />
                                                            </IconKey>{" "}
                                                            View
                                                          </IconKeyTextCont>
                                                        </Menu.Item>
                                                        <Menu.Item
                                                          style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            height: 35,
                                                            lineHeight: "unset",
                                                            margin: 0,
                                                            fontSize: 14,
                                                          }}
                                                          key="edit"
                                                        >
                                                          <IconKeyTextCont>
                                                            <IconKey>
                                                              <EditPenIcon />
                                                            </IconKey>{" "}
                                                            Edit
                                                          </IconKeyTextCont>
                                                        </Menu.Item>
                                                        <Menu.Item
                                                          style={{
                                                            display:
                                                              lowerCase(item.status) === "published"
                                                                ? "none"
                                                                : "flex",
                                                            alignItems: "center",
                                                            height: 35,
                                                            lineHeight: "unset",
                                                            margin: 0,
                                                            fontSize: 14,
                                                          }}
                                                          key="publish"
                                                        >
                                                          <IconKeyTextCont>
                                                            <IconKey>
                                                              <PublishIcon />{" "}
                                                            </IconKey>{" "}
                                                            Publish
                                                          </IconKeyTextCont>
                                                        </Menu.Item>
                                                        <Menu.Item
                                                          style={{
                                                            display:
                                                              lowerCase(item.status) ===
                                                                "archived" ||
                                                              lowerCase(item.status) === "draft"
                                                                ? "none"
                                                                : "flex",
                                                            alignItems: "center",
                                                            height: 35,
                                                            lineHeight: "unset",
                                                            margin:
                                                              lowerCase(item.status) === "archived"
                                                                ? 0
                                                                : "0 0 0.5rem 0",
                                                            fontSize: 14,
                                                          }}
                                                          key="archive"
                                                        >
                                                          <IconKeyTextCont>
                                                            <IconKey>
                                                              <ArchiveIcon />{" "}
                                                            </IconKey>{" "}
                                                            Archive
                                                          </IconKeyTextCont>
                                                        </Menu.Item>
                                                        <Menu.Item
                                                          className="delete-menu-item"
                                                          style={{
                                                            display:
                                                              lowerCase(item.status) === "published"
                                                                ? "none"
                                                                : "flex",
                                                            alignItems: "center",
                                                            height: 35,
                                                            lineHeight: "unset",
                                                            margin: "0 0 0.5rem 0",
                                                            color: "red",
                                                            fontSize: 14,
                                                          }}
                                                          key="delete"
                                                        >
                                                          <IconKeyTextCont>
                                                            <IconKey>
                                                              <DeleteIcon
                                                                style={{ color: "red" }}
                                                              />
                                                            </IconKey>{" "}
                                                            Delete
                                                          </IconKeyTextCont>
                                                        </Menu.Item>
                                                      </SubMenu>
                                                    </Menu>
                                                  </MenuContainer>
                                                </td>
                                              </tr>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </tbody>
                                    )}
                                  </Droppable>
                                </table>
                                {data.length === 0 && loading === false && (
                                  <NoResultFoundWrap className="no-results">
                                    <img src={NoResultFound} alt="No Results Found" />
                                    <span>No results Found</span>
                                  </NoResultFoundWrap>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DragDropContext>
                </InfiniteScroll>
              </DemoCont>
            </Col>
          </Row>
        </Spin>
      </ContentContainer>
    </div>
  );
};

export default CustomDashboardList;
