import { useEffect, useState } from "react";
import { Button, Col, Collapse, Divider, Row, Tabs } from "antd";
import { FieldArray } from "formik";
import { FormItem, Input, Select } from "formik-antd";
import { useQuery } from "@apollo/client";
import { DeleteFilled, InfoCircleFilled, PlusCircleFilled } from "@ant-design/icons";

import ContentContainer from "@/components/ContentContainer";
import Richtext2 from "@/components/Richtext2";
import {
  AddHotelContainer,
  ButtonWrapper,
  CountryCode,
  PayToggle,
  HotelDeleteButton,
  ManageHotelContainer,
  MobileNumber,
  PhotoDescription,
  SmallNote,
  StyledButton,
  StyledInnerPanel,
  StyledPanel,
  StyledTabs,
  StyledUploadImageRectangle,
  StyledUploadImageSmall,
} from "../styled";
import { GET_COUNTRY_CODE_LIST } from "../../../graphql";

const DOMPurify = require('dompurify')(window);

interface Props {
  formikBag: any;
}

const { Option } = Select;

const ManageHotels = ({ formikBag }: Props) => {
  const { values, errors, setFieldValue } = formikBag;

  const { data: countryCodes } = useQuery(GET_COUNTRY_CODE_LIST);

  const [countryCodeList, setCountryCodeList] = useState([]);
  const [isFieldRequired, setIsFieldRequired] = useState(false);
  const showAsterisk = isFieldRequired ? "*" : "";

  const hotelPhotoLibrary = (item, key) => {
    let photoArray: JSX.Element[] = [];
    const hideButton = (e) => {
      e.target.remove();
    };

    for (let index = 0; index < 10; index++) {
      const checkValue = item.uploaded_hotel_photos && item.uploaded_hotel_photos[index]?.url;
      const showButton =
        item.uploaded_hotel_photos &&
        item.uploaded_hotel_photos[index]?.photo_description ? null : (
          <Button
            type="text"
            style={{ padding: 0, position: "absolute", zIndex: 1, backgroundColor: "#f6f6f6" }}
            onClick={hideButton}
          >
            + Add Photo Description
          </Button>
        );
      const newPhoto = (
        <Col key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <FormItem
            name={`hotels[${key}].uploaded_hotel_photos[${index}].url`}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <StyledUploadImageRectangle
              accept={"image/jpeg,image/jpg,image/png"}
              value={checkValue ? item.uploaded_hotel_photos[index] : ""}
              maxFileSize={5}
              sizeLimit={5}
              onChange={(e) => {
                const newValue = e ? e.url : "";
                setFieldValue(`hotels[${key}].uploaded_hotel_photos[${index}].url`, newValue);
              }}
            />
          </FormItem>
          <Row gutter={0}>
            {showButton}
            <FormItem
              name={`hotels[${key}].uploaded_hotel_photos[${index}].photo_description`}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <PhotoDescription
                bordered={false}
                name={`hotels[${key}].uploaded_hotel_photos[${index}].photo_description`}
              />
            </FormItem>
          </Row>
        </Col>
      );
      photoArray.push(newPhoto);
    }

    return photoArray;
  };

  const handleDjubo = (e) => {
    e.target.value ? setIsFieldRequired(true) : setIsFieldRequired(false);
  };

  useEffect(() => {
    let arrayList: any = [];

    if (countryCodes) {
      const countryCodeArr = countryCodes.countryCodes;
      countryCodeArr.forEach((item, index) => {
        if (item.dial_code) {
          arrayList.push({ value: item.dial_code, label: item.dial_code, flag: item.flag });
        }
      });
      setCountryCodeList(arrayList);
    }
  }, [countryCodes]);

  const countryCodeOptions = countryCodeList.map((item, index) => {
    const { value, label, flag } = item;

    return <Option key={index} value={value}>{`${flag} ${label}`}</Option>;
  });

  const renderItems = ({ event, values }) => {
    return (
      <>
        {values.hotels &&
          values.hotels.length > 0 &&
          values?.hotels?.map((item, key) => {
            return (
              <Col span={24}>
                <ManageHotelContainer>
                  <Row justify="end">
                    <Col>
                      <HotelDeleteButton
                        onClick={() => {
                          event.remove(key);
                        }}
                      >
                        <DeleteFilled /> Delete Hotel
                      </HotelDeleteButton>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} style={{ marginBottom: 24 }}>
                      <StyledUploadImageSmall
                        label="Hotel Logo*"
                        notePlacing="right"
                        accept={"image/jpeg,image/jpg,image/png"}
                        hasNote
                        ratio="1:1"
                        sampleRatio="512 x 512"
                        formatStr="PNG transparent BG."
                        maxFileSize={5}
                        sizeLimit={5}
                        extraNoteBg="Color should be lack or any dark color."
                        value={values.hotels[key].website_logo}
                        onChange={(e) => {
                          setFieldValue(`hotels[${key}].website_logo`, e);
                        }}
                        errors={errors.hotels && errors.hotels[key].website_logo}
                      />
                    </Col>
                    <Col span={12} style={{ marginBottom: 24 }}>
                      <StyledUploadImageSmall
                        label={`Hotel Logo For App${showAsterisk}`}
                        notePlacing="right"
                        accept={"image/jpeg,image/jpg,image/png"}
                        hasNote
                        ratio="3:2"
                        sampleRatio="60 x 40"
                        formatStr="JPG or PNG"
                        maxFileSize={1}
                        sizeLimit={1}
                        value={values.hotels[key].app_logo}
                        onChange={(e) => {
                          setFieldValue(`hotels[${key}].app_logo`, e);
                        }}
                        errors={errors.hotels && errors.hotels[key].app_logo}
                      />
                    </Col>
                  </Row>
                  <Row>Hotel Photo Library{showAsterisk}</Row>
                  <Row gutter={[32, 24]}>{hotelPhotoLibrary(item, key)}</Row>
                  <Row>
                    <SmallNote>
                      <span>NOTE:</span> Maximum of 10 Images. Image must be in 16:9 ratio. Example
                      1920x1080 pixel. Format: JPG or PNG. Maximum file size of 5mb.
                    </SmallNote>
                  </Row>
                  <Divider />
                  <Row>
                    <Col span={11}>
                      <Col span={24}>
                        <FormItem
                          label={"Hotel Name*"}
                          name={`hotels[${key}].name`}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input name={`hotels[${key}].name`} placeholder="Enter hotel name" />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label={"Address*"}
                          name={`hotels[${key}].address`}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input name={`hotels[${key}].address`} placeholder="Enter Address" />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label={"Website Link*"}
                          name={`hotels[${key}].website_link`}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input name={`hotels[${key}].website_link`} placeholder="Enter Link" />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label="Hotel Meetings & Celebrations Request For Proposal Link*"
                          name={`hotels[${key}].request_for_proposal_link`}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input
                            name={`hotels[${key}].request_for_proposal_link`}
                            placeholder="Enter Link"
                          />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label="Trip Advisor redirect link (Leave a Review)"
                          name={`hotels[${key}].tripadvisor_link`}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input
                            name={`hotels[${key}].tripadvisor_link`}
                            placeholder="Enter Redirect Link"
                          />
                        </FormItem>
                      </Col>
                      <Row>
                        <Col span={6}>
                          <FormItem
                            label={`Mobile No.${showAsterisk}`}
                            name={`hotels[${key}].country_code`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Row>
                              <Col span={24}>
                                <CountryCode
                                  name={`hotels[${key}].country_code`}
                                  defaultValue="🇵🇭 +63"
                                >
                                  {countryCodeOptions}
                                </CountryCode>
                              </Col>
                            </Row>
                          </FormItem>
                        </Col>
                        <Col span={18}>
                          <FormItem
                            name={`hotels[${key}].mobile_no`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Col span={24}>
                              <MobileNumber
                                name={`hotels[${key}].mobile_no`}
                                placeholder="xxx xxxxxxx"
                                type="number"
                              />
                            </Col>
                          </FormItem>
                        </Col>
                      </Row>
                      <Col span={24}>
                        <FormItem
                          label={`Enter location coordinates${showAsterisk}`}
                          name={`hotels[${key}].longitude`}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input
                            name={`hotels[${key}].longitude`}
                            placeholder="Enter Longitude (Eg. 14.234557)"
                          />
                        </FormItem>
                      </Col>
                    </Col>
                    <Col span={11} offset={2}>
                      <Col span={24}>
                        <FormItem
                          label="DJUBO Partner Code"
                          name={`hotels[${key}].partner_hotel_code`}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input
                            name={`hotels[${key}].partner_hotel_code`}
                            placeholder="Enter DJUBO Partner Code"
                            onChange={handleDjubo}
                          />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label="Hotel Meetings & Celebrations Link*"
                          name={`hotels[${key}].meetings_and_celebrations_link`}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input
                            name={`hotels[${key}].meetings_and_celebrations_link`}
                            placeholder="Enter Link"
                          />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label="Hotel Booking Engine Link*"
                          name={`hotels[${key}].booking_engine_link`}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input
                            name={`hotels[${key}].booking_engine_link`}
                            placeholder="Enter Link"
                          />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label="Hotel Group Booking Link*"
                          name={`hotels[${key}].group_booking_link`}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input
                            name={`hotels[${key}].group_booking_link`}
                            placeholder="Same link required for all hotels"
                          />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label={`Email Address${showAsterisk}`}
                          name={`hotels[${key}].email_address`}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input
                            type="email"
                            name={`hotels[${key}].email_address`}
                            placeholder="Enter email address"
                          />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          label={`Landline Number${showAsterisk}`}
                          name={`hotels[${key}].landline_no`}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input
                            name={`hotels[${key}].landline_no`}
                            placeholder="e.g +63212345678"
                            // minLength={12}
                            // maxLength={13}
                          />
                        </FormItem>
                      </Col>
                      <Col span={24}>
                        <FormItem
                          name={`hotels[${key}].latitude`}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input
                            style={{ marginTop: "32px" }}
                            name={`hotels[${key}].latitude`}
                            placeholder="Enter Latitude (Eg. 121.12345)"
                          />
                        </FormItem>
                      </Col>
                    </Col>
                  </Row>
                  <Divider dashed={true} />
                  <Collapse
                    bordered={false}
                    defaultActiveKey={["1"]}
                    expandIconPosition="right"
                    ghost
                  >
                    <StyledPanel header="Search Engine Optimization (SEO)" key="1">
                      <Row>
                        <Col span={24}>
                          <Collapse>
                            <StyledInnerPanel header="BASIC TAGS" key="1">
                              <Row>
                                <Col span={11}>
                                  <Col span={24}>
                                    <FormItem
                                      label="Page Title"
                                      name={`hotels[${key}].page_title`}
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      tooltip={{
                                        title:
                                          "The text to display in the title bar of a visitor's web browser when they view this page. This meta tag may also be used as the title of the page when a visitor bookmars or favorites this page, or as the page title in a search engine result. It is common to append '[site:name]' to the end of this, so the site's name is automatically added. Title should contain maximum 55-65 characters long, including spaces.",
                                        icon: <InfoCircleFilled style={{ color: "#144480" }} />,
                                      }}
                                    >
                                      <Input
                                        name={`hotels[${key}].page_title`}
                                        placeholder="[node:title][current-page:pager][site:name]"
                                      />
                                    </FormItem>
                                  </Col>
                                  <Col span={24}>
                                    <FormItem
                                      label="Description"
                                      name={`hotels[${key}].seo_description`}
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      tooltip={{
                                        title:
                                          "A brief and concise summary of the page's content, with 150 characters or less. Where as the description meta tag may be used by search engines to display a snippet about the page in search results, the abstract tag is used by engines to archive a summary about the page. This will be truncated to a maximum of 380 characters.",
                                        icon: <InfoCircleFilled style={{ color: "#144480" }} />,
                                      }}
                                    >
                                      <Input
                                        name={`hotels[${key}].seo_description`}
                                        placeholder="[node:summary]"
                                      />
                                    </FormItem>
                                  </Col>
                                </Col>
                                <Col span={11} offset={2}>
                                  <Col span={24}>
                                    <FormItem
                                      label="Keywords"
                                      name={`hotels[${key}].seo_keywords`}
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      tooltip={{
                                        title:
                                          "A comma-separate list of keywords about the page. This meta tag is no longer supported by most search engines anymore.",
                                        icon: <InfoCircleFilled style={{ color: "#144480" }} />,
                                      }}
                                    >
                                      <Input
                                        name={`hotels[${key}].seo_keywords`}
                                        placeholder="Enter Content"
                                      />
                                    </FormItem>
                                  </Col>
                                </Col>
                              </Row>
                            </StyledInnerPanel>
                          </Collapse>
                        </Col>
                      </Row>
                    </StyledPanel>
                  </Collapse>
                  <Divider dashed={true} />
                  <Collapse
                    bordered={false}
                    defaultActiveKey={["1"]}
                    expandIconPosition="right"
                    ghost
                  >
                    <StyledPanel header="Hotel Information" key="1">
                      <Row gutter={[0, 16]}>
                        <Col span={24}>
                          <FormItem
                            label={`Hotel Description${showAsterisk}`}
                            name={`hotels[${key}].description`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Richtext2
                              value={DOMPurify.sanitize(values.hotels[key].description)}
                              placeholder="Enter Content"
                              onChange={(e) => {
                                if (e === "<p><br></p>") {
                                  setFieldValue(`hotels[${key}].description`, "");
                                } else setFieldValue(`hotels[${key}].description`, e);
                              }}
                              errors={errors.description}
                            />
                          </FormItem>
                        </Col>
                        <Col span={24}>
                          <FormItem
                            label={`Hotel Policies${showAsterisk}`}
                            name={`hotels[${key}].policies`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Richtext2
                              value={DOMPurify.sanitize(values.hotels[key].policies)}
                              placeholder="Enter Content"
                              onChange={(e) => {
                                if (e === "<p><br></p>") {
                                  setFieldValue(`hotels[${key}].policies`, "");
                                } else setFieldValue(`hotels[${key}].policies`, e);
                              }}
                              errors={errors.policies}
                            />
                          </FormItem>
                        </Col>
                        <Col span={24}>
                          <FormItem
                            label={`Facilities and Amenities${showAsterisk}`}
                            name={`hotels[${key}].facilities_and_amenities`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Richtext2
                              value={DOMPurify.sanitize(values.hotels[key].facilities_and_amenities)}
                              placeholder="Enter Content"
                              onChange={(e) => {
                                if (e === "<p><br></p>") {
                                  setFieldValue(`hotels[${key}].facilities_and_amenities`, "");
                                } else setFieldValue(`hotels[${key}].facilities_and_amenities`, e);
                              }}
                              errors={errors.facilities_and_amenities}
                            />
                          </FormItem>
                        </Col>
                        <Col span={24}>
                          <FormItem
                            label={`Dining${showAsterisk}`}
                            name={`hotels[${key}].dining`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Richtext2
                              value={DOMPurify.sanitize(values.hotels[key].dining)}
                              placeholder="Enter Content"
                              onChange={(e) => {
                                if (e === "<p><br></p>") {
                                  setFieldValue(`hotels[${key}].dining`, "");
                                } else setFieldValue(`hotels[${key}].dining`, e);
                              }}
                              errors={errors.dining}
                            />
                          </FormItem>
                        </Col>
                        <Col span={24}>
                          <FormItem
                            label={`Nearby Attractions${showAsterisk}`}
                            name={`hotels[${key}].nearby_attractions`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Richtext2
                              value={DOMPurify.sanitize(values.hotels[key].nearby_attractions)}
                              placeholder="Enter Content"
                              onChange={(e) => {
                                if (e === "<p><br></p>") {
                                  setFieldValue(`hotels[${key}].nearby_attractions`, "");
                                } else setFieldValue(`hotels[${key}].nearby_attractions`, e);
                              }}
                              errors={errors.nearby_attractions}
                            />
                          </FormItem>
                        </Col>
                      </Row>
                    </StyledPanel>
                  </Collapse>
                  <Divider dashed={true} />
                  <Collapse
                    bordered={false}
                    defaultActiveKey={["1"]}
                    expandIconPosition="right"
                    ghost
                  >
                    <StyledPanel header="App Footnote" key="1">
                      <Row>
                        <Col span={24}>
                          <FormItem
                            label="Add app footnote title"
                            name={`hotels[${key}].footnote_title`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input
                              name={`hotels[${key}].footnote_title`}
                              placeholder="Enter footnote title"
                            />
                          </FormItem>
                        </Col>
                        <Col span={24}>
                          <FormItem
                            label="Add app footnote"
                            name={`hotels[${key}].footnote_body`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Richtext2
                              value={DOMPurify.sanitize(values.hotels[key].footnote_body)}
                              placeholder="Enter Content"
                              onChange={(e) => {
                                if (e === "<p><br></p>") {
                                  setFieldValue(`hotels[${key}].footnote_body`, "");
                                } else setFieldValue(`hotels[${key}].footnote_body`, e);
                              }}
                              errors={errors.footnote_body}
                            />
                          </FormItem>
                        </Col>
                      </Row>
                    </StyledPanel>
                  </Collapse>
                  <Divider dashed={true} />
                  <Collapse
                    bordered={false}
                    defaultActiveKey={["1"]}
                    expandIconPosition="right"
                    ghost
                  >
                    <StyledPanel header="Payment Options" key="1">
                      <Row>
                        <Col span={24}>
                          <StyledTabs tabBarGutter={0} type="card" tabBarStyle={{ margin: "0" }}>
                            <Tabs.TabPane tab="Website" key="1">
                              <ContentContainer style={{ margin: "0", borderRadius: "0" }}>
                                <Row>
                                  <label style={{ fontWeight: 700 }}>
                                    Pay Online{showAsterisk}
                                  </label>
                                </Row>
                                <Row style={{ marginTop: "10px", lineHeight: "16px" }}>
                                  <Col span={6}>
                                    <small style={{ color: "#808080" }}>
                                      Toggle to enable or disable payment option at the booking page
                                    </small>
                                  </Col>
                                  <Col span={6} offset={12}>
                                    <FormItem name={`hotels[${key}].payment_options[0].status`}>
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <PayToggle
                                          name={`hotels[${key}].payment_options[0].status`}
                                        />
                                        <label
                                          style={{
                                            color: `${
                                              item.payment_options && item.payment_options[0].status
                                                ? "#73c531"
                                                : "#1b467f"
                                            }`,
                                            marginLeft: "20px",
                                          }}
                                        >
                                          {item.payment_options && item.payment_options[0].status
                                            ? "Enabled"
                                            : "Disabled"}
                                        </label>
                                      </div>
                                    </FormItem>
                                  </Col>
                                </Row>
                                <Col span={13}>
                                  <FormItem
                                    name={`hotels[${key}].payment_options[0].merchant_name`}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <Input
                                      name={`hotels[${key}].payment_options[0].merchant_name`}
                                      placeholder={`Merchant name${showAsterisk}`}
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={13}>
                                  <FormItem
                                    name={`hotels[${key}].payment_options[0].merchant_id`}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <Input
                                      name={`hotels[${key}].payment_options[0].merchant_id`}
                                      placeholder={`Merchant ID${showAsterisk}`}
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={13}>
                                  <FormItem
                                    name={`hotels[${key}].payment_options[0].shared_key`}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <Input
                                      name={`hotels[${key}].payment_options[0].shared_key`}
                                      placeholder={`Shared Key${showAsterisk}`}
                                    />
                                  </FormItem>
                                </Col>
                                <Divider />
                                <Row>
                                  <label style={{ fontWeight: 700 }}>Pay at Hotel</label>
                                </Row>
                                <Row style={{ margin: "10px 0 20px 0", lineHeight: "16px" }}>
                                  <Col span={6}>
                                    <small style={{ color: "#808080" }}>
                                      Toggle to enable or disable payment option at the booking page
                                    </small>
                                  </Col>
                                  <Col span={6} offset={12}>
                                    <FormItem
                                      name={`hotels[${key}].payment_options[0].pay_at_hotel`}
                                    >
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <PayToggle
                                          name={`hotels[${key}].payment_options[0].pay_at_hotel`}
                                        />
                                        <label
                                          style={{
                                            color: `${
                                              item.payment_options &&
                                              item.payment_options[0].pay_at_hotel
                                                ? "#73c531"
                                                : "#1b467f"
                                            }`,
                                            marginLeft: "20px",
                                          }}
                                        >
                                          {item.payment_options &&
                                          item.payment_options[0].pay_at_hotel
                                            ? "Enabled"
                                            : "Disabled"}
                                        </label>
                                      </div>
                                    </FormItem>
                                  </Col>
                                </Row>
                              </ContentContainer>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Mobile App" key="2">
                              <ContentContainer style={{ margin: "0", borderRadius: "0" }}>
                                <Row>
                                  <label style={{ fontWeight: 700 }}>
                                    Pay Online{showAsterisk}
                                  </label>
                                </Row>
                                <Row style={{ marginTop: "10px", lineHeight: "16px" }}>
                                  <Col span={6}>
                                    <small style={{ color: "#808080" }}>
                                      Toggle to enable or disable payment option at the booking page
                                    </small>
                                  </Col>
                                  <Col span={6} offset={12}>
                                    <FormItem name={`hotels[${key}].payment_options[1].status`}>
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <PayToggle
                                          name={`hotels[${key}].payment_options[1].status`}
                                        />
                                        <label
                                          style={{
                                            color: `${
                                              item.payment_options && item.payment_options[1].status
                                                ? "#73c531"
                                                : "#1b467f"
                                            }`,
                                            marginLeft: "20px",
                                          }}
                                        >
                                          {item.payment_options && item.payment_options[1].status
                                            ? "Enabled"
                                            : "Disabled"}
                                        </label>
                                      </div>
                                    </FormItem>
                                  </Col>
                                </Row>
                                <Col span={13}>
                                  <FormItem
                                    name={`hotels[${key}].payment_options[1].merchant_name`}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <Input
                                      name={`hotels[${key}].payment_options[1].merchant_name`}
                                      placeholder={`Merchant name${showAsterisk}`}
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={13}>
                                  <FormItem
                                    name={`hotels[${key}].payment_options[1].merchant_id`}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <Input
                                      name={`hotels[${key}].payment_options[1].merchant_id`}
                                      placeholder={`Merchant ID${showAsterisk}`}
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={13}>
                                  <FormItem
                                    name={`hotels[${key}].payment_options[1].shared_key`}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <Input
                                      name={`hotels[${key}].payment_options[1].shared_key`}
                                      placeholder={`Shared Key${showAsterisk}`}
                                    />
                                  </FormItem>
                                </Col>
                                <Divider />
                                <Row>
                                  <label style={{ fontWeight: 700 }}>Pay at Hotel</label>
                                </Row>
                                <Row style={{ margin: "10px 0 20px 0", lineHeight: "16px" }}>
                                  <Col span={6}>
                                    <small style={{ color: "#808080" }}>
                                      Toggle to enable or disable payment option at the booking page
                                    </small>
                                  </Col>
                                  <Col span={6} offset={12}>
                                    <FormItem
                                      name={`hotels[${key}].payment_options[1].pay_at_hotel`}
                                    >
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <PayToggle
                                          name={`hotels[${key}].payment_options[1].pay_at_hotel`}
                                        />
                                        <label
                                          style={{
                                            color: `${
                                              item.payment_options &&
                                              item.payment_options[1].pay_at_hotel
                                                ? "#73c531"
                                                : "#1b467f"
                                            }`,
                                            marginLeft: "20px",
                                          }}
                                        >
                                          {item.payment_options &&
                                          item.payment_options[1].pay_at_hotel
                                            ? "Enabled"
                                            : "Disabled"}
                                        </label>
                                      </div>
                                    </FormItem>
                                  </Col>
                                </Row>
                              </ContentContainer>
                            </Tabs.TabPane>
                          </StyledTabs>
                        </Col>
                      </Row>
                    </StyledPanel>
                  </Collapse>
                </ManageHotelContainer>
              </Col>
            );
          })}
        <Col span={24}>
          <ButtonWrapper>
            <StyledButton
              type="dashed"
              onClick={() =>
                event.push({
                  country_code: "+63",
                  payment_options: [
                    { platform: "Website", status: true },
                    { platform: "Mobile", status: true },
                  ],
                  uploaded_hotel_photos: [{ url: "" }],
                })
              }
            >
              <AddHotelContainer>
                <PlusCircleFilled
                  style={{ fontSize: "2rem", color: "var(--color-general-blue-gray)" }}
                />
                <span>Add Hotel</span>
              </AddHotelContainer>
            </StyledButton>
          </ButtonWrapper>
        </Col>
      </>
    );
  };

  return (
    <Row gutter={[20, 20]}>
      <FieldArray name="hotels" render={(event) => renderItems({ event, values })} />
    </Row>
  );
};

export default ManageHotels;
