import React from "react";

import SectionOne from "./section1";
import SectionTwo from "./section2";
import SectionThree from "./section3";
import SectionFour from "./section4";
import SectionFive from "./section5";
import SectionSix from "./section6";
import SectionSeven from "./section7";
import SectionEight from "./section8";

const SectionTemplate = (
  { fields, type },
  index,
  setFieldValue,
  fieldsArrayEvent,
  values,
  uploadComplete,
  setUploadStatus,
  providedRef,
  provided,
  errors
) => {
  const props = {
    index,
    setFieldValue,
    fields,
    fieldsArrayEvent,
    values,
    uploadComplete,
    setUploadStatus,
    providedRef,
    provided,
    errors,
  };

  switch (type) {
    case "LEFT_SIDE":
      return <SectionOne {...props} />;

    case "CENTER_TOP":
      return <SectionTwo {...props} />;

    case "CENTER_BOTTOM":
      return <SectionThree {...props} />;

    case "RIGHT_SIDE":
      return <SectionFour {...props} />;

    case "LEFT_ALIGN":
      return <SectionFive {...props} />;

    case "CENTER_ALIGN":
      return <SectionSix {...props} />;

    case "RIGHT_ALIGN":
      return <SectionSeven {...props} />;

    case "FEATURED_VIDEO":
      return <SectionEight {...props} />;

    default:
      return "";
  }
};

export default SectionTemplate;
