import React from "react";
import { Row, Col, Button, Layout } from "antd";
import { Form, FormItem, Input } from "formik-antd";

import FeaturedImage from "../../assets/images/featured_image.png";
import Logo from "../../assets/images/logos/logo.svg";

import {
  Base,
  FirstColumn,
  SecondColumn,
  Welcome,
  LoginInstruction,
  Copyright,
  PoweredBy,
  Portal,
} from "./styled";

interface Props {
  formikBag: any;
  isAccessDenied?: boolean;
  loading?: boolean;
}

const { Sider } = Layout;

const Forms: React.FC<Props> = ({ formikBag, isAccessDenied, loading }) => {
  const { submitForm, errors, isValid, dirty } = formikBag;
  return (
    <>
      <Form>
        <Base>
          <Layout className="login">
            <Sider className="sider-form">
              <FirstColumn>
                <div>
                  <img src={Logo} alt="Robinsons Hotel & Resorts" />
                </div>
                <div>
                  <Welcome>Welcome!</Welcome>
                  <LoginInstruction>Login to your account</LoginInstruction>
                  <div>
                    <Row>
                      <Col span={15}>
                        <Row>
                          <Col span={24}>
                            <FormItem
                              name={`email`}
                              labelCol={{ span: 24 }}
                              wrapperCol={{ span: 24 }}
                            >
                              <Input name={`email`} type="email" placeholder="Email" />
                            </FormItem>
                          </Col>
                          <Col span={24}>
                            <FormItem
                              name={`password`}
                              labelCol={{ span: 24 }}
                              wrapperCol={{ span: 24 }}
                            >
                              <Input.Password name={`password`} placeholder="Password" />
                            </FormItem>
                            {isAccessDenied && (
                              <div
                                style={{
                                  color: "#ff4d4f",
                                  fontSize: 14,
                                  position: errors.password ? "relative" : "absolute",
                                  top: errors.password ? "0" : "1.95rem",
                                }}
                              >
                                Oh no! Email and password does not match!
                              </div>
                            )}
                          </Col>
                          <Col span={24}>
                            <Button
                              className="login-btn"
                              disabled={!isValid || !dirty}
                              block
                              type="primary"
                              loading={loading}
                              onClick={() => submitForm()}
                            >
                              LOGIN
                            </Button>
                          </Col>
                          <Col span={24}>
                            <Button type="text" danger className="forgot-btn">
                              Forgot Password
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div style={{ position: "absolute", bottom: "1.5em" }}>
                  <Copyright>© 2021 Robinsons Hotels and Resorts</Copyright>
                </div>
              </FirstColumn>
            </Sider>
            <Sider className="sider-bg">
              <SecondColumn>
                <Portal>Content Management Portal</Portal>
                <div>
                  <img src={FeaturedImage} alt="featured" />
                </div>
                <PoweredBy style={{ position: "absolute", bottom: "1.5em", right: "1.5em" }}>
                  Powered by White Cloak Technologies Inc.
                </PoweredBy>
              </SecondColumn>
            </Sider>
          </Layout>
        </Base>
      </Form>
    </>
  );
};

export default Forms;
