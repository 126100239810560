import React, { useState, useContext } from "react";
import axios from "axios";
import { ActionButtons } from "./styled";
import CustomModal from "@/components/CustomModal";
import { Context } from "../../context";

const DeployContainer = ({ isPromotion }) => {
  const {
    dispatch: { storeIsDeployed },
  } = useContext(Context);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [showModalError, setShowModalError] = useState<boolean>(false);
  const [bodyMessageError, setBodyMessageError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [isRetryAllowed, setIsRetryAllowed] = useState<boolean>(false);
  const [isDeploying, setIsDeploying] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [okTxt, setOkTxt] = useState("OK");
  const [isPublish, setIsPublish] = useState<boolean>(false);
  const [bodyMessageSuccess, setBodyMessageSuccess] = useState("");

  const handleDeploy = () => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage("Are you ready to deploy all saved changes?");
    setOkTxt("Deploy");
    setIsPublish(true);
  };
  const deploy = () => {
    const subDirectory = isPromotion ? "promotions/deploy" : "deploy";
    const headersData = isPromotion
      ? {
          authorization: `Bearer ${sessionStorage.getItem("act")}` || null,
          "Content-Type": "application/x-www-form-urlencoded",
        }
      : {
          authorization: `Bearer ${sessionStorage.getItem("act")}` || null,
        };
    const BASE_URL = `${process.env.REACT_APP_CONTENT_SERVICE}/${subDirectory}`;
    setIsDeploying(true);

    axios({
      method: "POST",
      url: BASE_URL,
      headers: headersData,
    })
      .then((res) => {
        storeIsDeployed(true);
        setIsDeploying(false);
        setShowModal(false);
        setShowModalSuccess(true);
        setBodyMessageSuccess(
          "The content has been successfully deployed. Note: 5-10 mins before fully visible in website/mobile app"
        );
      })
      .catch((err) => {
        setIsDeploying(false);
        if (err.response) {
          setIsDeploying(false);
          setShowModal(false);
          setShowModalError(true);
          setTitleError("Oops!");
          setBodyMessageError("It looks like there's an ongoing deployment. Try again later.");
          setIsRetryAllowed(false);
        } else {
          setIsDeploying(false);
          setShowModal(false);
          setShowModalError(true);
          setTitleError("Oh Snap!");
          setBodyMessageError(
            "We encountered an error while trying to deploy. Please try again. Note: if you continue to experience this please check your internet connection or contact your IT for support"
          );
          setIsRetryAllowed(true);
        }
      });
  };
  return (
    <>
      <ActionButtons type="primary" onClick={handleDeploy} loading={isDeploying}>
        Deploy
      </ActionButtons>
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showModal}
        onOk={deploy}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon
        titleMessage="Success!"
        visible={showModalSuccess}
        onOk={() => setShowModalSuccess(false)}
        onCancel={() => setShowModalSuccess(false)}
        message={bodyMessageSuccess}
        okText={isPublish ? "Great!" : "OK"}
      />
      <CustomModal
        noCancelBtn={!isRetryAllowed}
        closable={false}
        maskClosable={false}
        isErrorIcon
        titleMessage={titleError}
        visible={showModalError}
        onOk={() => {
          setShowModalError(false);
          if (isRetryAllowed) deploy();
        }}
        onCancel={() => setShowModalError(false)}
        message={bodyMessageError}
        okText={isRetryAllowed ? "Retry" : "Okay"}
      />
    </>
  );
};

export default DeployContainer;
