import React, { useState, useEffect } from "react";
import { Col, Dropdown, Row, Spin, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroller";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ActionsMenu from "@/components/ActionsMenu";
import { lowerCase } from "lodash";
import ContentContainer from "@/components/ContentContainer";
import moment from "moment";
import NoResultFound from "../../assets/images/icons/no-result-found-icon.svg";
import { StyledSearch, DemoCont, StyledButton, StyledGroup, NoResultFoundWrap } from "./styled";
import { capitalizeFirstLetter } from "../../utilities/string-format";

interface Props {
  actions: Array<any>;
  loading?: boolean;
  headerColumns?: any;
  handleData: React.Dispatch<any>;
  data: any;
  handleMenu?: (menuItem: string, id: string) => void;
  handleAddBtn?: () => void;
  handleInfiniteOnLoad?: any;
  hasMore?: boolean;
  onDragEnd?: any;
  searchPlaceholder?: string;
  addLabelText?: string;
  handleSelectedRow?: (id: string, slug: string, brandName?: string) => void;
  dataTypes: Array<any>;
  selectedData: any;
}

const CustomDashboard: React.FC<Props> = ({
  actions,
  loading,
  headerColumns,
  data,
  selectedData,
  handleData,
  handleMenu,
  handleAddBtn,
  handleInfiniteOnLoad,
  hasMore,
  onDragEnd,
  addLabelText,
  handleSelectedRow,
  searchPlaceholder,
  dataTypes,
}) => {
  const [dataTypeLabel, setDataTypeLabel] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string[]>(dataTypes.map((type) => type.value));
  const [storeSelectedData, setStoreSelectedData] = useState<any>([]);
  const [searchInput, setSearchInput] = useState<string>("");

  const onClickRow = (id, slug, brandName) => {
    handleSelectedRow && handleSelectedRow(id, slug, brandName);
  };

  const onSearchInput = (e) => {
    const searchValue = e.toLowerCase();
    const newData = storeSelectedData.filter((item) => {
      const guestId = item.guest_id ? item.guest_id.toLowerCase() : item.id.toLowerCase();
      const name = item.first_name ? item.first_name.toLowerCase() : item.__typename.toLowerCase();
      const lastName = item.last_name
        ? item.last_name.toLowerCase()
        : item.__typename.toLowerCase();
      const platform = "mobile";
      return (
        guestId.includes(searchValue) ||
        name.includes(searchValue) ||
        lastName.includes(searchValue) ||
        platform.includes(searchValue)
      );
    });

    handleData(newData);
  };

  const onChangeSearchInput = (e) => {
    const { value } = e.target;
    setSearchInput(value);
    if (value === "") {
      handleData(
        data.filter((item) => {
          return selectedType.includes(item.__typename);
        })
      );
    } else {
      onSearchInput(value);
    }
  };

  const onChangeDataTypes = (checktype) => {
    setSelectedType([...checktype]);
  };

  useEffect(() => {
    setStoreSelectedData(data);
  }, [data]);

  useEffect(() => {
    if (selectedType.length === dataTypes.length) {
      handleData(data);
    }

    const placeholder = dataTypes
      .filter((item) => selectedType.includes(item.value))
      .map((item) => item.label)
      .join(", ");

    setDataTypeLabel(placeholder);

    const filteredData = data.filter((item) => {
      return selectedType.includes(item.__typename);
    });

    handleData(filteredData);
    setStoreSelectedData(filteredData);
    setSearchInput("");
  }, [selectedType]);

  const sampleMenu = (
    <Menu>
      <Menu.Item key="data types" disabled>
        <StyledGroup
          options={dataTypes}
          defaultValue={dataTypes.map((type) => type.value)}
          onChange={onChangeDataTypes}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <ContentContainer>
        <Row justify="space-between" style={{ marginBottom: "2rem", paddingTop: "1rem" }}>
          <Col span={7}>
            <StyledSearch
              placeholder={searchPlaceholder}
              onSearch={onSearchInput}
              onChange={onChangeSearchInput}
              enterButton
              allowClear
              value={searchInput}
            />
          </Col>

          <Col span={8} style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            <span style={{ marginRight: "1rem", width: "7rem", color: "black" }}>
              Select Data Type
            </span>
            <Dropdown overlay={sampleMenu}>
              <StyledButton>
                <span className="label">{dataTypeLabel}</span> <DownOutlined />
              </StyledButton>
            </Dropdown>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Row>
            <Col span={24}>
              <DemoCont>
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={handleInfiniteOnLoad}
                  // hasMore={!loader && hasMore}
                  hasMore={hasMore}
                  useWindow={false}
                >
                  <DragDropContext onDragEnd={onDragEnd}>
                    <div className="ant-table-wrapper">
                      <div className="ant-spin-nested-loading">
                        <div className="ant-spin-container">
                          <div className="ant-table" data-index="table">
                            <div className="ant-table-container">
                              <div className="ant-table-content">
                                <table style={{ tableLayout: "fixed" }}>
                                  <colgroup></colgroup>
                                  <thead className="ant-table-thead">
                                    <tr
                                      className="ant-table-row"
                                      style={{ position: "sticky", top: 0, zIndex: 1 }}
                                    >
                                      {headerColumns.map((item, index) => (
                                        <th className="ant-table-cell" key={`title_${index}`}>
                                          {item.title}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <Droppable droppableId="droppable">
                                    {(provided) => (
                                      <tbody className="ant-table-tbody" ref={provided.innerRef}>
                                        {selectedData.map((item, index) => (
                                          <Draggable
                                            key={index}
                                            draggableId={`dragId_${index}`}
                                            index={index}
                                            isDragDisabled={true}
                                          >
                                            {(provided, snapshots) => (
                                              <tr
                                                key={index}
                                                className={`ant-table-row tr-draggable ${
                                                  lowerCase(item.status) === "deleted" &&
                                                  "tr-deleted"
                                                }`}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                onClick={() =>
                                                  onClickRow(item.id, item.slug, item.name)
                                                }
                                              >
                                                <td
                                                  className="ant-table-cell"
                                                  style={{ color: "black" }}
                                                >
                                                  {item.guest_id || item.id}
                                                </td>
                                                <td
                                                  className="ant-table-cell"
                                                  style={{ color: "black" }}
                                                >
                                                  {item.first_name
                                                    ? `${item.first_name} ${item.last_name}`
                                                    : "Anonymous Guest"}
                                                </td>
                                                <td
                                                  className="ant-table-cell"
                                                  style={{ color: "#7B95B6" }}
                                                >
                                                  {capitalizeFirstLetter(item.platform) || "Mobile"}
                                                </td>
                                                <td
                                                  className="ant-table-cell"
                                                  style={{ color: "gray" }}
                                                >
                                                  {moment(item.updated_at).format(
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  ) ||
                                                    moment(item.last_logged_in).format(
                                                      "YYYY-MM-DD HH:mm:ss"
                                                    )}
                                                </td>
                                                <td
                                                  className="ant-table-cell"
                                                  style={{ color: "#7B95B6" }}
                                                >
                                                  {item.lastEditedBy || "N/A"}
                                                </td>
                                                <td
                                                  className="ant-table-cell"
                                                  style={{ color: "black" }}
                                                >
                                                  {item.status || "Active"}
                                                </td>

                                                <td
                                                  className="ant-table-cell"
                                                  onClick={(e) => e.stopPropagation()}
                                                >
                                                  {item.guest_id && (
                                                    <ActionsMenu
                                                      handleMenu={handleMenu}
                                                      data={item}
                                                      actions={actions}
                                                    />
                                                  )}
                                                </td>
                                              </tr>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </tbody>
                                    )}
                                  </Droppable>
                                </table>
                                {selectedData.length === 0 && loading === false ? (
                                  <NoResultFoundWrap className="no-results">
                                    <img src={NoResultFound} alt="No Results Found" />
                                    <span>No results Found</span>
                                  </NoResultFoundWrap>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DragDropContext>
                </InfiniteScroll>
              </DemoCont>
            </Col>
          </Row>
        </Spin>
      </ContentContainer>
    </div>
  );
};

export default CustomDashboard;
