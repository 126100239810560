import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import PromotionsList from "./components/promotions/promotions-list";
import PromotionsView from "./components/promotions/promotions-view";
import PromotionsForm from "./components/promotions/promotions-form";
import NotificationsList from "./components/notifications/notifications-list";
import NotificationsForm from "./components/notifications/notifications-form";
import NotificationsView from "./components/notifications/notifications-view";

const NotificationSettings: React.FC = () => {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.path}/promotions`}
          render={(props: any) => <PromotionsList {...props} />}
        />
        <Route
          exact
          path={`${match.path}/promotions/add-promotion/`}
          render={(props: any) => <PromotionsForm {...props} />}
        />
        <Route
          exact
          path={`${match.path}/promotions/edit-promotion/:pageId`}
          render={(props: any) => <PromotionsForm {...props} />}
        />
        <Route
          exact
          path={`${match.path}/promotions/view-promotion/:pageId`}
          render={(props: any) => <PromotionsView {...props} />}
        />
        <Route
          exact
          path={`${match.path}/notifications`}
          render={(props: any) => <NotificationsList {...props} />}
        />
        <Route
          exact
          path={`${match.path}/notifications/add-notification/`}
          render={(props: any) => <NotificationsForm {...props} />}
        />
        <Route
          exact
          path={`${match.path}/notifications/view-notification/:pageId`}
          render={(props: any) => <NotificationsView {...props} />}
        />
        <Route
          exact
          path={`${match.path}/notifications/edit-notification/:pageId`}
          render={(props: any) => <NotificationsForm {...props} />}
        />
      </Switch>
    </>
  );
};

export default NotificationSettings;
