import React from "react";
import { useLocation, Link } from "react-router-dom";
import { startCase, toLower } from "lodash";
import { Breadcrumb, Row, Col } from "antd";

import DeployContainer from "../CustomDeploy";

import { Wrapper, StyledBreadcrumb, LabelTitle } from "./styled";

import { UUID_PATTERN } from "../../utilities/regex";

const BreadcrumbItem = Breadcrumb.Item;

const LayoutBreadcrumb: React.FC = () => {
  const location = useLocation();
  const isPromotion = location.pathname.split("/").includes("promotions");

  const shouldRenderDeploy = (location) => {
    const thePath = location.pathname.split("/").filter((item) => item !== "home");
    const lastItem = thePath.pop();
    const hasNumber = UUID_PATTERN.test(lastItem);
    const hasCustomerUser = location.pathname.split("/").indexOf("customer-user") >= 0;
    const hasRoleManagement = location.pathname.split("/").indexOf("role-management") >= 0;
    const hasCMSUsersManagement = location.pathname.split("/").indexOf("cms-user-management") >= 0;

    return hasNumber || hasCMSUsersManagement || hasCustomerUser || hasRoleManagement
      ? false
      : true;
  };

  const getLastItem = (location) => {
    if (location) {
      const thePath = location.pathname
        .split("/")
        .filter((item) => item !== "home")
        .map((item, index, originalArr) => {
          let title = item;
          let skipLast = false;
          const hasNumber = UUID_PATTERN.test(item);
          const management = originalArr.filter((m) => m.endsWith("-management"));
          const cms_user_view = originalArr.filter((m) => m.startsWith("view-user"));
          const cms_user_add = originalArr.filter((m) => m.startsWith("add-user"));
          const cms_user_edit = originalArr.filter((m) => m.startsWith("edit-user"));

          if (item !== "") {
            let featureName = item.split("-").join(" ");
            title = `${startCase(toLower(featureName))}`;
            if (item === "rhr-hero-banner") {
              title = "RHR Hero Banner";
            } else if (item === "promos-and-packages") {
              title = "Promos and Packages";
            } else if (item === "rhr-informations") {
              title = "RHR Information";
            } else if (item === "edit-rhr-informations") {
              title = "Edit RHR Information";
            } else if (item === "cms-user-management") {
              title = "CMS User Management";
            }
          }
          if (management.length > 0) {
            if (index > 0 && [management[0], "add"].every((s) => originalArr.includes(s))) {
              if (item === "add") {
                let featureName = management[0]
                  .split("-")
                  .filter((_f, i) => i === 0)
                  .join(" ");
                if (featureName === "brand") {
                  featureName = "New Brand";
                }

                title = `Add ${startCase(toLower(featureName))}`;
              } else if (index === originalArr.length - 1) {
                skipLast = true;
              }
            }
            if (index > 0 && [management[0], "edit"].every((s) => originalArr.includes(s))) {
              if (item === "edit") {
                let featureName = management[0]
                  .split("-")
                  .filter((_f, i) => i === 0)
                  .join(" ");
                if (featureName === "brand") {
                  featureName = "Brand";
                }

                title = `Edit ${startCase(toLower(featureName))}`;
              } else if (index === originalArr.length - 1) {
                skipLast = true;
              }
            }
          }

          if (cms_user_view.length) {
            title = "View User";
          }

          if (cms_user_add.length) {
            title = "Add User";
          }

          if (cms_user_edit.length) {
            title = "Edit User";
          }

          return !hasNumber && !skipLast && title;
        });
      const lastItem =
        typeof thePath[thePath.length - 1] === "boolean"
          ? thePath[thePath.length - 2]
          : thePath[thePath.length - 1];
      return lastItem;
    }
  };

  const renderBreadcrumbItem = (location) => {
    if (location) {
      const breadcrumbItems = location.pathname
        .split("/")
        .filter((item) => item !== "home")
        .map((item, index, originalArr) => {
          let title = item;
          let skipLast = false;
          const hasNumber = UUID_PATTERN.test(item);
          const management = originalArr.filter((m) => m.endsWith("-management"));

          if (management.length > 0) {
            if (index > 0 && [management[0], "add"].every((s) => originalArr.includes(s))) {
              if (item === "add") {
                let featureName = management[0]
                  .split("-")
                  .filter((_f, i) => i === 0)
                  .join(" ");
                if (featureName === "brand") {
                  featureName = "New Brand";
                }

                title = `Add ${startCase(toLower(featureName))}`;
              } else if (index === originalArr.length - 1) {
                skipLast = true;
              }
            }
            if (index > 0 && [management[0], "edit"].every((s) => originalArr.includes(s))) {
              if (item === "edit") {
                let featureName = management[0]
                  .split("-")
                  .filter((_f, i) => i === 0)
                  .join(" ");
                if (featureName === "brand") {
                  featureName = "Brand";
                }

                title = `Edit ${startCase(toLower(featureName))}`;
              } else if (index === originalArr.length - 1) {
                skipLast = true;
              }
            }
          }

          const auth0_id = title.startsWith("auth0");

          if (auth0_id) {
            title = "User";
          }

          if (title === "rhr-informations") {
            title = "RHR Information";
          }

          if (title === "edit-rhr-informations") {
            title = "Edit RHR Information";
          }

          return (
            !hasNumber &&
            !skipLast && (
              <BreadcrumbItem>
                {!["home-page-management"].includes(item) ? (
                  <Link to={originalArr.slice(0, index + 1).join("/")}>
                    {index === 0
                      ? "Home"
                      : title === "rhr-hero-banner"
                      ? "RHR Hero Banner"
                      : title === "RHR Information"
                      ? "RHR Information"
                      : title === "Edit RHR Information"
                      ? "Edit RHR Information"
                      : startCase(toLower(title.replace(/-/g, " ")))}
                  </Link>
                ) : index === 0 ? (
                  "Home"
                ) : (
                  startCase(toLower(title.replace(/-/g, " ")))
                )}
              </BreadcrumbItem>
            )
          );
        });

      return breadcrumbItems;
    }
  };

  return (
    <Wrapper>
      <Row>
        <Col span="12">
          <StyledBreadcrumb>{renderBreadcrumbItem(location)}</StyledBreadcrumb>
          <LabelTitle>{getLastItem(location)}</LabelTitle>
        </Col>
        {shouldRenderDeploy(location) && (
          <Col
            span="12"
            style={{
              textAlign: "right",
              zIndex: 0,
              paddingTop: "25px",
              paddingRight: "20px",
            }}
          >
            <DeployContainer isPromotion={isPromotion} />
          </Col>
        )}
      </Row>
    </Wrapper>
  );
};

export default LayoutBreadcrumb;
