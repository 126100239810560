import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Button, Modal } from "antd";
import { Field } from "formik";
import { Form, FormItem, Input } from "formik-antd";

import ContentContainer from "@/components/ContentContainer";
import CustomButton from "@/components/CustomButton";
import CustomSelect from "@/components/CustomSelect";
import CustomModal from "@/components/CustomModal";
import CustomSwitch from "@/components/CustomSwitch";
import Richtext2 from "@/components/Richtext2";

import ContactInformationSection from "./field-array-sections/contact-information";
import FooterInfo from "./field-array-sections/footer-info";

import WarningIcon from "../../../../../assets/images/icons/ic_report.svg";

import {
  InstructionLabel,
} from "./styled";

interface Props {
  formikBag: any;
  loader?: boolean;
  landlineError?: boolean;
  mobileError?: boolean;
  emailError?: boolean;
}

const Forms: React.FC<Props> = ({ formikBag, loader, landlineError, mobileError, emailError }) => {
  const { values, submitForm, errors, setFieldValue } = formikBag;
  const { pageId } = useParams();

  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isModalVisibleLeaving, setIsModalVisibleLeaving] = useState<boolean>(false);
  const [isStatusEnable, setIsStatusEnable] = useState<boolean>(
    values.redirect_link_status && values.redirect_link_status
  );
  const enableText =
    values.redirect_link_status && values.redirect_link_status === true ? "Enabled" : "Disabled";

  const handleOk = () => {
    setIsStatusEnable(!isStatusEnable);
    setFieldValue("redirect_link_status", false);
    setIsModalVisible(false);
  };

  const handleOkLeaving = () => {
    setIsModalVisibleLeaving(false);
    history.push("/footer-management/rhr-informations");
  };

  const handleCancelLeaving = () => {
    setIsModalVisibleLeaving(false);
  };

  const handleEditConfirm = (submitForm) => {
    const btnProps: any = {
      size: "large",
      style: {
        width: "6em",
        textTransform: "uppercase",
      },
    };
    const onClickHandle = submitForm;

    Modal.confirm({
      title: (
        <>
          <div style={{ textAlign: "center" }}>
            <img src={WarningIcon} alt="" />
            <br />
            <h4 style={{ color: "var(--color-primary)", fontSize: 20, margin: "1em 0" }}>
              Save Changes?
            </h4>
            <div style={{ color: "var(--color-general-black)", lineHeight: 1.5, fontSize: 16 }}>
              Are you sure you want to save changes for footer information?
            </div>
          </div>
        </>
      ),
      icon: <></>,
      okText: "SAVE",
      cancelText: "CANCEL",
      cancelButtonProps: btnProps,
      okButtonProps: btnProps,
      onOk() {
        onClickHandle();
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Form>
        <Row>
          <InstructionLabel>* Required fields, do not leave this blank.</InstructionLabel>
        </Row>

        { landlineError ? <Row>
          <InstructionLabel>* Please insert a unique landline number.</InstructionLabel>
        </Row> : null }

        { mobileError ? <Row>
          <InstructionLabel>* Please insert a unique mobile number.</InstructionLabel>
        </Row> : null }

        { emailError ? <Row>
          <InstructionLabel>* Please insert a unique email address.</InstructionLabel>
        </Row> : null }

        <ContentContainer>
          <ContactInformationSection formikBag={formikBag} />
          <FooterInfo formikBag={formikBag} />
        </ContentContainer>

        <div style={{ display: "flex", justifyContent: "end", gap: "1rem" }}>
          <Col span={3}>
            <Button style={{ width: "100%" }} onClick={() => setIsModalVisibleLeaving(true)}>
              CANCEL
            </Button>
          </Col>
          <Col span={3}>
            <CustomButton
              style={{ width: "100%" }}
              type="primary"
              onClick={pageId ? () => handleEditConfirm(submitForm) : () => submitForm()}
            >
              SAVE
            </CustomButton>
          </Col>
        </div>
      </Form>

      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={pageId ? "Cancel Editing?" : "Leave this page?"}
        visible={isModalVisibleLeaving}
        onOk={handleOkLeaving}
        onCancel={handleCancelLeaving}
        message={
          pageId
            ? "Are you sure you want to cancel editing?"
            : "All unsaved data will be lost. Are you sure you want to leave this page?"
        }
        cancelText={pageId ? "No" : "Stay here"}
        okText="Yes"
        okButtonWidth="7.5em"
        cancelButtonWidth="7.5em"
      />
    </>
  );
};

export default Forms;
