import { useState, useEffect } from "react";
import { Formik } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import { Spin, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { has, snakeCase, isEmpty } from "lodash";
import * as crypto from "crypto";

import CustomModal from "@/components/CustomModal";
import Form from "./form";

import { CREATE_BRAND, GET_CATEGORY_LIST, MODIFY_BRAND, GET_BRAND_BYID } from "../../graphql";
import { validationSchema } from "./validations";
import generateId from "../../../../utilities/generate-id";
import getFilename from "../../../../utilities/get-filename";

const BrandManagementForm = () => {
  const history = useHistory();
  const params = useParams();

  const { data: categoryList } = useQuery(GET_CATEGORY_LIST);
  const [createBrand] = useMutation(CREATE_BRAND);
  const [modifyBrand] = useMutation(MODIFY_BRAND);
  const { data: pageData, loading } = useQuery(GET_BRAND_BYID, {
    skip: !params.pageId,
    fetchPolicy: "no-cache",
    variables: {
      id: params.pageId || "",
    },
  });

  const [brandCategoryList, setBrandCategoryList] = useState();

  const [brandName, setBrandName] = useState("");
  const [titleMessage, setTitleMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [reinitialize, setReinitialize] = useState(false);
  const [initialValues, setInitialValues] = useState({
    logo: null,
    name: "",
    category: "",
    short_description: "",
    long_description: "",
    redirect_link: "",
    redirect_link_status: true,
    status: "DRAFT",
    banner_feature_images: [],
    category_logo: null,
    carousel_image: null,
    page_title: "",
    seo_description: "",
    seo_keywords: "",
    landline_nos: [{ input: "" }],
    mobile_nos: [
      {
        input: "",
        country_code: "+63",
      },
    ],
    email_addresses: [{ input: "" }],
    social_media: {
      facebook: "",
      instagram: "",
      youtube: "",
      twitter: "",
      linkedin: "",
      kumu: "",
      viber_community: "",
      tiktok: "",
    },
    hotels: [
      {
        country_code: "+63",
        payment_options: [
          { platform: "Website", status: true },
          { platform: "Mobile", status: true },
        ],
        uploaded_hotel_photos: [{ url: "" }],
      },
    ],
    sections: [],
  });

  const handleOkSuccess = () => {
    history.push("/brand-management");
  };

  useEffect(() => {
    if (reinitialize) {
      setReinitialize(false);
    }
  }, [reinitialize]);

  useEffect(() => {
    if (params.pageId && has(pageData, "getBrandById")) {
      const data = pageData.getBrandById;
      const bName = pageData.getBrandById.name;
      const fields: any = initialValues;

      Object.keys(data).forEach((key, index) => {
        if (key === "logo") {
          const fileName = getFilename(data.logo);
          fields["logo"] = {
            keyObj: fileName,
            url: data.logo ? data.logo : "",
          };
        } else if (key === "category") {
          fields["category"] = data.category.id;
        } else if (key === "redirect_link_status") {
          fields["redirect_link_status"] = data.redirect_link_status === "ENABLED" ? true : false;
        } else if (key === "page_title") {
          fields["page_title"] = data.page_title;
        } else if (key === "seo_description") {
          fields["seo_description"] = data.seo_description;
        } else if (key === "seo_keywords") {
          fields["seo_keywords"] = data.seo_keywords;
        } else if (key === "hotels") {
          let hotelList: any = [];
          fields["hotels"] =
            data.hotels.length === 0
              ? []
              : data.hotels.map((res) => {
                  const decrypt = (sharedKey) => {
                    const encrypted = sharedKey;
                    const iv = encrypted.split(".")[0];
                    const text = encrypted.split(".")[1];
                    const secretKey = "c1BwqNDZjHHtpSDoAD40PCF1hRe3t3Xn";
                    const decipher = crypto.createDecipheriv("aes-256-cbc", secretKey, iv);
                    let decryptedKey = decipher.update(text, "hex", "utf8");
                    decryptedKey += decipher.final("utf8");

                    return decryptedKey;
                  };

                  hotelList.push(res);

                  return {
                    id: res.id ? res.id : "",
                    website_logo: res.website_logo ? { url: res.website_logo } : { url: "" },
                    app_logo: res.app_logo ? { url: res.app_logo } : { url: "" },
                    name: res.name ? res.name : "",
                    address: res.address ? res.address : "",
                    website_link: res.website_link ? res.website_link : "",
                    request_for_proposal_link: res.request_for_proposal_link
                      ? res.request_for_proposal_link
                      : "",
                    tripadvisor_link: res.tripadvisor_link ? res.tripadvisor_link : "",
                    country_code: res.country_code ? res.country_code : "",
                    mobile_no: res.mobile_no ? res.mobile_no.toString() : "",
                    longitude: res.longitude ? res.longitude.toString() : "",
                    latitude: res.latitude ? res.latitude.toString() : "",
                    partner_hotel_code: res.partner_hotel_code ? res.partner_hotel_code : "",
                    meetings_and_celebrations_link: res.meetings_and_celebrations_link
                      ? res.meetings_and_celebrations_link
                      : "",
                    booking_engine_link: res.booking_engine_link ? res.booking_engine_link : "",
                    group_booking_link: res.group_booking_link ? res.group_booking_link : "",
                    email_address: res.email_address ? res.email_address : "",
                    landline_no: res.landline_no ? `${res.landline_no.toString()}` : "",
                    page_title: res.page_title ? res.page_title : "",
                    seo_description: res.seo_description ? res.seo_description : "",
                    seo_keywords: res.seo_keywords ? res.seo_keywords : "",
                    description: res.description ? res.description : "",
                    policies: res.policies ? res.policies : "",
                    facilities_and_amenities: res.facilities_and_amenities
                      ? res.facilities_and_amenities
                      : "",
                    dining: res.dining ? res.dining : "",
                    nearby_attractions: res.nearby_attractions ? res.nearby_attractions : "",
                    footnote_title: res.footnote_title ? res.footnote_title : "",
                    footnote_body: res.footnote_body ? res.footnote_body : "",
                    payment_options: res.payment_options
                      ? [
                          {
                            id: res.payment_options[0].id || "",
                            platform: "WEBSITE",
                            merchant_name: res.payment_options[0].merchant_name || "",
                            merchant_id: res.payment_options[0].merchant_id || "",
                            shared_key: decrypt(res.payment_options[0].shared_key) || "",
                            status: res.payment_options[0].status === "ENABLED" ? true : false,
                            pay_at_hotel:
                              res.payment_options[0].pay_at_hotel === "ENABLED" ? true : false,
                          },
                          {
                            id: res.payment_options[1].id || "",
                            platform: "MOBILE",
                            merchant_name: res.payment_options[1].merchant_name || "",
                            merchant_id: res.payment_options[1].merchant_id || "",
                            shared_key: decrypt(res.payment_options[1].shared_key) || "",
                            status: res.payment_options[1].status === "ENABLED" ? true : false,
                            pay_at_hotel:
                              res.payment_options[1].pay_at_hotel === "ENABLED" ? true : false,
                          },
                        ]
                      : [
                          {
                            platform: "WEBSITE",
                            merchant_name: "",
                            merchant_id: "",
                            shared_key: "",
                            status: true,
                          },
                          {
                            platform: "MOBILE",
                            merchant_name: "",
                            merchant_id: "",
                            shared_key: "",
                            status: true,
                          },
                        ],
                    uploaded_hotel_photos: res.uploaded_hotel_photos
                      ? res.uploaded_hotel_photos
                      : [],
                  };
                });
        } else if (key === "landline_nos") {
          let landlineList: any = [];
          fields["landline_nos"] =
            data.landline_nos.length === 0
              ? [{ input: "" }]
              : data.landline_nos.map((res) => {
                  landlineList.push(res);
                  return {
                    id: res.id ? res.id : "",
                    input: res.input ? res.input : "",
                  };
                });
        } else if (key === "mobile_nos") {
          let mobileList: any = [];
          fields["mobile_nos"] =
            data.mobile_nos.length === 0
              ? [{ input: "", country_code: "+63" }]
              : data.mobile_nos.map((res) => {
                  mobileList.push(res);
                  return {
                    id: res.id ? res.id : "",
                    input: res.input ? res.input : "",
                    country_code: res.country_code ? res.country_code : "+63",
                  };
                });
        } else if (key === "email_addresses") {
          let emailList: any = [];
          fields["email_addresses"] =
            data.email_addresses.length === 0
              ? [{ input: "" }]
              : data.email_addresses.map((res) => {
                  emailList.push(res);
                  return {
                    id: res.id ? res.id : "",
                    input: res.input ? res.input : "",
                  };
                });
        } else if (key === "uploaded_media") {
          let bannerImages: any = [];
          data.uploaded_media.forEach((item, index) => {
            if (item.uploaded_media_type.name === "carousel_image") {
              const fileName = getFilename(item.url);
              fields["carousel_image"] = { keyObj: fileName, url: item.url };
            } else if (item.uploaded_media_type.name === "category_logo") {
              const fileName = getFilename(item.url);
              fields["category_logo"] = { keyObj: fileName, url: item.url };
            } else if (item.uploaded_media_type.name === "banner_feature_image") {
              const fileName = getFilename(item.url);
              bannerImages.push({ keyObj: fileName, url: item.url, uid: generateId() });
              fields["banner_feature_images"] = bannerImages;
            }
          });
        } else if (key === "social_media") {
          data.social_media.forEach((item) => {
            if (item.social_media_type.name === "facebook") {
              fields["social_media"].facebook = item.url;
            } else if (item.social_media_type.name === "instagram") {
              fields["social_media"].instagram = item.url;
            } else if (item.social_media_type.name === "youtube") {
              fields["social_media"].youtube = item.url;
            } else if (item.social_media_type.name === "twitter") {
              fields["social_media"].twitter = item.url;
            } else if (item.social_media_type.name === "linkedin") {
              fields["social_media"].linkedin = item.url;
            } else if (item.social_media_type.name === "tiktok") {
              fields["social_media"].tiktok = item.url;
            } else if (item.social_media_type.name === "kumu") {
              fields["social_media"].kumu = item.url;
            } else if (item.social_media_type.name === "viber_community") {
              fields["social_media"].viber_community = item.url;
            }
          });
        } else if (key === "sections") {
          fields["sections"] =
            data.sections.length === 0
              ? []
              : data.sections
                  .sort((item1, item2) => {
                    return item1.order - item2.order;
                  })
                  .map((fieldSection, index) => {
                    const snakeUpperCase = fieldSection.section_type.name.toUpperCase();
                    const newFieldObj: any = {
                      type: snakeUpperCase,
                      fields: {},
                    };
                    Object.keys(fieldSection).forEach((fieldKey, i) => {
                      if (fieldKey === "photo_thumbnail_url") {
                        const fileName = getFilename(fieldSection.photo_thumbnail_url);

                        newFieldObj["fields"]["image"] = fieldSection.photo_thumbnail_url
                          ? {
                              keyObj: fileName,
                              url: fieldSection.photo_thumbnail_url
                                ? fieldSection.photo_thumbnail_url
                                : "",
                              uid: generateId(),
                            }
                          : "";
                      } else if (fieldKey === "pdf_url") {
                        const fileName = getFilename(fieldSection.pdf_url);
                        const fileNameStr = fileName?.substring(fileName.indexOf("-") + 1);
                        newFieldObj["fields"]["file"] = fieldSection.pdf_url
                          ? {
                              keyObj: fileName,
                              url: fieldSection.pdf_url ? fieldSection.pdf_url : "",
                              uid: generateId(),
                              name: fileNameStr ? fileNameStr : "",
                            }
                          : "";
                      } else if (fieldKey === "section_type") {
                        newFieldObj["fields"]["section_type"] =
                          fieldSection.section_type.name.toUpperCase();
                      } else if (fieldKey === "section_status") {
                        newFieldObj["fields"]["section_status"] =
                          fieldSection.section_status === "ENABLED" ? true : false;
                      } else {
                        newFieldObj["fields"][fieldKey] = fieldSection[fieldKey];
                      }
                    });
                    return newFieldObj;
                  });
        } else {
          fields[snakeCase(key)] = data[key];
        }
      });

      setBrandName(bName);
      setInitialValues(fields);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      setReinitialize(true);
    }
  }, [pageData]);

  useEffect(() => {
    let arrayList: any = [];

    if (categoryList) {
      const cateList = categoryList.getCategories;
      cateList.forEach((item) => {
        arrayList.push({ value: item.id, label: item.name });
      });
      setBrandCategoryList(arrayList);
    }
  }, [categoryList]);

  const sectionsMapper = (values) => {
    let filteredSection = values.sections
      .filter((f) => f.fields.header_title !== "")
      .map((item, index) => {
        let componentField: any = item.fields;
        const newFieldObj: any = {
          fields: { section_type: item.type, order: index },
        };
        Object.keys(componentField).forEach((key) => {
          if (key === "image") {
            newFieldObj["fields"]["photo_thumbnail_url"] = componentField.image
              ? componentField.image.url
              : "";
          } else if (key === "file") {
            newFieldObj["fields"]["pdf_url"] = componentField.file ? componentField.file.url : "";
          } else if (key === "section_type") {
          } else if (key === "section_status") {
            newFieldObj["fields"]["section_status"] =
              componentField.section_status === true ? "ENABLED" : "DISABLED";
          } else if (key === "header_title") {
            newFieldObj["fields"]["header_title"] = componentField.header_title;
          } else if (key === "text_content") {
            newFieldObj["fields"]["text_content"] = componentField.text_content;
          } else if (key === "link") {
            newFieldObj["fields"]["link"] = componentField.link;
          }
        });
        return newFieldObj["fields"];
      });

    return filteredSection;
  };

  const photoLibraryMapper = (array) => {
    const newData = array
      .filter((item) => item && item.url !== "")
      .map((item) => {
        const { __typename, ...newItem } = item;
        return newItem;
      });

    return newData;
  };

  const hotelsMapper = (values) => {
    const hotels = values.hotels
      .filter((item) => item.name.trim() !== "")
      .map((item, index) => {
        return {
          hotel_details: {
            ...(!isEmpty(item.id) && { id: item.id }),
            website_logo: item.website_logo ? item.website_logo.url : "",
            app_logo: item.app_logo ? item.app_logo.url : "",
            name: item.name ? item.name.trim() : "",
            address: item.address ? item.address.trim() : "",
            website_link: item.website_link ? item.website_link.trim() : "",
            request_for_proposal_link: item.request_for_proposal_link
              ? item.request_for_proposal_link
              : "",
            tripadvisor_link: item.tripadvisor_link ? item.tripadvisor_link : "",
            country_code: item.country_code ? item.country_code : "",
            mobile_no: item.mobile_no ? item.mobile_no.toString() : "",
            longitude: item.longitude ? item.longitude.toString() : "",
            latitude: item.latitude ? item.latitude.toString() : "",
            partner_hotel_code: item.partner_hotel_code ? item.partner_hotel_code : "",
            meetings_and_celebrations_link: item.meetings_and_celebrations_link
              ? item.meetings_and_celebrations_link
              : "",
            booking_engine_link: item.booking_engine_link ? item.booking_engine_link : "",
            group_booking_link: item.group_booking_link ? item.group_booking_link : "",
            email_address: item.email_address ? item.email_address : "",
            landline_no: item.landline_no ? `${item.landline_no.toString()}` : "",
            page_title: item.page_title ? item.page_title : "",
            seo_description: item.seo_description ? item.seo_description : "",
            seo_keywords: item.seo_keywords ? item.seo_keywords : "",
            description: item.description ? item.description : "",
            policies: item.policies ? item.policies : "",
            facilities_and_amenities: item.facilities_and_amenities
              ? item.facilities_and_amenities
              : "",
            dining: item.dining ? item.dining : "",
            nearby_attractions: item.nearby_attractions ? item.nearby_attractions : "",
            footnote_title: item.footnote_title ? item.footnote_title : "",
            footnote_body: item.footnote_body ? item.footnote_body : "",
          },
          payment_options: [
            {
              ...(!isEmpty(item.payment_options[0].id) && { id: item.payment_options[0].id }),
              platform: "WEBSITE",
              merchant_name: item.payment_options[0].merchant_name || "",
              merchant_id: item.payment_options[0].merchant_id || "",
              shared_key: item.payment_options[0].shared_key || "",
              status: item.payment_options[0].status === true ? "ENABLED" : "DISABLED",
              pay_at_hotel: item.payment_options[0].pay_at_hotel === true ? "ENABLED" : "DISABLED",
            },
            {
              ...(!isEmpty(item.payment_options[1].id) && { id: item.payment_options[1].id }),
              platform: "MOBILE",
              merchant_name: item.payment_options[1].merchant_name || "",
              merchant_id: item.payment_options[1].merchant_id || "",
              shared_key: item.payment_options[1].shared_key || "",
              status: item.payment_options[1].status === true ? "ENABLED" : "DISABLED",
              pay_at_hotel: item.payment_options[1].pay_at_hotel === true ? "ENABLED" : "DISABLED",
            },
          ],
          uploaded_hotel_photos: photoLibraryMapper(item.uploaded_hotel_photos),
        };
      });
    return hotels;
  };

  const removeEmptyInputMobile = (input) => {
    const mobile = input
      .filter((item) => item.input !== "")
      .map((val) => {
        return val;
      });

    if (mobile.length === 0) {
      return [];
    }

    return [...new Map(mobile.map((item) => [item["input"], item])).values()];
  };

  const removeEmptyInput = (input) => {
    const contactInfo = input
      .filter((item) => item.input !== "")
      .map((val) => {
        return val;
      });

    if (contactInfo.length === 0) {
      return [];
    }

    return [...new Map(contactInfo.map((item) => [item["input"], item])).values()];
  };

  const bannerImgMapper = (values) => {
    const images = values.banner_feature_images.map((item, index) => {
      return {
        url: item.url ? item.url : "",
      };
    });
    return images;
  };

  const dataMapper = (values) => {
    let data: any = {
      brand_details: {
        ...(!isEmpty(params.pageId) && { id: params.pageId }),
        logo: values.logo ? values.logo.url : "",
        name: values.name ? values.name.trim() : "",
        category: values.category,
        short_description: values.short_description ? values.short_description.trim() : "",
        long_description: values.long_description ? values.long_description.trim() : "",
        status: values.status,
        redirect_link: values.redirect_link ? values.redirect_link.trim() : "",
        redirect_link_status: values.redirect_link_status === true ? "ENABLED" : "DISABLED",
        page_title: values.page_title ? values.page_title : "",
        seo_description: values.seo_description ? values.seo_description : "",
        seo_keywords: values.seo_keywords ? values.seo_keywords : "",
      },
      contact_information: {
        landline_nos: removeEmptyInput(values.landline_nos),
        mobile_nos: removeEmptyInputMobile(values.mobile_nos),
        email_addresses: removeEmptyInput(values.email_addresses),
      },
      social_media: values.social_media,
      hotels: hotelsMapper(values),
      uploaded_media: {
        category_logo: {
          url: values.category_logo ? values.category_logo.url : "",
        },
        carousel_image: {
          url: values.carousel_image ? values.carousel_image.url : "",
        },
        banner_feature_images: bannerImgMapper(values),
      },
      sections: sectionsMapper(values),
    };
    return data;
  };

  const handleSubmit = async (values) => {
    let data = dataMapper(values);

    setLoader(true);
    try {
      let update = false;
      if (pageData && has(pageData, "getBrandById")) {
        update = true;
        await modifyBrand({
          variables: {
            data,
          },
        });
      } else {
        await createBrand({
          variables: {
            data,
          },
        });
      }

      setTimeout(() => {
        setLoader(false);
      }, 1000);

      setIsSuccess(true);
      setShowModal(true);
      setTitleMessage("Success!");
      setBodyMessage(
        update
          ? `Changes for ${brandName} was successfully saved.`
          : "You have successfully added a new brand!"
      );
    } catch (error: any) {
      // console.log("errorerror", error);
      if (error) {
        if (error.message.startsWith("Brand with name")) {
          setTitleMessage("Data not saved!");
          setBodyMessage("Brand Name already exist.");
          setShowModal(true);
        } else if (error.graphQLErrors && error.graphQLErrors[0]) {
          const errGraphqlRes = error.graphQLErrors[0].extensions.response;
          let errStr = "";

          if (errGraphqlRes) {
            errStr = errGraphqlRes?.message;
          }
          message.error(errStr);
        } else {
          setTitleMessage("Data not saved!");
          setBodyMessage("Kindly check your internet connection.");
          setShowModal(true);
        }
      }

      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  };

  return (
    <>
      <Spin spinning={loading || loader}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={reinitialize}
          onSubmit={async (values: any) => {
            await handleSubmit(values);
          }}
          render={(formikBag) => (
            <Form
              {...{
                formikBag,
                brandCategoryList,
                loader,
                brandName,
              }}
            />
          )}
        />
      </Spin>

      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isErrorIcon={!isSuccess}
        isSuccessIcon={isSuccess}
        titleMessage={titleMessage}
        visible={showModal}
        onOk={isSuccess ? handleOkSuccess : () => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={isSuccess ? "Great" : "OK"}
      />
    </>
  );
};

export default BrandManagementForm;
