import styled from "styled-components";
import { Button } from "antd";
import { typography } from "../../utilities/styles/theme";

export const Base = styled("div")({
  ".note-right": {
    display: "flex",
    gap: "1rem",
    alignItems: "flex-end",
  },
});
export const Wrapper = styled("div")({
  ".hasError .ant-upload .upload-button": {
    borderColor: "var(--color-secondary)",
  },
});

export const Container = styled.div`
  position: relative;
  height: 100px;
  width: 160px;
`;

export const ButtonStyle = styled(Button)`
  height: 100px;
  width: 160px;
  padding: 0;
  overflow: hidden;
  color: #d8d8d8;
  background: #fafafa;

  img {
    height: 100px;
    width: 160px;
    object-fit: cover;
  }

  .anticon {
    svg {
      font-size: 24px;
    }
  }

  h1 {
    margin: 0;
    color: inherit;
  }
`;

export const WrapperPreview = styled.div`
  height: 100px;
  width: 160px;
  padding: 0;
  overflow: hidden;
  color: #d8d8d8;
  background: #fafafa;

  img {
    height: 100px;
    width: 160px;
    object-fit: cover;
  }

  .video-banner {
    width: 100%;
    object-fit: cover;
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    max-height: 100%;
  }

  & > div {
    height: 100%;
  }
`;

export const RemoveBtn = styled.div`
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 3px;

  .anticon {
    svg {
      font-size: 15px;
    }

    &:hover {
      color: #00714b;
    }
  }
  img:hover {
    opacity: 0.85;
  }
`;

export const FileListStyled = styled.div`
  display: flex;
  margin-bottom: 5px;
  gap: 0.25em;
  font-size: 14px;
  margin-top: 0.25rem;

  // &:hover {
  //   background: #d8d8d8;
  // }

  h4 {
    // width: 100%;
    margin: 0;
  }

  .anticon {
    padding: 0 8px;
  }
`;

export const BrokenImageContainer = styled.div`
  position: relative;

  img {
    width: 100%;
    opacity: 10%;
  }

  p {
    position: absolute;
    top: 115px;
    background: darkgrey;
    width: 100%;
    text-align: center;
    padding: 12px;
    font-weight: 500;
    color: #000;
  }
`;

export const ImageNotScannedContainer = styled.div`
  position: relative;

  p {
    position: absolute;
    top: 70px;
    background: darkgrey;
    width: 100%;
    text-align: center;
    padding: 12px;
    font-weight: 500;
    color: #000;
    opacity: 80%;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
`;

export const Note = styled("p")({
  ...typography("caption"),
  color: "var(--color-general-black)",
  margin: "0.5rem 0 0",
});

export const NoteList = styled("ul")({
  ...typography("caption"),
  padding: "0 0 0 0.5rem",
  margin: 0,
});

export const NoteListItem = styled("li")({
  ...typography("caption"),
  color: "var(--color-general-blue-gray)",
  "::marker": {
    content: "'- '",
    display: "list-item",
  },
});

export const UploadLabel = styled("label")({
  lineHeight: "25.15px",
  height: 32,
  display: "inline-flex",
  position: "relative",
  alignItems: "center",
});
