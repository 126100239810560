import { message } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import { activeColumns, actions } from "./constants";
import CustomDashboardList from "@/components/CustomDashboardList4";
import CustomModal from "@/components/CustomModal";
import { GET_ROLES, UPDATE_ROLE_STATUS } from "../../../graphql";
import { STATUS } from "../../../../../utilities/enums";

const RolesManagementList: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const [isPublish, setIsPublish] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState<string>("");
  const [bodyMessage, setBodyMessage] = useState<string>("");
  const [bodyMessageSuccess, setBodyMessageSuccess] = useState<string>("");
  const [statusStr, setStatusStr] = useState<string>("");
  const [okTxt, setOkTxt] = useState<string>("OK");
  const [roleId, setRoleId] = useState("");
  const [rolesList, setRolesList] = useState<any>([]);
  const [modifyStatus] = useMutation(UPDATE_ROLE_STATUS);

  const { loading, refetch, data } = useQuery(GET_ROLES);

  useEffect(() => {
    if (data) {
      refetch();
      const list = [...data.getRoles];

      setRolesList(list);
    }
  }, [data, refetch]);

  const handleAddBtn = () => {
    history.push(`${match.path}/add-role`);
  };

  const handleSelectedRow = (id): void => {
    history.push(`${match.path}/view-role/${id}`, { pageId: id });
  };

  const constructVariables = (roleId: string, status: STATUS): object => {
    return {
      role_id: roleId,
      status: status,
    };
  };

  const updateStatus = async (id: string, status: STATUS): Promise<void> => {
    const input = constructVariables(id, status);

    try {
      const { data: res } = await modifyStatus({
        variables: {
          updateRoleStatus: input,
        },
      });

      if (res.updateRoleStatus) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const checkStatusStrOkModal = async (id, statusStr): Promise<void> => {
    if (statusStr === "activate") {
      await updateStatus(id, STATUS.ACTIVE);
      setBodyMessageSuccess("This role was successfully activated.");
    } else if (statusStr === "deactivate") {
      await updateStatus(id, STATUS.DEACTIVATED);
      setBodyMessageSuccess("This role was successfully deactivated.");
    } else if (statusStr === "deleted") {
      await updateStatus(id, STATUS.DELETED);
      setBodyMessageSuccess("This role was successfully deleted.");
    }
    setShowModal(false);
    setShowModalSuccess(true);
  };

  const handleActivate = (id, name): void => {
    setShowModal(true);
    setTitleMessage("Activate Role?");
    setBodyMessage(`Are you sure you want to activate '${name}'?`);
    setStatusStr("activate");
    setOkTxt("Activate");
    setIsPublish(true);
    setRoleId(id);
  };

  const handleDelete = (id, name): void => {
    setShowModal(true);
    setTitleMessage("Delete role?");
    setBodyMessage(`Are you sure you want to delete '${name}'?`);
    setStatusStr("deleted");
    setOkTxt("Delete");
    setIsPublish(false);
    setRoleId(id);
  };

  const handleDeactivate = (id, name): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(`Are you sure you want to deactivate '${name}'?`);
    setStatusStr("deactivate");
    setOkTxt("Deactivate");
    setIsPublish(false);
    setRoleId(id);
  };

  const handleMenu = (menuItem: string, id: string, name: string): void => {
    if (menuItem === "Edit") {
      history.push(`${match.path}/edit-role/${id}`, { pageId: id });
    }
    if (menuItem === "View") {
      history.push(`${match.path}/view-role/${id}`, { pageId: id });
    }
    if (menuItem === "Activate") {
      handleActivate(id, name);
    }
    if (menuItem === "Delete") {
      handleDelete(id, name);
    }
    if (menuItem === "Deactivate") {
      handleDeactivate(id, name);
    }
  };

  const handleSearch = () => {};

  return (
    <>
      <CustomDashboardList
        actions={actions}
        addLabelText="Role"
        data={rolesList}
        dragDisable={true}
        loading={loading}
        handleAddBtn={handleAddBtn}
        headerColumns={activeColumns}
        handleMenu={handleMenu}
        handleSearch={handleSearch}
        handleSelectedRow={handleSelectedRow}
        noResultPlaceholder="No roles yet"
        searchPlaceholder="Search Roles"
      />

      {/* Confirmation modal */}
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showModal}
        okButtonWidth="8em"
        onOk={() => checkStatusStrOkModal(roleId, statusStr)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />

      {/* Success Modal */}
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon
        titleMessage="Success!"
        visible={showModalSuccess}
        onOk={() => setShowModalSuccess(false)}
        onCancel={() => setShowModalSuccess(false)}
        message={bodyMessageSuccess}
        okText={isPublish ? "Great!" : "OK"}
      />
    </>
  );
};

export default RolesManagementList;
