import { message } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import CustomDashboardList from "@/components/CustomDashboardList3";
import CustomModal from "@/components/CustomModal";

import { activeColumns, actions } from "./constants";
import { PromosI } from "../../../../../models/PromosAndPackagesInterface";
import {
  GET_PROMOS_AND_PACKAGES,
  UPDATE_PROMO_AND_PACKAGE_ORDER,
  UPDATE_PROMO_AND_PACKAGE_STATUS,
} from "../../../graphql";
import { STATUS } from "../../../../../utilities/enums";

const PromosAndPackagesList: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const [isPublish, setIsPublish] = useState<boolean>(false);
  const [showArrangeModal, setShowArrangeModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [bodyMessageSuccess, setBodyMessageSuccess] = useState("");
  const [statusStr, setStatusStr] = useState("");
  const [okTxt, setOkTxt] = useState("OK");
  const [promoId, setPromoId] = useState("");
  const [startIndex, setStartIndex] = useState<number>(0);
  const [targetIndex, setTargetIndex] = useState<number>(0);
  const [promosList, setPromosList] = useState<PromosI[]>([]);
  const [modifyStatus] = useMutation(UPDATE_PROMO_AND_PACKAGE_STATUS);
  const [modifyOrder] = useMutation(UPDATE_PROMO_AND_PACKAGE_ORDER);

  const { loading, refetch, data } = useQuery(GET_PROMOS_AND_PACKAGES, {
    variables: {
      search: "",
    },
  });

  useEffect(() => {
    if (data) {
      refetch();
      const list = [...data.getPromosAndPackages].sort((a, b): any => {
        return a.order < b.order;
      });

      setPromosList(list);
    }
  }, [data, refetch]);

  const handleAddBtn = (): void => {
    history.push(`${match.path}/add-promo`);
    refetch();
  };

  const handleSearch = (e): void => {
    const value = e.toLowerCase();

    const newData = data.getPromosAndPackages.filter((item) => {
      const title = item.title.toLowerCase();
      const updatedBy = item.updated_by.toLowerCase();

      return title.includes(value) || updatedBy.includes(value);
    });
    setPromosList(newData);
  };

  const handleSelectedRow = (id): void => {
    history.push(`${match.path}/view-promo/${id}`, { pageId: id });
  };

  const constructVariables = (promoAndPackageId: string, status: STATUS): object => {
    return {
      promoAndPackage_id: promoAndPackageId,
      status: status,
    };
  };

  const updateStatus = async (id: string, status: STATUS): Promise<void> => {
    const input = constructVariables(id, status);

    try {
      const { data: res } = await modifyStatus({
        variables: {
          updatePromoAndPackageStatusInput: input,
        },
      });

      if (res.updatePromoAndPackageStatus) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const checkStatusStrOkModal = async (id, statusStr): Promise<void> => {
    if (statusStr === "published") {
      await updateStatus(id, STATUS.PUBLISHED);
      setBodyMessageSuccess("This promo was successfully published.");
    } else if (statusStr === "archived") {
      await updateStatus(id, STATUS.ARCHIVED);
      setBodyMessageSuccess("This promo was successfully archived.");
    } else if (statusStr === "deleted") {
      await updateStatus(id, STATUS.DELETED);
      setBodyMessageSuccess("This promo was successfully deleted.");
    }
    setShowModal(false);
    setShowModalSuccess(true);
  };

  const handleArchived = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By archiving this promo, informations and documents uploaded in this promo will not be visible in the website."
    );
    setStatusStr("archived");
    setOkTxt("Archive");
    setIsPublish(false);
    setPromoId(id);
  };

  const handleRearrange = async (start, target, id): Promise<void> => {
    const reorderedList = promosList
      .filter((item) => {
        if (start < target) {
          return item.order > start && item.order <= target;
        } else {
          return item.order < start && item.order >= target;
        }
      })
      .map((item, index) => {
        const newOrder = start < target ? item.order - 1 : item.order + 1;

        return {
          id: item.id,
          order: newOrder,
        };
      });

    try {
      const { data: res } = await modifyOrder({
        variables: {
          updatePromoAndPackageOrderInput: {
            details: [
              ...reorderedList,
              {
                id: id,
                order: target,
              },
            ],
          },
        },
      });

      if (res.updatePromoAndPackageOrder) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
    setShowArrangeModal(false);
  };

  const handleDelete = (id): void => {
    setShowModal(true);
    setTitleMessage("Delete Promos and Packages?");
    setBodyMessage("Are you sure you want to delete this promo?");
    setStatusStr("deleted");
    setOkTxt("Delete");
    setIsPublish(false);
    setPromoId(id);
  };

  const handlePublish = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By publishing this promo, all information and documents uploaded in this promo will be visible in the website when deployed."
    );
    setStatusStr("published");
    setOkTxt("Publish");
    setIsPublish(true);
    setPromoId(id);
  };

  const handleKebabMenu = (menuItem: string, id: string): void => {
    if (menuItem === "Edit") {
      history.push(`${match.path}/edit-promo/${id}`, { pageId: id });
    }
    if (menuItem === "View") {
      history.push(`${match.path}/view-promo/${id}`, { pageId: id });
    }
    if (menuItem === "Publish") {
      handlePublish(id);
    }
    if (menuItem === "Delete") {
      handleDelete(id);
    }
    if (menuItem === "Archive") {
      handleArchived(id);
    }
  };

  const handleDrag = (result): void => {
    if (!result.destination) {
      return;
    }

    if (result.source.index !== result.destination.index) {
      setShowArrangeModal(true);
      setOkTxt("Ok");
      setBodyMessage(`Are you sure you want to reorder promos and packages?`);
      setStartIndex(result.source.index);
      setTargetIndex(result.destination.index);
      setPromoId(result.draggableId);
    }
  };

  return (
    <>
      <CustomDashboardList
        actions={actions}
        addLabelText=""
        data={promosList}
        dragDisable={false}
        loading={loading}
        handleAddBtn={handleAddBtn}
        handleDrag={handleDrag}
        handleMenu={handleKebabMenu}
        handleSearch={handleSearch}
        handleSelectedRow={handleSelectedRow}
        headerColumns={activeColumns}
        noResultPlaceholder="No results found"
        searchPlaceholder="Search Promos and Packages"
      />

      {/* Confirmation modal */}
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showModal}
        onOk={() => checkStatusStrOkModal(promoId, statusStr)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />

      {/* Success Modal */}
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon
        titleMessage="Success!"
        visible={showModalSuccess}
        onOk={() => setShowModalSuccess(false)}
        onCancel={() => setShowModalSuccess(false)}
        message={bodyMessageSuccess}
        okText={isPublish ? "Great!" : "OK"}
      />

      {/* Arrange Confirmation Modal */}
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showArrangeModal}
        onOk={() => handleRearrange(startIndex, targetIndex, promoId)}
        onCancel={() => setShowArrangeModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />
    </>
  );
};

export default PromosAndPackagesList;
