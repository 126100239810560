import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import { Spin, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { has, snakeCase } from "lodash";

import CustomModal from "@/components/CustomModal";
import Form from "./form";

import { CREATE_UPDATE_PROMOTION, GET_PROMOTION, GET_ALL_PROMOTION_TITLES } from "../../../graphql";
import { validationSchema } from "./validations";

const PromotionForm = () => {
  const history = useHistory();
  const { pageId } = useParams();
  const [createUpdatePromotion] = useMutation(CREATE_UPDATE_PROMOTION);
  const { data: existingPromoTitles } = useQuery(GET_ALL_PROMOTION_TITLES);
  const { data: pageData, loading } = useQuery(GET_PROMOTION, {
    skip: !pageId,
    fetchPolicy: "no-cache",
    variables: {
      id: pageId || "",
    },
  });

  const [promoName, setPromoName] = useState<string>("");
  const [titleMessage, setTitleMessage] = useState<string>("");
  const [bodyMessage, setBodyMessage] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [reinitialize, setReinitialize] = useState(false);
  const [featuredPromoLimit, setfeaturedPromoLimit] = useState(false);
  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
    photo: null,
    start_date: null,
    start_time: null,
    end_date: null,
    end_time: null,
    cta_button_name: "Book Now",
    cta_button_link: "",
    status: "DRAFT",
    is_featured: false,
    participating_hotels: [],
  });

  const handleOkSuccess = () => {
    history.push("/notification-settings/promotions/");
  };

  useEffect(() => {
    if (reinitialize) {
      setReinitialize(false);
    }
  }, [reinitialize]);

  useEffect(() => {
    if (pageId && has(pageData, "getPromotionById")) {
      const data = pageData.getPromotionById;
      const bName = data.title;
      const fields: any = initialValues;

      Object.keys(data).forEach((key, index) => {
        if (key === "photo_url") {
          fields["photo"] = data.photo_url
            ? {
                keyObj: data.photo_url,
                url: data.photo_url,
              }
            : "";
        } else if (key === "promotions_participating_hotels") {
          fields["participating_hotels"] = data.promotions_participating_hotels.map((item) => {
            return JSON.stringify({
              brand_id: item.brand.id,
              hotel_id: item.hotel.id,
            });
          });
        } else {
          fields[snakeCase(key)] = data[key];
        }
      });

      setPromoName(bName);
      setInitialValues(fields);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      setReinitialize(true);
    }
  }, [pageData]);

  const getExistingTitlesList = () => {
    const { getPromotions: existingTitleList } = existingPromoTitles;

    return existingTitleList
      .filter((entry) => entry.title !== initialValues.title)
      .map((entry) => entry.title);
  };

  const dataMapper = (values) => {
    let participatingHotels: any[] = [];
    values.participating_hotels.forEach((item) => {
      const parsedItem: any = JSON.parse(item);
      if (Array.isArray(parsedItem) && parsedItem.length > 0) {
        participatingHotels = [...participatingHotels, ...parsedItem];
      } else {
        participatingHotels = [...participatingHotels, parsedItem];
      }
    });

    const promotionDetails = {
      id: pageId,
      title: values.title,
      photo_url: values.photo.url,
      description: values.description,
      start_date: values.start_date,
      start_time: values.start_time,
      end_date: values.end_date,
      end_time: values.end_time,
      cta_button_name: values.cta_button_name,
      cta_button_link: values.cta_button_link,
      status: values.status,
      is_featured: values.is_featured,
    };

    const newData = {
      promotion_details: promotionDetails,
      participating_hotels: participatingHotels,
    };

    return newData;
  };

  const handleSubmit = async (values) => {
    const data = dataMapper(values);

    setLoader(true);
    try {
      let update = false;
      if (pageData && has(pageData, "getPromotionById")) {
        update = true;
      }

      await createUpdatePromotion({
        variables: {
          createOrUpdateNotificationInput: data,
        },
      });

      setTimeout(() => {
        setLoader(false);
      }, 1000);

      setIsSuccess(true);
      setShowModal(true);
      setTitleMessage("Success!");
      setBodyMessage(
        update
          ? "Changes for this content was successfully saved."
          : "You have successfully added a new content!"
      );
    } catch (error: any) {
      if (error) {
        if (error.message.startsWith("Brand with name")) {
          setTitleMessage("Data not saved!");
          setBodyMessage("Promo already exist.");
          setShowModal(true);
        } else if (error.graphQLErrors && error.graphQLErrors[0]) {
          const errGraphqlRes = error.graphQLErrors[0].extensions.response;
          let errStr = "";
          if (errGraphqlRes) {
            if (typeof errGraphqlRes.message === "string") {
              errStr = errGraphqlRes.message;
            } else if (errGraphqlRes?.message?.length > 0) {
              errGraphqlRes?.message?.forEach((item) => (errStr += item));
            } else errStr = errGraphqlRes?.message;
          }
          setfeaturedPromoLimit(true);
          message.error(errStr);
        } else {
          setTitleMessage("Data not saved!");
          setBodyMessage("Kindly check your internet connection.");
          setShowModal(true);
        }
      }

      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  };

  return (
    <>
      <Spin spinning={loading || loader}>
        <Formik
          initialValues={initialValues}
          validationSchema={() => validationSchema(getExistingTitlesList())}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={reinitialize}
          onSubmit={async (values: any) => {
            await handleSubmit(values);
          }}
          render={(formikBag) => (
            <Form
              {...{
                formikBag,
                loader,
                promoName,
                featuredPromoLimit,
              }}
            />
          )}
        />
      </Spin>

      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isErrorIcon={!isSuccess}
        isSuccessIcon={isSuccess}
        titleMessage={titleMessage}
        visible={showModal}
        onOk={isSuccess ? handleOkSuccess : () => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={isSuccess ? "Great" : "OK"}
      />
    </>
  );
};

export default PromotionForm;
