import { useState, useEffect } from "react";
import { Formik } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import { Spin, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { has, snakeCase, isEmpty } from "lodash";

import CustomModal from "@/components/CustomModal";
import Form from "./form";

import { MODIFY_FOOTER, GET_FOOTER_BY_ID } from "../../../graphql";
import { validationSchema } from "./validations";

const FooterInformationForm = () => {
  const history = useHistory();
  const params = useParams();

  const [modifyFooter] = useMutation(MODIFY_FOOTER);
  const { data: pageData, loading } = useQuery(GET_FOOTER_BY_ID, {
    skip: !params.pageId,
    fetchPolicy: "no-cache",
    variables: {
      id: params.pageId || "",
    },
  });

  const [titleMessage, setTitleMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [reinitialize, setReinitialize] = useState(false);
  const [landlineError, setLandlineError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [initialValues, setInitialValues] = useState({
    id: null,
    location: "",
    footnote_title: "",
    footnote_body: "",
    description: "",
    landline_nos: [{ input: "" }],
    mobile_nos: [
      {
        input: "",
        country_code: "+63",
      },
    ],
    email_addresses: [{ input: "" }],
    social_media: {
      facebook: "",
      instagram: "",
      youtube: "",
      twitter: "",
      linkedin: "",
      kumu: "",
      viber_community: "",
      tiktok: "",
    },
  });

  const handleOkSuccess = () => {
    history.push("/footer-management/rhr-informations");
  };

  useEffect(() => {
    if (reinitialize) {
      setReinitialize(false);
    }
  }, [reinitialize]);

  useEffect(() => {
    if (params.pageId && has(pageData, "getFooterById")) {
      const data = pageData.getFooterById;
      const fields: any = initialValues;

      Object.keys(data).forEach((key, index) => {
        if (key === "landline_nos") {
          let landlineList: any = [];
          fields["landline_nos"] =
            data.landline_nos.length === 0
              ? [{ input: "" }]
              : data.landline_nos.map((res) => {
                  landlineList.push(res);
                  return {
                    input: res.input ? res.input : "",
                  };
                });
        } else if (key === "mobile_nos") {
          let mobileList: any = [];
          fields["mobile_nos"] =
            data.mobile_nos.length === 0
              ? [{ input: "", country_code: "+63" }]
              : data.mobile_nos.map((res) => {
                  mobileList.push(res);
                  return {
                    input: res.input ? res.input : "",
                    country_code: res.country_code ? res.country_code : "+63",
                  };
                });
        } else if (key === "email_addresses") {
          let emailList: any = [];
          fields["email_addresses"] =
            data.email_addresses.length === 0
              ? [{ input: "" }]
              : data.email_addresses.map((res) => {
                  emailList.push(res);
                  return {
                    input: res.input ? res.input : "",
                  };
                });
        } else if (key === "social_media") {
          data.social_media.forEach((item) => {
            if (item.social_media_type.name === "facebook") {
              fields["social_media"].facebook = item.url;
            } else if (item.social_media_type.name === "instagram") {
              fields["social_media"].instagram = item.url;
            } else if (item.social_media_type.name === "youtube") {
              fields["social_media"].youtube = item.url;
            } else if (item.social_media_type.name === "twitter") {
              fields["social_media"].twitter = item.url;
            } else if (item.social_media_type.name === "linkedin") {
              fields["social_media"].linkedin = item.url;
            } else if (item.social_media_type.name === "tiktok") {
              fields["social_media"].tiktok = item.url;
            } else if (item.social_media_type.name === "kumu") {
              fields["social_media"].kumu = item.url;
            } else if (item.social_media_type.name === "viber_community") {
              fields["social_media"].viber_community = item.url;
            }
          });
        } else {
          fields[snakeCase(key)] = data[key];
        }
      });

      setInitialValues(fields);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      setReinitialize(true);
    }
  }, [pageData]);

  const removeEmptyInputMobile = (input) => {
    const mobile = input
      .filter((item) => item.input !== "")
      .map((val) => {
        return val;
      });

    if (mobile.length === 0) {
      return [];
    }

    return [...new Map(mobile.map((item) => [item["input"], item])).values()];
  };

  const removeEmptyInput = (input) => {
    const contactInfo = input
      .filter((item) => item.input !== "")
      .map((val) => {
        return val;
      });

    if (contactInfo.length === 0) {
      return [];
    }

    return [...new Map(contactInfo.map((item) => [item["input"], item])).values()];
  };

  const dataMapper = (values) => {
    let data: any = {
      footer_details: {
        ...(!isEmpty(params.pageId) && { id: params.pageId }),
        location: values.location ? values.location.trim() : "",
        footnote_title: values.footnote_title ? values.footnote_title.trim() : "",
        footnote_body: values.footnote_body ? values.footnote_body.trim() : "",
        description: values.description ? values.description.trim() : "",
      },
      contact_information: {
        landline_nos: removeEmptyInput(values.landline_nos),
        mobile_nos: removeEmptyInputMobile(values.mobile_nos),
        email_addresses: removeEmptyInput(values.email_addresses),
      },
      social_media: values.social_media,
    };
    return data;
  };

  const handleSubmit = async (values) => {
    var valueArrLandline = values.landline_nos.map(function(item){ return item.input });
    var isLandlineDuplicate = valueArrLandline.some(function(item, idx){ 
        return valueArrLandline.indexOf(item) !== idx 
    });

    var valueArrMobile = values.mobile_nos.map(function(item){ return item.input });
    var isMobileDuplicate = valueArrMobile.some(function(item, idx){ 
        return valueArrMobile.indexOf(item) !== idx 
    });

    var valueArrEmail = values.email_addresses.map(function(item){ return item.input });
    var isEmailDuplicate = valueArrEmail.some(function(item, idx){ 
        return valueArrEmail.indexOf(item) !== idx 
    });

    if (isLandlineDuplicate) {
      setLandlineError(true);
    } else {
      setLandlineError(false);
    }

    if (isMobileDuplicate) {
      setMobileError(true);
    } else {
      setMobileError(false);
    }

    if (isEmailDuplicate) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    let data = dataMapper(values);

    if (!isLandlineDuplicate && !isMobileDuplicate && !isEmailDuplicate) {
      setLoader(true);
      try {
        await modifyFooter({
          variables: {
            createOrUpdateFooterInput: data,
          },
        });

        setTimeout(() => {
          setLoader(false);
        }, 1000);

        setIsSuccess(true);
        setShowModal(true);
        setTitleMessage("Success!");
        setBodyMessage(`Changes for footer information was successfully saved.`);
      } catch (error: any) {
        // console.log("errorerror", error);
        if (error) {
          if (error.graphQLErrors && error.graphQLErrors[0]) {
            const errGraphqlRes = error.graphQLErrors[0].extensions.response;
            let errStr = "";

            if (errGraphqlRes) {
              errStr = errGraphqlRes?.message;
            }
            message.error(errStr);
          } else {
            setTitleMessage("Data not saved!");
            setBodyMessage("Kindly check your internet connection.");
            setShowModal(true);
          }
        }

        setTimeout(() => {
          setLoader(false);
        }, 500);
      }
    }
  };

  return (
    <>
      <Spin spinning={loading || loader}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={reinitialize}
          onSubmit={async (values: any) => {
            await handleSubmit(values);
          }}
          render={(formikBag) => (
            <Form
              {...{
                formikBag,
                loader,
                landlineError,
                mobileError,
                emailError
              }}
            />
          )}
        />
      </Spin>

      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isErrorIcon={!isSuccess}
        isSuccessIcon={isSuccess}
        titleMessage={titleMessage}
        visible={showModal}
        onOk={isSuccess ? handleOkSuccess : () => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={isSuccess ? "Great" : "OK"}
      />
    </>
  );
};

export default FooterInformationForm;
