export const dummyData = {
  id: "0107471a-2ecb-4f7e-ac5e-8de6ceccfec9",
  name: "Happy To Be Home 23",
  photo_url:
    "https://018-rhr-uat-website-bucket.s3.ap-southeast-1.amazonaws.com/1643865794112-RHRA_Promotions 338x200px.png",
  description: "<p>Special rates for our dear Balikbayans!</p>",
  start_date: "02/03/2022",
  start_time: "12:00 AM",
  end_date: "03/31/2022",
  end_time: "11:59 PM",
  cta_button_name: "Book Now",
  cta_button_link: "",
  notification_frequency: "MONTHLY",
  frequency_details: {
    start_date: "10/18/2022",
    start_time: "12:00 AM",
    end_date: "10/20/2022",
    end_time: "1:00 AM",
    recur_every: 1,
    weeks_on: [],
    months_on: "first day of the month",
    ends: "never",
  },
  status: "PUBLISHED",
  notification_type: "PROMOTION",
  created_at: "2022-10-17T12:51:42.155Z",
  created_by: "auth0|61f2102cf2705a006852425b",
  updated_at: "2022-10-17T12:51:42.137Z",
  updated_by: "auth0|61f2102cf2705a006852425b",
  sent_at: null,
  sent_by: null,
  is_deleted: false,
  participating_hotels: [
    {
      id: "4cd591c1-eefe-48c3-ab31-17d88d13b11e",
      notification_id: "0107471a-2ecb-4f7e-ac5e-8de6ceccfec9",
      brand: {
        id: "e81bac56-55c6-4b92-ab5b-8dd276f8a50d",
        name: "Grand Summit Hotels",
      },
      hotel: {
        id: "c0886ce2-56cb-4b51-896d-9d4f282dfcb4",
        name: "Grand Summit Hotel General Santos",
      },
    },
    {
      id: "55b04bfe-23d8-4914-a51d-8d344a7f5e16",
      notification_id: "0107471a-2ecb-4f7e-ac5e-8de6ceccfec9",
      brand: {
        id: "eb7ac792-1916-45e0-9d2d-79ca1ee69d6b",
        name: "Summit Hotels and Resorts",
      },
      hotel: {
        id: "a431f713-79ef-4a44-a9dd-5a364d5e7ed2",
        name: "Summit Hotel Tacloban",
      },
    },
    {
      id: "6a4f2974-468f-491e-bd0f-aa4835a6207d",
      notification_id: "0107471a-2ecb-4f7e-ac5e-8de6ceccfec9",
      brand: {
        id: "af782574-d322-46e0-a628-6990187cdfe1",
        name: "Go Hotels",
      },
      hotel: {
        id: "1a28f2e9-dc59-44bd-a11a-091390b2240c",
        name: "Go Hotels Lanang - Davao",
      },
    },
    {
      id: "6f8603d7-9655-4ffa-b7ed-e8e642b83de1",
      notification_id: "0107471a-2ecb-4f7e-ac5e-8de6ceccfec9",
      brand: {
        id: "eb7ac792-1916-45e0-9d2d-79ca1ee69d6b",
        name: "Summit Hotels and Resorts",
      },
      hotel: {
        id: "d564061f-3f1b-42ec-b97e-0356a1dfe542",
        name: "TEST Summit Galleria Cebu",
      },
    },
    {
      id: "714a7bb4-22dd-4165-af6c-ea2d3019865e",
      notification_id: "0107471a-2ecb-4f7e-ac5e-8de6ceccfec9",
      brand: {
        id: "af782574-d322-46e0-a628-6990187cdfe1",
        name: "Go Hotels",
      },
      hotel: {
        id: "aa4757a6-4004-4d2d-9f77-aec442234d2b",
        name: "Go Hotels Otis-Manila",
      },
    },
    {
      id: "ac954cc0-7b8a-43a6-9e21-e4422da1ab04",
      notification_id: "0107471a-2ecb-4f7e-ac5e-8de6ceccfec9",
      brand: {
        id: "eb7ac792-1916-45e0-9d2d-79ca1ee69d6b",
        name: "Summit Hotels and Resorts",
      },
      hotel: {
        id: "a742fd6c-08fe-471d-bf3c-7cb6a2654537",
        name: "Summit Hotel Magnolia",
      },
    },
    {
      id: "b23739c1-662e-4aaa-af39-07bc3b88e700",
      notification_id: "0107471a-2ecb-4f7e-ac5e-8de6ceccfec9",
      brand: {
        id: "eb7ac792-1916-45e0-9d2d-79ca1ee69d6b",
        name: "Summit Hotels and Resorts",
      },
      hotel: {
        id: "c4ee6e51-b825-4ddd-ba89-f380cbe0833a",
        name: "Summit Galleria Cebu",
      },
    },
    {
      id: "bd1d8fca-5af0-4e50-a5b5-42c428269aa7",
      notification_id: "0107471a-2ecb-4f7e-ac5e-8de6ceccfec9",
      brand: {
        id: "eb7ac792-1916-45e0-9d2d-79ca1ee69d6b",
        name: "Summit Hotels and Resorts",
      },
      hotel: {
        id: "76f736ee-3333-4b17-aeee-d9fca444b031",
        name: "Summit Ridge Tagaytay",
      },
    },
    {
      id: "be2eb24b-b6de-4487-93bf-6e8291e54edd",
      notification_id: "0107471a-2ecb-4f7e-ac5e-8de6ceccfec9",
      brand: {
        id: "e81bac56-55c6-4b92-ab5b-8dd276f8a50d",
        name: "Grand Summit Hotels",
      },
      hotel: {
        id: "91e57890-16f4-4ad9-9e7d-7e7f9bafbb84",
        name: "TEST Grand Summit Hotel General Santos",
      },
    },
    {
      id: "cd3d911d-858d-4508-987f-76167b1d5321",
      notification_id: "0107471a-2ecb-4f7e-ac5e-8de6ceccfec9",
      brand: {
        id: "eb7ac792-1916-45e0-9d2d-79ca1ee69d6b",
        name: "Summit Hotels and Resorts",
      },
      hotel: {
        id: "d2206330-7ac8-40af-b794-6a79302950bf",
        name: "Summit Hotel Greenhills",
      },
    },
    {
      id: "d7e8d02a-281e-4268-a78b-7a4f8e21a385",
      notification_id: "0107471a-2ecb-4f7e-ac5e-8de6ceccfec9",
      brand: {
        id: "af782574-d322-46e0-a628-6990187cdfe1",
        name: "Go Hotels",
      },
      hotel: {
        id: "5d5edffd-b30a-4c59-9b8a-4569728f2d0c",
        name: "Go Hotels Ortigas Center",
      },
    },
    {
      id: "e99f4c35-c807-4613-8b44-71a93dc38633",
      notification_id: "0107471a-2ecb-4f7e-ac5e-8de6ceccfec9",
      brand: {
        id: "eb7ac792-1916-45e0-9d2d-79ca1ee69d6b",
        name: "Summit Hotels and Resorts",
      },
      hotel: {
        id: "2e60c0b6-fb35-4cd0-a35e-c427708c251c",
        name: "Summit Hotel Naga",
      },
    },
    {
      id: "eefa1ecc-646a-4a5f-bdfb-d8625b888638",
      notification_id: "0107471a-2ecb-4f7e-ac5e-8de6ceccfec9",
      brand: {
        id: "eb7ac792-1916-45e0-9d2d-79ca1ee69d6b",
        name: "Summit Hotels and Resorts",
      },
      hotel: {
        id: "ef3b01f4-35dd-451e-a055-a30d8bcbff96",
        name: "Summit Circle Cebu",
      },
    },
  ],
  release_schedules: [
    {
      id: "3d235678-5e39-4ab8-a1bb-2688ab54269b",
      schedule_type: "AFTER",
      datetime_value: null,
      before_after_value: 1,
      between_value: null,
    },
    {
      id: "d589f85b-5ee8-4129-9a03-42f278773558",
      schedule_type: "BETWEEN",
      datetime_value: null,
      before_after_value: null,
      between_value: "13:47:00",
    },
    {
      id: "ebaf0fdd-be6e-4fa8-879f-da70fd5eb76e",
      schedule_type: "BEFORE",
      datetime_value: null,
      before_after_value: 1,
      between_value: null,
    },
    {
      id: "f0109c88-33a2-4977-b4de-99c31e6caf9f",
      schedule_type: "DATETIME",
      datetime_value: "1665014400000",
      before_after_value: null,
      between_value: null,
    },
  ],
  recipients: [
    {
      id: "0e2e6a52-aeca-478a-8ad5-7eae45029f19",
      recipient_type: "STATUS",
      value: '{"name":"status","value":["active"]}',
    },
    {
      id: "1253f2dd-7556-41e5-b493-52ee299547fe",
      recipient_type: "HOTEL_BOOKING",
      value: '{"name":"hotel_booking","value":["Completed"]}',
    },
    {
      id: "15abc31a-c1dc-44eb-8d5f-4c9afd995daa",
      recipient_type: "LOYALTY_CARD",
      value: '{"name":"","value":[]}',
    },
    {
      id: "51d1cdc6-614e-4a63-a088-bd982e7c5c36",
      recipient_type: "APP_BEHAVIOR",
      value: '{"name":"","value":[]}',
    },
  ],
};

export const dummyPromo = [
  {
    id: "df0cbcef-e223-4f8a-80d1-25e51d7c0daf",
    status: "DRAFT",
    title: "test ",
  },
  {
    id: "df0cbcef-e223-4f8a-80d1-25e51d7c0daf",
    status: "PUBLISHED",
    title: "Happy To Be Home",
  },
  {
    id: "df0cbcef-e223-4f8a-80d1-25e51d7c0daf",
    status: "DELETED",
    title: "Go Flexi",
  },
];
