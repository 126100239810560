import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import Login from "./features/Login";

import { Provider } from "./context";
import client from "./utilities/apollo";

const RootRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={["", "/", "/admin/login"]}
        component={() => {
          return <Login />;
        }}
      />
      <Route path={["/:page/:subPage", "/:page"]} render={() => <App />} />
    </Switch>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider>
        <Router>
          <RootRoutes />
        </Router>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
