import styled from "styled-components";
import { Input, Table } from "antd";

const { Search } = Input;

export const StyledTable = styled(Table)`
  thead {
    font-size: 16px;
  }

  tbody {
    font-size: 14px;
    color: #000000;
  }

  .deleted {
    background: #fcedee !important;

    &:hover > td {
      background: #fcedee !important;
    }
  }
`;

export const DateAndTime = styled.span`
  color: #7791b3;
`;

export const Status = styled.span`
  color: #606860;
`;

export const StyledSearch = styled(Search)({
  ".ant-input-group > .ant-input-group-addon:last-child": {
    left: 10,
    borderRadiusTopLeft: 0,
    borderRadiusBottomLeft: 0,
  },
  ".ant-input-group > .ant-input-affix-wrapper .ant-input-suffix span svg": {
    display: "none",
  },
  ".ant-input-group > .ant-input-affix-wrapper .ant-input-suffix span": {
    background: "var(--color-primary)",
    height: "13px",
    position: "relative",
    width: "3px",
    transform: "rotate(45deg)",
  },
  ".ant-input-group > .ant-input-affix-wrapper, .ant-input-group > .ant-input-group-addon .ant-input-search-button":
    {
      height: "50px",
      borderRadius: "3px !important",
    },
  ".ant-input-group > .ant-input-affix-wrapper .ant-input-suffix span:after": {
    content: "''",
    background: "var(--color-primary)",
    height: "3px",
    left: "-5px",
    position: "absolute",
    top: "5px",
    width: "14px",
  },
});
