import styled from "styled-components";
import CustomButton from "../CustomButton";

export const Base = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

export const ActionButton = styled(CustomButton)`
  margin-right: 15px;
  border-radius: 3px;
  color: ${(props) => (props.type === "primary" ? "#fff" : "#d7272d")};

  svg path {
    fill: ${(props) => (props.type === "primary" ? "#fff" : "#d7272d")};
  }

  :last-child {
    margin-right: 0;
  }
`;

export const IconKeyTextCont = styled("div")({
  display: "block",
  "> *": {
    verticalAlign: "middle",
  },
});

export const IconKey = styled("div")({
  display: "inline-block",
  marginRight: "0.5rem",
  svg: {
    width: "1.3rem",
  },
});
