import React, { useState } from "react";
import { FieldArray } from "formik";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CustomButton from "@/components/CustomButton";

import ModalComponent from "../modal-components";
import SectionTemplate from "../section-template";

interface Props {
  formikBag: any;
}

const Sections: React.FC<Props> = ({ formikBag }) => {
  const { values, errors, setFieldValue } = formikBag;

  const [modalComponent, setModalComponent] = useState(false);
  const [uploadComplete, setUploadStatus] = useState("");

  const handleModalComponent = (component, push) => {
    push(component);
    setModalComponent(false);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (fieldValueSetter) => {
    return (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      // const items = reorder(sectionData, result.source.index, result.destination.index);
      const items = reorder(values.sections, result.source.index, result.destination.index);

      fieldValueSetter("sections", items);
    };
  };

  const renderSections = ({ event, values, setFieldValue, errors }) => {
    return (
      <>
        <DragDropContext onDragEnd={onDragEnd(setFieldValue)}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} className="sections-wrapper">
                {/* {sectionData.map((component, index) => ( */}
                {values.sections.map((component, index) => (
                  <>
                    <Draggable
                      key={`dragKey_${index}`}
                      draggableId={`dragSectionId_${index}`}
                      index={index}
                    >
                      {(provided, snapshots) => {
                        return SectionTemplate(
                          component,
                          index,
                          setFieldValue,
                          event,
                          values,
                          uploadComplete,
                          setUploadStatus,
                          provided.innerRef,
                          provided,
                          errors.sections
                        );
                      }}
                    </Draggable>
                  </>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <CustomButton
          type="primary"
          className="add-section"
          style={{ width: "100%", fontSize: 13, fontWeight: 300 }}
          onClick={() => {
            setModalComponent(true);
          }}
        >
          <p style={{ margin: 0 }}>+ Add Section</p>
        </CustomButton>
        <ModalComponent
          visible={modalComponent}
          onChange={handleModalComponent}
          push={event.push}
          onCancel={() => setModalComponent(false)}
        />
      </>
    );
  };
  return (
    <FieldArray
      name="sections"
      render={(event) => renderSections({ event, values, setFieldValue, errors })}
    />
  );
};

export default Sections;
