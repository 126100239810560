import styled from "styled-components";

export const Container = styled("div")({
  background: "#fff",
  margin: "24px 0",
  padding: "24px 32px",
  display: "flex",
  borderRadius: "0.6rem",
  flexDirection: "column",
});
