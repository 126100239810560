import { CSSObject } from "styled-components";

export const typography = (style: string): CSSObject => ({
  fontSize: `var(--font-size-${style})`,
  // @ts-ignore
  fontWeight: `var(--font-weight-${style})`,
  lineHeight: `var(--line-height-${style})`,
  letterSpacing: `var(--letter-spacing-${style})`,
  // @ts-ignore
  textTransform: `var(--text-transform-${style})`,
});
