import { message, Row, Col, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";

import ContentContainer from "@/components/ContentContainer";
import CustomModal from "@/components/CustomModal";
import GoLogo from "../../../../../assets/images/callback-image-no-BG.png";
import { IconKey, IconKeyTextCont } from "@/components/ActionsMenu/styled";
import { EditPenIcon, PublishIcon, DeleteIcon, ArchiveIcon } from "../../../../../utilities/icons";
import { AnnouncementsI } from "../../../../../models/UpdatesAndAnnouncementsInterface";
import {
  GET_UPDATE_AND_ANNOUNCEMENT,
  UPDATE_UPDATES_AND_ANNOUNCEMENTS_STATUS,
} from "../../../graphql";
import { STATUS } from "../../../../../utilities/enums";
import {
  ActionButtons,
  ActionButtonsContainer,
  ActionButtonWrap,
  ArchivedLink,
  BorderTop,
  ImgPhoto,
  AnnouncementPageWrap,
  SectionWrap,
  Sectiontitle,
  Span,
  SpanBY,
  Subcontext,
  Subtitle,
} from "./styled";

const UpdatesAndAnnouncementsView = (): JSX.Element => {
  const { pageId }: { pageId: string } = useParams();
  const history = useHistory();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState<string>("");
  const [bodyMessage, setBodyMessage] = useState<string>("");
  const [bodyMessageSuccess, setBodyMessageSuccess] = useState<string>("");
  const [statusStr, setStatusStr] = useState<string>("");
  const [okTxt, setOkTxt] = useState<string>("OK");
  const [announcementData, setAnnouncementData] = useState<AnnouncementsI>();

  const [showActionButtons, setShowActionButtons] = useState(false);
  const [showArchiveBtn, setShowArchiveBtn] = useState(false);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [showEditBtn, setShowEditBtn] = useState(false);
  const [showPublishBtn, setShowPublishBtn] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [status, setStatus] = useState<string>("draft");
  const [modifyStatus] = useMutation(UPDATE_UPDATES_AND_ANNOUNCEMENTS_STATUS);

  const { loading, refetch, data } = useQuery(GET_UPDATE_AND_ANNOUNCEMENT, {
    skip: !pageId,
    fetchPolicy: "no-cache",
    variables: {
      id: pageId,
    },
  });

  useEffect(() => {
    if (data) {
      refetch();
      setAnnouncementData(data.getUpdateAndAnnouncementById);
      setStatus(data.getUpdateAndAnnouncementById.status);
    }
  }, [data, refetch]);

  useEffect(() => {
    if (announcementData) {
      const { status } = announcementData;
      setShowActionButtons(status !== "DELETED");
      setShowArchiveBtn(status !== "ARCHIVED" && status !== "DRAFT" && status !== "DELETED");
      setShowDeleteBtn(status !== "PUBLISHED" && status !== "DELETED");
      setShowEditBtn(status !== "DELETED");
      setShowPublishBtn(status !== "PUBLISHED" && status !== "DELETED");
      setIsArchived(status === "ARCHIVED");
    }
  }, [announcementData]);

  const onErrorImg = (e): void => {
    e.target.src = GoLogo;
  };

  const constructVariables = (updateAndAnnouncementId: string, status: STATUS): object => {
    return {
      updateAndAnnouncement_id: updateAndAnnouncementId,
      status: status,
    };
  };

  const updateStatus = async (id: string, status: STATUS): Promise<void> => {
    const input = constructVariables(id, status);

    try {
      const { data: res } = await modifyStatus({
        variables: {
          updateUpdateAndAnnouncementStatusInput: input,
        },
      });

      if (res.updateUpdateAndAnnouncementStatus) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const checkStatusStrOkModal = async (statusStr) => {
    if (statusStr === "published") {
      await updateStatus(pageId, STATUS.PUBLISHED);
      setBodyMessageSuccess("This announcement was successfully published.");
    }

    if (statusStr === "archived") {
      await updateStatus(pageId, STATUS.ARCHIVED);
      setBodyMessageSuccess("This announcement was successfully archived.");
    }
    if (statusStr === "deleted") {
      await updateStatus(pageId, STATUS.DELETED);
      setBodyMessageSuccess("This announcement was successfully deleted.");
    }
    setShowModal(false);
    setShowModalSuccess(true);
  };

  // Go to Edit page
  const handleEdit = () => {
    history.push(`/home-page-management/updates-and-announcements/edit-update/${pageId}`, {
      pageId,
    });
  };

  // Delete confirmation modal content
  const handleDelete = () => {
    setShowModal(true);
    setTitleMessage("Delete Updates And Announcements?");
    setBodyMessage(`Are you sure you want to delete ${announcementData?.title}?`);
    setStatusStr("deleted");
    setOkTxt("Delete");
  };

  // Archive confirmation modal content
  const handleArchived = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By archiving this article, it will not be visible in the website when deployed"
    );
    setStatusStr("archived");
    setOkTxt("Archive");
  };

  const handlePublish = (id): void => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By publishing this article, all information and documents uploaded in this article will be visible in the website when deployed"
    );
    setStatusStr("published");
    setOkTxt("Publish");
  };

  const archived = announcementData && (
    <Row>
      <ArchivedLink>
        <Subtitle>Archived Link</Subtitle>
        <Span>{announcementData.redirect_link}</Span>
      </ArchivedLink>
    </Row>
  );

  return (
    <>
      <Spin spinning={loading}>
        {announcementData !== undefined && (
          <AnnouncementPageWrap>
            <ActionButtonsContainer show={showActionButtons}>
              <Row>
                <Col span="12" offset="12" style={{ textAlign: "right" }}>
                  <ActionButtonWrap>
                    <ActionButtons show={showDeleteBtn} onClick={handleDelete}>
                      <IconKeyTextCont>
                        <IconKey>
                          <DeleteIcon style={{ color: "red" }} />
                        </IconKey>{" "}
                        Delete
                      </IconKeyTextCont>
                    </ActionButtons>
                    <ActionButtons type="primary" show={showEditBtn} onClick={handleEdit}>
                      <IconKeyTextCont>
                        <IconKey>
                          <EditPenIcon />
                        </IconKey>{" "}
                        Edit
                      </IconKeyTextCont>
                    </ActionButtons>
                    <ActionButtons type="primary" show={showArchiveBtn} onClick={handleArchived}>
                      <IconKeyTextCont>
                        <IconKey>
                          <ArchiveIcon />{" "}
                        </IconKey>{" "}
                        Archive
                      </IconKeyTextCont>
                    </ActionButtons>
                    <ActionButtons type="primary" show={showPublishBtn} onClick={handlePublish}>
                      <IconKeyTextCont>
                        <IconKey>
                          <PublishIcon />{" "}
                        </IconKey>{" "}
                        Publish
                      </IconKeyTextCont>
                    </ActionButtons>
                  </ActionButtonWrap>
                </Col>
              </Row>
            </ActionButtonsContainer>
            <SectionWrap>
              <ContentContainer>
                <Row justify="end">This announcement is currently {status.toUpperCase()}</Row>
                <Row>
                  <Col span={8}>
                    <Subtitle>Photo</Subtitle>
                    <ImgPhoto
                      src={announcementData.photo_url}
                      onError={onErrorImg}
                      alt="Announcement Image"
                    />
                  </Col>
                  <Col span={16}>
                    <Subcontext>
                      <Subtitle>Title</Subtitle>
                      <Span primary>{announcementData.title}</Span>
                    </Subcontext>
                    <Subcontext>
                      <Subtitle>Description</Subtitle>
                      <Span primary>{announcementData.short_description}</Span>
                    </Subcontext>
                    <Subcontext>
                      <Subtitle>Redirect Link</Subtitle>
                      <Span href={announcementData.redirect_link}>
                        {announcementData.redirect_link}
                      </Span>
                    </Subcontext>
                  </Col>
                </Row>
              </ContentContainer>
              <BorderTop />
              <ContentContainer>
                <Sectiontitle>Announcement Details</Sectiontitle>
                <Row>
                  <Col span={8}>
                    <Subtitle>Date/Time Created</Subtitle>
                    <Span primary>
                      {moment(announcementData.created_at).format("YYYY-MM-DD HH:mm:ss")}
                    </Span>
                    <SpanBY>by {announcementData.created_by}</SpanBY>
                  </Col>
                  <Col span={8}>
                    <Subtitle>Last Date/Time Edited</Subtitle>
                    <Span primary>
                      {moment(announcementData.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                    </Span>
                    <SpanBY>by {announcementData.updated_by}</SpanBY>
                  </Col>
                  <Col span={8}>
                    <Subtitle>Last Date/Time Deployed</Subtitle>
                    <Span primary>
                      {announcementData.deployed_at
                        ? moment(announcementData.deployed_at).format("YYYY-MM-DD HH:mm:ss")
                        : "-"}
                    </Span>
                    <SpanBY>
                      {announcementData.deployed_by ? `by ${announcementData.deployed_by}` : "-"}
                    </SpanBY>
                  </Col>
                </Row>
                {isArchived ? archived : null}
              </ContentContainer>
            </SectionWrap>
          </AnnouncementPageWrap>
        )}
      </Spin>

      {/* Confirmation Modal */}
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showModal}
        onOk={() => checkStatusStrOkModal(statusStr)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={okTxt}
      />

      {/* Success Modal */}
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon
        titleMessage="Success!"
        visible={showModalSuccess}
        onOk={() => setShowModalSuccess(false)}
        onCancel={() => setShowModalSuccess(false)}
        message={bodyMessageSuccess}
        okText={"OK"}
      />
    </>
  );
};

export default UpdatesAndAnnouncementsView;
