import * as yup from "yup";
import { ALPHANUM_PATTERN, URL_PATTERN, ALPHANUM_AND_SYMBOLS_PATTERN } from "../../../../../utilities/regex";

const imageSchema = yup
  .object()
  .shape({
    status: yup.string().matches(/done/, { message: "File is still uploading" }),
    url: yup.string(),
  })
  .nullable();

export const validationSchema = yup.object().shape({
  photo_url: imageSchema.required("This is a required field.").nullable(),
  title: yup
    .string()
    .trim()
    .matches(ALPHANUM_AND_SYMBOLS_PATTERN, "Invalid format.")
    .required("This is a required field.")
    .min(2, "Oops! enter at least 2 characters.")
    .max(70, "Maximum of 70 characters."),
  short_description: yup
    .string()
    .trim()
    .required("This is a required field.")
    .min(2, "Oops! enter at least 2 characters.")
    .max(150, "Maximum of 150 characters."),
  redirect_link: yup
    .string()
    .trim()
    .required("This is a required field.")
    .matches(URL_PATTERN, "Invalid format."),
});
