import React from "react";

import { StyledSwitchButtonContainer, StyledSwitchButton } from "./styled";

interface Props {
  name: string;
  onClick?: any;
  isStatusEnable?: boolean;
}

const CustomSwitch: React.FC<Props> = ({ name, onClick, isStatusEnable }) => {
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [isStatusEnable, setIsStatusEnable] = useState(false);

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  return (
    <>
      {/* <Switch name={name} onClick={showModal} />
      <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal> */}
      {/* <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal> */}
      <StyledSwitchButtonContainer>
        <StyledSwitchButton
          className={`custom-switch ${isStatusEnable ? "custom-switch-checked" : ""}`}
          role="switch"
          onClick={onClick}
          aria-checked={isStatusEnable}
          ant-click-animating={isStatusEnable}
          type="button"
        >
          <div className="custom-switch-handle"></div>
          <span className="custom-switch-inner" />
        </StyledSwitchButton>
      </StyledSwitchButtonContainer>
    </>
  );
};

export default CustomSwitch;
