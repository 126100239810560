import { useState, useEffect, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { message } from "antd";
import { upperCase } from "lodash";
import { Context } from "../../../../context";

import CustomDashboardList from "@/components/CustomDashboardList";
import CustomModal from "@/components/CustomModal";

import { activeColumns } from "./constants";
import { BrandsI, DetailsI } from "../../../../models/RHRInterface";

import { GET_BRANDS, MODIFY_BRAND_STATUS, REORDER_BRAND } from "../../graphql";
import { STATUS } from "../../../../utilities/enums";

const BrandManagementList = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const [isPublish, setIsPublish] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [titleMessage, setTitleMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [bodyMessageSuccess, setBodyMessageSuccess] = useState("");
  const [statusStr, setStatusStr] = useState("");
  const [brandId, setBrandId] = useState("");
  const [brandNameModal, setBrandNameModal] = useState("");
  const [okTxt, setOkTxt] = useState("OK");
  const [order, setOrder] = useState<boolean>(false);
  const [reOrder, setReOrder] = useState<DetailsI[]>([]);

  const {
    loading: brandLoading,
    refetch,
    data,
  } = useQuery(GET_BRANDS, {
    variables: {
      search: search,
    },
  });
  const {
    state,
    dispatch: { storeIsDeployed },
  } = useContext(Context);
  const [modifyBrandStatus] = useMutation(MODIFY_BRAND_STATUS);
  const [reOrderBrandDrag] = useMutation(REORDER_BRAND);
  const [dataBrands, setDataBrands] = useState<BrandsI[]>([]);
  const [handleBataBrands, sethandleDataBrands] = useState<BrandsI[]>([]);

  const handleSearch = (val) => {
    setSearch(val);
  };
  useEffect(() => {
    if (state?.isDeployed === true) {
      refetch();
      setDataBrands(data.getBrands.slice(0, 10));
      sethandleDataBrands(data.getBrands.slice(0, 10));
      storeIsDeployed(false);
    }
  }, [state]);

  useEffect(() => {
    if (data) {
      refetch();
      setDataBrands(data.getBrands.slice(0, 10));
      sethandleDataBrands(data.getBrands.slice(0, 10));
    }
  }, [data]);

  const fetchData = (callback) => {
    if (data) {
      callback(data.getBrands.slice(dataBrands.length).slice(0, 10));
    }
  };

  const handleAddBtn = () => {
    history.push(`${match.path}/add`);
    refetch();
  };

  const handleSelectedRow = (id, slug, brandName) => {
    history.push(`${match.path}/${slug}/${id}`, { id, slug });
  };

  const constructVariables = (brand_id: string, status: STATUS) => {
    return {
      variables: {
        brand_id: brand_id,
        status: upperCase(status),
      },
    };
  };

  const updateStatus = async (id: string, status: STATUS) => {
    try {
      const { data: res } = await modifyBrandStatus(constructVariables(id, status));

      if (res.updateBrandStatus) {
        refetch();
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const checkStatusStrOkModal = async (id, statusStr, brandNameModal) => {
    if (statusStr === "published") {
      await updateStatus(id, STATUS.PUBLISHED);
      setBodyMessageSuccess(`"${brandNameModal}" was successfully published.`);
    }

    if (statusStr === "archived") {
      await updateStatus(id, STATUS.ARCHIVED);
      setBodyMessageSuccess(`"${brandNameModal}" was successfully archived.`);
    }
    if (statusStr === "deleted") {
      await updateStatus(id, STATUS.DELETED);
      setBodyMessageSuccess(`"${brandNameModal}" was successfully deleted.`);
    }
    setShowModal(false);

    setShowModalSuccess(true);
  };

  const handlePublish = (id, brandName) => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage(
      "By Publishing this brand, Informations and documents uploaded in this brand will be visible in the website when deployed."
    );
    setStatusStr("published");
    setBrandNameModal(brandName);
    setBrandId(id);
    setOkTxt("Publish");
    setIsPublish(true);
  };

  const handleArchived = (id, brandName) => {
    setShowModal(true);
    setTitleMessage("Heads Up!");
    setBodyMessage("By archiving this brand, it will not be visible in the website when deployed");
    setStatusStr("archived");
    setBrandNameModal(brandName);
    setBrandId(id);
    setOkTxt("Archive");
    setIsPublish(false);
  };

  const handleDelete = (id, brandName) => {
    setShowModal(true);
    setTitleMessage("Delete Brand?");
    setBodyMessage(`Are you sure you want to delete "${brandName}?"`);
    setStatusStr("deleted");
    setBrandNameModal(brandName);
    setBrandId(id);
    setOkTxt("Delete");
    setIsPublish(false);
  };

  const handleKebabMenu = (menuItem: string, slug: string, id: string, brandName: string) => {
    if (menuItem === "edit") {
      history.push(`${match.path}/edit/${id}`, { pageId: id });
    }
    if (menuItem === "view") {
      history.push(`${match.path}/${slug}/${id}`, { pageId: id, slug });
    }
    if (menuItem === "publish") {
      handlePublish(id, brandName);
    }
    if (menuItem === "delete") {
      handleDelete(id, brandName);
    }
    if (menuItem === "archive") {
      handleArchived(id, brandName);
    }
  };

  const handleInfiniteOnLoad = () => {
    let dataList = data?.getBrands;

    fetchData((res) => {
      dataList = dataBrands.concat(res);
      setTimeout(() => {
        setDataBrands(dataList);
      }, 600);
    });
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    if (result.source.index !== result.destination.index) {
      setOrder(true);
      setShowModal(true);
      setOkTxt("Ok");
      setBodyMessage(`Are you sure you want to reorder brands?`);

      const items = reorder(data.getBrands, result.source.index, result.destination.index);
      setDataBrands(items);
    }
  };

  const updateBrandOrder = async () => {
    dataBrands.forEach((brand, index) => {
      if (brand.order !== index) {
        reOrder.push({ id: brand.id, order: index });
      }
    });

    try {
      const { data: res } = await reOrderBrandDrag({
        variables: {
          details: reOrder,
        },
      });

      if (res.updateBrandOrder) {
        refetch();
        setShowModal(false);
        setShowModalSuccess(true);
        setReOrder([]);
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const cancelReorder = () => {
    setShowModal(false);
    setDataBrands(handleBataBrands);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result: BrandsI[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  return (
    <>
      <CustomDashboardList
        loading={brandLoading}
        headerColumns={activeColumns}
        data={dataBrands}
        handleAddBtn={handleAddBtn}
        handleInfiniteOnLoad={handleInfiniteOnLoad}
        handleMenu={handleKebabMenu}
        handleSearch={handleSearch}
        hasMore={true}
        onDragEnd={onDragEnd}
        addLabelText="Brand"
        handleSelectedRow={handleSelectedRow}
        hasSearchResult={search !== "" && dataBrands.length > 0}
        searchPlaceholder="Search Brand Management"
      />
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage={titleMessage}
        visible={showModal}
        onOk={() =>
          order ? updateBrandOrder() : checkStatusStrOkModal(brandId, statusStr, brandNameModal)
        }
        onCancel={() => cancelReorder()}
        message={bodyMessage}
        okText={okTxt}
      />
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon
        titleMessage="Success!"
        visible={showModalSuccess}
        onOk={() => setShowModalSuccess(false)}
        onCancel={() => setShowModalSuccess(false)}
        message={bodyMessageSuccess}
        okText={isPublish ? "Great!" : "OK"}
      />
    </>
  );
};

export default BrandManagementList;
