import { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Spin, message } from "antd";
import { useHistory, useParams } from "react-router-dom";

import CustomModal from "@/components/CustomModal";
import Form from "./form";
import { GET_CMS_EDIT_USER, CREATE_CMS_USER, EDIT_CMS_USER, GET_ROLES } from "../../../graphql";
import { featuresLabel } from "./constants";
import { has, snakeCase } from "lodash";
import { Formik } from "formik";
import { validationSchema } from "./validations";

const CMSUserManagementForm = () => {
  const history = useHistory();
  const { pageId } = useParams();
  const { data: getRoles } = useQuery(GET_ROLES);

  const [createUser] = useMutation(CREATE_CMS_USER);
  const [editUser] = useMutation(EDIT_CMS_USER);
  const { data: pageData, loading } = useQuery(GET_CMS_EDIT_USER, {
    skip: !pageId,
    fetchPolicy: "no-cache",
    variables: {
      id: pageId || "",
    },
  });


  const [titleMessage, setTitleMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [reinitialize, setReinitialize] = useState(false);
  const [roleList, setRoleList] = useState([]);

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    company: "",
    position: "",
    access_role: "",
    password: "Hello!23",
    status: "ACTIVE",
  });

  const handleOkSuccess = () => {
    history.push("/user-role-management/cms-user-management");
  };

  useEffect(() => {
    let arrayList: any = [];

    if (getRoles) {
      const getRolesArr = getRoles.getRoles;
      getRolesArr.forEach((item, index) => {
          arrayList.push({ value: item.id, label: item.name });
      });
      setRoleList(arrayList);
    }
  }, [getRoles]);

  useEffect(() => {
    if (pageId && has(pageData, "getUserById")) {
      const data = pageData.getUserById;
      const fields: any = initialValues;

      Object.keys(data).forEach((key, index) => {
        if (key === "access_role") {
          let role_name = data[key];

          const result = roleList.find(obj => {
            return obj['label'] === role_name
          })

          if (result) {
            fields[snakeCase(key)] = result['value'];
          } else {
            fields[snakeCase(key)] = '';
          }
        } else {
          fields[snakeCase(key)] = data[key];
        }
        
      });

      setInitialValues(fields);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      setReinitialize(true);
    }
  }, [pageData]);

  useEffect(() => {
    if (reinitialize) {
      setReinitialize(false);
    }
  }, [reinitialize]);

  const handleSubmit = async (data) => {
    setLoader(true);
    try {
      let update = false;
      if (pageData && has(pageData, "getUserById")) {
        update = true;
      }

      if (update) {
        let finalData = {
          id: pageId 
        }

        Object.keys(data).forEach((key, index) => {
          if (key !== 'password' && key !== 'status' && key !== 'typename') {
            finalData[key] = data[key];
          }
        });
        await editUser({
          variables: {
            editUserInput: finalData,
          },
        });
      } else {
        await createUser({
          variables: {
            createUserInput: data,
          },
        });
      }

      setTimeout(() => {
        setLoader(false);
      }, 1000);

      setIsSuccess(true);
      setShowModal(true);
      setTitleMessage("Success!");
      setBodyMessage(
        update
          ? "Changes for this content was successfully saved."
          : "You have successfully added a new user!"
      );
    } catch (error: any) {
      if (error) {
        if (error.graphQLErrors && error.graphQLErrors[0]) {
          const errGraphqlRes = error.graphQLErrors[0].extensions.response;
          let errStr = "";

          if (errGraphqlRes) {
            if (errGraphqlRes?.message.length > 0) {
              errGraphqlRes?.message.forEach((item) => (errStr += item));
            } else errStr = errGraphqlRes?.message;
          }
          message.error(errStr);
        } else {
          setTitleMessage("Data not saved!");
          setBodyMessage("Kindly check your internet connection.");
          setShowModal(true);
        }
      }

      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  };

  return (
    <>
      <Spin spinning={loading || loader}>
        
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={reinitialize}
          onSubmit={async (values: any) => {
            await handleSubmit(values);
          }}
          render={(formikBag) => (
            <Form
              {...{
                formikBag,
                loader,
              }}
            />
          )}
        />
      </Spin>

      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isErrorIcon={!isSuccess}
        isSuccessIcon={isSuccess}
        titleMessage={titleMessage}
        visible={showModal}
        onOk={isSuccess ? handleOkSuccess : () => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={isSuccess ? "Great" : "OK"}
      />
    </>
  );
};

export default CMSUserManagementForm;
