export const activeColumns = [
  {
    title: "Guest ID",
    dataIndex: "guestId",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Platform",
    dataIndex: "platform",
  },
  {
    title: "Last Logged in",
    dataIndex: "lastLoggedIn",
  },
  {
    title: "Last Edited by",
    dataIndex: "lastEditedBy",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

export const dataTypes = [
  {
    label: "Mobile App User",
    value: "CustomerUser",
  },
  {
    label: "Anonymous Guest",
    value: "AnonymousGuest",
  },
];

export const actions = [
  {
    name: "View",
    checkStatus: null,
  },
];
