import React from "react";
import { Row, Col } from "antd";

import UploadMultipleImages from "@/components/UploadMultipleImages";

interface Props {
  formikBag: any;
}

const BannerFeatureImages: React.FC<Props> = ({ formikBag }) => {
  const { values, errors, setFieldValue } = formikBag;

  return (
    <Row gutter={20} style={{ marginTop: "2rem" }}>
      <Col span={24}>
        <label
          style={{
            lineHeight: "25.15px",
            height: 32,
            display: "inline-flex",
            position: "relative",
            alignItems: "center",
          }}
        >
          Brand Page Banner Feature Images*
        </label>
        <div>
          <UploadMultipleImages
            accept={"image/jpeg,image/jpg,image/png"}
            validate={values.banner_feature_images.length < 5}
            fileList={values.banner_feature_images}
            sizeLimit={5}
            onChange={(e) => {
              setFieldValue(`banner_feature_images`, e);
            }}
            errors={errors.banner_feature_images}
          />
          <label style={{ color: "var(--color-general-blue-gray)", fontSize: 14 }}>
            <span style={{ color: "var(--color-general-black)" }}>NOTE:</span> Maximum of 5 Images.
            Image must be in 16:9 ratio. Example 1920x1080 pixel. Format: JPG or PNG. Maximum file
            size of 5mb.
          </label>
        </div>
      </Col>
    </Row>
  );
};

export default BannerFeatureImages;
