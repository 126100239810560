import styled from "styled-components";
import { Button, Input } from "antd";
import { typography } from "../../../../../utilities/styles/theme";

export const ErrorTableMessage = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  color: red;
  font-size: 14px;
`;

export const InstructionLabel = styled("label")({
  ...typography("body-2"),
  color: "var(--color-secondary)",
});

export const Subtitle = styled.div`
  margin-bottom: 5px;
  color: #1b467f;
`;

export const StyledInput = styled(Input)`
  padding: 20px;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  margin-right: 15px;
  width: 140px;
  height: auto;
  padding: 16px 0;
`;
