import { useState, useEffect } from "react";
import { Formik } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import { Spin, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { has, snakeCase, isEmpty } from "lodash";

import CustomModal from "@/components/CustomModal";
import Form from "./form";

import { CREATE_UPDATE_HERO_BANNER, GET_HERO_BANNER } from "../../../graphql";

import { validationSchema } from "./validations";

const RhrHeroBannerForm = () => {
  const history = useHistory();
  const params = useParams();
  const [createUpdateBanner] = useMutation(CREATE_UPDATE_HERO_BANNER);
  const { data: pageData, loading } = useQuery(GET_HERO_BANNER, {
    skip: !params.pageId,
    fetchPolicy: "no-cache",
    variables: {
      id: params.pageId || "",
    },
  });

  const [brandName, setBrandName] = useState("");
  const [titleMessage, setTitleMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [reinitialize, setReinitialize] = useState(false);
  const [mobileBannerId, setMobileBannerId] = useState<string | null>(null);
  const [websiteBannerId, setWebsiteBannerId] = useState<string | null>(null);
  const [initialValues, setInitialValues] = useState({
    website_image: null,
    mobile_image: null,
    title: "",
    description: "",
    location: "",
    media_type: "image",
    status: "DRAFT",
  });

  const handleOkSuccess = () => {
    history.push("/home-page-management/rhr-hero-banner");
  };

  useEffect(() => {
    if (reinitialize) {
      setReinitialize(false);
    }
  }, [reinitialize]);

  useEffect(() => {
    if (params.pageId && has(pageData, "getHeroBannerById")) {
      const data = pageData.getHeroBannerById;
      const bName = pageData.getHeroBannerById.title;
      const fields: any = initialValues;

      const getWebMediatype = data.media_hero_banner
        .filter((mediaHeroBanner) => mediaHeroBanner.platform === "WEBSITE")
        .map((mediaHeroBanner) => mediaHeroBanner.media_type);
      const getWebBannerUrl = data.media_hero_banner
        .filter((mediaHeroBanner) => mediaHeroBanner.platform === "WEBSITE")
        .map((mediaHeroBanner) => mediaHeroBanner.url);
      const getMobileBannerUrl = data.media_hero_banner
        .filter((mediaHeroBanner) => mediaHeroBanner.platform === "MOBILE")
        .map((mediaHeroBanner) => mediaHeroBanner.url);

      Object.keys(data).forEach((key, index) => {
        if (key === "media_hero_banner") {
          fields["website_image"] =
            getWebBannerUrl.length > 0
              ? {
                  keyObj: getWebBannerUrl[0],
                  url: getWebBannerUrl[0],
                }
              : "";

          fields["mobile_image"] =
            getMobileBannerUrl.length > 0
              ? {
                  keyObj: getMobileBannerUrl[0],
                  url: getMobileBannerUrl[0],
                }
              : null;
        } else if (key === "media_type") {
          fields["media_type"] = getWebMediatype === "IMAGE" ? "image" : "video";
        } else {
          fields[snakeCase(key)] = data[key];
        }
      });

      const mobileHeroBanners = pageData.getHeroBannerById.media_hero_banner;
      const newMobileBannerId =
        mobileHeroBanners[0]?.platform === "MOBILE" ? mobileHeroBanners[0].id : null;
      const newWebsiteBannerId =
        mobileHeroBanners[1]?.platform === "WEBSITE"
          ? mobileHeroBanners[1].id
          : mobileHeroBanners[0].id;

      setBrandName(bName);
      setInitialValues(fields);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      setReinitialize(true);
      setMobileBannerId(newMobileBannerId);
      setWebsiteBannerId(newWebsiteBannerId);
    }
  }, [pageData]);

  const dataMapper = (values) => {
    let data: any = {
      heroBanner_details: {
        ...(!isEmpty(params.pageId) && { id: params.pageId }),

        title: values.title ? values.title.trim() : "",
        description: values.description ? values.description.trim() : "",
        location: values.location ? values.location.trim() : "",
      },
      media_hero_banner: [
        {
          ...(!isEmpty(websiteBannerId) && { id: websiteBannerId }),
          platform: "WEBSITE",
          url: values.website_image.url,
          media_type: values.media_type === "image" ? "IMAGE" : "VIDEO",
        },
        ...(values.mobile_image
          ? [
              {
                ...(!isEmpty(mobileBannerId) && { id: mobileBannerId }),
                platform: "MOBILE",
                url: values.mobile_image.url,
                media_type: "IMAGE",
              },
            ]
          : []),
      ],
    };

    return data;
  };

  const handleSubmit = async (values) => {
    let data = dataMapper(values);

    setLoader(true);
    try {
      let update = false;
      if (pageData && has(pageData, "getHeroBannerById")) {
        update = true;
      }
      await createUpdateBanner({
        variables: {
          data,
        },
      });

      setTimeout(() => {
        setLoader(false);
      }, 1000);

      setIsSuccess(true);
      setShowModal(true);
      setTitleMessage("Success!");
      setBodyMessage(
        update
          ? `Changes for ${brandName} was successfully saved.`
          : "You have successfully added a new content!"
      );
    } catch (error: any) {
      if (error) {
        if (error.graphQLErrors && error.graphQLErrors[0]) {
          const errGraphqlRes = error.graphQLErrors[0].extensions.response;
          let errStr = "";

          if (errGraphqlRes) {
            if (errGraphqlRes?.message.length > 0) {
              errGraphqlRes?.message.forEach((item) => (errStr += item));
            } else errStr = errGraphqlRes?.message;
          }
          message.error(errStr);
        } else {
          setTitleMessage("Data not saved!");
          setBodyMessage("Kindly check your internet connection.");
          setShowModal(true);
        }
      }

      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  };

  return (
    <>
      <Spin spinning={loading || loader}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={reinitialize}
          onSubmit={async (values: any) => {
            await handleSubmit(values);
          }}
        >
          {(formikBag) => (
            <Form
              {...{
                formikBag,
                brandName,
              }}
            />
          )}
        </Formik>
      </Spin>

      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isErrorIcon={!isSuccess}
        isSuccessIcon={isSuccess}
        titleMessage={titleMessage}
        visible={showModal}
        onOk={isSuccess ? handleOkSuccess : () => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        message={bodyMessage}
        okText={isSuccess ? "Great" : "OK"}
      />
    </>
  );
};

export default RhrHeroBannerForm;
