import * as yup from "yup";
import { URL_PATTERN } from "../../../../../utilities/regex";

const imageSchema = yup
  .object()
  .shape({
    status: yup.string().matches(/done/, { message: "File is still uploading" }),
    url: yup.string(),
  })
  .nullable();

export const validationSchema = yup.object().shape({
  media_type: yup.string().required(),
  website_image: yup.object().when("media_type", {
    is: (media_type) => media_type === "image",
    then: imageSchema.required("This is a required field.").nullable(),
    otherwise: imageSchema.nullable(),
  }),
  video_url: yup.string().when("media_type", {
    is: (media_type) => media_type === "video",
    then: yup
      .string()
      .trim()
      .required("This is a required field.")
      .matches(URL_PATTERN, "Invalid format."),
    otherwise: yup.string().nullable(),
  }),
  title: yup
    .string()
    .trim()
    .required("This is a required field.")
    .min(2, "Oops! enter at least 2 characters.")
    .max(30, "Maximum of 30 characters."),
  description: yup
    .string()
    .trim()
    .required("This is a required field.")
    .min(10, "Oops! enter at least 10 characters.")
    .max(50, "Maximum of 50 characters."),
  location: yup
    .string()
    .trim()
    .required("This is a required field.")
    .min(2, "Oops! enter at least 2 characters.")
    .max(30, "Maximum of 30 characters."),
});
