import styled from "styled-components";

export const StyledSwitchButtonContainer = styled.div`
  .custom-switch {
    margin: 0;
    padding: 0;
    color: #1b467f;
    font-size: 16px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    min-width: 44px;
    height: 22px;
    line-height: 22px;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.25);
    border: 0;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.2s;
    -webkit-user-select: none;
    user-select: none;

    > div.custom-switch-handle {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 18px;
      height: 18px;
      transition: all 0.2s ease-in-out;

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
        border-radius: 9px;
        box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
        transition: all 0.2s ease-in-out;
        content: "";
      }
    }

    > span.custom-switch-inner {
      display: block;
      margin: 0 7px 0 25px;
      color: #fff;
      font-size: 12px;
      transition: margin 0.2s;
    }
  }

  .custom-switch:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }

  .custom-switch:focus:hover {
    box-shadow: none;
  }

  // if switch is true
  .custom-switch-checked {
    background-color: #73c532;

    .custom-switch-handle {
      left: calc(100% - 18px - 2px) !important;
    }

    .custom-switch-inner {
      margin: 0 25px 0 7px !important;
    }
  }

  .custom-switch-checked:focus {
    box-shadow: 0 0 0 2px rgba(27, 70, 127, 0.2);
  }
`;

export const StyledSwitchButton = styled.button``;
