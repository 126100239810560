import React, { ReactChild, useState, useEffect } from "react";
import { Upload, Spin, message } from "antd";
import has from "lodash/has";
import axios from "axios";
import { DeleteFilled, PlusCircleFilled } from "@ant-design/icons";
import {
  Base,
  Wrapper,
  ButtonStyle,
  RemoveBtn,
  Container,
  WrapperPreview,
  FileListStyled,
  Note,
  NoteList,
  NoteListItem,
  UploadLabel,
} from "./styled";
import { convertFileToBase64 } from "../../utilities/convert-to-base64";
import XIcon from "../../assets/images/icons/ic_x_circle.svg";

let BASE_URL = `${process.env.REACT_APP_CONTENT_SERVICE}`;

interface Props {
  value: any;
  onChange: any;
  path?: string;
  className?: string;
  renameAddStr?: string;
  accept?: string;
  sizeLimit?: number;
  children?: React.ReactNode;
  noteMessage?: string | React.ReactChild | boolean;
  showUploadList?: boolean;
  noteMessageStyle?: object;
  notePlacing?: string;
  label?: string | ReactChild;
  ratio?: string;
  sampleRatio?: string;
  formatStr?: string;
  maxFileSize?: string | number;
  extraNoteBg?: string;
  hasNote?: boolean;
  errors?: any;
}

const UploadSingleFile: React.FC<Props> = ({
  value,
  onChange,
  path,
  className,
  accept,
  sizeLimit,
  children,
  noteMessage,
  showUploadList,
  noteMessageStyle,
  notePlacing,
  label,
  ratio,
  sampleRatio,
  formatStr,
  maxFileSize,
  extraNoteBg,
  hasNote,
  errors,
}) => {
  const [sourceImage, setSourceImage] = useState("");
  useEffect(() => {
    if (value) {
      setSourceImage(value.url || value.thumbUrl);
    }
  }, [value]);

  const imgOrVidElelement = (value) => {
    const regExp = /\.(gif|jpe?g|png|gif)$/i;
    const imgTypePattern = new RegExp(regExp);

    const imgFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    return (
      <>
        {imgTypePattern.test(sourceImage) || imgFileTypes.includes(value.type) ? (
          <img src={sourceImage} alt="img" />
        ) : (
          <video className="video-banner" src={sourceImage} controls />
        )}
      </>
    );
  };
  const previewFile = (value) => {
    return (
      <Spin spinning={value.status === "uploading"}>
        <WrapperPreview className={"uploaded-file"}>
          {(value.url || value.thumbUrl) && imgOrVidElelement(value)}
        </WrapperPreview>
      </Spin>
    );
  };

  const handleOnChange = async ({ file, fileList: list }) => {
    const limit = !!sizeLimit ? sizeLimit : 49;
    const isLt2M = file.size / 1024 / 1024 < limit;

    if (file.status !== "uploading" && has(file, "response")) {
      const base64 = await convertFileToBase64(file.originFileObj);
      const urlLink = file.response.url;
      file["url"] = urlLink;
      file["keyObj"] = urlLink.substring(urlLink.substring(urlLink.lastIndexOf("/") + 1));
      file["thumbUrl"] = base64;
      onChange(file);
    }

    if (file.status === "uploading" && isLt2M) {
      file["thumbUrl"] = "";
      file["url"] = "";
      file["keyObj"] = "";
      onChange(file);
    }
  };

  const UploadImage = () => {
    const node = (
      <ButtonStyle type="dashed" className="upload-button">
        {children ? (
          children
        ) : (
          <div>
            <PlusCircleFilled
              style={{ fontSize: "1.75rem", color: "var(--color-general-blue-gray)" }}
            />
          </div>
        )}
      </ButtonStyle>
    );

    if (value && showUploadList) {
      return <Spin spinning={value.status === "uploading"}>{node}</Spin>;
    }

    return node;
  };

  const handleBeforeUpload = (file) => {
    // Check file type
    let acceptedFile = accept || "";
    let errMessageFile;
    let fileTypes;
    let acceptedFileList = acceptedFile.split(",");

    if (acceptedFile.includes("image")) {
      fileTypes = acceptedFileList;
      if (acceptedFile === "image/png") {
        errMessageFile = "PNG";
      } else {
        errMessageFile = "JPG/PNG";
      }
      BASE_URL = `${process.env.REACT_APP_CONTENT_SERVICE}/upload`;
    }

    if (acceptedFile.includes("pdf")) {
      fileTypes = ["application/pdf"];
      errMessageFile = "PDF";
      BASE_URL = `${process.env.REACT_APP_CONTENT_SERVICE}/upload/pdf`;
    }

    if (acceptedFile.includes("video")) {
      fileTypes = ["video/mp4"];
      errMessageFile = "MP4";
      BASE_URL = `${process.env.REACT_APP_CONTENT_SERVICE}/upload/video`;
    }

    if (!fileTypes.includes(file.type)) {
      message.error(`You can only upload ${errMessageFile} file type`);
      return false;
    }

    const limit = !!sizeLimit ? sizeLimit : 49;
    const isLt2M = file.size / 1024 / 1024 < limit;

    if (!isLt2M) {
      message.error("File is too big");
      return false;
    }
    return file;
  };

  const handleRequest = (option) => {
    let formData = new FormData();
    formData.append("file", option.file);

    axios({
      method: "POST",
      headers: { authorization: `Bearer ${sessionStorage.getItem("act")}` || null },
      url: BASE_URL,
      data: formData,
    })
      .then((res) => {
        option.onSuccess(res.data, option.file);
      })
      .catch((err) => {
        console.log("error uploadsingleimg", err);
      });
  };

  const renderList = () => {
    let element: any;
    if (value && value.status !== "uploading") {
      element = (
        <FileListStyled>
          <h4>{value.name}</h4>
          <a onClick={() => onChange(null)}>
            <DeleteFilled style={{ color: "var(--color-secondary)" }} />
          </a>
        </FileListStyled>
      );
    }
    return element;
  };

  return (
    <Base>
      <Wrapper>
        <div className={notePlacing === "right" ? "note-right" : ""}>
          <div>
            {label && <UploadLabel>{label}</UploadLabel>}
            <Container className={`${className} ${errors && errors ? "hasError" : ""}`}>
              {!showUploadList && value && previewFile(value)}
              <Upload
                action={BASE_URL}
                showUploadList={showUploadList ? true : false}
                customRequest={handleRequest}
                onChange={(event) => handleOnChange(event)}
                beforeUpload={handleBeforeUpload}
                accept={accept}
              >
                {!value && UploadImage()}
                {value && showUploadList && UploadImage()}
              </Upload>

              {!showUploadList && value && (value.thumbUrl || value.url) && (
                <RemoveBtn className={"remove-file"} onClick={() => onChange(null)}>
                  <img src={XIcon} alt="X" />
                </RemoveBtn>
              )}
            </Container>
          </div>
          {errors && errors ? (
            <>
              {typeof errors === "string" ? (
                <div style={{ color: "#ff4d4f", fontSize: 14 }}>{errors}</div>
              ) : null}
            </>
          ) : null}
          {hasNote && (
            <div
              style={{
                ...noteMessageStyle,
              }}
            >
              <>
                <Note>NOTE:</Note>
                <NoteList>
                  {ratio && (
                    <NoteListItem>
                      Image must be in {ratio} ratio
                      <br />
                      {sampleRatio && <>Ex.: {sampleRatio} pixels</>}
                    </NoteListItem>
                  )}
                  {formatStr && <NoteListItem>Format: {formatStr}</NoteListItem>}
                  {maxFileSize && (
                    <NoteListItem>Maximum file size of {maxFileSize}mb.</NoteListItem>
                  )}
                  {extraNoteBg && <NoteListItem>{extraNoteBg}</NoteListItem>}
                </NoteList>{" "}
              </>
            </div>
          )}
        </div>
        {showUploadList && renderList()}
      </Wrapper>
    </Base>
  );
};

export default UploadSingleFile;
