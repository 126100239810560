import * as yup from "yup";
import { MOBILE_NUM_PATTERN } from "../../../../../utilities/regex";

export const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .required("This is a required field."),
  last_name: yup
    .string()
    .trim()
    .required("This is a required field."),
  email: yup.string().trim().required("This is a required field.").email("Invalid email."),
  // contact_number: yup.object().shape({
  //   input: yup.string().when(["country_code"], {
  //     is: (country_code) => country_code === "+63",
  //     then: yup
  //       .string()
  //       .trim()
  //       .max(12, "Mobile no. must not exceed 11 characters.")
  //       .matches(MOBILE_NUM_PATTERN, "Invalid format.")
  //       .required("This is a required field."),
  //     otherwise: yup
  //       .string()
  //       .trim()
  //       .matches(/^[0-9]*$/, "Invalid format.")
  //       .required("This is a required field."),
  //   }),
  //   country_code: yup.string().default("+63"),
  // }),
  contact_number: yup
    .string()
    .trim()
    .max(12, "Mobile no. must not exceed 11 characters.")
    .matches(MOBILE_NUM_PATTERN, "Invalid format.")
    .required("This is a required field."),
  company: yup
    .string()
    .trim()
    .required("This is a required field."),
  position: yup
    .string()
    .trim()
    .required("This is a required field."),
  access_role: yup
    .string()
    .trim()
    .required("This is a required field."),
});
