import { useState } from "react";
import { FormItem, Input } from "formik-antd";
import { Row, Col } from "antd";
import { Field } from "formik";
import { DeleteFilled } from "@ant-design/icons";

import CustomSwitch from "@/components/CustomSwitch";
import CustomModal from "@/components/CustomModal";

import ReorderIcon from "../../../../../assets/images/icons/ic_reorder.svg";

import {
  WrapperForm,
  MediaContainer,
  SectionHeader,
  DeleteButton,
  IconKeyTextCont,
  IconKey,
} from "./styled";

const SectionEight = ({
  fields,
  index,
  setFieldValue,
  fieldsArrayEvent,
  values,
  uploadComplete,
  setUploadStatus,
  providedRef,
  provided,
  errors,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isStatusEnable, setIsStatusEnable] = useState<boolean>(
    values.sections[index].fields.section_status && values.sections[index].fields.section_status
  );
  const enableText =
    values.sections[index].fields.section_status &&
    values.sections[index].fields.section_status === true
      ? "Enabled"
      : "Disabled";

  const handleOk = () => {
    setIsStatusEnable(!isStatusEnable);
    setFieldValue(`sections[${index}].fields.section_status`, false);
    setIsModalVisible(false);
  };

  return (
    <>
      <WrapperForm ref={providedRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <div style={{ color: "var(--color-general-blue-gray)", fontWeight: 600 }}>
            <IconKeyTextCont>
              <IconKey>
                <img src={ReorderIcon} alt="Reorder" />
              </IconKey>{" "}
              <span>Reorder</span>
            </IconKeyTextCont>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <div style={{ display: "flex", gap: "1em" }}>
              <label>Status:</label>
              <div>
                <div style={{ display: "flex", gap: "1em" }}>
                  <Field name={`sections[${index}].fields.status`}>
                    {() => (
                      <CustomSwitch
                        name={`sections[${index}].fields.status`}
                        isStatusEnable={
                          values.sections[index].fields.section_status &&
                          values.sections[index].fields.section_status
                        }
                        onClick={
                          values.sections[index].fields.section_status &&
                          values.sections[index].fields.section_status === true
                            ? () => setIsModalVisible(true)
                            : () => setFieldValue(`sections[${index}].fields.section_status`, true)
                        }
                      />
                    )}
                  </Field>
                  <span
                    style={{
                      color:
                        values.sections[index].fields.section_status &&
                        values.sections[index].fields.section_status
                          ? "var(--color-green)"
                          : "var(--color-primary)",
                    }}
                  >
                    {enableText}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <DeleteButton
                type="text"
                onClick={() => {
                  uploadComplete !== "uploading" && fieldsArrayEvent.remove(index);
                }}
              >
                <DeleteFilled /> Delete
              </DeleteButton>
            </div>
          </div>
        </div>
        <div style={{}}>
          <SectionHeader>Featured Video</SectionHeader>
        </div>
        <Row gutter={40}>
          <Col span={8}>
            <MediaContainer
              label="Video Thumbnail*"
              value={values.sections[index].fields.image}
              accept={"image/jpeg,image/jpg,image/png"}
              noteMessage={".jpeg, .jpg, .png (max 50 MB)"}
              onChange={(e) => {
                setUploadStatus(e?.status);
                setFieldValue(`sections[${index}].fields.image`, e);
              }}
              hasNote
              ratio="8:5"
              sampleRatio="1440 x 900"
              maxFileSize={5}
              sizeLimit={5}
              formatStr="Format: JPG or PNG"
              errors={errors && errors.length > 0 && errors[index]?.fields?.image}
            />
          </Col>
          <Col span={16}>
            <FormItem
              label="Facebook link or Embedded Youtube link*"
              name={`sections[${index}].fields.link`}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                name={`sections[${index}].fields.link`}
                placeholder="e.g https://www.youtube.com/embed/Y1qZ-66QhvM"
              />
            </FormItem>
          </Col>
        </Row>
      </WrapperForm>
      <CustomModal
        closable={false}
        maskClosable={false}
        isWarningIcon
        titleMessage="Heads up!"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        message="By Disabling, this section will not be visible once published."
        okText="Yes, Disable"
        okButtonWidth="8em"
        cancelButtonWidth="8em"
      />
    </>
  );
};

export default SectionEight;
