import { Alert } from "antd";
import styled from "styled-components";
import UploadSingleFile from "../../../../../components/UploadSingleFile";
import { typography } from "../../../../../utilities/styles/theme";

export const HeaderSubtitle = styled("p")({
  ...typography("body-1"),
  marginBottom: "1em",
  color: "var(--color-primary)",
});

export const StyledUploadImage = styled(UploadSingleFile)`
  width: 100%;
  height: 220px;
  display: block;
  overflow: hidden;
  background: #fff;

  .ant-upload {
    display: block;

    .upload-button {
      height: 220px;
      width: 100%;
      display: block;
    }
  }

  .upload-button {
    height: 220px;
    width: 100%;
    display: block;
  }

  .uploaded-file {
    height: 220px;
    width: 100%;

    img {
      height: 220px;
      width: 100%;
    }
  }

  .remove-file {
    top: 4px;
    right: 4px;
  }
`;

export const ErrorMessageContainer = styled("div")({
  color: "#ff4d4f",
  fontSize: "14px",
  position: "absolute",
});

export const IconKey = styled("div")({
  display: "inline-block",
  marginRight: "0.5rem",
});

export const InstructionLabel = styled("label")({
  ...typography("body-2"),
  color: "var(--color-secondary)",
});

export const AlertContainer = styled(Alert)`
  margin-bottom: 40px;
`;

export const AlertDescription = styled.span`
  font-size: 14px;
  font-style: italic;
  color: #a2a2a2;
`;

export const AlertMessage = styled.div`
  font-size: 16px;
  color: #050e19;
  display: flex;
  justify-content: space-between;
`;
