import styled from "styled-components";
import { Button, Collapse, Tabs } from "antd";

import UploadSingleFile from "../../../../../components/UploadSingleFile";
import { typography } from "../../../../../utilities/styles/theme";

const { Panel } = Collapse;

export const ErrorMessageContainer = styled("div")({
  color: "#ff4d4f",
  fontSize: "14px",
  position: "absolute",
});

export const HeaderSubtitle = styled("p")({
  ...typography("body-1"),
  marginBottom: "1em",
  color: "var(--color-primary)",
});

export const StyledUploadImage = styled(UploadSingleFile)`
  width: 338px;
  height: 220px;
  display: block;
  overflow: hidden;
  background: #fff;

  .ant-upload {
    display: block;

    /* .upload-button {
      height: 220px;
      width: 100%;
      display: block;
    } */
  }

  .upload-button {
    height: 220px;
    width: 100%;
    display: block;
  }

  .uploaded-file {
    height: 220px;
    width: 100%;

    img {
      height: 220px;
      width: 100%;
    }
  }

  .remove-file {
    top: 4px;
    right: 4px;
  }
`;

export const IconKey = styled("div")({
  display: "inline-block",
  marginRight: "0.5rem",
});

export const InstructionLabel = styled("label")({
  ...typography("body-2"),
  color: "var(--color-secondary)",
});

export const Subheader = styled("div")`
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 24px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 0.3rem;
  background-color: #e8edf2;

  &:focus {
    background-color: #e8edf2;
  }
`;

export const StyledPanel = styled(Panel)`
  border-bottom: 0 !important;

  & .ant-collapse-header {
    font-size: 20px;
    font-weight: 700;
    padding: 12px 0px !important;
  }

  & .ant-collapse-content .ant-collapse-content-box {
    padding: 0;
  }
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0px;
  }

  & .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
    background-color: #7791b3;
    color: #ffffff;
    border-radius: 5px 5px 0 0;
    text-align: center;
    /* padding: 16px 24px; */

    &.ant-tabs-tab-active {
      background-color: #1b467f;
      & .ant-tabs-tab-btn {
        color: #ffffff;
      }
    }
  }
`;

export const DeleteButton = styled(Button)`
  margin-top: 30px;
  background-color: #e8edf2;
  border-color: #e8edf2;

  &:hover,
  &:focus {
    background-color: #e8edf2;
  }
`;

export const AddSchedule = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-left: 10px;
  }
`;
